<template>
  <div class="bg-gray-800 w-full flex flex-col stage-height">
    <div class="p-4 flex justify-between bg-gray-900 items-center">
      <h1 class="text-xl text-white">RFPs</h1>
    </div>
    <div class="flex flex-col rounded pb-4 relative">
      <div class="flex p-4 items-center justify-between sticky top-0">
        <div class="text-crewfareGreen">
          <strong class="rounded-full bg-black px-3 py-1">{{
            rfpsFound
          }}</strong>
          RFPs
        </div>
      </div>
      <div class="text-white">
        <List :rfps="rfps" />
      </div>
    </div>
  </div>
</template>

<script>
import List from "@/components/dashboard/List.vue";
import { firestore } from "@/utils/firebase";
import { getDocs, query, collection, orderBy, where } from "firebase/firestore";
import { statusList } from "@/utils/rfp.jsx";
import {getHotelsIdsByGroup} from "@/utils/hotel";

export default {
  components: {
    List,
  },
  data() {
    return {
      tab: 0,
      rfpsFull: [],
      rfps: [],
      loading: false,
      status: "All",
      search: "",
      archived: false,
    };
  },
  async mounted() {
    this.getData();
    document.title = `Launchpad - Crewfare - RFPs`;
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
    rfpsFound() {
      return this.rfps.length;
    },
  },
  methods: {
    async getData() {
      const hotel_ids = await getHotelsIdsByGroup();
      let qRfps = query(
        collection(firestore, "rfp_hotel"),
        orderBy("updated_at", "desc")
      );
      const rfps = await getDocs(qRfps);
      let rfpsList = rfps.docs.filter(
        (rfp) =>
          statusList.includes(rfp.data().status.toLowerCase()) &&
          hotel_ids.includes(rfp.data().hotel_id)
      );
      this.rfps = rfpsList;
    },
  },
};
</script>
