import { createRouter, createWebHistory } from 'vue-router';
import Invite from '@/views/Invite.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import ForgotPassword from '@/views/ForgotPassword.vue';
import { search } from '@/views/search';
import MyAccount from '@/views/MyAccount.vue';
import PageNotFound from '@/views/PageNotFound.vue';
import { rfps } from '@/views/rfps';
import { rfp } from '@/views/rfp';
import { users } from '@/views/user';
import { hotel } from '@/views/hotel';
import { group } from '@/views/group';
import { nso } from '@/views/nso';
import { messages } from '@/views/message';
import { notifications } from '@/views/notification';
import { files } from '@/views/files';
import Dashboard from '@/layouts/Dashboard.vue';
import List from '@/views/rfps/List.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: Dashboard,
      meta: {
        view: 'RFPs',
      },
      children: [
        {
          path: '',
          name: 'default',
          component: List,
        },
      ],
    },
    {
      path: '/forgot-password',
      name: 'forgotPassword',
      component: ForgotPassword,
    },
    {
      path: '/invite/:accountId',
      name: 'invite',
      component: Invite,
    },
    {
      path: '/reset-password',
      name: 'resetPassword',
      component: ResetPassword,
    },
    {
      path: '/my-account',
      name: 'myAccount',
      component: MyAccount,
    },
    ...notifications,
    ...search,
    ...rfp,
    ...rfps,
    ...messages,
    ...users,
    ...hotel,
    ...group,
    ...nso,
    ...files,
    { path: '/:pathMatch(.*)*', component: PageNotFound },
  ],
});

export default router;
