import { rfpInformaticIcons } from './utils/rfpInformaticIcons';

const hotelInviteEmailContent = (attendees: number, nights: number, venue: string, roomsPerNight: number) => {
  return `<tr>
  <td style="width: 100%;border-radius: 8px;background: rgba(34, 197, 94, 0.04);border: 1px dashed rgba(34, 197, 94, 1);padding: 40px 24px;text-align:center; ">
    <div style="font: bold 24px Poppins, Helvetica, Arial;color: #292929;">Your RFP is waiting to be reviewed.</div>
    <div style="margin-top: 24px;margin-bottom:40px;font: bold 14px Poppins, Helvetica, Arial;color: #292929;">
    We’re inviting the ##hotel_name## to be a partner hotel for our ##name## taking place from ##formatedDate##.
    </div>
    ${rfpInformaticIcons(attendees, nights, venue, roomsPerNight)}
  </td>

</tr>
<tr>
  <td height="24px"></td>
</tr>
<tr>
  <td style="width: 100%;border-radius: 8px;background: rgba(255, 209, 102, 0.04);border: 1px dashed #FFD166;padding: 48px 24px;text-align:center; ">
    <div style="font: 20px Poppins, Helvetica, Arial;color: #292929;">Response due date</div>
    <div style="font: bold 32px Poppins, Helvetica, Arial;color: #292929;">##responseDueDateFormated##</div>
  </td>

</tr>
<tr>
  <td height="24px"></td>
</tr>
<tr>
  <td style="width: 100%;border-radius: 8px;background: #F5F5F5;padding:24px;text-align: center;">
    <div style="font: 14px Poppins, Helvetica, Arial;color: #292929;">Please click the link below to review the proposal details and submit your bid.</div>
    <a href="##link##"
      style="border-radius: 8px;font: bold 16px Poppins, Helvetica, Arial;display:block;color: #fff;background: #4323FF;text-align: center;width: 90px;margin: 24px auto 0;text-decoration: none;padding: 16px 18px;">Bid Link</a>
    <div style="margin-top:24px;font: 14px Poppins, Helvetica, Arial;color: #292929;">Be sure to answer it by ##responseDueDateFormated##</div>
    <div style="margin-top:24px;font: 14px Poppins, Helvetica, Arial;color: #292929;">Click <a href="##link##" style="color: #4323FF;text-decoration:none;">here</a> to change or add who receives this RFP.</div>
    <div style="margin-top:24px;font: 14px Poppins, Helvetica, Arial;color: #292929;">If you have any questions, please reply to this email to reach our team.</div>
  </td>
</tr>
<tr>
  <td height="24px"></td>
</tr>
<tr>
  <td style="width: 100%;border-radius: 8px;background: #F5F5F5;padding:24px;text-align: left;">
    <div style="font: 14px Poppins, Helvetica, Arial;color: #808080;">Crewfare is the next generation of travel software, focusing on bringing people together for the experiences they love.</div>

    <div style="margin-top:24px;font: 14px Poppins, Helvetica, Arial;color: #808080;">We work with over 100+ events globally in all different categories, from the biggest music festivals to iconic sporting events and more! Get to know us more through our deck <a href="https://docsend.com/view/jga3cha99iq2f6b6" style="color: #808080;text-decoration: underline" target="_blank">HERE</a>.</div>
  </td>
</tr>`;
};

const hotelInviteSubject = 'Crewfare is inviting you to be a hotel partner for ##name##';

export { hotelInviteEmailContent, hotelInviteSubject };
