<template>
  <div>
    <div class="fixed top-0 right-0 left-0 bottom-0" @click="closeDialog"></div>
    <div class="overlay">
      <div
        class="bg-gray-900 shadow-md rounded-lg flex min-w-[598px] flex-col items-center relative text-gray-200"
      >
        <div class="px-4 pt-4 flex justify-between items-center gap-4 w-full">
          <p class="text-2xl">Contract History</p>
          <span
            @click="closeDialog"
            class="cursor-pointer top-2 right-2 text-red-200 hover:text-red-400 material-symbols-rounded"
          >
            close
          </span>
        </div>
        <div class="w-full max-h-[400px] overflow-auto pl-4">
          <FwbTimeline v-if="contractHistory?.length>0">
            <ContractHistoryItem
              v-for="(item, index) in contractHistory"
              :key="index"
              :item="item"
            />
          </FwbTimeline>
          <div class="w-full min-h-[300px] flex items-center justify-center" v-else>
            There is no uploaded contract.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FwbTimeline } from "flowbite-vue";
import ContractHistoryItem from "./ContractHistoryItem.vue";

export default {
  props: ["contractHistory"],
  components: {
    FwbTimeline,
    ContractHistoryItem,
  },
  methods: {
    closeDialog() {
      this.$emit("hideGroupDialog");
    },
  },
};
</script>
