export const renderCenterIcon = (iconUrl: string, text: string, extraPadding = 0, iconWidth = 100) => {
  return `
    <td width="${iconWidth}" valign="top">
    <table style="margin: 0 auto;">
        <tr>
        <td valign="center" align="center" style="min-width: 60px;height:60px;background: #4323FF;border-radius:40px;">
            <img src="${iconUrl}" width="25px" ${extraPadding ? `style="padding-top:${extraPadding}px"` : ''} />
        </td>
        </tr>
    </table>
    <table style="margin: 0 auto;">
        <tr>
        <td valign="center" align="center">
            <div style="text-align:center; word-break: break-word;margin-top:8px;font: bold 14px Poppins, Helvetica, Arial;color: #292929;">${text}</div>
        </td>
        </tr>
    </table>
    </td>
    <td width="20px"></td>
    `;
};
