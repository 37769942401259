<template>
    <div v-if="loading">
        <Loading size="text-[30px]" />
    </div>
    <CreateRFPDialogListItem
        v-else
        v-for="(rfp, index) of rfps"
        :key="index"
        :rfp="rfp"
    />
    <Pagination
    :actual="currentPage"
    :max="per_page"
    :total="total"
    @togglePage="togglePage"
  />
</template>
<script>
import { RFPStatus } from "@crewfare/commons/src/model";
import CreateRFPDialogListItem from "./CreateRFPDialogListItem.vue";
import Pagination from "@/components/default/Pagination.vue";
import { rfpsApi } from "@/utils/apis/rfpsApi";
import Loading from "../default/Loading.vue";

export default{
    data(){
        return{
            search: "",
            rfps: [],
            loading: false,
            currentPage: 0,
            per_page: 20,
            total: 0,
            signals: {
                        list: {
                        controller: new AbortController(),
                        count: 0,
                        }
                    }
        };
    },
    computed: {
        account() {
            return this.$store.state.account;
        },
    },
    mounted() {
        this.listRFP(this.signals.list.count, this.signals.list.controller.signal);
    },
    methods: {
        async listRFP(listCount, signal){
            this.loading = true;
            console.log("Listing for count", listCount)
            let rfpOptions = {
                per_page: this.per_page,
                page: this.currentPage,
                statusFilters: JSON.stringify([RFPStatus.SENT]),
                search: this.search,
            }
            const rfps = await rfpsApi.list(rfpOptions, signal).then((res) => res.data||[]);
            if(listCount == this.signals.list.count){
                console.log("Result came for count and is lates", listCount)
                this.rfps = rfps?.rfps||[];
                this.total = rfps?.total||0;
                this.loading = false;
                this.loading = false;
            }
            else{
                console.log("Result came for count and is old", listCount)
            }
        },
        async togglePage(page) {
            this.currentPage = page;
            this.signals.list.count++;
            this.signals.list.controller.abort();
            this.signals.list.controller = new AbortController();
            this.listRFP(this.signals.list.count, this.signals.list.controller.signal);
        },
    },
    components: { CreateRFPDialogListItem, Pagination, Loading },
};
</script>