<template>
  <tr v-if="rfp">
    <td class="text-sm px-4 py-2 capitalize">
      <div class="text-sm flex justify-center gap-2 capitalize flex-col">
        <div>
          <span class="text-gray-200 mr-2">From</span>
          <span class="font-medium">{{ formatedStartDate }}</span>
        </div>
        <div>
          <span class="text-gray-200 mr-2">To</span>
          <span class="font-medium">{{ formatedEndDate }}</span>
        </div>
      </div>
    </td>
    <td class="text-sm px-4 capitalize">{{ cutoffDateFormatted }}</td>
    <td class="text-sm p-4">{{ rfp.data().name }}</td>
    <td class="text-sm p-4">
      <a
        v-if="rfp.data().contractMethod === 'external'"
        :href="`${downloadLink}`"
        target="_blank"
        class="text-crewfareGreen"
        >Contract</a
      >
      <router-link
        v-else
        :to="{
          name: 'rfpContract',
          params: { rfp: rfp.id },
        }"
        class="text-crewfareGreen"
        >Contract</router-link
      >
    </td>
    <td class="text-sm p-4">
      <SendMessage :rfp="rfp" />
    </td>
  </tr>
</template>

<script>
import moment from "moment";
import SendMessage from "./SendMessage.vue";
import FieldFile from "./FieldFile.vue";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { getMomentFromFirebaseDate } from "@/utils/dateUtils";

export default {
  components: {
    SendMessage,
    FieldFile,
  },
  props: ["rfp"],
  data() {
    return {
      apiUrl: import.meta.env.VITE_API_URL,
    };
  },
  computed: {
    downloadLink() {
      let url;
      const file = this.rfp.data().url ?? "";
      if (file.indexOf('firebasestorage') > 0) {
        const storage = getStorage();
        const fileName = file.split("?")[0];
        const storageRef = ref(storage, fileName);
        getDownloadURL(storageRef).then(async (url) => {
          url = url;
        });
      } else {
        url = file;
      }
      return url
    },
    cutoffDateFormatted() {
      if (!this.rfp.data().cutoffDate) return "";
      return getMomentFromFirebaseDate(this.rfp.data().cutoffDate).format(
        "MMM. DD, YYYY"
      );
    },
    formatedStartDate() {
      if (!this.rfp.data().startDate) return "";
      const startDate = this.rfp.data().startDate;
      return getMomentFromFirebaseDate(startDate).format("ddd MMM. DD, YYYY");
    },
    formatedEndDate() {
      if (!this.rfp.data().endDate) return "";
      const endDate = this.rfp.data().endDate;
      return getMomentFromFirebaseDate(endDate).format("ddd MMM. DD, YYYY");
    },
  },
};
</script>
