import Form from './Form.vue';
import Single from './Single.vue';
import View from '@/components/default/View.vue';
import ContactForm from './contacts/Form.vue';
import ContactList from './contacts/List.vue';

export const group = [
  {
    path: '/group',
    name: 'group',
    component: View,
    meta: {
      view: 'Group',
    },
    children: [
      {
        path: '',
        name: 'hotelsGroups',
        component: Single,
      },
      {
        path: 'hotel/add',
        name: 'groupHotelAdd',
        component: Form,
      },
      {
        path: 'hotel/:id/settings',
        name: 'groupHotelEdit',
        component: Form,
      },
      {
        path: 'hotel/:id/contacts',
        name: 'groupHotelsContacts',
        children: [
          {
            path: '',
            name: 'groupHotelsContactsList',
            component: ContactList,
          },
          {
            path: 'add',
            name: 'groupHotelContactsAdd',
            component: ContactForm,
          },
          {
            path: ':contactId/edit',
            name: 'groupHotelContactsEdit',
            component: ContactForm,
          },
        ],
      },
    ],
  },
];
