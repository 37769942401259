<template>
  <div>
    <button
      @click="showDialog = true"
      class="text-xs relative px-4 font-normal print:px-0 text-crewfareGreen border rounded border-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900 py-1"
    >
    Download RFP Resume
    </button>
    <div
      v-if="showDialog"
      class="fixed bottom-0 right-0 z-[200] rounded-lg left-0 top-0 bg-gray-300 bg-opacity-40 flex items-center justify-center"
    >
      <div
        class="bg-gray-900 rounded flex flex-col gap-4 items-center w-full max-w-[900px] relative text-white"
      >
        <div class="flex w-full flex-col gap-4">
          <div
            class="text-xl text-white p-4 py-3 leading-10 flex justify-between"
          >
            <span class="flex items-center gap-2"> Accepted Terms </span>
            <div class="flex gap-4">
              <button @click="download" class="green-button-small flex gap-2">
                <span
                  v-if="loading"
                  class="material-symbols-rounded animate-spin text-[14px]"
                >
                  autorenew
                </span>
                Download RFP Resume
              </button>
              <button
                @click="showDialog = false"
                class="material-symbols-rounded hover:text-crewfareGreen"
              >
                close
              </button>
            </div>
          </div>
          <div
            ref="rfpResume"
            class="overflow-auto max-h-[400px] flex flex-col gap-2 text-base"
          >
            <strong class="px-4 text-xl mb-2">{{ rfp.name }}</strong>

            <p class="px-4" style="margin-top: 12px">
              Property name: {{ rfp.hotel_name }}
            </p>
            <p class="px-4" style="margin-top: 12px">
              Name of client (sourcing): Crewfare
            </p>
            <p class="px-4" style="margin-top: 12px">
              Date downloaded: {{ todayFormated }}
            </p>
            <div
              v-if="rfp.attrition"
              class="px-4"
              style="margin-top: 12px"
            >
              Currency:
              <strong>{{ rfp.defaultCurrency || "$ USD" }}</strong>
            </div>
            <div
              v-for="(roomItem, index) in rfp.roomList"
              :key="index"
              class="flex gap-2 px-4"
              style="margin-top: 12px"
            >
              <div class="flex flex-col gap-2">
                <div>
                  Date: <strong>{{ roomItem.date }}</strong>
                </div>
                <div v-for="(room, roomIndex) in roomItem.rooms">
                  {{ room.amount }} {{ room.type }} -
                  {{ rfp.defaultCurrency || "$ USD" }} ${{ room.value }}
                </div>
              </div>
            </div>
            <strong class="px-4" style="margin-top: 12px">Occupancy</strong>
            <div class="px-4" v-for="room in rfp.roomTypes">
              {{ room.name }} - Occuppancy: {{ room.occupancy }} - Value:
              {{ rfp.defaultCurrency || "$ USD" }} ${{ room.additional }}
            </div>
            <div class="grid grid-cols-3 gap-4 p-4 w-full">
              <div v-if="rfp.attrition" style="margin-top: 12px">
                Attrition:
                <strong>{{ rfp.attrition }}</strong>
              </div>
              <div v-if="rfp.cutoffDate" style="margin-top: 12px">
                Cut-Off Date:
                <strong>{{ cutoffDateFormatted }}</strong>
              </div>
              <div
                v-if="rfp.commission"
                class="flex flex-col gap-2"
                style="margin-top: 12px"
              >
                <span>
                  Commission(%):
                  <strong>{{ rfp.commission }}</strong>
                </span>
                <div v-if="rfp.commission === 'Non Commissionable'">
                  Commission Amount:
                  <strong>{{ rfp.commissionAmount }}</strong>
                </div>
              </div>
              <div v-if="rfp.iata" style="margin-top: 12px">
                IATA #:
                <strong>{{ rfp.iata }}</strong>
              </div>
              <div
                v-if="rfp.rebate"
                class="flex flex-col gap-2"
                style="margin-top: 12px"
              >
                <span>
                  Rebate: <strong>{{ rfp.rebate }}</strong>
                </span>
                <span>
                  Rebate Type: <strong>{{ rfp.rebateType }}</strong>
                </span>
              </div>
              <div
                v-if="rfp.groupDateHonored"
                class="flex flex-col gap-2"
                style="margin-top: 12px"
              >
                <div>
                  Group Rate Honored:
                  <strong>{{ rfp.groupDateHonored }}</strong>
                </div>
                <div v-if="rfp.groupDateHonored === 'No'">
                  <div>
                    <span class="flex px-2 items-center justify-center">$</span>
                    <span>
                      {{ rfp.groupDateHonoredPreRate }}
                    </span>
                  </div>
                  <div>
                    <span class="flex px-2 items-center justify-center">$</span>
                    <span>
                      {{ rfp.groupDateHonoredPostRate }}
                    </span>
                  </div>
                </div>
                <div>
                  <div v-if="rfp.groupDateHonoredPre">
                    Days Pre Event:
                    <strong>{{ rfp.groupDateHonoredPreDays }}</strong>
                  </div>
                  <div v-if="rfp.groupDateHonoredPost">
                    Days Post Event
                    <strong>{{ rfp.groupDateHonoredPostDays }}</strong>
                  </div>
                </div>
              </div>
              <div v-if="rfp.compRooms" style="margin-top: 12px">
                Comp Rooms:
                <strong>{{ rfp.compRooms }}</strong>
              </div>
              <div v-if="rfp.suiteUpgrades" style="margin-top: 12px">
                Suite Upgrades:
                <strong>{{ rfp.suiteUpgrades }}</strong>
              </div>
              <div v-if="rfp.rewardPoints" style="margin-top: 12px">
                Reward Points:
                <strong>{{ rfp.rewardPoints }}</strong>
              </div>
              <div v-if="rfp.staffDiscount" style="margin-top: 12px">
                Staff Rooms:
                <strong>{{ rfp.staffRooms }}</strong>
              </div>
              <div
                v-if="rfp.staffDiscount"
                class="flex flex-col gap-2"
                style="margin-top: 12px"
              >
                <div>
                  Staff Discount:
                  <strong>{{ rfp.staffDiscount }}</strong>
                </div>
                <div v-if="rfp.staffDiscount === 'Other'">
                  Staff Discount Amount:
                  <strong>{{ rfp.staffDiscountAmount }}</strong>
                </div>
              </div>
              <div
                v-if="rfp.resortFee"
                class="flex flex-col gap-2"
                style="margin-top: 12px"
              >
                <div>
                  Resort Fee:
                  <strong>{{ rfp.resortFee }}</strong>
                </div>
                <div v-if="rfp.resortFee === 'Resort Fee'">
                  Resort Fee Amount:
                  <strong>{{ rfp.resortFeeAmount }}</strong>
                </div>
              </div>
              <div
                v-if="rfp.billing"
                class="flex flex-col gap-2"
                style="margin-top: 12px"
              >
                <div>
                  Reservation Submission:
                  <strong>{{ rfp.reservationSubmission }}</strong>
                </div>
              </div>
              <div
                v-if="rfp.billing"
                class="flex flex-col gap-2"
                style="margin-top: 12px"
              >
                <div>
                  Billing:
                  <strong>{{ rfp.billing }}</strong>
                </div>
                <div v-if="rfp.billing === 'Other'">
                  Other: <strong>{{ rfp.billingsOther }}</strong>
                </div>
              </div>
              <div
                v-if="rfp.incidentals"
                class="flex flex-col gap-2"
                style="margin-top: 12px"
              >
                <div>
                  Incidentals:
                  <strong>{{ rfp.incidentals }}</strong>
                </div>
                <div v-if="rfp.incidentals === 'Other'">
                  Other: <strong>{{ rfp.incidentalsOther }}</strong>
                </div>
              </div>
              <div
                v-if="rfp.wifi"
                class="flex flex-col gap-2 w-full"
                style="margin-top: 12px"
              >
                <div>
                  Wi-Fi:
                  <strong>{{ rfp.parking }}</strong>
                </div>
                <div v-if="rfp.wifi === 'Not included'">
                  Price:
                  <strong>
                    {{ rfp.wifi_price }}
                  </strong>
                </div>
              </div>
              <div
                v-if="rfp.parking"
                class="flex flex-col gap-2 w-full"
                style="margin-top: 12px"
              >
                <div>
                  Parking:
                  <strong>{{ rfp.parking }}</strong>
                </div>
                <div v-if="rfp.parking === 'Not included'">
                  Price:
                  <strong>
                    {{ rfp.parkingOther }}
                  </strong>
                </div>
              </div>
              <div
                v-if="rfp.breakfast"
                class="flex flex-col gap-2 w-full"
                style="margin-top: 12px"
              >
                <div>
                  Breakfast:
                  <strong>{{ rfp.breakfast }}</strong>
                </div>
                <div v-if="rfp.breakfast === 'Not included'">
                  Price:
                  <strong>
                    {{ rfp.breakfastOther }}
                  </strong>
                </div>
              </div>
              <div
                v-if="rfp.otherFees"
                class="flex flex-col gap-2 w-full"
                style="margin-top: 12px"
              >
                <div>
                  Other Fees:
                  <strong>{{ rfp.otherFees }}</strong>
                </div>
                <div v-if="rfp.otherFees === 'Yes'">
                  Price:
                  <strong>
                    {{ rfp.otherFeesAmount }}
                  </strong>
                </div>
              </div>
            </div>
            <div v-if="rfp.terms" class="p-4 flex flex-col gap-1">
              <div>Additional Terms (Optional)</div>
              <div v-html="rfp.terms"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMomentFromFirebaseDate } from "@/utils/dateUtils";
import moment from "moment";
export default {
  props: ["rfp"],
  data() {
    return {
      loading: false,
      showDialog: false,
    };
  },
  computed: {
    todayFormated() {
      return moment().format("ddd MMM. DD, YYYY");
    },
    cutoffDateFormatted() {
      if (!this.rfp.cutoffDate) return "";
      return getMomentFromFirebaseDate(this.rfp.cutoffDate).format("D-M-Y");
    },
  },
  methods: {
    async download() {
      this.loading = true;
      const dataContract = {
        name: `${this.$route.params.rfp}${Math.floor(Math.random() * 100000)}`,
        content: this.$refs.rfpResume.innerHTML,
      };
      const resp = await fetch(`${import.meta.env.VITE_API_URL}/pdf`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContract),
      }).then((response) => response.json());
      const link = document.createElement("a");
      link.setAttribute("href", `${import.meta.env.VITE_API_URL}${resp.url}`);
      link.setAttribute("target", "_blank");
      link.setAttribute("download", true);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.loading = false;
    },
  },
};
</script>
