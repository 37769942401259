<template>
  <div
    v-if="rfp"
    class="bg-gray-800 w-full flex flex-col stage-height text-white"
  >
    <div class="p-4 flex justify-between bg-gray-900 items-center">
      <div class="flex gap-4 h-[42px] items-center">
        <router-link
          :to="{ name: 'rfpsRemindersList', params: { id: rfp.id } }"
          class="material-symbols-rounded hover:text-crewfareGreen"
        >
          chevron_left
        </router-link>
        <h1 class="text-xl">Reminders - {{ title }}</h1>
      </div>
    </div>
    <form
      @submit.prevent="save"
      class="flex flex-col gap-4 text-white w-full p-4"
    >
      <div class="flex gap-4">
        <span
          class="green-button"
          :class="isInternal && 'green-button-active'"
          @click="toggleInternal()"
        >
          Internal emails
        </span>
        <span
          class="green-button"
          :class="isHotel && 'green-button-active'"
          @click="toggleHotel()"
        >
          Hotel emails
        </span>
      </div>
      <div class="flex flex-col gap-1">
        <label class="text-white font-bold">Status</label>
        <select v-model="status" class="w-full bg-gray-700 rounded px-4">
          <option>Select status</option>
          <option
            :value="statusItem"
            v-for="statusItem in statusList"
            class="capitalize"
          >
            {{ statusItem }}
          </option>
        </select>
      </div>
      <div class="flex flex-col gap-1 w-full">
        <label class="text-white font-bold">Days after status change</label>
        <input
          type="text"
          v-model="days"
          class="w-full bg-gray-700 rounded px-4"
        />
      </div>
      <div class="flex flex-col gap-1 w-full">
        <label class="text-white font-bold">Content</label>
        <Editor v-model="content" />
      </div>
      <div class="flex gap-4 mt-4 justify-between">
        <router-link
          :to="{ name: 'rfpsRemindersList', params: { id: rfp.id } }"
          class="cancel-button"
        >
          Cancel
        </router-link>
        <button :disabled="!valid || loading" class="green-button">
          <span
            v-if="loading"
            class="material-symbols-rounded animate-spin flex items-center"
          >
            autorenew
          </span>
          <span v-else> Save </span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import router from "@/router";
import { firestore } from "@/utils/firebase";
import { addDoc, setDoc, doc, getDoc, collection } from "firebase/firestore";

export default {
  components: {
    Editor,
  },
  data() {
    return {
      reminder: {},
      rfp: null,
      error: null,
      loading: false,
      content: "",
      days: 0,
      isHotel: false,
      isInternal: true,
      type: "",
      status: "",
      statusList: [
        "accepted by hotel",
        "accepted by crewfare",
        "bid opened",
        "progress saved",
        "bid viewed",
        "sent",
        "crewfare counter",
        "bid submited",
        "hotel countered",
      ],
    };
  },
  computed: {
    title() {
      return this.$route.params.reminder_id ? "Edit" : "New";
    },
    valid() {
      return false;
    },
    accountLogged() {
      return this.$store.state.account;
    },
  },
  async mounted() {
    const rfpRef = doc(firestore, "rfps", this.$route.params.id);
    this.rfp = await getDoc(rfpRef);
    if (this.$route.params.reminder_id) {
      const reminderRef = doc(
        firestore,
        "rfp_reminders",
        this.$route.params.reminder_id
      );
      this.reminder = await getDoc(reminderRef);
      this.status = this.account.status;
      this.type = this.account.type;
      this.isHotel = this.account.isHotel;
      this.isInternal = this.account.isInternal;
      this.days = this.account.days;
      this.content = this.account.content;
    }
    document.title = `Launchpad - Crewfare - Reminder - ${this.title}`;
  },
  methods: {
    toggleHotel() {
      this.isHotel = true;
      this.isInternal = false;
    },
    toggleInternal() {
      this.isHotel = false;
      this.isInternal = true;
    },
    async save() {
      this.error = null;
      if (!this.valid) return;
      this.loading = true;

      if (this.$route.params.reminder_id) {
        await setDoc(
          doc(firestore, "rfp_reminders", this.$route.params.reminder_id),
          {
            ...this.reminder.data(),
            status: this.status,
            days: this.days,
            isHotel: this.isHotel,
            isInternal: this.isInternal,
            content: this.content,
            updated_at: new Date(),
          }
        );
        this.$store.commit("setToast", {
          content: `<p>Reminder updated</p>`,
        });
      } else {
        await addDoc(collection(firestore, "rfp_reminders"), {
          status: this.status,
          days: this.days,
          content: this.content,
          isHotel: this.isHotel,
          isInternal: this.isInternal,
          updated_at: new Date(),
          created_at: new Date(),
          rfp_id: this.$route.params.id,
        });
        this.$store.commit("setToast", {
          content: `<p>Reminder added</p>`,
        });
      }
      router.push({ name: "rfpsRemindersList" });
      this.loading = false;
    },
  },
};
</script>
