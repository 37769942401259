<template>
  <div v-if="rfp" class="overlay">
    <div
      class="bg-gray-900 rounded flex flex-col gap-4 items-center w-full max-w-[450px] p-4 relative text-white"
    >
      <div class="flex w-full flex-col gap-4">
        <div class="flex w-full justify-between">
          <p class="text-xl">Forward this RFP</p>
          <span
            @click="close"
            class="cursor-pointer text-red-400 material-symbols-rounded"
          >
            close
          </span>
        </div>
        <div class="flex flex-col gap-2">
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">Name</label>
            <input
              type="text"
              v-model="name"
              class="w-full bg-gray-700 rounded px-4"
            />
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">Email</label>
            <input
              type="text"
              v-model="email"
              class="w-full bg-gray-700 rounded px-4"
            />
          </div>
          <div class="flex justify-between items-center">
            <span class="text-gray-200" @click="close">Cancel</span>
            <button
              class="green-button gap-2"
              :disabled="!valid || loading"
              @click="save"
            >
              <span
                v-if="loading"
                class="material-symbols-rounded animate-spin text-[12px]"
              >
                autorenew
              </span>
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { forwardRFP } from "@/utils/rfp.jsx";

export default {
  props: ["rfp"],
  computed: {
    valid() {
      return this.email.length > 0 && this.name.length > 0;
    },
  },
  data() {
    return {
      message: false,
      name: "",
      email: "",
      loading: false,
    };
  },
  methods: {
    async save() {
      if (this.loading) return;
      this.loading = true;
      await forwardRFP(this.rfp, this.email, this.name);
      this.email = "";
      this.name = "";
      this.$store.commit("setToast", {
        content: `<p>You just sent 1 RFP</p>`,
      });
      this.$emit("getData");
      this.$emit("closeDialog");
      this.loading = false;
    },
    close() {
      this.$emit("closeDialog");
    },
  },
};
</script>
