<template>
  <div
    id="bg-login"
    class="bg-black min-h-[100vh] bg-center bg-no-repeat bg-cover flex items-center"
  >
    <div class="max-w-[548px] w-full mx-auto px-6 pt-16 pb-6">
      <div class="flex flex-col gap-12">
        <img src="/logo.svg" class="h-[60px]" />
        <div v-if="reseted" class="rounded-3xl bg-[#23262F]">
          <div class="max-w-[400px] mx-auto p-6 md:p-11 flex flex-col gap-4">
            <p class="text-white text-center text-2xl">Password Reset</p>
            <p class="text-neutral-400 text-center text-sm">Your password has been successfully reset. Please enter your information below to log in to your account</p>
            <form @submit.prevent="doLogin" class="flex flex-col gap-4">
              <div class="flex flex-col gap-3">
                <label for="emailInput" class="text-white text-base">
                  Email
                </label>
                <input
                  id="emailInput"
                  class="px-3 leading-12 bg-[#2F2F31] border-0 text-white"
                  type="text"
                  required
                  v-model="email"
                  placeholder="Email"
                />
              </div>
              <div class="flex flex-col gap-3">
                <label for="passwordInput" class="text-white text-base">
                  Password
                </label>
                <div class="relative flex items-center">
                  <input
                    v-if="showPasswordLogin"
                    id="passwordInput"
                    class="px-3 w-full leading-12 bg-[#2F2F31] border-1 border-[#2F2F31] text-white"
                    :class="error && 'border-[#FF6A55]'"
                    type="text"
                    required
                    v-model="passwordLogin"
                    placeholder="Password"
                  />
                  <input
                    v-else
                    id="passwordInput"
                    class="px-3 w-full leading-12 bg-[#2F2F31] border-1 border-[#2F2F31] text-white"
                    :class="error && 'border-[#FF6A55]'"
                    type="password"
                    required
                    v-model="passwordLogin"
                    placeholder="Password"
                  />
                  <div class="absolute right-3">
                    <label
                      for="toggleShow"
                      class="text-[#A3A3A3]"
                    >
                      <input
                        v-model="showPasswordLogin"
                        type="checkbox"
                        id="toggleShow"
                        class="opacity-0 absolute"
                      />
                      <font-awesome-icon
                      class="cursor-pointer"
                        :icon="['fas', showPasswordLogin ? 'eye' : 'eye-slash']"
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div class="flex justify-end">
                <a href="/forgot-password" class="text-white text-sm">
                  Forgot your password
                </a>
              </div>
              <p
                v-if="error"
                v-html="message"
                class="text-red-600 mt-2 px-2 text-center text-sm max-w-[420px]"
              ></p>
              <button
                :disabled="!valid"
                class="bg-[#4323FF] rounded-lg text-center text-white leading-10"
              >
                {{ loading ? "Loading..." : "Log in" }}
              </button>
            </form>
          </div>
        </div>
        <div v-else class="rounded-3xl bg-[#23262F]">
          <div class="max-w-[400px] mx-auto p-6 md:p-11 flex flex-col gap-4">
            <p class="text-white text-center text-2xl">
              Set New Password
            </p>
            <p class="text-neutral-400 text-center text-sm">Your new password must be different from any previously used passwords.</p>
            <p v-if="invalid && !reseted" class="my-6 text-neutral-400 text-center">
              <span class="text-red-600">Your code is invalid.</span><br />
              You can request a new one
              <router-link
                to="/forgot-password"
                class="underline hover:text-sky-600"
                >clicking here</router-link
              >
              or log in with your old password
              <router-link to="/" class="underline hover:text-sky-600"
                >clicking here</router-link
              >.
            </p>
            <form
              v-else
              @submit.prevent="doReset"
              class="flex flex-col gap-4 mt-4"
            >
              <div class="flex flex-col gap-3">
                <label for="passwordInput" class="text-white text-base">
                  Password
                </label>
                <div class="relative flex items-center">
                  <input
                    v-if="showPassword"
                    id="passwordInput"
                    class="px-3 w-full leading-12 bg-[#2F2F31] border-0 text-white"
                    type="text"
                    required
                    v-model="password"
                    placeholder="Password"
                  />
                  <input
                    v-else
                    id="passwordInput"
                    class="px-3 w-full leading-12 bg-[#2F2F31] border-0 text-white"
                    type="password"
                    required
                    v-model="password"
                    placeholder="Password"
                  />
                  <div class="absolute right-3">
                    <label
                      for="toggleShow"
                      class="text-[#A3A3A3]"
                    >
                      <input
                        v-model="showPassword"
                        type="checkbox"
                        id="toggleShow"
                        class="opacity-0 absolute"
                      />
                      <font-awesome-icon
                      class="cursor-pointer"
                        :icon="['fas', showPassword ? 'eye' : 'eye-slash']"
                      />
                    </label>
                  </div>
                </div>
              </div>
              <p class="text-xs text-neutral-400">
                Minimum of 6 character password
              </p>
              <div class="flex flex-col gap-3">
                <label for="passwordConfirmInput" class="text-white text-base">
                  Re-enter Password
                </label>
                <div class="relative flex items-center">
                  <input
                    v-if="showPassword"
                    id="passwordInput"
                    class="px-3 w-full leading-12 bg-[#2F2F31] border-0 text-white"
                    type="text"
                    required
                    v-model="confirmPassword"
                    placeholder="Re-enter Password"
                  />
                  <input
                    v-else
                    id="passwordInput"
                    class="px-3 w-full leading-12 bg-[#2F2F31] border-0 text-white"
                    type="password"
                    required
                    v-model="confirmPassword"
                    placeholder="Re-enter Password"
                  />
                  <div class="absolute right-3">
                    <label
                      for="toggleShow"
                      class="text-[#A3A3A3]"
                    >
                      <input
                        v-model="showPassword"
                        type="checkbox"
                        id="toggleShow"
                        class="opacity-0 absolute"
                      />
                      <font-awesome-icon
                      class="cursor-pointer"
                        :icon="['fas', showPassword ? 'eye' : 'eye-slash']"
                      />
                    </label>
                  </div>
                </div>
              </div>
              <button
                :disabled="!valid"
                class="bg-[#4323FF] rounded-lg text-center text-white leading-10"
              >
                {{ loading ? "Submiting..." : "Submit" }}
              </button>
            </form>
              <div class="flex justify-center">
                <a href="/" class="text-neutral-400 text-sm">
                  <font-awesome-icon
                    class="mr-2"
                    :icon="['fas', 'arrow-left']" />
                  Back to log in
                </a>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { signInWithEmailAndPassword, confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
import { auth, firestore } from "@/utils/firebase";
import {
  doc,
  collection,
  setDoc,
  where,
  or,
  query,
  getDocs,
} from "firebase/firestore";
import { sendEmail } from "@/utils/emails";
import { passwordUpdatedEmailContent, passwordUpdatedSubject } from "@/emails/password-updated";

export default {
  name: "Login",
  data: () => {
    return {
      password: "",
      passwordLogin: "",
      confirmPassword: "",
      done: false,
      error: false,
      message: "",
      loading: false,
      done: false,
      showPassword: false,
      showPasswordLogin: false,
      invalid: false,
      reseted: false,
      email: "",
    };
  },
  computed: {
    valid() {
      return (
        this.password.length >= 6 && this.password === this.confirmPassword
      );
    },
  },
  created() {
    const code = this.$route.query.oobCode;
    verifyPasswordResetCode(auth, code)
      .then((email) => {
        this.email = email;
        this.invalid = false;
      })
      .catch(() => {
        this.invalid = true;
      });
  },
  methods: {
    doReset() {
      this.loading = true;
      this.error = false;
      this.message = "";
      const code = this.$route.query.oobCode;
      confirmPasswordReset(auth, code, this.password)
        .then(() => {
          this.$store.commit("setToast", {
            content: `<p>Password updated! You can log in now, using your new password.</p>`,
          });
          this.loading = false;
          this.reseted = true;
          sendEmail({
            to: this.email,
            template: "emails.new-default",
            subject: passwordUpdatedSubject,
            content: passwordUpdatedEmailContent,
          });
          this.$router.push("/");
        })
        .catch((e) => {
          this.loading = false;
          this.message =
            "Something went wrong updating your password, try again later";
          this.error = true;
        });
    },
    doLogin() {
      this.loading = true;
      this.error = false;
      this.message = "";
      signInWithEmailAndPassword(auth, this.email, this.passwordLogin)
        .then(async (result) => {
          try {
            const user = result.user;
            this.$store.commit("setUser", user);
            const qAccounts = query(
              collection(firestore, "accounts"),
              or(
                where("owner", "==", user.uid),
                where("email", "==", this.email)
              )
            );
            const accountDocs = await getDocs(qAccounts);
            if (accountDocs.empty) {
              this.message =
                "Invalid login credentials.<br/> Please try again or contact support if you continue to have issues.";
              this.error = true;
            } else {
              const account = accountDocs.docs[0];
              await setDoc(doc(firestore, "accounts", account.id), {
                ...account.data(),
                owner: user.uid,
                last_login: new Date(),
              });
              this.$router.push("/rfps")
            }
          } catch (e) {
            console.log(e);
            this.loading = false;
            this.message = "Account not found or incorrect password";
            this.error = true;
          }
        })
        .catch((e) => {
          this.loading = false;
          this.message = "Account not found or incorrect password";
          this.error = true;
        });
    },
  },
};
</script>