<template>
  <div class="px-6 py-3 bg-[#23262F]">
    <div class="flex justify-between">
      <Breadcrumb icon="hotel" :items="['Hotels']" />

      <div class="flex items-center gap-4">
        <button @click="chainMessage" class="green-button gap-2">
          Chain message
        </button>
        <router-link :to="{ name: 'nsoHotelAdd' }" class="green-button">
          Add hotel
        </router-link>
        <button @click="exportHotels" class="green-button gap-2">
          <span class="material-symbols-rounded"> download </span>
          Export Hotels
        </button>
        <button @click="$refs['fileUpload'].click()" class="green-button gap-2">
          <span
            class="material-symbols-rounded"
            :class="importing && 'animate-spin'"
          >
            {{ importing ? "autorenew" : "upload" }}
          </span>
          {{ importing ? `Importing Hotels` : "Import Hotels" }}
        </button>
        <input
          type="file"
          ref="fileUpload"
          id="fileUpload"
          @change="importHotels"
          class="cursor-pointer fixed -left-[100vw]"
        />
      </div>
    </div>
    <div class="bg-[#141416] p-4 mt-6 flex flex-col gap-4 rounded-lg">
      <div class="flex flex-col gap-2 rounded pb-4 relative">
        <div
          class="flex items-center py-2 bg-[#141416] justify-between sticky top-0 z-30"
        >
          <div class="flex gap-2 items-center text-crewfareGreen">
            <strong class="rounded-full bg-gray-700 px-3 py-1">{{
              hotelsFound
            }}</strong>
            Hotels
          </div>
          <FiltersNSO
            v-if="hotels.length > 0"
            :filteredHotels="filteredHotels"
            @filter="filter"
          />
        </div>
        <div v-if="!loading" class="mt-4">
          <div v-if="!selectedState" class="flex flex-col gap-4">
            <div v-for="(state, index) in stateList" :key="index">
              <div
                @click="toggleState(state)"
                class="border rounded-full py-3 px-4 text-white flex justify-between items-center cursor-pointer hover:bg-white hover:text-gray-900"
              >
                <span class="text-xl">
                  {{ state.name }} ({{ state.count }})
                </span>

                <font-awesome-icon :icon="['fas', 'chevron-right']" />
              </div>
            </div>
          </div>
          <div v-else>
            <div
              @click="toggleState(null)"
              class="flex cursor-pointer justify-between items-center"
            >
              <span class="text-xl text-crewfareGreen flex gap-2 items-center">
                <span class="material-symbols-rounded hover:text-crewfareGreen">
                  chevron_left
                </span>
                {{ selectedState.name }} ({{ selectedState.count }})
              </span>
            </div>
            <div class="text-white">
              <table class="w-full py-2">
                <thead class="py-2 text-white border-b border-gray-700">
                  <tr>
                    <th class="px-4 py-2 font-regular text-left">Hotel Name</th>
                    <th class="px-4 py-2 font-regular text-left">Data</th>
                    <th class="px-4 py-2 font-regular text-left">Contact</th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-dashed divide-gray-700">
                  <ListItemHotel
                    v-for="hotel in sliceHotels"
                    :key="hotel.id"
                    :hotel="hotel"
                    @getData="getData"
                  />
                </tbody>
              </table>
              <Pagination
                :actual="actual"
                :max="15"
                :total="hotels.length"
                @togglePage="togglePage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { firestore } from "@/utils/firebase";
import {
  getDocs,
  query,
  collection,
  where,
  documentId,
  orderBy,
} from "firebase/firestore";
import { ListItemHotel, ListItemSkeleton } from "@/components/nso";
import { FiltersNSO } from "@/components/hotels";
import { importHotels, exportHotels, filterHotels } from "@/utils/hotel.jsx";
import { sendMessageChain } from "@/utils/messages.jsx";
import Pagination from "@/components/default/Pagination.vue";
import { uniqueSortedList } from "@/utils/formatter.jsx";
import Breadcrumb from "@/components/default/Breadcrumb.vue";

export default {
  components: {
    ListItemHotel,
    ListItemSkeleton,
    Pagination,
    Breadcrumb,
    FiltersNSO,
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
    sliceHotels() {
      const sliceStart = this.actual * 15;
      return this.filteredHotels.slice(sliceStart, sliceStart + 15);
    },
    hotelsFound() {
      if (this.selectedState) return this.filteredHotels.length;
      return this.stateList.reduce((acc, state) => acc + state.count, 0);
    },
    hotelImporting() {
      return this.$store.state.hotelImporting;
    },
    countHotels() {
      const state = this.hotels.map((hotel) => hotel.data().state);
      const hotelByState = state.forEach((state) => {
        return {
          count: this.hotels.filter((hotel) => hotel.data().state === state)
            .length,
        };
      });
      return hotelByState;
    },
  },
  data() {
    return {
      importing: false,
      search: "",
      actual: 0,
      range: [...Array(3).keys()],
      loading: false,
      loadingMore: false,
      hotels: [],
      filteredHotels: [],
      linesToImport: null,
      linesProccessed: 0,
      report: {},
      stateList: [],
      selectedState: null,
    };
  },
  watch: {
    hotelImporting() {
      this.importing = this.hotelImporting;
      if (!this.hotelImporting) this.getData();
    },
    search() {
      if (this.search.length > 3) {
        this.filteredHotels = this.hotels.filter(
          (hotel) =>
            hotel
              .data()
              .name.toLowerCase()
              .indexOf(this.search.toLowerCase()) >= 0
        );
      } else {
        this.filteredHotels = this.hotels;
      }
      this.actual = 0;
    },
  },
  mounted() {
    this.getStates();
  },
  methods: {
    togglePage(page) {
      this.actual = page;
    },
    toggleState(state) {
      if (!state) {
        this.selectedState = null;
      } else {
        this.selectedState = state;
        this.getData();
      }
    },
    chainMessage() {
      sendMessageChain(this.account, this.chains[0]);
    },
    async importHotels() {
      this.importing = true;
      const file = document.getElementById("fileUpload").files[0];
      await importHotels(file);
      this.getData();
      this.importing = false;
    },
    async exportHotels() {
      let qChain = query(
        collection(firestore, "chains"),
        where("manager_ids", "array-contains-any", [this.account.id])
      );
      const chain = await getDocs(qChain);
      this.chains = chain.docs.map((chain) => chain.data().name);
      const qHotels = query(
        collection(firestore, "hotels"),
        where("chain", "in", this.chains),
        orderBy("name")
      );
      const hotels = await getDocs(qHotels);
      exportHotels(hotels.docs);
    },
    async getStates() {
      this.loading = true;
      let qChain = query(
        collection(firestore, "chains"),
        where("manager_ids", "array-contains-any", [this.account.id])
      );
      const chain = await getDocs(qChain);
      this.chains = chain.docs.map((chain) => chain.data().name);
      const qHotels = query(
        collection(firestore, "hotels"),
        where("chain", "in", this.chains),
        orderBy("name")
      );
      const hotels = await getDocs(qHotels);
      const hotelStates = hotels.docs.map((hotel) => hotel.data().state);
      document.title = `Launchpad - Crewfare - Hotels`;
      const stateUnique = uniqueSortedList(hotelStates);
      const stateList = [];
      stateUnique.forEach((state) => {
        stateList.push({
          name: state,
          count: hotelStates.filter((hotel) => hotel === state).length,
        });
      });
      this.stateList = stateList;
      this.loading = false;
    },
    async getData() {
      this.loading = true;
      if (!this.selectedState) return;
      const qHotels = query(
        collection(firestore, "hotels"),
        where("chain", "in", this.chains),
        where("state", "==", this.selectedState.name),
        orderBy("name")
      );
      this.actual = 0;
      const hotels = await getDocs(qHotels);
      const hotelsDocs = hotels.docs;
      this.hotels = this.filteredHotels = hotelsDocs;
      this.loading = false;
    },
    filter(filters) {
      this.filteredHotels = filterHotels(this.hotels, filters);
    },
  },
};
</script>
