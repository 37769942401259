<template>
  <Vue3Lottie
    :animationData="animationData"
    :height="4000"
    width="100%"
    class="absolute top-0 right-0 left-0 opacity-50"
  />
</template>

<script>
import { Vue3Lottie } from "vue3-lottie";
import animationData from "@/assets/confetti.json";

export default {
  components: {
    Vue3Lottie,
  },
  data() {
    return {
      animationData: animationData,
    };
  },
};
</script>
