<template>
  <div
    v-if="show_dialog"
    class="fixed bottom-0 right-0 z-[100] rounded-lg left-0 top-0 bg-gray-300 bg-opacity-40 flex items-center justify-center"
  >
    <div
      class="bg-gray-900 rounded flex flex-col gap-4 items-center w-full max-w-[900px] p-4 relative text-white"
    >
      <div class="flex w-full flex-col gap-4">
        <div class="text-xl text-white leading-10 flex justify-between">
          <span class="flex flex-col">
            <span>{{ message.data().title }}</span>
            <span class="text-sm" v-if="message.data().subtitle">{{
              message.data().subtitle
            }}</span>
          </span>
          <div class="flex gap-4 items-center">
            <div class="flex items-center">
              <button
                v-if="isComment"
                @click="markAsDone"
                class="green-button-small"
                :disabled="done"
              >
                {{ done ? "This note is done" : "Mark as done" }}
              </button>
            </div>
            <button
              @click="closeFloat"
              class="material-symbols-rounded hover:text-crewfareGreen"
            >
              close
            </button>
          </div>
        </div>
        <div class="flex flex-col flex-grow min-h-[600px]">
          <div
            ref="messageList"
            class="flex-grow flex flex-col gap-4 h-full overflow-auto py-2 pr-4 chat-holder"
          >
            <ListItem
              v-for="message in messages"
              :message="message"
              :key="message.id"
            />
          </div>
          <Form @addFile="addFile" @addChat="addChat" :message="message" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { firestore } from "@/utils/firebase";
import {
  onSnapshot,
  doc,
  setDoc,
  query,
  collection,
  getDoc,
  where,
  orderBy,
  Timestamp,
} from "firebase/firestore";
import { Form, ListItem } from "@/components/messages/chat";
import { addChat, addFile } from "@/utils/messages.jsx";
import { updateRFPNotes, markAsDoneRFPNotes } from "@/utils/rfp.jsx";

export default {
  components: {
    ListItem,
    Form,
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
    notesOpened() {
      return this.$store.state.notesOpened;
    },
    isComment() {
      return this.message.data().type === "Comment on concession";
    },
  },
  data: () => {
    return {
      messages: [],
      message: null,
      done: false,
      loading: false,
      has_message: false,
      show_dialog: false,
    };
  },
  watch: {
    notesOpened() {
      if (this.notesOpened) {
        this.message = this.notesOpened;
        this.done = this.message.data().done;
        this.getData();
        this.show_dialog = true;
      } else {
        this.show_dialog = false;
      }
    },
  },
  methods: {
    closeFloat() {
      this.show_dialog = false;
      this.$store.commit("setNotesOpened", null);
    },
    async getData() {
      this.loading = true;
      setDoc(doc(firestore, "messages", this.message.id), {
        ...this.message.data(),
        new_message: false,
        done: this.done || false,
      });
      onSnapshot(
        query(
          collection(firestore, "chat"),
          where("message_id", "==", this.message.id),
          orderBy("created_at", "asc")
        ),
        (doc) => {
          this.messages = doc.docs;
          setTimeout(() => {
            if (this.$refs.messageList)
              this.$refs.messageList.scrollTop =
                this.$refs.messageList?.scrollHeight || 0;
          }, 200);
        }
      );
      this.loading = false;
    },
    async addChat(content) {
      this.done = false;
      addChat(this.account, this.message, content);
      if (this.isComment) {
        updateRFPNotes(this.message.data().rfp, this.message.data().field);
        this.$store.commit("setUpdateRFP", true);
      }
    },
    async addFile({ url, name }) {
      this.done = false;
      addFile(this.account, this.message, url, name);
      if (this.isComment) {
        updateRFPNotes(this.message.data().rfp, this.message.data().field);
        this.$store.commit("setUpdateRFP", true);
      }
    },
    async markAsDone() {
      markAsDoneRFPNotes(this.message.data().rfp, this.message.data().field);
      this.done = true;
      setDoc(doc(firestore, "messages", this.message.id), {
        ...this.message.data(),
        done: true,
      });
      this.$store.commit("setUpdateRFP", true);
    },
  },
};
</script>
