<template>
  <div class="bg-gray-800 w-full flex flex-col stage-height">
    <div class="p-4 flex justify-between bg-gray-900 items-center">
      <h1 class="text-xl text-white">Files</h1>
    </div>
    <div class="flex flex-col rounded pb-4 relative">
      <div class="flex p-4 items-center justify-between sticky top-0">
        <div class="text-crewfareGreen">
          <strong class="rounded-full bg-black px-3 py-1">{{
            rfpsFound
          }}</strong>
          RFPs
        </div>
      </div>
      <div v-if="loading" class="flex flex-1 items-center justify-center">
        <p class="text-center text-sm text-white py-12">Fetching RFPs...</p>
      </div>
      <div v-else>
        <div v-if="allRfps.length > 0">
          <table
            class="w-full justify-between py-2 border-t border-gray-700 divide-y divide-gray-700"
          >
            <tr class="text-white text-left border-b border-gray-700">
              <th class="py-2 px-4">Event Name</th>
              <th class="py-2 px-4">RFP Name</th>
              <th class="py-2 px-4 max-w-[150px]">Signed Hotels</th>
              <th class="py-2 px-4 max-w-[150px]">Signed Rooms (Per Night)</th>
              <th class="py-2 px-4">Event Dates</th>
            </tr>
            <ListItemAdmin v-for="rfp in allRfps" :key="rfp.id" :rfp="rfp" />
          </table>
        </div>
        <div v-else>
          <p class="text-center text-sm text-white py-12">
            No RFPs in this status at the moment!
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { firestore } from "@/utils/firebase";
import { getDocs, query, collection, orderBy, where } from "firebase/firestore";
import ListItemAdmin from "@/components/files/ListItemAdmin.vue";

export default {
  components: {
    ListItemAdmin,
  },
  data() {
    return {
      rfpsFull: [],
      rfpsFiltered: [],
      rfps: [],
      loading: false,
      status: "All",
      search: "",
      archived: false,
      myRfps: [],
      allRfps: [],
      myDrafts: [],
      archived: [],
      createDialogToggle: false,
    };
  },
  computed: {
    account() {
      const account = this.$store.state.account;
      if (!["internal", "admin"].includes(account.type.toLowerCase())) {
        router.push({ name: "rfpsList" });
        return false;
      }
      return account;
    },
    rfpsFound() {
      return this.allRfps.filter((rfp) => rfp.signedHotelsList > 0).length || 0;
    },
  },
  async mounted() {
    this.getAllRfps();
    document.title = `Launchpad - Crewfare - RFPs`;
  },
  methods: {
    async getAllRfps() {
      this.loading = true;
      let qRfps = query(
        collection(firestore, "rfps"),
        where("status", "==", "Sent"),
        orderBy("updated_at", "desc")
      );
      const rfps = await getDocs(qRfps);
      const rfpList = rfps.docs;
      const parsedRfps = this.parseData(rfpList);
      for (let rfp of parsedRfps) {
        const qRFPHotel = query(
          collection(firestore, "rfp_hotel"),
          where("rfp", "==", rfp.id)
        );
        const RFPHotel = await getDocs(qRFPHotel);
        let rfp_hotels = this.parseData(RFPHotel.docs);

        const filterHotels = rfp_hotels.filter(
          (rfp) =>
            rfp.status && rfp.status.toLowerCase() === "signed by both sides"
        );
        let roomCount = 0;
        filterHotels.forEach((hotel) => {
          hotel.roomList[0].rooms.forEach(
            (room) => (roomCount += parseInt(room.amount || 0))
          );
        });
        rfp.signedHotelsList = filterHotels.length;
        rfp.roomCount = roomCount;
      }
      this.allRfps = parsedRfps;
      this.loading = false;
    },
    parseData(list) {
      const rfpsList = [];
      list.forEach((rfp) => {
        rfpsList.push({
          id: rfp.id,
          name: rfp.data().name,
          event_name: rfp.data().event_name,
          venue: rfp.data().venue,
          search: rfp.data().search,
          hotel_goal: rfp.data().hotel_goal,
          room_goal: rfp.data().room_goal,
          created_by: rfp.data().created_by,
          status: rfp.data().status,
          location: rfp.data().location,
          shared_with: rfp.data().shared_with,
          startDate: rfp.data().startDate,
          endDate: rfp.data().endDate,
          roomList: rfp.data().roomList || [],
          created_at: rfp.data().created_at,
          updated_at: rfp.data().updated_at,
          url: rfp.data().url,
          contract_method: rfp.data().contractMethod,
        });
      });
      return rfpsList;
    },
  },
};
</script>
