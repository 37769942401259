import Form from './Form.vue';
import Single from './Single.vue';
import View from '@/components/default/View.vue';
import ContactForm from './contacts/Form.vue';
import ContactList from './contacts/List.vue';
import Dashboard from '@/layouts/Dashboard.vue';

export const nso = [
  {
    path: '/nso',
    name: 'nso',
    component: Dashboard,
    meta: {
      view: 'NSO',
    },
    children: [
      {
        path: '',
        name: 'hotelsNso',
        component: Single,
      },
      {
        path: 'hotel/add',
        name: 'nsoHotelAdd',
        component: Form,
      },
      {
        path: 'hotel/:id/settings',
        name: 'nsoHotelEdit',
        component: Form,
      },
      {
        path: 'hotel/:id/contacts',
        name: 'nsoHotelsContacts',
        children: [
          {
            path: '',
            name: 'nsoHotelsContactsList',
            component: ContactList,
          },
          {
            path: 'add',
            name: 'nsoHotelContactsAdd',
            component: ContactForm,
          },
          {
            path: ':contactId/edit',
            name: 'nsoHotelContactsEdit',
            component: ContactForm,
          },
        ],
      },
    ],
  },
];
