<template>
  <div class="flex gap-4 py-4">
    <div class="flex flex-col gap-1 w-full">
      <label class="text-white font-bold">Name</label>
      <input
        type="text"
        v-model="contact.name"
        class="w-full bg-gray-700 rounded px-4"
      />
    </div>
    <div class="flex flex-col gap-1 w-full">
      <label class="text-white font-bold">Role</label>
      <input
        type="text"
        v-model="contact.role"
        class="w-full bg-gray-700 rounded px-4"
      />
    </div>
    <div class="flex flex-col gap-1 w-full">
      <label class="text-white font-bold">Email</label>
      <input
        type="text"
        v-model="contact.email"
        :disabled="contact.contact && contact.contact.id"
        class="w-full bg-gray-700 rounded px-4"
      />
    </div>
    <div class="flex flex-col gap-1 w-full">
      <label class="text-white font-bold">Phone</label>
      <input
        type="text"
        v-model="contact.phone"
        class="w-full bg-gray-700 rounded px-4"
      />
    </div>
    <div class="flex items-center">
      <span
        @click="removeContact"
        class="red-button-small material-symbols-rounded"
      >
        delete
      </span>
    </div>
  </div>
</template>

<script>
import { deleteContact } from "@/utils/hotel.jsx";

export default {
  props: ["contact", "hotel_id"],
  mounted() {},
  methods: {
    async removeContact() {
      if (!confirm("Are you sure you want to delete this contact?")) return;
      if (this.hotel_id)
        await deleteContact(this.hotel_id, this.contact.contact);
      this.$emit("removeContact");
    },
  },
};
</script>
