<template>
  <div v-if="init">
    <Editor
      ref="editor"
      api-key="d074tealriws5uqr0ziqlwdqanrwu935w4mbmy8c7z05e78j"
      :init="init"
    />
    <RFPDialog :rfp="rfp" :dialog="dialog" @hide_dialog="toggleDialog" />
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import moment from "moment";
import RFPDialog from "./RFPDialog.vue";
import { pluginVariable } from "@/plugins/variable.ts";
import { firestore } from "@/utils/firebase";
import { doc, setDoc } from "firebase/firestore";
import { getMomentFromFirebaseDate } from "@/utils/dateUtils";

export default {
  props: [
    "rfp",
    "hotel",
    "signed",
    "contract_content",
    "has_changes",
    "has_comments",
  ],
  components: {
    Editor,
    RFPDialog,
  },
  computed: {
    cutoffDays() {
      const date = getMomentFromFirebaseDate(this.rfp.data().startDate);
      const cutoff = getMomentFromFirebaseDate(this.rfp.data().cutoffDate);
      const diff = date.diff(cutoff);
      const duration = moment.duration(diff);
      return Math.floor(duration.asDays());
    },
    types() {
      if (!this.rfp.data()) return;
      if (this.rfp.data().roomTypes) {
        const types = this.rfp
          .data()
          .roomTypes.filter((type) => type.name.length > 0);
        return types;
      }
      const types = [];
      this.rfp.data().roomList.forEach((day) => {
        day.rooms.forEach((room) => {
          if (room.type.length > 0) types.push(room.type);
        });
      });
      return [...new Set(types)];
    },
    dates() {
      const days = [];
      this.rfp.data().roomList.forEach((day) => {
        days.push(day.date);
      });
      return [...new Set(days)];
    },
    rooms() {
      const roomsList = [];
      this.rfp.data().roomList.forEach((day) => {
        roomsList.push({
          day: day.date,
          ...day.rooms,
        });
      });
      return roomsList;
    },
    endDateFormated() {
      if (!this.rfp.data().endDate) return "";
      const date = this.rfp.data().endDate;
      return getMomentFromFirebaseDate(date).format("MM-DD-YYYY");
    },
    startDateFormated() {
      if (!this.rfp.data().startDate) return "";
      const date = this.rfp.data().startDate;
      return getMomentFromFirebaseDate(date).format("MM-DD-YYYY");
    },
    effectiveDateFormated() {
      if (!this.rfp.data().effective_date) return "";
      const date = this.rfp.data().effective_date;
      return getMomentFromFirebaseDate(date).format("MM-DD-YYYY");
    },
    cutoffDateFormated() {
      if (!this.rfp.data().cutoffDate) return "";
      const date = this.rfp.data().cutoffDate;
      return getMomentFromFirebaseDate(date).format("MM-DD-YYYY");
    },
    account() {
      return this.$store.state.account;
    },
    isHotel() {
      if (!this.account) return;
      return ["contact"].includes(this.account.type.toLowerCase());
    },
  },
  data() {
    return {
      changeIndex: 0,
      content: null,
      dialog: false,
      hotel_person_name: "",
      hotel_address: "",
      hotel_email: "",
      hotel_telephone: "",
      hotel_title: "",
      hotel_date: "",
      crewfare_date: "",
      crewfare_name: "",
      crewfare_title: "",
      crewfare_members: "",
      hotel_taxes: "",
      hotel_other_fees: "",
      init: null,
      protect_component: `class="variable" contenteditable="false"`,
      initial_value: "",
      is_comment_open: false,
    };
  },
  methods: {
    toggleDialog() {
      this.dialog = !this.dialog;
    },
    validateDate(date) {
      const regex =
        /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/;
      if (!regex.test(date)) {
        date = "";
      }
    },
    getTotal() {
      const roomList = this.rfp.data().roomList;
      const total = roomList.map((day) =>
        day.rooms
          .map((room) => parseFloat(room.amount || 0))
          .reduce((accumulator, value) => accumulator + value, 0)
      );
      return total.reduce((accumulator, value) => accumulator + value, 0);
    },
    getTotalByType(indexType) {
      const roomList = this.rfp.data().roomList;
      const total = roomList.map((day) =>
        day.rooms
          .filter((room, indexType) => indexType === indexType)
          .map((room) => parseFloat(room.amount || 0))
      );
      return total.reduce(
        (accumulator, value) => accumulator + parseFloat(value[0]),
        0
      );
    },
    getTotalByDay(rooms) {
      const amount = rooms.map((room) => parseFloat(room.amount || 0));
      return amount.reduce((accumulator, value) => accumulator + value, 0);
    },
    getTypeLoop() {
      let tdTypes = "";
      if (!this.types) return;
      this.types.forEach((date) => {
        tdTypes += `<td style="padding: 4px 6px;text-align: center;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${date}
            </span>
            </span>
          </td>`;
      });
      return tdTypes;
    },
    getDateLoop() {
      let tdDates = "";
      if (!this.types) return;
      this.dates.forEach((date) => {
        tdDates += `<td style="padding: 4px 6px;text-align: center;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${date}
            </span>
            </span>
          </td>`;
      });
      return tdDates;
    },
    getTotalByTypeLoop() {
      let tdTypes = "";
      if (!this.dates) return;
      this.dates.forEach((type, index) => {
        tdTypes += `<td style="padding: 4px 6px;text-align: center;"
          >
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${this.getTotalByType(index)}</span></span>
          </td>`;
      });
      return tdTypes;
    },
    getTotalByDateLoop() {
      let tdTypes = "";
      this.dates.forEach((date) => {
        let amount = 0;
        this.rfp
          .data()
          .roomList.filter((day) => day.date === date)
          .forEach((days) => {
            amount = days.rooms
              .map((day) => day.amount)
              .reduce(
                (accumulator, value) => accumulator + parseFloat(value),
                0
              );
          });
        tdTypes += `<td style="padding: 4px 6px;text-align: center;"
          >
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${amount}</span></span>
          </td>`;
      });
      return tdTypes;
    },
    roomRateLoop() {
      let trRoomRate = "";
      this.types.forEach((type, typeIndex) => {
        const typeValue = type.name || type;
        trRoomRate += `<tr>
        <td
              style="padding: 4px 6px;text-align: left;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
          ${typeValue}</span></span>
        </td>`;
        this.rfp.data().roomList.forEach((days) => {
          trRoomRate += `<td style="padding: 4px 6px;text-align: center;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${
              days.rooms.find((room, index) => index === typeIndex)?.value || 0
            }</span></span>
          </td>`;
        });
        trRoomRate += `</tr>`;
      });
      return trRoomRate;
    },
    getRoomListLoop() {
      let trRoomList = "";
      let types = this.types;
      if (!types) {
        types = [
          {
            name: "King",
            type: "Single",
            configuration: "1 King Bed",
            occupancy: "2",
            extra_value: "",
          },
          {
            name: "Double Queen",
            type: "Double",
            configuration: "Double Queen Beds",
            occupancy: "4",
            extra_value: "",
          },
        ];
      }
      types.forEach((type, typeIndex) => {
        const typeValue = type.name || type;
        trRoomList += `<tr>
        <td
              style="padding: 4px 6px;text-align: left;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
          ${typeValue}</span></span>
        </td>`;
        let total = 0;
        this.rfp.data().roomList.forEach((days) => {
          const amount = parseFloat(
            days.rooms.find((room, index) => index === typeIndex)?.amount || 0
          );
          total += amount;
          trRoomList += `<td style="padding: 4px 6px;text-align: center;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${amount}</span></span>
          </td>`;
        });
        trRoomList += `<td style="padding: 4px 6px;text-align: center;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold; text-align: center;"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${total}</span></span></td></tr>`;
      });
      return trRoomList;
    },
    getResortFee() {
      return this.rfp.data().resortFee === "Resort Fee"
        ? this.rfp.data().resortFeeAmount
        : this.rfp.data().resortFee;
    },
    getOtherFees() {
      return !this.rfp.data().otherFees
        ? "No"
        : this.rfp.data().otherFees === "Yes"
        ? this.rfp.data().otherFeesAmount
        : this.rfp.data().otherFees;
    },
    getOccupancyLoop() {
      let trOccupancyList = "";
      let typeNames = this.types;
      let types = this.rfp.data().roomTypes;
      if (!types) {
        types = [
          {
            name: typeNames[0] || "King",
            type: "Single",
            configuration: "1 King Bed",
            occupancy: "2",
            extra_value: "",
          },
          {
            name: typeNames[1] || "Double Queen",
            type: "Double",
            configuration: "Double Queen Beds",
            occupancy: "4",
            extra_value: "",
          },
        ];
      }
      types?.forEach((days) => {
        trOccupancyList += `<tr>
          <td style="padding: 4px 6px;text-align: left;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${days.name}</span></span>
          </td>
          <td style="padding: 4px 6px;text-align: left;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${days.type || "-"}</span></span>
          </td><td style="padding: 4px 6px;text-align: left;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${days.configuration || "-"}</span></span>
          </td><td style="padding: 4px 6px;text-align: left;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${days.occupancy || "-"}</span></span>
          </td><td style="padding: 4px 6px;text-align: left;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${days.extra_value || "-"}</span></span>
          </td>
          </tr>`;
      });
      return trOccupancyList;
    },
    getRoomListTable() {
      return `<table
            style="
              margin-top: 12px;
              width: 100%;
              border-color: #000;
              border-collapse: collapse;
            "

            border="1"
            class="w-full"
          >
            <tr>
              <td  style="padding: 4px 6px;text-align: left;">Room Name<br/>
                (as seen on your website)</td>
              ${this.getDateLoop()}
              <td  style="padding: 4px 6px;text-align: center;">Total</td>
            </tr>
            ${this.getRoomListLoop()}
            <tr>
              <td  style="padding: 4px 6px;text-align: left;"></td>
              ${this.getTotalByDateLoop()}
              <td style="padding: 4px 6px;text-align: center;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
                ${this.getTotal()}
                </span></span>
              </td>
            </tr>
          </table>`;
    },
    getRoomTypeTable() {
      return `<table
            style="
              margin-top: 12px;
              width: 100%;
              border-color: #000;
              border-collapse: collapse;
            "
            border="1"
            class="w-full"
          >
            <tr>
              <td  style="padding: 4px 6px;text-align: left;">Room Name<br/>
                (as seen on your website)</td>
              <td  style="padding: 4px 6px;text-align: center;">Room Type</td>
              <td  style="padding: 4px 6px;text-align: center;">Room Configuration</td>
              <td  style="padding: 4px 6px;text-align: center;">Hotel Room<br/>Occupancy Limit</td>
              <td  style="padding: 4px 6px;text-align: center;">Additional Person<br/>Value</td>
            </tr>
            ${this.getOccupancyLoop()}
          </table>`;
    },
    getRoomRateTable() {
      return `<table
          style="
            margin-top: 12px;
            width: 100%;
            border-color: #000;
            border-collapse: collapse;
          "
          border="1"
          class="w-full"
          ${this.protect_component}
        >
          <tr>
            <td style="padding: 4px 6px;text-align: left;">Room Name <br/>(As seen in your website)</td>
            ${this.getDateLoop()}
          </tr>
          ${this.roomRateLoop()}
        </table>`;
    },
    getGroupHonoredRate() {
      return this.rfp.data().groupDateHonored === "No"
        ? `
        <div style="margin-top: 12px" ${this.protect_component}>
          The group rate is
          ${
            this.rfp.data().groupDateHonoredPre
              ? `
          <span>
            <span>
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900"
              ${this.protect_component} data-original-variable="{{room_type}}"
              >$${this.rfp.data().groupDateHonoredPreRate}</span>
              </span>
            pre
          </span>
          `
              : ""
          }
          ${
            this.rfp.data().groupDateHonoredPre &&
            this.rfp.data().groupDateHonoredPost
              ? `
          <span>&nbsp;and&nbsp;</span>
          `
              : ""
          }
          ${
            this.rfp.data().groupDateHonoredPost
              ? `
          <span>
            <span>
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900"
              ${this.protect_component} data-original-variable="{{room_type}}"
              >$${this.rfp.data().groupDateHonoredPostRate}</span></span>
            post
          </span>
          `
              : ""
          }
          on "Shoulder Nights" known as +/-
          ${
            this.rfp.data().groupDateHonoredPre
              ? `
          <span>
            <span>
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900"
              ${this.protect_component} data-original-variable="{{room_type}}"
              >${this.rfp.data().groupDateHonoredPreDays}</span></span>
            pre
          </span>
          `
              : ""
          }
          ${
            this.rfp.data().groupDateHonoredPre &&
            this.rfp.data().groupDateHonoredPost
              ? `
          <span>&nbsp;and&nbsp;</span>
          `
              : ""
          }
          ${
            this.rfp.data().groupDateHonoredPost
              ? `
          <span>
            <span>
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900"
              ${this.protect_component} data-original-variable="{{room_type}}"
              >${this.rfp.data().groupDateHonoredPostDays}</span></span>
            post
          </span>
          `
              : ""
          }
          the "Peak Nights".
        </div>
        `
        : "" + this.rfp.data().groupDateHonored === "Yes"
        ? `
        <div style="margin-top: 12px" ${this.protect_component}>
          The group rate is on "Shoulder Nights" known as +/-
          ${
            this.rfp.data().groupDateHonoredPre
              ? `
          <span>
            <span>
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900"
              ${this.protect_component} data-original-variable="{{room_type}}"
              >${this.rfp.data().groupDateHonoredPreDays}</span></span>
            pre
          </span>
          `
              : ""
          }
          ${
            this.rfp.data().groupDateHonoredPre &&
            this.rfp.data().groupDateHonoredPost
              ? `
          <span>&nbsp;and&nbsp;</span>`
              : ""
          }
          ${
            this.rfp.data().groupDateHonoredPost
              ? `
            <span>
            <span>
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900"
              ${this.protect_component} data-original-variable="{{room_type}}"
              >${this.rfp.data().groupDateHonoredPostDays}</span></span>
              post
            </span>
          `
              : ""
          }
          the "Peak Nights".
        </div>
        `
        : "";
    },
    getDefaultContractContent() {
      return `
        <div style="padding: 0 12px">
          <h2 class="text-xl text-center mb-4">CREWFARE HOTEL AGREEMENT</h2>
          <div style="margin-top: 12px" ${this.protect_component}>
            This Master Hotel Agreement (this "Agreement") is made and entered into as
            of
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{effective_date_formated}}</span>
            (the "Effective Date") by and between Crewfare LLC ("Group") and
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{hotel_name}}</span>
            (the "Hotel"). Group and Hotel are sometimes referred to herein
            individually as a "Party" and collectively as the "Parties".
          </div>
          <div style="margin-top: 12px" class="text-xl font-bold text-center py-4" ${this.protect_component}>
            TENTATIVE NAME OF EVENT: <span style="color: rgb(202, 138, 4); font-weigth: bold">{{rfp_name}}</span> (the "Event").
          </div>
          <table
            style="margin-top: 12px; width: 100%"
            cellpadding="0"
            cellspacing="0"
            ${this.protect_component}
          >
            <tr>
              <td valign="top" width="50%">
                <h3 class="text-xl">GROUP:</h3>
                <div style="margin-top: 12px">Company: Crewfare LLC</div>
                <div style="margin-top: 12px">Name: Jason van Esso</div>
                <div style="margin-top: 12px">Title: Partner</div>
                <div style="margin-top: 12px">
                  Address: 2678 Edgewater Court, Weston FL 33332
                </div>
                <div style="margin-top: 12px">Telephone: 954-591-9005</div>
                <div style="margin-top: 12px">Email: jason@crewfare.com</div>
              </td>
              <td valign="top" width="50%">
                <h3 class="text-xl">HOTEL:</h3>
                <div style="margin-top: 12px">
                  Hotel Name (that the group will be staying): <span style="color: rgb(202, 138, 4); font-weigth: bold">{{hotel_name}}</span>
                </div>
                <div style="margin-top: 12px" class="flex gap-2 items-center">
                  Name:
                  <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{hotel_person_name}}</span>
                </div>
                <div style="margin-top: 12px" class="flex gap-2 items-center">
                  Title:
                  <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{hotel_title}}</span>
                </div>
                <div style="margin-top: 12px" class="flex gap-2 items-center">
                  Address (that the group will be staying):
                  <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{hotel_address}}</span>
                </div>
                <div style="margin-top: 12px" class="flex gap-2 items-center">
                  Telephone:
                  <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{hotel_telephone}}</span>
                </div>
                <div style="margin-top: 12px" class="flex gap-2 items-center">
                  Email:
                  <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{hotel_email}}</span>
                </div>
              </td>
            </tr>
          </table>
          <h3 style="margin-top: 12px" class="text-xl">1. TERMS.</h3>
          <div style="margin-top: 12px">
            <span class="underline">A. Reservation of Rooms.</span> The Hotel will
            reserve, exclusively for the Event's team, staff, fans, or other guests
            (collectively the "Guests" or the "Group" or the "Patrons"), sleeping
            rooms (the "Rooms") on the event dates below (the "Reservation Period")
            subject to the terms of this Agreement.
          </div>
          <div style="margin-top: 12px">
            <span class="underline">B. Courtesy Room Block.</span> Room rates have
            been established for Group's room block ("Room Block"). The Hotel is
            obligated to hold the Room Block available to the Group up until the
            Cut-Off Date. There is no commitment by Group to pick up these rooms.
          </div>
          <div style="margin-top: 12px" ${this.protect_component}>
            <span class="underline">C. Room Assignments.</span>
            No later than
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{cutoff_days}}</span>
            days prior to the Event Date (the "Cut-Off Date")
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{cutoff_date_formated}}</span>, the Group will notify the Hotel of such assignments by providing a
            Rooming List to the Hotel with the assigned names to the Hotel. It is
            expressly agreed and understood that the Group makes no representations
            that the number of Rooms reserved under this Agreement will be assigned.
            Only assigned Rooms will be paid for. After the Cut-off Date, Rooms
            reserved under this Agreement, but not assigned to specific Patrons, may
            be released, at no cost to the Group, for sale to the general public.
            After the Cut-off Date, Hotel will accept additional reservations from the
            Group, subject to availability, at Group Rates
          </div>
          <div style="margin-top: 12px">
            <span class="underline">D. Room Types.</span>
          </div>
          {{room_types_table}}
          <div style="margin-top: 12px"  ${this.protect_component}>
            <span class="underline">E. Room Inventory (the "Inventory").</span>&nbsp;
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{start_date_formated}}</span>
            through
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{end_date_formated}}</span>
            shall be the "Peak Nights". The number of rooms below shall be allocated
            and committed to Group as Rooms for the Guests.
          </div>
          {{room_list_table}}
          <div style="margin-top: 12px">
            <span class="underline">F. Room Rates. (the "Room Rates").</span>
          </div>
          {{room_rate_table}}
        {{group_honored_rate}}
        <div style="margin-top: 12px">
          <span class="underline">G. Taxes & Fees.</span> Rates above are quoted
          exclusive of applicable state and local taxes, fees and other charges
          known as the "Ancillary Costs". All Ancillary Costs must be stated in the
          below chart in order for the Hotel to be compensated for those fees
        </div>
        <table
          style="
            width: 100%;
            border-color: #000;
            border-collapse: collapse;
            margin-top: 12px;
          "
          border="1"
          class="w-full"
          ${this.protect_component}
        >
          <tr>
            <th class="px-4 py-1 border border-black text-center">Charge</th>
            <th class="px-4 py-1 border border-black text-center">
              Per Room Night
            </th>
          </tr>
          <tr>
            <td class="px-4 py-1 border border-black">Local Occupancy Tax</td>
            <td class="px-4 py-1 border border-black text-center">
              <span>
                <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                  {{hotel_local_occupancy_tax}}
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td class="px-4 py-1 border border-black">State Occupancy Tax</td>
            <td class="px-4 py-1 border border-black text-center">
              <span>
                <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                  {{hotel_state_occupancy_tax}}
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td class="px-4 py-1 border border-black">City Tax</td>
            <td class="px-4 py-1 border border-black text-center">
              <span>
                <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                  {{hotel_city_tax}}
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td class="px-4 py-1 border border-black">Convention & Tourism Tax</td>
            <td class="px-4 py-1 border border-black text-center">
              <span>
                <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                  {{hotel_convention_tax}}
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td class="px-4 py-1 border border-black">VAT Tax</td>
            <td class="px-4 py-1 border border-black text-center">
              <span>
                <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                  {{hotel_vat_tax}}
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td class="px-4 py-1 border border-black">SAT Tax</td>
            <td class="px-4 py-1 border border-black text-center">
              <span>
                <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                  {{hotel_sat_tax}}
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td class="px-4 py-1 border border-black">Municipal Accommodation Tax</td>
            <td class="px-4 py-1 border border-black text-center">
              <span>
                <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                  {{hotel_municipal_accomodation_tax}}
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td class="px-4 py-1 border border-black">HST Tax</td>
            <td class="px-4 py-1 border border-black text-center">
              <span>
                <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                  {{hotel_hst_tax}}
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td class="px-4 py-1 border border-black">Sustaintability Fee</td>
            <td class="px-4 py-1 border border-black text-center">
              <span>
                <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                  {{hotel_sustaintability_fee}}
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td class="px-4 py-1 border border-black">Other Taxes</td>
            <td class="px-4 py-1 border border-black text-center">
              <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                {{hotel_taxes}}
              </span>
            </td>
          </tr>
          <tr>
            <td class="px-4 py-1 border border-black">Resort Fee</td>
            <td class="px-4 py-1 border border-black text-center">
              <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                {{resort_fee}}
              </span>
            </td>
          </tr>
          <tr>
            <td class="px-4 py-1 border border-black">Other Fees</td>
            <td class="px-4 py-1 border border-black text-center">
              <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                {{other_fees}}
              </span>
            </td>
          </tr>
        </table>
        <div style="margin-top: 12px">
          <span class="underline">H. Pricing Confidentiality.</span> The prices
          contained in this Agreement are considered confidential and Hotel will not
          disclose such information to any third parties or Patrons without Group's
          prior written consent.
        </div>
        <div style="margin-top: 12px">
          <span class="underline">I. Rate Integrity.</span> If, from the date of
          contracting through the Cut-Off Date, it is determined that a qualifying
          lower single/double rate for the same room type, dates, bed type, number
          of guests, same or better restrictions and policies is offered to other
          guests over the Group's scheduled dates, Hotel agrees to use commercially
          reasonable efforts to promptly remove the lower published rate from all
          distribution channels. This commitment only applies to direct published
          rates made available to the general public.
        </div>
        <div style="margin-top: 12px"  ${this.protect_component}>
          <span class="underline">J. Commissions and Rebates.</span> Rooms occupied
          and actualized by Guests shall include a
          <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{commission}}</span>
          commission and
          <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{rebate}}</span>
          rebate, payable per actualized room night booked in the Room Block and all
          room nights found outside of the Room Block, regardless of actual guest
          room rate. For cancelled reservations or No-Shows where the reservation
          was guaranteed with a deposit or major credit card, commission/rebates are
          due on any charges that Hotel is able to collect from the Patrons causing
          the cancellation or No-Show, as applicable. Any commission or rebate must
          be detailed in a final folio to Group, sent no later than seven (7) days
          after the Event. Commissions and rebates are payable to Crewfare LLC (IATA
          #:
          <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{iata}}</span>) no later than thirty (30) days from invoice date.
        </div>
          <div style="margin-top: 12px" ${this.protect_component}>
            <span class="underline">K. Event Planner Program.</span> Jason van Esso
            (collectively, the "Event Planner") is eligible to earn an event planner
            bonus for qualifying events. Event Planner is eligible to earn
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{reward_points}}</span>. The Event Planner's member number is
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{crewfare_members}}</span>.
          </div>
          <div style="margin-top: 12px" ${this.protect_component}>
            <span class="underline">L. Complimentary Rooms.</span> For its use during
            the dates of the Event, and ancillary planning dates, Group will be
            entitled to
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{comp_rooms}}</span>
            complimentary room night for every room nights occupied, on a cumulative
            basis. Hotel agrees to credit to Group's Master Account the actual value
            of the earned complimentary rooms.
          </div>
          <div style="margin-top: 12px">
            <span class="underline">M. Exclusive Right to Use Rooms.</span> Hotel
            acknowledges and agrees that Group shall have the exclusive right to sell,
            rent, or otherwise assign the Rooms. It is expressly agreed that where the
            Group resells Rooms, the rates that Group charges may vary from the Room
            Rates listed in this Agreement. Hotel agrees (i) to keep the rate charged
            to the Group for any given Room confidential between the Parties, and (ii)
            not to disclose the rate charged to the Group to any Patrons, whether
            directly or indirectly, under any circumstances. Notwithstanding anything
            to the contrary in this Agreement, substitution of any reservations (i.e.,
            a change to the name/details included on the Rooming List) by the Group
            shall be allowed at any time up to and through the time of the Event.
          </div>
          <h3 class="text-xl">2. HOTEL TAXES AND OTHER FEES.</h3>
          <div style="margin-top: 12px">
            Hotel sleeping room rates are subject to applicable state and local taxes
            in effect at the time of check-in. During the term of this Agreement,
            Hotel shall not impose any other mandatory charge on guests or master
            accounts associated with the Group or the Event. Hotel will notify Group
            of the tax requirements and any changes in the tax requirements.
          </div>
          <div style="margin-top: 12px">
            <span class="underline">A. Master Account & Payment Arrangements.</span>
            Master account credit card will the expenses for the room and applicable
            tax for the room only. All Guests must provide credit card at check-in for
            their own incidental charges, resort fees and other charges. Group shall
            not be responsible for any incidental charges for any Hotel guests or any
            other charges without written consent from Group
          </div>
          <div style="margin-top: 12px">
            <span class="underline">B. Deposit Schedule.</span> Group and Hotel agree
            to the following deposit schedule. Hotel agrees to obtain approval from
            Crewfare's accounting team,
            <a href="mailto:accounting@crewfare.com" class="underline">
              accounting@crewfare.com</a>, (Tony Tohme) before making any such charges to the credit card on file
            at any time
          </div>
          <table
            style="
              margin-top: 12px;
              width: 100%;
              border-color: #000;
              border-collapse: collapse;
            "
            border="1"
            class="w-full"
          >
            <tr>
              <th></th>
              <th class="border border-black px-4 py-2">Due Date</th>
              <th class="border border-black px-4 py-2">
                Amount (Not Including Service Charge or Tax)
              </th>
            </tr>
            <tr>
              <th class="border border-black px-4 py-2">Upon Signing</th>
              <td class="border border-black px-4 py-2">Upon signing of Agreement</td>
              <td class="border border-black px-4 py-2">
                Credit Card Guarantee Only ($0.00 deposit)
              </td>
            </tr>
            <tr>
              <th class="border border-black px-4 py-2">Second Deposit</th>
              <td class="border border-black px-4 py-2">At Cut-Off Date:</td>
              <td class="border border-black px-4 py-2">
                25% of Anticipated Master Account Balance (Based On Group Pick Up)
              </td>
            </tr>
            <tr>
              <th class="border border-black px-4 py-2">Final Deposit</th>
              <td class="border border-black px-4 py-2">3 Days Ahead of Arrival:</td>
              <td class="border border-black px-4 py-2">
                100% Of Anticipated Master Account Balance.
              </td>
            </tr>
          </table>
          <div style="margin-top: 12px">
            <span class="underline">C. Early Departure.</span> Neither Group nor the
            Guests will be charged for early departure fees.
          </div>
          <div style="margin-top: 12px">
            <span class="underline">D. No-Shows.</span> Hotel agrees that it will not
            release a No-Show accommodation without prior approval from Crewfare. A
            No-Show room night billed to the master account is considered an
            actualized room night and will count towards Group's final pick-up.
          </div>
          <div style="margin-top: 12px">
            <span class="underline">E. Construction, Renovation, Hotel Closings.</span>
            Hotel agrees to immediately notify the Group of any plans for renovation
            or construction. Under such circumstances, the Room Rate shall be reduced
            by fifty percent (50%) and the Group shall have the right to reduce or
            terminate their planned use of the Hotel upon written notice to Hotel and
            if so terminated, the Group shall be indemnified by the Hotel for any
            costs and expenses incurred in hosting the Patrons at the Hotel.
          </div>
          <div style="margin-top: 12px">
            <span class="underline">F. Americans with Disabilities Act.</span> As
            among the parties, Hotel shall be responsible for compliance with the
            Americans with Disabilities Act ("ADA") including, but not limited to,
            compliance with respect to: (i) the Hotel's policies practices, procedures
            and eligibility criteria; (ii) the provision of auxiliary aids and
            services in the Hotel; (iii) architectural, communications and
            transportation barriers in the Hotel; and (iv) the provision of wheelchair
            seating spaces in assembly areas
          </div>
          <div style="margin-top: 12px">
            <span class="underline">G. Insurance.</span> Hotel will maintain full
            insurance coverage throughout the term of this Agreement, in such amounts,
            coverages, types and insurers as are maintained by other hotels of similar
            quality in the State of Florida, against all third party liability,
            including insurance in respect of liability to guests and others using the
            hotel facilities and liability to the employees and agents of the Hotel,
            and will supply certificates of insurance to the Group upon request.
          </div>
          <div style="margin-top: 12px">
            <span class="underline">H. Change in Hotel Management or Ownership.</span>
            Hotel shall notify Group of any change in Hotel management or ownership.
            Group shall have the right to terminate this Agreement without liability
            if, after the time of contracting but before the Event, the Hotel changes
            the hotel brand, such that the level of service provided by the new brand
            is lower than Hotel's original brand at the time of contracting. Any
            change in the point of contact must also be communicated to the Group.
            Conversely, new management or ownership agrees to honor Group under terms
            set forth in this agreement
          </div>
          <div style="margin-top: 12px">
            <span class="underline">I. Concessions</span>
          </div>
          <ol class="upgrade-list" ${this.protect_component}>
            <li>
              Suite upgrades:
              <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                {{suite_upgrades}}</span>
            </li>
            <li>
              Wi-fi:
              <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                {{wifi}}</span>
            </li>
            <li>
              Parking:
              <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                {{parking}}</span>
            </li>
            <li>
              Breakfast:
              <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{breakfast}}</span>
            </li>
            <li>
              Additional terms:
              <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{terms}}</span>
            </li>
            <li>Check-In Age: <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{hotel_check_in_age}}</span></li>
            <li>Check-In Time: <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{hotel_check_in_time}}</span></li>
            <li>Check-Out Time: <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{hotel_check_out_time}}</span></li>
          </ol>
          <div style="margin-top: 12px">
            <span class="font-bold">3. COMPLIANCE WITH LAW.</span> Each of the Parties
            undertakes to comply with all applicable laws, rules regulations and
            ordinances, whether applicable nationally, at federal, state or local
            level or by a competent foreign lawmaker. For the avoidance of doubt, this
            includes applicable laws, rules, regulations and ordinances which relate
            to the processing of personal data/personally identifiable information.
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">4. ACCEPTANCE.</span> Once executed, this
            Agreement shall be effective as of the date first above mentioned (the
            "Effective Date")
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">5. INTELLECTUAL PROPERTY.</span> Hotel shall not
            use any Intellectual Property Rights of Group, Event, or any of their
            respective affiliates. No such rights are granted pursuant to this
            Agreement. For these purposes, "Intellectual Property Rights" means all
            patents, trademarks, and copyright of Group or Event
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">6. INDEMNIFICATION.</span> Hotel agrees to
            indemnify, defend and hold harmless Group, Group's owners, managers,
            partners, subsidiaries, affiliates, officers, directors, employees and
            agents (collectively, the "Group Indemnified Parties"), from and against
            any and all Claims (as such term is defined above) arising out of or
            relating to the Event that is the subject of this Agreement but only to
            the extent any such Claim(s) to the extent any such Claim(s) arise out of
            the negligence, gross negligence or intentional misconduct of Hotel's
            employees, agents, or contractors.
          </div>
          <h3 class="text-xl">7. MISCELLANEOUS.</h3>
          <div style="margin-top: 12px">
            <span class="font-bold">A.</span> Severability Hotel agrees that it will
            not release a No-Show accommodation without prior approval from Crewfare.
            A No-Show room night billed to the master account is considered an
            actualized room night and will count towards Group's final pick-up.
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">B.</span> Entire Agreement This Agreement,
            including any attachments, constitutes the entire agreement of the parties
            and its provisions supersede any and all prior and contemporaneous
            agreements or understandings relating to the same subject matter
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">C.</span> Governing Law. This Agreement shall be
            governed and construed in accordance with the laws of the State of
            Florida, USA, without regard to its choice-of-law principles. Any claim
            arising under this Agreement shall be prosecuted exclusively in a federal
            or state court of competent jurisdiction located within Miami-Dade
            County,Florida, USA and the parties consent to the jurisdiction of such
            court and to the service of process by mail. Notwithstanding anything to
            the contrary contained herein, each Party hereby waives, to the maximum
            extent not prohibited by law, any right it may have to claim or recover in
            any legal action or proceeding arising under this Agreement any special,
            exemplary, punitive or consequential damages.
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">D.</span> Successors and Assigns. This Agreement
            may not be transferred or assigned by any party without the prior written
            consent of the other parties, which may not be unreasonably withheld or
            delayed, provided that F1R may assign this Agreement to its subsidiary
            undertakings, its holding company or holding companies and other
            subsidiary undertakings of each of its holding companies from time to
            time, each, an "Affiliate", and "Affiliates" shall mean all or more than
            one such Affiliate. Any attempted assignment or transfer in violation of
            this Section shall be void and a material breach of this Agreement. This
            Agreement will be binding upon any authorized successors or assigns. Prior
            to any transfer or assignment of this Agreement, the party seeking to
            assign or transfer this Agreement shall obtain a written acknowledgement
            of the binding nature of this Agreement from the successor or assignee
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">E.</span> Authority Hotel hereby represents and
            warrants to Crewfare that: (i) Hotel has the authority to enter into this
            Agreement; (ii) its execution and performance of this Agreement does not
            and will not conflict with or violate any law, regulation or agreement by
            which it is bound; and (iii) Hotel has obtained all consents, approvals
            and/or authorizations necessary to make this Agreement binding upon the
            Hotel, the owner of the Hotel, Hotel's management and all other persons or
            entities which ay have any interest in the Hotel, its management and/or
            premises and upon any and all successors purchaser or transferees which
            may obtain any interest in Hotel, its management and/or premises during
            the term of this Agreement. At Crewfare's request, Hotel shall use its
            best efforts to obtain a written non-interference agreement from any
            person or entity which may have any ownership, mortgage or deb-related
            interest in Hotel. This non-interference agreement shall provide that any
            successor-in-title by foreclosure or to other conveyance will be bound by
            this Agreement.
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">F.</span> Notices. All notices, demands, requests
            or other communications relating to this agreement shall be in writing and
            shall be mailed, first class, or transmitted by hand delivery or email,
            addressed to the contacts as included in the beginning of the Agreement.
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">G.</span> Amendments. This Agreement may not be
            modified, amended, terminated or otherwise changed, except by a written
            instrument executed by each of the Parties.
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">H.</span> Interpretation. No provisions of this
            Agreement shall be construed against or interpreted to the disadvantage of
            any party to this Agreement by any court or other governmental or judicial
            authority by reason of such party having or being deemed to have drafted
            or dictated such provision.
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">I.</span> Independent Contractors. The status of
            the Parties under this Agreement shall be that of independent contractors.
            No Party shall be authorized to waive any right, or assume or create any
            contract or obligation of any kind in the name of, or on behalf of, the
            others or to make any statement that it has the authority to do so.
            Nothing in this Agreement shall be construed as establishing a
            partnership, joint venture, agency, employment or other similar
            relationship among the parties hereto
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">J.</span> Waiver. The failure of any party to
            enforce any condition or part of this Agreement at any time shall not be
            construed as a waiver of that condition or part, nor shall it forfeit any
            rights to future enforcement thereof.
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">K.</span> Counterparts. This Agreement may be
            executed and delivered in counterparts, each of which shall be deemed an
            original and all of which, taken together, shall constitute one
            instrument. Copies of executed counterparts of this Agreement transmitted
            by email shall be considered original executed counterparts provided that
            receipt of such email is confirmed.
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">L.</span> Costs Each Party shall bear its own
            costs in connection with the preparation, negotiation and execution of
            this Agreement
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">M.</span> Force Majeure. Neither party shall be
            liable for any failure or delay in performance of its obligations under
            this Agreement arising out of or caused, directly or indirectly, by
            circumstances beyond its reasonable control, including, without
            limitation, cancellation of the Event for whatever reason; government
            regulations prohibiting travel to the city in which the Hotel is located
            over the Event dates and/or the issuance of a "Level 3 Travel Warning" by
            the Centers for Disease Control that specifically advises travelers to
            avoid all non-essential travel to the city in which the Hotel or the Event
            is located and which travel advisory is in effect over the Event dates;
            acts of God; earthquakes; fires; floods; wars; civil or military
            disturbances; acts of terrorism; sabotage; strikes; epidemics; riots;
            power failures; computer failure and any such circumstances beyond its
            reasonable control as may cause interruption, loss or malfunction of
            utility, transportation, computer (hardware or software) or telephone
            communication service; accidents; labor disputes; acts of civil or
            military authority; governmental actions; or inability to obtain labor,
            material, equipment or transportation. The affected party may terminate
            this Agreement without liability upon providing written notice to the
            other party within ten (10) days of the occurrence. If the Event is
            properly cancelled this shall be considered a valid impossibility force
            majeure occurrence, then upon written request by Group, Hotel agrees to
            refund to Group all prepaid deposits or advance payments paid to Hotel
            without deduction, less any expenses incurred by Hotel in preparation for
            the Event.
          </div>
          <div style="margin-top: 12px">
            IN WITNESS WHEREOF, the Parties have executed this Agreement as of the
            Effective Date.
          </div>
        </div>
      `;
    },
    async saveComment() {},
    setInit() {
      this.init = {
        external_plugins: {
          flite: "/flite/plugin.ts",
        },
        plugins: "code tinycomments pluginVariable",
        toolbar:
          "bold italic underline | highlightcomments prevcomment showcomments nextcomment | flite",
        menubar: "format flite tc",
        menu: {
          tc: {
            title: "Comments",
            items: "addcomment deleteallconversations",
          },
        },
        height: 700,
        variable_mapper: {
          hotel_title: this.rfp.data().hotel_title || "-",
          hotel_person_name: this.rfp.data().hotel_person_name || "-",
          hotel_address: this.rfp.data().hotel_address || "-",
          hotel_email: this.rfp.data().hotel_email || "-",
          iata: this.rfp.data().iata || "-",
          crewfare_members: this.rfp.data().crewfare_members || "-",
          rebate:
            this.rfp.data().rebate === "Other"
              ? this.rfp.data().rebateAmount
              : this.rfp.data().rebate || "-",
          effective_date_formated: this.effectiveDateFormated || "-",
          rfp_name: this.rfp.data().name || "-",
          room_list_table: this.getRoomListTable() || "",
          room_rate_table: this.getRoomRateTable() || "",
          commission:
            this.rfp.data().commission === "Other"
              ? `${this.rfp.data().commissionOtherAmount}%`
              : this.rfp.data().commission || "-",
          group_honored_rate: this.getGroupHonoredRate() || "-",
          start_date_formated: this.startDateFormated || "-",
          end_date_formated: this.endDateFormated || "-",
          cutoff_date_formated: this.cutoffDateFormated || "-",
          suite_upgrades: this.rfp.data().suiteUpgrades || "",
          wifi:
            this.rfp.data().wifi === "Not included"
              ? this.rfp.data().wifi_price
              : this.rfp.data().wifi || "-",
          parking:
            this.rfp.data().parking === "Not included"
              ? this.rfp.data().parkingOther
              : this.rfp.data().parking || "-",
          breakfast:
            this.rfp.data().breakfast === "Not included"
              ? this.rfp.data().breakfastOther
              : this.rfp.data().breakfast || "-",
          terms: !this.rfp.data().terms ? "No" : this.rfp.data().terms,
          comp_rooms: this.rfp.data().compRooms || "-",
          hotel_telephone: this.rfp.data().hotel_telephone || "-",
          get_room_list_loop: this.getRoomListLoop() || "-",
          cutoff_days: this.cutoffDays || "-",
          get_total: this.getTotal() || "-",
          get_total_by_type_loop: this.getTotalByTypeLoop() || "-",
          room_rate_loop: this.roomRateLoop() || "-",
          room_types_table: this.getRoomTypeTable() || "-",
          reward_points: this.rfp.data().rewardPoints || "-",
          hotel_taxes: this.rfp.data().hotel_taxes || "-",
          resort_fee: this.getResortFee() || "-",
          other_fees: this.getOtherFees() || "-",
          group_date_honored: this.rfp.data().groupDateHonored || "-",
          hotel_name: this.rfp.data().hotel_name || "-",
          hotel_check_in_age: this.rfp.data().hotel_check_in_age || "-",
          hotel_check_in_time: this.rfp.data().hotel_check_in_time || "-",
          hotel_check_out_time: this.rfp.data().hotel_check_out_time || "-",
          hotel_local_occupancy_tax:
            this.rfp.data().hotel_local_occupancy_tax || "-",
          hotel_state_occupancy_tax:
            this.rfp.data().hotel_state_occupancy_tax || "-",
          hotel_city_tax: this.rfp.data().hotel_city_tax || "-",
          hotel_convention_tax: this.rfp.data().hotel_convention_tax || "-",
          hotel_vat_tax: this.rfp.data().hotel_vat_tax || "-",
          hotel_sat_tax: this.rfp.data().hotel_sat_tax || "-",
          hotel_municipal_accomodation_tax:
            this.rfp.data().hotel_municipal_accomodation_tax || "-",
          hotel_hst_tax: this.rfp.data().hotel_hst_tax || "-",
          hotel_sustaintability_fee:
            this.rfp.data().hotel_sustaintability_fee || "-",
          room_type: "",
        },
        tinycomments_mode: "embedded",
        tinycomments_author: this.account.name || "Anonymous",
        tinycomments_can_resolve: (req, done, fail) => {
          const allowed =
            req.comments.length > 0 && req.comments[0].author === currentAuthor;
          done({
            canResolve: allowed || currentAuthor === userAllowedToResolve,
          });
        },
        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:16px }.mce-annotation.tox-comment { background-color: #ffe89d; }",
        flite: {
          isTracking: true,
          sessionPolicy: "always",
          isVisible: true,
          autoNext: true,
          removeDeleteOnPaste: true,
          allowFindDeletedText: false,
          preserveWhiteSpace: true,
          trackApiInsert: true,
          showChangesWhenInactive: true,
          ignoreContainers: "",
          contextmenu: true,
          users: [
            { name: "Hotel", id: "1" },
            { name: "crewfare", id: "0" },
          ],
          user: this.isHotel ? "1" : "0",
          debug: {
            log: true,
            error: true,
            warn: true,
            debug: false,
          },
          statusCallback: (options) => {
            if (!options.enabled || !options.change) {
                // we only override if the cursor is in a change and FLITE
                // thinks the command should be enabled
                return;
            }
            if (options.command !== "flite-acceptone"
                && options.command !== "flite-rejectone") {
                return;
            }
            const u = options.plugin.getUserInfo();
            return {
                enabled: u?.id !== options.change.userid
            }
          }
        },
        setup: (editor) => {
          const moveToComment = (change)=>{
              const comments  = editor.annotator.getAll('tinycomments');
              const commentIds = Object.keys(comments)
              if(!commentIds.length) return;
              if(!this.is_comment_open){
                editor.execCommand("ToggleSidebar", false, "showcomments");
              }
              const nextKeyIndex = (this.changeIndex+change) % commentIds.length;
              this.changeIndex = nextKeyIndex
              const nextKey = commentIds[nextKeyIndex]
              const commentNode = comments[nextKey][0]
              //Focus inside editor
              editor.focus()
              //get current scroll position of browser
              const scrollY = 152
              editor.selection.setCursorLocation(commentNode, 1)
              commentNode.scrollIntoView({behavior: "instant", block: "end", inline: "start"})
              // setTimeout(()=>{
              //   //Move the scroll position to the previous position
              //   window.scrollTo(0, scrollY)
              // }, 50)
              

            };
          editor.ui.registry.addButton('prevcomment', {
            icon: 'chevron-left',
            onAction: (_) => {
              moveToComment(-1)
            }
          });
          editor.ui.registry.addButton('nextcomment', {
            icon: 'chevron-right',
            onAction: (_) => {
              moveToComment(1)
            }
          });
          editor.on("ToggleSidebar", ()=>{
            this.is_comment_open = !this.is_comment_open;
          })
          editor.on("init", () => {
            editor.setContent(
              this.contract_content || this.getDefaultContractContent()
            );
          });
          editor.on("flite:init", (flite) => {
            this.$emit("initChanges", editor, flite);
            moveToComment(0)
          });
          editor.on("flite:change", (flite) => {
            console.log("Flite change called")
            this.$emit("hasChanges", editor, flite);
          });
          editor.on("flite:accept", (flite) => {
            this.$emit("acceptChange", editor, flite);
          });
          editor.on("flite:reject", (flite)=>{
            this.$emit("rejectChange", editor, flite)
          })
          editor.on("CommentChange", () => {
            this.$emit("saveComment", editor);
          });
          editor.on("variableClick", () => {
            this.dialog = true;
          });
          editor.on("BeforeExecCommand", (evt) => {
            function prevent(){
              evt.preventDefault();
              evt.stopImmediatePropagation();
            }
            const flite = editor.plugins.flite;
            const currentNode = flite.tracker.currentChangeNode(flite.getSelectedNode());
            console.log(flite.tracker)
            const user = flite.getUserInfo();
            const opts = {
                exclude: [user.id ]
              };
            const changes = flite.getChanges().changes;
            switch(evt.command){
              case "flite-acceptall":
                prevent()
                flite.acceptAll(opts);
                return false;
              case "flite-acceptone":
                prevent()
                const changeId = flite.tracker.getNodeChangeId(currentNode)
                const change = changes[changeId]
                console.log({changeId, change})
                if(change.item.userid === user.id){
                  alert("You are not allowed to accept your own change");
                  return;
                }
                flite.acceptChange(currentNode, opts)
                return false;
              case "flite-rejectall":
                prevent()
                flite.rejectAll()
                return false;
              case "flite-rejectone":
                prevent()
                flite.rejectChange(currentNode)
                return false;
            }
          })
          window.tinymce.PluginManager.add("pluginVariable", pluginVariable);
        },
      };
    },
  },
  mounted() {
    this.setInit();
  },
  created() {
    window.onbeforeunload = () =>
      this.has_comments || this.has_changes ? true : null;
  },
  // async beforeUnmount() {
  //   if (this.has_comments || this.has_changes) {
  //     const contract_content = this.$refs["editor"].getEditor().getContent();
  //     let data = {
  //       ...this.rfp.data(),
  //       contract_content,
  //     };
  //     await setDoc(doc(firestore, "rfp_hotel", this.rfp.id), data);
  //   }
  // },
};
</script>
