<template>
  <div
    class="animate-pulse grid grid-cols-5 px-4 flex justify-between py-2 items-center text-white"
  >
    <p class="text-sm">
      <span class="flex text-sm w-[150px] h-[18px] bg-gray-500 rounded"></span>
    </p>
    <p class="text-sm">
      <span class="flex text-sm w-[160px] h-[18px] bg-gray-500 rounded"></span>
    </p>
    <p class="text-sm">
      <span class="flex text-sm w-[160px] h-[18px] bg-gray-500 rounded"></span>
    </p>
    <p class="text-sm">
      <span class="flex text-sm w-[70px] h-[18px] bg-gray-500 rounded"></span>
    </p>
    <div class="flex gap-4 justify-end items-center">
      <span class="w-[52px] h-[28px] bg-gray-500 rounded"> </span>
      <span class="w-[52px] h-[28px] bg-gray-500 rounded"> </span>
    </div>
  </div>
</template>
