<template>
  <div
    class="bg-gray-900 w-full py-4 flex max-h-screen overflow-hidden justify-center gap-4 stage-height items-center text-white relative"
  >
    <div>
      <img src="/logo.svg" class="h-[50px] mb-12 mx-auto" />
      <div class="text-center">
        <div class="text-center text-xl">Your bid have been sent!</div>
        <div class="text-center">Our team will get back to you shortly!</div>
      </div>
    </div>
    <ConffetiAnimation />
  </div>
</template>

<script>
import ConffetiAnimation from "@/components/default/ConffetiAnimation.vue";

export default {
  components: {
    ConffetiAnimation,
  },
};
</script>
