<template>
  <div class="text-white flex-grow flex flex-col gap-4 px-6 py-3 bg-[#23262F]">
    <div class="flex justify-between">
      <Breadcrumb icon="th-large" :items="['Messages']" />

      <button
        v-if="!newMessage"
        @click="newMessage = true"
        class="border border-crewfareGreen text-crewfareGreen hover:bg-crewfareGreen hover:text-black rounded-full px-4 py-2 flex cursor-pointer justify-between items-center gap-2"
      >
        <font-awesome-icon :icon="['fas', 'add']" />
        New Message
      </button>
      <button
        v-else
        @click="newMessage = false"
        class="border border-white rounded-full px-4 py-2 flex cursor-pointer justify-between items-center"
      >
        <span class="material-symbols-rounded text-[16px]">close</span>
        Back
      </button>
    </div>
    <div class="bg-[#141416] p-4 flex flex-col flex-1 gap-4">
      <div v-if="isCrewfare" class="flex bg-[#000000]">
        <button
          @click="toggleType('messages')"
          class="px-4 py-2 w-full border-b border-[#000]"
          :class="type === 'messages' ? 'border-[#6707FD]' : ''"
        >
          Messages
        </button>
        <button
          @click="toggleType('comments')"
          class="px-4 py-2 w-full border-b border-[#000]"
          :class="type === 'comments' ? 'border-[#6707FD]' : ''"
        >
          Comments On Concessions
        </button>
        <button
          @click="toggleType('notes')"
          class="px-4 py-2 w-full border-b border-[#000]"
          :class="type === 'notes' ? 'border-[#6707FD]' : ''"
        >
          Notes On RFP
        </button>
      </div>
      <div class="flex flex-grow gap-4">
        <div
          class="p-2 flex-grow min-w-[310px] max-w-[310px] bg-[#23262F] rounded-lg overflow-auto h-[81vh]"
        >
          <div v-if="newMessage" class="flex flex-col gap-2">
            <AdminInternal
              v-if="isInternal"
              @toggleMessage="newMessage = false"
            />
            <Hotel
              v-if="['contact'].includes(account.type.toLowerCase())"
              @toggleMessage="newMessage = false"
            />
            <NSO
              v-if="
                ['nso', 'lead manager'].includes(
                  account.type.toLowerCase()
                )
              "
              @toggleMessage="newMessage = false"
            />
          </div>
          <div v-else class="flex flex-col gap-4">
            <input
              type="search"
              placeholder="Search..."
              v-model="search"
              class="bg-gray-800 rounded"
            />
            <div v-if="isInternal" class="flex gap-4">
              <button
                @click="filteredMine = true"
                class="w-full py-2"
                :class="filteredMine ? 'border-b' : 'text-gray-400'"
              >
                Mine
              </button>
              <button
                @click="filteredMine = false"
                class="w-full py-2"
                :class="!filteredMine ? 'border-b' : 'text-gray-400'"
              >
                All
              </button>
            </div>
            <ListItem
              v-for="message in messagesFiltered"
              :key="message.id"
              :message="message"
              @getData="getData"
            />
          </div>
        </div>
        <div class="bg-[#23262F] rounded-lg flex flex-grow">
          <RouterView />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RouterView } from "vue-router";
import Breadcrumb from "@/components/default/Breadcrumb.vue";
import { ListItem, ListItemSkeleton } from "@/components/messages";
import { firestore } from "@/utils/firebase";
import {
  onSnapshot,
  orderBy,
  query,
  getDocs,
  getDoc,
  collection,
  limit,
  where,
  or,
  doc,
  startAfter,
} from "firebase/firestore";
import { sendMessageCrewfare } from "@/utils/messages.jsx";
import { AdminInternal, Hotel, NSO } from "@/components/messages/options/";
import { getHotelsIdsByGroup } from "@/utils/hotel";

export default {
  components: {
    Breadcrumb,
    ListItem,
    RouterView,
    ListItemSkeleton,
    AdminInternal,
    Hotel,
    NSO,
  },
  data() {
    return {
      newMessage: false,
      loadingMessage: false,
      loading: false,
      messages: [],
      type: null,
      search: "",
      filteredMine: true,
    };
  },
  computed: {
    isInternal() {
      return ["internal", "admin"].includes(
        this.account.type.toLowerCase()
      );
    },
    messagesFiltered() {
      if (this.search.length > 0) {
        return this.messages.filter((message) => {
          return (
            message
              .data()
              .title?.toLowerCase()
              .includes(this.search.toLowerCase()) ||
            message
              .data()
              .subtitle?.toLowerCase()
              .includes(this.search.toLowerCase()) ||
            message
              .data()
              .hotel_name?.toLowerCase()
              .includes(this.search.toLowerCase())
          );
        });
      }
      if (this.isInternal) {
        if (this.filteredMine) {
          return this.messages.filter((message) =>
            message.data().accounts?.includes(this.account.email)
          );
        }
      }
      return this.messages;
    },
    account() {
      return this.$store.state.account;
    },
    isNSO() {
      return this.account.type.toLowerCase() === "nso";
    },
    isCrewfare() {
      return ["internal", "admin"].includes(
        this.account.type.toLowerCase()
      );
    },
    isHotel() {
      return ["contact", "group manager"].includes(
        this.account.type.toLowerCase()
      );
    },
    messagesFound() {
      return this.messages.length || 0;
    },
  },
  created() {
    document.title = "Launchpad - Crewfare - Messages";
  },
  async mounted() {
    if (this.isNSO) {
      this.type = "direct";
    }
    if (this.isCrewfare) {
      this.type = "messages";
    }
    this.getData();
  },
  methods: {
    sendMessageCrewfareAction() {
      this.loadingMessage = true;
      sendMessageCrewfare(this.account);
      this.loadingMessage = false;
    },
    toggleType(type) {
      this.messages = [];
      this.type = type;
      this.getData();
    },
    async getData() {
      this.loading = true;
      switch (this.account.type.toLowerCase()) {
        case "internal":
        case "admin":
          await this.getAdminMessages();
          break;
        case "group manager":
          await this.getGroupMessages();
          break;
        case "nso":
          await this.getNSOMessages();
          break;
      }
      if (this.account.hotelIds && this.account.hotelIds.length > 0) {
        await this.getHotelMessages();
      }
      this.loading = false;
    },
    async getHotelMessages() {
      onSnapshot(
        query(
          collection(firestore, "messages"),
          or(
            where("hotel_id", "in", this.account.hotelIds)
          ),
          orderBy("last_interaction", "desc")
        ),
        async (doc) => {
          const messages = doc.docs;
          this.messages = messages;
        }
      );
    },
    getAdminMessages() {
      let q = [];
      if (this.type === "messages") {
        q.push(where("internal", "==", true));
      }
      if (this.type === "comments") {
        q.push(where("type", "==", "Comment on concession"));
      }
      if (this.type === "notes") {
        q.push(where("type", "==", "Notes on RFP"));
      }
      onSnapshot(
        query(
          collection(firestore, "messages"),
          ...q,
          orderBy("last_interaction", "desc")
        ),
        async (doc) => {
          this.messages = doc.docs;
        }
      );
    },
    async getGroupMessages() {
      const hotel_ids = await getHotelsIdsByGroup();
      const hotels_filtered = hotel_ids.splice(0, 30);

      onSnapshot(
        query(
          collection(firestore, "messages"),
          where("hotel_id", "in", hotels_filtered),
          orderBy("last_interaction", "desc")
        ),
        async (doc) => {
          const messages = doc.docs;
          this.messages = messages;
        }
      );
    },
    async getNSOMessages() {
      if (this.type === "direct") {
        onSnapshot(
          query(
            collection(firestore, "messages"),
            or(where("account_id", "array-contains-any", [this.account.id])),
            orderBy("last_interaction", "desc")
          ),
          async (doc) => {
            const messages = doc.docs;
            this.messages = messages;
          }
        );
      }
      if (this.type === "property") {
        let qChain = query(
          collection(firestore, "chains"),
          where("manager_ids", "array-contains-any", [this.account.id])
        );

        const chains = await getDocs(qChain);
        this.chains = chains.docs.map((chain) => chain.data().name);
        onSnapshot(
          query(
            collection(firestore, "messages"),
            or(
              where("chain_single", "in", this.chains),
              where("chain_all", "in", this.chains)
            ),
            orderBy("last_interaction", "desc")
          ),
          async (doc) => {
            const messages = doc.docs;
            this.messages = messages;
          }
        );
      }
    },
  },
};
</script>
