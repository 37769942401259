import ListHolder from './Hotels.vue';
import HotelsList from './List.vue';
import HotelsListState from './ListState.vue';
import Dashboard from '@/layouts/Dashboard.vue';
import Form from './Form.vue';
import Groups from './Groups.vue';
import GroupForm from './GroupForm.vue';
import ContactForm from './contacts/Form.vue';
import ContactList from './contacts/List.vue';
import Chain from './Chain.vue';
import ChainForm from './ChainForm.vue';

export const hotel = [
  {
    path: '/hotels',
    name: 'hotels',
    component: Dashboard,
    meta: {
      view: 'Hotels',
    },
    children: [
      {
        path: '',
        name: 'hotelsHolder',
        component: ListHolder,
        children: [
          {
            path: 'list',
            name: 'hotelsList',
            component: HotelsListState,
          },
          {
            path: 'list/:state',
            name: 'hotelsListState',
            component: HotelsList,
          },
        ],
      },
      {
        path: 'chain',
        name: 'hotelsChainList',
        component: Chain,
      },
      {
        path: 'chain/:chain_id/edit',
        name: 'hotelsChainEdit',
        component: ChainForm,
      },
      {
        path: 'groups',
        name: 'hotelsGroupsList',
        component: Groups,
      },
      {
        path: 'groups/add',
        name: 'hotelsGroupAdd',
        component: GroupForm,
      },
      {
        path: 'groups/:group_id/edit',
        name: 'hotelsGroupEdit',
        component: GroupForm,
      },
      {
        path: 'add',
        name: 'hotelAdd',
        component: Form,
      },
      {
        path: ':id/settings',
        name: 'hotelEdit',
        component: Form,
      },
      {
        path: ':id/contacts',
        name: 'hotelsContacts',
        children: [
          {
            path: '',
            name: 'hotelsContactsList',
            component: ContactList,
          },
          {
            path: 'add',
            name: 'hotelContactsAdd',
            component: ContactForm,
          },
          {
            path: ':contactId/edit',
            name: 'hotelContactsEdit',
            component: ContactForm,
          },
        ],
      },
    ],
  },
];
