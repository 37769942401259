<template>
  <div
    class="grid grid-cols-4 gap-4 px-4 flex justify-between py-2 items-center text-white"
  >
    <p class="text-sm">{{ file.data().name }}</p>
    <p class="text-sm">{{ file.data().created_name }}</p>
    <p class="text-sm capitalize">{{ formatedCreated }}</p>
    <div>
      <div class="flex gap-4 justify-end items-center">
        <button
          @click="removeMessage"
          class="text-red-400 text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1 border-red-400 hover:bg-red-400 hover:text-white"
        >
          delete
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { firestore } from "@/utils/firebase";
import { deleteDoc, doc } from "firebase/firestore";
import moment from "moment";

export default {
  props: ["file"],
  computed: {
    formatedCreated() {
      if (!this.file.data().created_at) return "";
      const created_at = moment(this.file.data().created_at.toDate());
      return created_at.fromNow();
    },
  },
  methods: {
    async removeFile() {
      if (!confirm("Delete this File?")) {
        return;
      }
      deleteDoc(doc(firestore, "files", this.file.id));
      this.$emit("getData");
    },
  },
};
</script>
