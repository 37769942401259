const contractSignedInternalEmailContent = `<tr>
  <td style="width: 100%;border-radius: 8px;background: rgba(34, 197, 94, 0.04);border: 1px dashed rgba(34, 197, 94, 1);padding: 48px 24px;text-align:center; ">
    <table style="margin: 0 auto 40px;">
      <tr>
        <td valign="top">
          <table style="margin: 0 auto;">
            <tr>
              <td valign="center" align="center" style="width: 60px;height:60px;background: rgba(34, 197, 94, 0.08);border-radius:40px;">
                <img src="https://dev-api-launchpad.crewfare.com/email/email-check.png" />
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
    <div style="margin-top:24px;font: bold 24px Poppins, Helvetica, Arial;color: #292929;">Crewfare just signed a contract!</div>
    <div style="margin-top: 24px;font: bold 14px Poppins, Helvetica, Arial;color: #292929;">
    The ##name## contract for ##hotel_name## has just been signed by Crewfare. </div>
  </td>
</tr>
<tr>
  <td height="24px"></td>
</tr>
<tr>
  <td style="width: 100%;border-radius: 8px;background: #F5F5F5;padding:24px;text-align: center;">
    <div style="font: 14px Poppins, Helvetica, Arial;color: #292929;">Click the button below to view the contract.</div>
    <a href="##link##"
      style="border-radius: 8px;font: bold 16px Poppins, Helvetica, Arial;display:block;color: #fff;background: #4323FF;text-align: center;width: 180px;margin: 24px auto 0;text-decoration: none;padding: 16px 18px;">View Contract</a>
    <div style="margin-top:24px;font: 12px Poppins, Helvetica, Arial;color: #292929;">If you have any questions, please reply to this email to reach our team.</div>
  </td>
</tr>`;

const contractSignedInternalSubject = 'Crewfare’s Launchpad - The ##name## contract has just been signed by Crewfare!';

export { contractSignedInternalEmailContent, contractSignedInternalSubject };
