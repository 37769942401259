<template>
  <form @submit.prevent="save" class="gap-4 flex flex-col text-white">
    <p>Account data</p>
    <div class="flex flex-col gap-2">
      <label>Name</label>
      <input
        class="w-full bg-gray-700 rounded px-4"
        v-model="name"
        type="text"
      />
    </div>
    <div class="flex flex-col gap-2">
      <label>Email</label>
      <input
        class="w-full bg-gray-700 rounded px-4"
        v-model="email"
        disabled
        type="text"
      />
    </div>
    <div>
      <button class="green-button">
        <span
          v-if="loading"
          class="material-symbols-rounded animate-spin flex items-center"
        >
          autorenew
        </span>
        Save
      </button>
    </div>
  </form>
</template>

<script>
import { updateAccount } from "@/utils/account.jsx";

export default {
  props: ["account"],
  data() {
    return {
      name: this.account.name,
      email: this.account.email,
      loading: false,
    };
  },
  methods: {
    async save() {
      if(this.loading) return;
      this.loading = true;
      await updateAccount({
        name: this.name,
      });
      this.$store.commit("setToast", {
        content: `<p>Account updated</p>`,
      });
      this.loading = false;
    },
  },
};
</script>
