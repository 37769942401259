<template>
  <div class="bg-gray-800 w-full flex flex-col stage-height">
    <div class="p-4 flex justify-between bg-gray-900 items-center">
      <div class="flex gap-4 items-center">
        <h1 class="text-xl text-white">Hotels - Chain</h1>
      </div>
    </div>
    <div class="flex flex-col gap-2 rounded pb-4 relative">
      <div
        class="flex p-4 items-center bg-gray-800 justify-between sticky top-0"
      >
        <div class="flex gap-2 items-center text-crewfareGreen">
          <strong class="rounded-full bg-black px-3 py-1">{{
            chainsFound
          }}</strong>
          Chains
        </div>
        <div class="flex items-center gap-4 text-white">
          <input
            type="search"
            v-model="search"
            class="bg-gray-700 rounded px-4"
            placeholder="Search..."
          />
        </div>
      </div>
      <div>
        <div v-if="!loading">
          <table
            class="w-full px-4 justify-between py-2 border border-gray-700 border-x-0 border-t-0 text-white"
          >
            <tr class="border-b px-4 border-gray-700">
              <th class="text-white px-4 text-left py-2">Name</th>
              <th class="text-white px-4 text-left">Hotels</th>
              <th class="text-white px-4 text-left">NSOs</th>
              <th class="flex gap-4 px-4 justify-end"></th>
            </tr>
            <tbody class="divide-y px-4 divide-gray-700">
              <ListItem
                v-for="chain in sliceChain"
                :key="chain.id"
                :chain="chain"
                @getData="getData"
              />
            </tbody>
          </table>
          <Pagination
            :max="16"
            :actual="actual"
            :total="filteredChains.length"
            @togglePage="togglePage"
          />
        </div>
        <div v-else>
          <Loading size="text-[30px]" />
        </div>
        
      </div>
    </div>
    <div id="map"></div>
  </div>
</template>

<script>
import { firestore } from "@/utils/firebase";
import { getDocs, query, collection, orderBy } from "firebase/firestore";
import { ListItem } from "@/components/chain";
import Pagination from "@/components/default/Pagination.vue";
import { v4 as uuidv4 } from "uuid";
import Loading from "@/components/default/Loading.vue";

export default {
  components: {
    ListItem,
    Pagination,
    Loading,
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
    sliceChain() {
      const sliceStart = this.actual * 15;
      return this.filteredChains.slice(sliceStart, sliceStart + 15);
    },
    chainsFound() {
      return this.filteredChains.length;
    },
  },
  data() {
    return {
      search: "",
      actual: 0,
      range: [...Array(8).keys()],
      loading: false,
      loadingMore: false,
      chains: [],
      filteredChains: [],
      report: {},
    };
  },
  watch: {
    search() {
      if (this.search.length > 3) {
        this.filteredChains = this.chains.filter(
          (chain) => chain.name.indexOf(this.search) >= 0
        );
      } else {
        this.filteredChains = this.chains;
      }
      this.actual = 0;
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    togglePage(page) {
      this.actual = page;
    },
    async getData() {
      this.loading = true;
      const qHotels = query(collection(firestore, "hotels"), orderBy("name"));
      const hotels = await getDocs(qHotels);
      const hotelsDocs = hotels.docs;
      const chains = [
        ...new Set(
          hotelsDocs
            .filter((hotel) => hotel.data().chain)
            .map((hotel) => hotel.data().chain)
        ),
      ];
      const chainList = [];
      chains.forEach((chain) => {
        const countHotels = hotelsDocs.filter(
          (hotel) => hotel.data().chain === chain
        ).length;
        const uuid = uuidv4();
        chainList.push({
          id: uuid,
          name: chain,
          countHotels,
        });
      });
      this.chains = this.filteredChains = chainList;
      document.title = `Launchpad - Crewfare - Chain`;
      this.loading = false;
    },
  },
};
</script>
