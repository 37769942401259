<template>
  <tr v-if="rfp">
    <td>
      <div class="text-sm px-4 flex flex-col gap-2 justify-center h-full">
        {{ rfp.hotel_name }}
        <div v-if="contacts && contacts.length > 0" class="flex w-full gap-3 items-center">
          <router-link
            :to="{
              name: 'groupHotelsContactsList',
              params: { id: rfp.hotel_id },
            }"
            class="text-white hover:text-crewfareGreen"
          >
            <span class="material-symbols-rounded text-sm">edit</span>
          </router-link>
          <div class="w-full flex flex-col divide-y">
            <span
              v-for="contact in contacts"
              :key="contact.id"
              class="text-xs flex justify-between w-full items-center py-2"
            >
              <span class="flex-grow">
                {{ contact.data().name }}<br />
                &lt;<span class="max-w-[80px] text-ellipsis overflow-hidden">{{
                  contact.data().email
                }}</span
                >&gt;
              </span>
              <span
                v-if="showLink"
                @click="copyRFPLink(contact.data().account_id)"
                class="text-sky-400 flex items-center hover:text-sky-100 gap-4 cursor-pointer"
              >
                Copy RFP link
              </span>
              <span
                v-if="showContractLink"
                @click="copyContractLink(contact.data().account_id)"
                class="text-sky-400 flex items-center hover:text-sky-100 gap-4 cursor-pointer"
              >
                Copy Contract link
              </span>
            </span>
          </div>
          <span
            v-if="contacts.length < 1"
            class="animate-spin material-symbols-rounded text-xs"
            >autorenew</span
          >
        </div>
      </div>
    </td>
    <td class="text-sm p-4">
      <div class="flex flex-col gap-2">
        <router-link
          :to="{
            name: 'rfpView',
            params: { rfp: rfp.id },
          }"
          class="flex flex-col gap-2 justify-center hover:text-crewfareGreen text-xl"
        >
          {{ rfp.name }}
        </router-link>
        <span v-html="statusPill"></span>
        <div class="flex">
          <span
            v-if="contractAvailable"
            class="border animate-pulse border-crewfareGreen px-4 rounded-full py-1 whitespace-nowrap text-crewfareGreen"
            >Contract Available</span
          >
        </div>
        <div class="flex">
          <div v-if="contractAvailable">
            <router-link
              :to="{
                name: 'rfpView',
                params: { rfp: rfp.id },
              }"
              class="hover:bg-crewfareGreen hover:text-black bg-crewfareGreen px-4 rounded-full py-1 text-gray-900 whitespace-nowrap"
            >
              Action needed
            </router-link>
          </div>
        </div>
        <div v-if="hasMessage">
          <router-link
            :to="{
              name: 'messagesChat',
              params: { id: message_id },
            }"
            class="border mt-4 animate-pulse border-red-400 px-4 rounded-full py-1 text-red-400"
          >
            New Message
          </router-link>
        </div>
        <div
          v-if="
            ['declined by hotel', 'declined by crewfare'].includes(
              rfp.status.toLowerCase()
            )
          "
        >
          <button @click="reopen" class="green-button-small gap-2">
            <span
              v-if="loading"
              class="material-symbols-rounded animate-spin text-[12px]"
            >
              autorenew
            </span>
            Reopen this bid
          </button>
        </div>
      </div>
    </td>
    <td class="text-sm px-4 capitalize">
      <div class="text-sm flex justify-center gap-2 capitalize flex-col">
        <div>
          <span class="text-gray-200 mr-2">From</span>
          <span class="font-medium">{{ formatedStartDate }}</span>
        </div>
        <div>
          <span class="text-gray-200 mr-2">To</span>
          <span class="font-medium">{{ formatedEndDate }}</span>
        </div>
      </div>
    </td>
    <td class="text-sm px-4 capitalize">{{ formatedDueDate }}</td>
    <td class="text-sm px-4 capitalize h-full">
      <div class="flex justify-between items-center">
        {{ formatedUpdatedAt }}
        <FwbDropdown placement="bottom">
          <template #trigger>
            <button class="text-white" type="button">
              <font-awesome-icon :icon="['fas', 'ellipsis-vertical']" />
            </button>
          </template>
          <FwbListGroup class="-ml-[170px] w-[180px]">
            <FwbListGroupItem class="!px-0 !py-0 dark:bg-gray-700 bg-gray-700">
              <button
                @click="toggleShareDialog = true"
                class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
              >
                Forward RFP
              </button>
            </FwbListGroupItem>
            <FwbListGroupItem
              v-if="account.type !== 'lead manager'"
              class="!px-0 !py-0"
            >
              <router-link
                :to="{
                  name: 'rfpView',
                  params: { rfp: rfp.id },
                }"
                class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
                :class="!contractAvailable && 'animate-pulse'"
              >
                View RFP
              </router-link>
            </FwbListGroupItem>
            <FwbListGroupItem
              v-if="showContractLink"
              class="!px-0 !py-0"
            >
              <router-link
                :to="{
                  name: 'rfpContract',
                  params: { rfp: rfp.id },
                }"
                class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
                :class="contractAvailable && 'animate-pulse'"
              >
                View Contract
              </router-link>
            </FwbListGroupItem>
            <FwbListGroupItem v-if="message_id" class="!px-0 !py-0">
              <router-link
                :to="{
                  name: 'messagesChat',
                  params: { id: message_id},
                }"
                class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
              >
                Send Message
              </router-link>
            </FwbListGroupItem>
          </FwbListGroup>
        </FwbDropdown>
      </div>
    </td>
    <ForwardDialog
      v-if="toggleShareDialog"
      :rfp="rfp"
      @getData="getData"
      @closeDialog="toggleShareDialog = false"
    />
  </tr>
</template>

<script>
import { firestore } from "@/utils/firebase";
import { reopenRFP } from "@/utils/rfp.jsx";
import { where, getDocs, query, collection } from "firebase/firestore";
import ForwardDialog from "@/components/dashboard/ForwardDialog.vue";
import { RFPStatusPill, contractPhase } from "@/utils/rfp.jsx";
import moment from "moment";
import { FwbDropdown, FwbListGroup, FwbListGroupItem } from "flowbite-vue";
import {getMomentFromFirebaseDate} from "@/utils/dateUtils";

export default {
  props: ["rfp"],
  components: {
    ForwardDialog,
    FwbDropdown,
    FwbListGroup,
    FwbListGroupItem,
  },
  name: "ListItem",
  computed: {
    showContractLink() {
      if (this.account.type !== 'lead manager') return false;
      return contractPhase.includes(this.rfp.status.toLowerCase());
    },
    showLink() {
      return ["sent", "bid viewed", "bid opened", "progress saved"].includes(
        this.rfp.status.toLowerCase()
      );
    },
    contractAvailable() {
      return this.rfp.accepted?.length == 2;
    },
    statusPill() {
      return RFPStatusPill(this.rfp.status, true);
    },
    account() {
      return this.$store.state.account;
    },
    formatedDates() {
      const startDate = this.rfp.startDate;
      const endDate = this.rfp.endDate;
      return `${getMomentFromFirebaseDate(startDate).format(
        "ddd MMM. DD, YYYY"
      )} to ${getMomentFromFirebaseDate(endDate).format("ddd MMM. DD, YYYY")}`;
    },
    formatedStartDate() {
      if (!this.rfp.startDate) return "";
      const startDate = this.rfp.startDate;
      return getMomentFromFirebaseDate(startDate).format("ddd MMM. DD, YYYY");
    },
    formatedEndDate() {
      if (!this.rfp.endDate) return "";
      const endDate = this.rfp.endDate;
      return getMomentFromFirebaseDate(endDate).format("ddd MMM. DD, YYYY");
    },
    formatedCreatedAt() {
      if (!this.rfp.created_at) return "";
      const created_at = getMomentFromFirebaseDate(this.rfp.created_at);
      return created_at.fromNow();
    },
    formatedDueDate() {
      if (!this.rfp.responseDueDate) return "";
      const response_due_date = getMomentFromFirebaseDate(this.rfp.responseDueDate);
      return response_due_date.format("ddd MMM. DD, YYYY");
    },
    formatedUpdatedAt() {
      if (!this.rfp.updated_at) return "";
      const updated_at = getMomentFromFirebaseDate(this.rfp.updated_at);
      return updated_at.fromNow();
    },
  },
  data() {
    return {
      loading: false,
      toggleShareDialog: false,
      contacts: null,
      hasMessage: false,
      message_id: ''
    };
  },
  async mounted() {
    this.getData();
  },
  watch: {
    rfp() {
      this.getData();
    },
  },
  methods: {
    async reopen() {
      this.loading = true;
      await reopenRFP(this.rfp, this.account);
      this.$emit("getData");
      this.loading = false;
    },
    async getData() {
      this.contacts = null;
      const qContacts = query(
        collection(firestore, "contacts"),
        where("hotel_id", "==", this.rfp.hotel_id)
      );
      const contacts = await getDocs(qContacts);
      this.contacts = contacts.docs;

      const qMessages = query(
        collection(firestore, "messages"),
        where("hotel_id", "==", this.rfp.hotel_id)
      );
      const message = await getDocs(qMessages);
      const messageForRFP = message.docs.filter(
        (doc) => doc.data().rfp === this.rfp.id
      );
      if (messageForRFP.length > 1){
        this.hasMessage = messageForRFP[0].new_message;
        this.message_id = messageForRFP[0].id;
      }
    },
    closeDialog() {
      this.toggleShareDialog = false;
    },
    copyContractLink(account_id) {
      navigator.clipboard.writeText(
        `${import.meta.env.VITE_URL}/rfp/${
          this.rfp.id
        }/contract?accountId=${account_id}`
      );
      this.showMenu = false;
      this.$store.commit("setToast", {
        content: `<p>Link copied!</p>`,
      });
    },
    copyRFPLink(account_id) {
      navigator.clipboard.writeText(
        `${import.meta.env.VITE_URL}/rfp/${
          this.rfp.id
        }/view?accountId=${account_id}`
      );
      this.showMenu = false;
      this.$store.commit("setToast", {
        content: `<p>Link copied!</p>`,
      });
    },
  },
};
</script>
