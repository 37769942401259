<template>
  <div>
    <div class="fixed top-0 right-0 left-0 bottom-0" @click="closeDialog"></div>
    <div class="overlay">
      <div
        class="bg-gray-900 shadow-md rounded-lg flex min-w-[598px] flex-col items-center relative text-gray-200"
      >
        <div class="px-4 pt-4 flex justify-between items-center gap-4 w-full">
          <p class="text-2xl">Authorization History</p>
          <span
            @click="closeDialog"
            class="cursor-pointer top-2 right-2 text-red-200 hover:text-red-400 material-symbols-rounded"
          >
            close
          </span>
        </div>
        <div class="w-full max-h-[400px] overflow-auto pl-4">
          <FwbFwbTimelineItem>
            <AuthorizationHistoryItem
              v-for="(item, index) in authorizationHistory"
              :key="index"
              :item="item"
            />
          </FwbFwbTimelineItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FwbTimeline } from "flowbite-vue";
import AuthorizationHistoryItem from "./AuthorizationHistoryItem.vue";

export default {
  props: ["authorizationHistory"],
  components: {
    FwbTimeline,
    AuthorizationHistoryItem,
  },
  methods: {
    closeDialog() {
      this.$emit("hideGroupDialog");
    },
  },
};
</script>
