const bidAcceptedHotelEmailContent = `<tr>
  <td style="width: 100%;border-radius: 8px;background: rgba(34, 197, 94, 0.04);border: 1px dashed rgba(34, 197, 94, 1);padding: 48px 24px;text-align:center; ">
    <div style="font: bold 24px Poppins, Helvetica, Arial;color: #292929;">Your bid has been accepted!</div>
    <div style="margin-top: 24px;font: bold 14px Poppins, Helvetica, Arial;color: #292929;">
    We are excited to move forward into an agreement together, please view our Launchpad contract in the link below!</div>
  </td>
</tr>
<tr>
  <td height="24px"></td>
</tr>
<tr>
  <td style="width: 100%;border-radius: 8px;background: #F5F5F5;padding:24px;text-align: center;">
    <div style="font: 14px Poppins, Helvetica, Arial;color: #292929;">
    We look forward to working with you.
      <br/> 
      Please view the agreement via the link below.
    </div>
    <a href="##link##"
      style="border-radius: 8px;font: bold 16px Poppins, Helvetica, Arial;display:block;color: #fff;background: #4323FF;text-align: center;width: 180px;margin: 24px auto 0;text-decoration: none;padding: 16px 18px;">View Contract</a>
    <div style="margin-top:24px;font: 12px Poppins, Helvetica, Arial;color: #292929;">If you have any questions, please reply to this email to reach our team.</div> 
  </td>
</tr>`;

const bidAcceptedHotelSubject = 'Crewfare’s Launchpad - Your bid for ##name## has been accepted';

export { bidAcceptedHotelEmailContent, bidAcceptedHotelSubject };
