<template>
  <div
    v-if="rfp"
    class="bg-gray-900 w-full py-4 flex justify-center gap-4 stage-height items-center text-white relative"
  >
    <div>
      <img src="/logo.svg" class="h-[50px] mb-12 mx-auto" />
      <div class="text-center text-xl">
        {{
          reasonSent
            ? "You declined this bid."
            : "You are about to decline this bid"
        }}
      </div>
      <div v-if="reasonSent" class="flex justify-center flex-col">
        <p class="text-green-400 my-8 text-center">
          {{ thankYouMessage }}
        </p>
        <div class="flex justify-center">
          <button @click="reopen" class="green-button-small">
            <span v-if="loading" class="material-symbols-rounded animate-spin">
              autorenew
            </span>
            Reopen this bid
          </button>
        </div>
      </div>
      <div v-else>
        <hr class="my-8" />
        <p class="mb-4">
          Can you please tell us why this proposal do not work for you?
        </p>
        <div class="mb-4">
          <div
            ref="reasonInput"
            @keydown="reasonHasChanges = true"
            contenteditable="true"
            class="w-full bg-gray-700 rounded px-4 py-2"
          ></div>
        </div>
        <div class="flex justify-between items-center">
          <router-link
            :to="{ name: 'rfpView', params: { rfp: rfp.id } }"
            class="cancel-button"
            >cancel</router-link
          >
          <span
            @click="sendReason"
            class="green-button"
            :class="
              !reasonHasChanges
                ? 'bg-gray-700 border-gray-700 text-gray-900'
                : 'cursor-pointer hover:bg-crewfareGreen hover:text-gray-900 border-crewfareGreen'
            "
          >
            <span v-if="loading" class="material-symbols-rounded animate-spin">
              autorenew
            </span>
            <span v-else> Send reason </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { firestore } from "@/utils/firebase";
import { doc, getDoc } from "firebase/firestore";
import { reopenRFP, declineRFP } from "@/utils/rfp.jsx";

export default {
  data() {
    return {
      reasonSent: false,
      reasonHasChanges: false,
      loading: false,
      rfp: null,
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
    message() {
      if (
        ["contact", "nso", "group manager"].includes(
          this.account.type.toLowerCase()
        )
      ) {
        return "Hope we work together soon!";
      } else {
        return "This bid is declined.";
      }
    },
    thankYouMessage() {
      if (
        ["contact", "nso", "group manager"].includes(
          this.account.type.toLowerCase()
        )
      ) {
        return "Thanks for sending your response, the team will get back to you soon!";
      } else {
        return "Reason sent!";
      }
    },
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    async reopen() {
      this.loading = true;
      await reopenRFP(this.rfp, this.account);
      router.push({ name: "rfpView", params: { rfp: this.rfp.id } });
      this.loading = false;
    },
    async getData() {
      const rfpRef = doc(firestore, "rfp_hotel", this.$route.params.rfp);
      this.rfp = await getDoc(rfpRef);
    },
    async sendReason() {
      if (!this.reasonHasChanges) return;
      this.loading = true;
      const reasonDecline = this.$refs.reasonInput.innerHTML;
      await declineRFP(this.rfp, this.account, reasonDecline);
      this.reasonSent = true;
      this.loading = false;
    },
  },
};
</script>
