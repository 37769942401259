<template>
  <div class="flex flex-col gap-2">
    <span>{{ text }}</span>
    <div class="flex gap-2">
      <a
        v-if="rfpData[field]"
        :href="rfpData[field]"
        class="green-button-small"
      >
        <span class="material-symbols-rounded"> download </span>
      </a>
      <button
        @click="$refs['fileUpload'].click()"
        class="green-button-small gap-2"
      >
        <span
          class="material-symbols-rounded"
          :class="importing && 'animate-spin'"
        >
          {{ importing ? "autorenew" : "upload" }}
        </span>
      </button>
      <input
        type="file"
        ref="fileUpload"
        id="fileUpload"
        @change="uploadFile"
        class="cursor-pointer fixed -left-[100vw]"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ["text", "field", "rfp"],
  computed: {
    rfpData() {
      return this.rfp.data();
    },
  },
  data() {
    return {
      importing: false,
    };
  },
  methods: {
    async uploadFile() {
      this.importing = true;
      var file = document.getElementById("fileUpload").files[0];
      if (file) {
        const storage = getStorage();
        const uuid = uuidv4();
        const name = file.name;
        const ext = name.split(".").reverse()[0];
        this.storageRef = ref(storage, `${uuid}.${ext}`);
        this.upload = true;
        uploadBytes(this.storageRef, file).then((snapshot) => {
          getDownloadURL(this.storageRef).then(async (url) => {
            const rfpRef = doc(firestore, "rfp_hotel", this.rfp.id);
            this.rfp = await getDoc(rfpRef);
            const rfpData = this.rfp.data();
            rfpData[this.field] = url;
            await setDoc(doc(firestore, "rfp_hotel", this.rfp.id), {
              ...rfpData,
              updated_at: new Date(),
            });
            this.upload = false;
          });
        });
      }
    },
  },
};
</script>
