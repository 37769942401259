<template>
  <nav class="flex justify-center mt-6" aria-label="Page navigation example">
    <ul class="inline-flex -space-x-px text-sm rounded-lg">
      <li v-for="(page, index) in pages" :key="index">
        <span
          v-if="page === '...'"
          class="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400"
        >
          ...
        </span>
        <span
          v-else
          @click="togglePage(page)"
          class="cursor-pointer flex items-center justify-center px-3 h-8 leading-tight text-gray-500 border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:hover:bg-gray-700 dark:hover:text-white"
          :class="
            actual === page ? 'bg-crewfareGreen text-gray-900' : 'text-white'
          "
        >
          {{ page + 1 }}
        </span>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: ["total", "actual", "max"],
  computed: {
    pages() {
      let total = Math.ceil(this.total / this.max);
      if (total <= 6) {
        return [...Array(total).keys()];
      }
      if (this.actual > 3 && this.actual < total - 3) {
        return [
          0,
          "...",
          this.actual - 2,
          this.actual - 1,
          this.actual,
          this.actual + 1,
          this.actual + 2,
          "...",
          total - 1,
        ];
      } else if (this.actual <= 3) {
        return [0, 1, 2, 3, 4, "...", total - 1];
      } else {
        return [
          0,
          "...",
          total - 6,
          total - 5,
          total - 4,
          total - 3,
          total - 2,
          total - 1,
        ];
      }
    },
  },
  methods: {
    togglePage(page) {
      this.$emit("togglePage", page);
    },
  },
};
</script>
