<template>
  <Modal :close="close">
    <div class="flex w-full flex-col gap-4 items-center justify-center">
      <template v-if="!fileHasUploaded">
        <div class="flex w-full justify-center gap-4">
          <p class="text-3xl font-semibold">Uploading Hotels</p>
        </div>
        <div class="flex w-full justify-center gap-2 cursor-pointer align-center" @click.capture="downloadTemplate">
          <div class="material-symbols-rounded text-crewfareGreen">download</div>
          <span class="text-base font-normal">Download sample file</span>
        </div>
        <DragAndDrop :passedFunction="handleFileUpload" :onDropFunction="onDropFile" :subtitle="'(Only .CSV File)'">
          <input
            type="file"
            ref="fileUpload"
            accept="text/csv"
            id="fileUpload"
            @change.prevent="updateFile"
            class="cursor-pointer absolute overflow-hidden opacity-0 w-full h-full"
          />
        </DragAndDrop>
      </template>
      <div class="flex flex-col gap-4">
        <div class="flex flex-row gap-2 items-center justify-between">
          <div v-if="uniqueHotelsCount > 0" class="flex gap-4 flex-col justify-center items-center">
            <div class="text-5xl font-bold">{{ uniqueHotelsCount }}</div>
            <div class="flex flex-col">
              <span>Unique hotels found</span>
            </div>
          </div>
        </div>
        <div v-if="uniqueHotelsCount > 0">
          <div class="flex flex-col gap-2 justify-between">
            <div class="flex gap-4">
              <div class="flex gap-4 flex-col">
                <div class="flex flex-col">
                  <span>Expected columns</span>
                </div>
                <div class="flex gap-2">
                  <div class="px-4 py-2" v-for="item in expectedColumns" :key="item">
                    {{ item }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!error">
              <p>Hotels to be uploaded:</p>
              <div class="flex flex-col mt-2 p-2 bg-gray-700 rounded">
                <div class="flex justify-between">
                  <div class="px-4 py-2">Hotel name</div>
                  <div class="px-4 py-2">Number of contacts</div>
                </div>
                <div class="max-h-[30vh] overflow-auto divide-y">
                  <div
                    v-for="hotel in hotelsUnique"
                    :key="hotel"
                    class="flex justify-between"
                    :class="importedHotels.includes(hotel.name) && 'text-crewfareGreen'"
                  >
                    <div class="px-4 py-2">
                      {{ hotel.name }}
                    </div>
                    <div class="px-4 py-2">
                      {{ hotel.contacts }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <p class="py-2 px-4 text-center text-red-600">Please fix following issue</p>
            </div>
          </div>
          <div class="flex items-center mt-4 justify-between gap-4">
            <p>
              {{ error }}
            </p>
            <button
              v-if="!error"
              @click="startUpload"
              class="border border-2 border-[#A4CDE3] hover:bg-[#A4CDE3] hover:text-black px-4 py-2 rounded-full flex gap-2 text-[#A4CDE3]"
            >
              Upload hotels
            </button>
          </div>
        </div>
      </div>
      <div v-if="hotelsImporting">
        <p class="py-2 text-center">The upload is in progress, please wait.</p>

        <div class="flex rounded overflow-hidden border border-crewfareGreen">
          <div
            class="h-[4px] bg-crewfareGreen"
            :style="{
              width: `${(importedHotels.length / uniqueHotelsCount) * 100}%`,
            }"
          ></div>
        </div>
      </div>
      <div v-if="hotelsImporting === false">
        <p class="py-2 text-center">The upload is done, you can close this screen.</p>
      </div>
    </div>
  </Modal>
</template>

<script>
import { downloadAssetFile, importHotels, importHotelsLatLng } from '@/utils/hotel.jsx';
import { isValidEmail, parseCSVLine } from '@crewfare/utils';
import Button from '@/components/ui/atoms/Button.vue';
import Modal from '@/components/ui/atoms/Modal.vue';
import { PUBLIC_FILES } from '@/enums/public-files.enum';
import DragAndDrop from '@/components/ui/Molecule/DragAndDrop.vue';
export default {
  data() {
    return {
      type: 'address',
      file: null,
      hotelsUnique: [],
      allHotels: [],
      expectedColumns: null,
      uniqueHotelsCount: 0,
      correctColumns: false,
      error: '',
      columnsAddress: [
        'Hotel',
        'Hotel Id',
        'Chain',
        'Address',
        'County',
        'City',
        'State',
        'Zip Code',
        'Hotel Contact',
        'Contact Email',
      ],
    };
  },
  components: {
    Button,
    Modal,
    DragAndDrop,
  },
  computed: {
    importedHotels() {
      return this.$store.state.importedHotels;
    },
    account() {
      return this.$store.state.account;
    },
    hotelsImporting() {
      return this.$store.state.hotelImporting;
    },
    fileHasUploaded() {
      return this.file !== null;
    },
  },
  watch: {
    file() {
      this.preUpload();
    },
    allHotels() {
      this.error = '';
      this.expectedColumns = this.columnsAddress;
      const emailIndex = this.expectedColumns.indexOf('Contact Email');
      for (let i = 0; i < this.allHotels.length; i++) {
        if (this.allHotels[i].length < this.expectedColumns.length) {
          this.error = `The hotel on line ${i + 1} has less columns than expected`;
          return;
        }
        const email = this.allHotels[i][emailIndex];
        if (!isValidEmail(email)) {
          this.error = `The hotel on line ${i + 1} has an invalid email: ${email}`;
          return;
        }
      }
    },
  },
  methods: {
    updateFile() {
      this.file = document.getElementById('fileUpload').files[0];
      this.preUpload();
    },
    preUpload() {
      const file = this.file;
      const type = this.type;
      if (file) {
        var reader = new FileReader();
        reader.readAsText(file, 'UTF-8');
        reader.onload = async evt => {
          const result = evt.target.result;
          const lines = result.split('\n');
          const header = lines[0].split(',');
          lines.shift();
          const allHotels = lines
            .map(line => {
              return parseCSVLine(line);
            })
            .filter(line => line[0].length > 0);
          this.allHotels = allHotels;
          const hotels = [...new Set(allHotels.map(line => line[0]))];
          const uniqueHotels = [];
          hotels.forEach(hotel => {
            uniqueHotels.push({
              name: hotel,
              contacts: allHotels.filter(line => line[0] === hotel).length,
            });
          });
          this.hotelsUnique = uniqueHotels;
          this.uniqueHotelsCount = this.hotelsUnique.length;
          this.expectedColumns = type === 'latLng' ? this.columnsLatLng : this.columnsAddress;
          this.correctColumns = header.length === this.expectedColumns.length;
        };
      }
    },
    close() {
      this.$store.commit('importedHotels', []);
      this.$store.commit('setHotelImporting', null);
      this.$emit('close');
    },
    async startUpload() {
      const file = this.file;
      const type = this.type;
      if (type === 'address') {
        await importHotels(file);
      } else {
        await importHotelsLatLng(file);
      }
    },

    downloadTemplate() {
      downloadAssetFile(PUBLIC_FILES.IMPORT_HOTEL_SAMPLE);
    },

    handleFileUpload() {
      this.$refs.fileUpload.click();
    },
    onDropFile(event) {
      this.file = event.dataTransfer.files[0];
      this.preUpload();
    },
  },
};
</script>
