import { firestore } from '@/utils/firebase';
import { query, getDocs, collection, writeBatch, where, addDoc } from 'firebase/firestore';
import store from '@/store';
import { getHotelsIdsByGroup, getHotelsIdByChain } from './hotel.jsx';

const sendNotificationAdmin = async ({ content, type = null, to = null, ids = undefined }) => {
  const qAdmins = query(collection(firestore, 'accounts'), where('type', '==', 'Admin'));
  const data = await getDocs(qAdmins);
  data.docs.forEach(account => {
    if (account.data().notifications && !account.data().notifications.includes(type)) return;
    addDoc(collection(firestore, 'notifications'), {
      content,
      to,
      unread: true,
      account_id: account.id,
      created_at: new Date(),
    });
  });
};

const sendNotificationHotels = async ({ content, type = null, to = null, ids }) => {
  ids.forEach(id => {
    addDoc(collection(firestore, 'notifications'), {
      content,
      to,
      unread: true,
      hotel_id: id,
      created_at: new Date(),
    });
  });
};

const markNotificationsAsRead = async () => {
  const account = store.state.account;
  switch (account.type.toLowerCase()) {
    case 'internal':
    case 'admin':
      await updateUserNotifications();
      break;
    case 'group manager':
      await updateGroupNotifications();
      break;
    case 'nso':
      await updateNSONotifications();
      break;
  }
  if (account.hotelIds && account.hotelIds.length > 0) {
    await updateHotelNotifications();
  }
};

const updateGroupNotifications = async () => {
  const hotel_ids = await getHotelsIdsByGroup();
  const notifications = await getNotifications([where('hotel_id', 'in', hotel_ids.splice(0, 30))]);
  await updateNotificationsAsRead(notifications);
};

const updateUserNotifications = async () => {
  const account = store.state.account;
  const notifications = await getNotifications([where('account_id', '==', account.id)]);
  await updateNotificationsAsRead(notifications);
};

const updateNSONotifications = async () => {
  const hotel_ids = await getHotelsIdByChain();
  const hotels_filtered = hotel_ids.splice(0, 30);
  const notifications = await getNotifications([where('hotel_id', 'in', hotels_filtered)]);
  await updateNotificationsAsRead(notifications);
};

const updateHotelNotifications = async () => {
  const account = store.state.account;
  const notifications = await getNotifications([where('hotel_id', 'in', account.hotelIds)]);
  await updateNotificationsAsRead(notifications);
};

const updateNotificationsAsRead = async notifications => {
  const batch = writeBatch(firestore);
  for (const notification of notifications.docs) {
    batch.update(notification.ref, { unread: false, updated_at: new Date() });
  }
  await batch.commit();
};

const getNotifications = async params => {
  let qNotifications = query(collection(firestore, 'notifications'), where('unread', '==', true), ...params);
  return await getDocs(qNotifications);
};

export { sendNotificationAdmin, sendNotificationHotels, markNotificationsAsRead };
