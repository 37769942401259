<template>
  <div class="flex flex-col gap-1 w-full">
    <div
      class="text-sm font-bold flex justify-between items-center mb-2"
      :class="has_changes && 'text-yellow-500'"
    >
      <div class="flex gap-2 items-center">
        <span
          class="material-symbols-rounded text-[16px]"
          :class="checked ? 'text-crewfareGreen' : 'text-white'"
        >
          {{ checked ? "check_box" : "check_box_outline_blank" }}
        </span>
        Resort Fee:
      </div>

      <div class="flex gap-4 print:hidden">
        <ButtonNotesDialog
          v-if="!isNSO"
          type="Comment on concession"
          :subtitle="rfp.name"
          title="Comments at Resort Fee"
          :id="`${rfp.id}-resort-fee`"
          :rfp="rfp.id"
          :hotel_id="rfp.hotel_id"
          field="Resort Fee"
          text="Comment"
        />
        <span
          v-if="is_locked"
          class="text-[18px] flex items-center px-4 print:px-0 material-symbols-rounded text-crewfarePurple py-1"
        >
          lock
        </span>
      </div>
    </div>
    <select
      :disabled="is_locked || is_blocked"
      class="w-full bg-gray-700 h-[40px] print:appearance-none px-4 print:px-0 rounded"
      v-model="resort"
      @change="save_resort"
      :class="
        has_changes
          ? 'bg-yellow-500 text-gray-900'
          : 'bg-gray-700 text-white print:text-black'
      "
    >
      <option>Select</option>
      <option>No Resort Fee</option>
      <option>Resort Fee</option>
    </select>
    <div v-if="resort === 'Resort Fee'" class="flex flex-col gap-1 mt-4">
      <div class="text-sm font-bold" :class="has_changes && 'text-yellow-500'">
        Resort Fee Amount:
      </div>
      <input
        :disabled="is_blocked || is_locked"
        type="text"
        v-model="amount"
        class="w-full bg-gray-700 rounded px-4 print:px-0"
        @blur="save_amount"
        :class="
          has_changes
            ? 'bg-yellow-500 text-gray-900'
            : 'bg-gray-700 text-white print:text-black'
        "
      />
      <small v-if="has_changes" class="text-yellow-500">{{ changes_by }}</small>
    </div>
  </div>
</template>

<script>
import { noteAdded, noteDone } from "@/utils/messages.jsx";
import {
  saveField,
  checkIsLocked,
  checkHasChanges,
  getChangesBy,
} from "@/utils/rfp.jsx";
import ButtonNotesDialog from "@/components/default/ButtonNotesDialog.vue";

export default {
  props: ["rfp", "account", "is_blocked"],
  components: {
    ButtonNotesDialog,
  },
  data() {
    return {
      resort: "",
      amount: "",
      is_locked: false,
      changes_by: null,
      has_changes: null,
    };
  },
  computed: {
    checked() {
      return this.resort === this.rfp.resortFee;
    },
    rfpSession() {
      return this.$store.state.rfpSession;
    },
    isNSO() {
      return this.account.type.toLowerCase() === "nso";
    },
  },
  watch: {
    rfpSession() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.resort = this.rfp.resortFee;
      this.amount = this.rfp.resortFeeAmount;
      if (this.account) {
        const account_type = this.account.type.toLowerCase();
        this.has_changes =
          checkHasChanges(this.rfp, "Resort Fee", account_type) ||
          checkHasChanges(this.rfp, "Resort Fee Amount", account_type);
        this.is_locked = checkIsLocked(this.rfp, "resortFee");
        if (this.has_changes) {
          this.changes_by = getChangesBy(this.has_changes, this.rfp);
        }
      }
    },
    async note_done() {
      noteDone("Resort Fee", this.rfp);
      this.$emit("refreshData");
    },
    async note_added() {
      noteAdded("Resort Fee", this.rfp);
      this.$emit("refreshData");
    },
    async save_resort() {
      if (this.is_blocked) return;
      await saveField(
        this.rfp,
        {
          label: "Resort Fee",
          before: this.rfp.resortFee,
          after: this.resort || "",
        },
        {
          resortFee: this.resort || "",
        },
        "resortFee"
      );
      this.$emit("refreshData");
    },
    async save_amount() {
      if (this.is_blocked) return;
      await saveField(
        this.rfp,
        {
          label: "Resort Fee Amount",
          before: this.rfp.resortFeeAmount,
          after: this.amount || "",
        },
        {
          resortFeeAmount: this.amount || "",
        },
        "resortFeeAmount"
      );
      this.$emit("refreshData");
    },
  },
};
</script>
