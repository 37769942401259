<template>
  <li>
    <span
      class="flex text-white p-4 flex-col hover:text-crewfareGreen cursor-pointer hover:bg-gray-800"
      :class="
        message.data().new_message &&
        message.data().new_message_by_id !== this.account.id &&
        'bg-gray-700'
      "
      @click="setMessage"
    >
      <div class="text-sm">
        {{ message.data().title && `${message.data().title} - ` }}
        {{ message.data().new_message_by_name }}
      </div>
      <div class="truncate">
        {{ content }}
      </div>
      <div class="text-xs capitalize">
        {{ formatedCreatedAt }}
      </div>
    </span>
  </li>
</template>

<script>
import moment from "moment";

export default {
  props: ["message"],
  computed: {
    account() {
      return this.$store.state.account;
    },
    content() {
      if (!this.message || !this.message.data().new_message_content) return;
      return this.message
        .data()
        .new_message_content.replace(/(<([^>]+)>)/gi, "");
    },
    formatedCreatedAt() {
      if (!this.message.data().new_message_date) return "";
      const new_message_date = moment(
        this.message.data().new_message_date.toDate()
      );
      return new_message_date.fromNow();
    },
  },
  methods: {
    setMessage() {
      this.$store.commit("setMessageOpened", this.message);
    },
  },
};
</script>
