import { firestore } from '@/utils/firebase';
import { doc, addDoc, collection, setDoc, getDoc, query, where, getDocs } from 'firebase/firestore';
import store from '@/store';
import { updateHotelContacts } from '@/utils/hotel.jsx';
import { sendNotificationAdmin } from '@/utils/notifications.jsx';

const save = async ({ name, email, role, phone, contact, hotel }) => {
  email = email.toLowerCase();
  const account = store.state.account;
  if (contact && contact.id) {
    await setDoc(doc(firestore, 'contacts', contact.id), {
      ...contact.data(),
      name,
      role: role || '',
      phone: phone || '',
      updated_at: new Date(),
    });
    const account_id = contact.data().account_id;
    const accountRef = doc(firestore, 'accounts', account_id);
    const account_contact = await getDoc(accountRef);
    await setDoc(doc(firestore, 'accounts', account_id), {
      ...account_contact.data(),
      name,
      updated_at: new Date(),
    });
    sendNotificationAdmin({
      type: 'contact_update',
      content: `Contact ${name} has been updated by ${account.name}`,
      to: {
        name: 'hotelContactsEdit',
        params: {
          contactId: contact.id,
          id: hotel.id,
        },
      },
    });
    store.commit('setToast', {
      content: `<p>Contact updated</p>`,
    });
  } else {
    const qAccounts = query(collection(firestore, 'accounts'), where('email', '==', email));
    const account_by_email = await getDocs(qAccounts);
    let account_contact;
    if (account_by_email.empty) {
      account_contact = await addDoc(collection(firestore, 'accounts'), {
        name,
        email,
        permissions: '',
        owner: '',
        type: 'contact',
        created_at: new Date(),
      });
    } else {
      account_contact = account_by_email.docs[0];
    }
    const contact = await addDoc(collection(firestore, 'contacts'), {
      name,
      email,
      phone: phone || '',
      role: role || '',
      hotel_id: hotel.id,
      account_id: account_contact.id,
      created_at: new Date(),
      type: 'contact',
    });
    sendNotificationAdmin({
      type: 'contact_update',
      content: `A new contact ${name} has been added to ${hotel.data().name} 
        by ${account.name}`,
      to: {
        name: 'hotelContactsEdit',
        params: { contactId: contact.id, id: hotel.id },
      },
    });
    store.commit('setToast', {
      content: `<p>Contact added</p>`,
    });
  }
  await updateHotelContacts(hotel.id);
};

export { save };
