<template>
  <div>
    <div class="fixed top-0 right-0 left-0 bottom-0" @click="closeDialog"></div>
    <div class="overlay">
      <div
        class="bg-gray-900 shadow-md rounded-lg flex min-w-[598px] flex-col items-center relative text-gray-200"
      >
        <div class="px-4 pt-4 flex justify-between items-center gap-4 w-full">
          <p class="text-2xl">Search Hotel</p>
          <span
            @click="closeDialog"
            class="cursor-pointer top-2 right-2 text-red-200 hover:text-red-400 material-symbols-rounded"
          >
            close
          </span>
        </div>
        <div class="p-4 w-full">
          <input
            v-model="search"
            type="text"
            class="w-full bg-gray-700 rounded px-4 py-2"
            placeholder="Search hotel"
          />
        </div>
        <div v-if="loading" class="my-6 flex items-center justify-center">
          <span class="material-symbols-rounded animate-spin text-[32px]">
            autorenew
          </span>
        </div>
        <div
          v-if="hotels.length > 0"
          class="h-[40vh] overflow-auto w-full"
        >
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Chain</th>
                <th>Contacts</th>
              </tr>
            </thead>
            <tbody>
              <AddHotelItem
                v-for="item in hotels"
                :key="item.name"
                :hotel="item"
              />
            </tbody>
          </table>
        </div>
        <div class="flex justify-between p-4 w-full">
          <button @click="closeDialog" class="cancel-button">Cancel</button>
          <button class="green-button" @click="save">
            {{ selectedHotels }} Add Hotels
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { firestore } from "@/utils/firebase";
import {
  query,
  orderBy,
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  where,
  setDoc,
} from "firebase/firestore";
import AddHotelItem from "./AddHotelItem.vue";
import { addToRfp } from "@/utils/rfp.jsx";
import { listHotelsForRFP } from "@/utils/hotel";
import { abort } from "process";
import moment from "moment";
import { debounce } from "@/utils/formatter";

export default {
  props: ["rfp"],
  components: {
    AddHotelItem,
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
    selectedHotels() {
      return this.hotels.filter((hotel) => hotel.selected).length;
    },
  },
  data() {
    return {
      search: "",
      hotels: [],
      loading: false,
      abortController: new AbortController(),
      currentSearchCount: 0,
    };
  },
  watch: {
    search: debounce(function(){
      const currentSearchCount = this.currentSearchCount + 1;
      this.currentSearchCount = currentSearchCount;
      this.searchHotels(currentSearchCount);
    }, 1000)
  },
  async mounted() {
    await this.searchHotels(0);
  },
  methods: {
    async searchHotels(searchCount) {
      console.log("Search started for count ", searchCount)
      this.loading = true;
      this.hotels = [];
      this.abortController.abort();
      this.abortController = new AbortController();
      this.hotels = await listHotelsForRFP(this.search, this.rfp.id, this.abortController.signal);
      console.log("Search completed for count ", searchCount, " vs current count ", this.currentSearchCount)
      if(searchCount == this.currentSearchCount){
        this.loading = false;
      }
    },
    closeDialog() {
      this.$emit("close");
    },
    async save() {
      await addToRfp({ rfp: this.rfp, hotels: this.hotels });
      this.$emit("addedHotels");
    },
  },
};
</script>
