<template>
  <div class="bg-gray-900 w-full p-4 flex flex-col gap-4 stage-height">
    <h1 class="text-xl text-white">Notifications</h1>
    <div class="flex flex-col gap-2 bg-gray-800 rounded pb-4 relative">
      <div class="divide-y divide-gray-700 text-white">
        <ListItem
          v-for="notification in notificationsSlice"
          :notification="notification"
          :key="notification.id"
        />
        <div class="mt-4">
          <Pagination
            :actual="actual"
            :max="10"
            :total="notifications.length"
            @togglePage="togglePage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { firestore } from "@/utils/firebase";
import {
  collection,
  where,
  query,
  onSnapshot,
  orderBy,
} from "firebase/firestore";
import ListItem from "@/components/notifications/ListItem.vue";
import Pagination from "@/components/default/Pagination.vue";

export default {
  components: {
    ListItem,
    Pagination,
  },
  data() {
    return {
      actual: 0,
      notifications: [],
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
    notificationsSlice() {
      const sliceStart = this.actual * 10;
      return this.notifications.slice(sliceStart, sliceStart + 10);
    },
  },
  mounted() {
    const queryData = [];
    if (this.account.hotelId) {
      queryData.push(where("hotel_id", "==", this.account.hotelId));
    } else {
      queryData.push(where("account_id", "==", this.account.id));
    }
    onSnapshot(
      query(
        collection(firestore, "notifications"),
        orderBy("created_at", "desc"),
        ...queryData
      ),
      async (doc) => {
        this.notifications = doc.docs;
      }
    );
    document.title = `Launchpad - Crewfare - Notifications`;
  },
  methods: {
    togglePage(page) {
      this.actual = page;
    },
  },
};
</script>
