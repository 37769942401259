<template>
  <div
    class="fixed bottom-0 right-0 z-[150] left-0 top-0 bg-black bg-opacity-[48%] flex items-center justify-center fixed"
  >
    <div class="bg-crewfareGreyDark rounded-xl min-w-[800px] flex flex-col p-6 relative text-white">
      <div @click="close" class="cursor-pointer material-symbols-rounded absolute top-4 right-4">close</div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    close: Function,
  },
};
</script>
