<template>
  <form
    @submit.prevent="save"
    class="flex gap-2 my-4 rounded-full mx-4 sticky bg-white bottom-0 pr-4 py-2"
  >
    <div class="flex flex-col w-full">
      <div
        v-if="mention"
        class="flex gap-2 px-2 border-l border-l-4 border-l-green-200 ml-4 mb-2"
      >
        <div class="w-full" v-html="mention"></div>
        <div>
          <span
            @click="removeMention"
            class="material-symbols-rounded text-red-400 px-2 py-1 cursor-pointer hover:text-white border border-red-400 rounded hover:bg-red-400"
          >
            close
          </span>
        </div>
      </div>
      <textarea 
        ref="contentInput"
        id="contentInput"
        type="textarea"
        class="text-gray-900 ml-4 p-2 px-2 w-full text-base border-none focus:outline-none focus:border-none border-transparent focus:border-transparent focus:ring-0"
        @keydown="keyDown"
        v-model="content"
        placeholder="Your message..."
      ></textarea>
    </div>
    <div class="flex items-center gap-4 ml-6">
      <span class="text-gray-900">
        <button :disabled="upload" @click="$refs['fileUpload'].click()">
          <span
            v-if="upload"
            class="material-symbols-rounded animate-spin text-[12px]"
          >
            autorenew
          </span>
          <font-awesome-icon v-else :icon="['fas', 'upload']" />
        </button>
      </span>
      <span
        @click="save"
        class="bg-[#6707FD] rounded-full h-[32px] w-[32px] flex items-center justify-center"
      >
        <font-awesome-icon :icon="['fas', 'paper-plane']" />
      </span>
    </div>
    <input
      type="file"
      ref="fileUpload"
      id="fileUpload"
      @change="uploadFile"
      class="cursor-pointer fixed -left-[100vw]"
    />
  </form>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export default {
  props: ["message", "rfpName"],
  name: "Form",
  computed: {
    account() {
      return this.$store.state.account;
    },
    mention() {
      return this.$store.state.mention;
    },
  },
  data() {
    return {
      upload: false,
      content: "",
    };
  },
  methods: {
    removeMention() {
      this.$store.commit("setMentionMessage", null);
    },
    save() {
      this.content = this.content.replace(/\r\n|\r|\n/g,"<br />")
      if (this.content.length < 1) return;
      if (this.mention) {
        this.content = `${this.mention.replace(
          /text-gray-300/g,
          "text-gray-900 mb-2"
        )} ${this.content}`;
      }
      this.$emit("addChat", this.content);
      this.content = "";
    },
    uploadFile() {
      var file = document.getElementById("fileUpload").files[0];
      if (file) {
        const storage = getStorage();
        const uuid = uuidv4();
        const name = file.name;
        const ext = name.split(".").reverse()[0];
        this.storageRef = ref(storage, `${uuid}.${ext}`);
        this.upload = true;
        uploadBytes(this.storageRef, file).then(() => {
          getDownloadURL(this.storageRef).then((url) => {
            this.$emit(
              "addChat",
              `${
                this.account.name
              } has uploaded a new file <a href="${url}" class="underline hover:text-sky-600" target="_blank">${name}</a>`
            );
            this.$emit("addFile", { url, name: file.name });
            this.upload = false;
          });
        });
      }
    },
  },
};
</script>
