export const parseCSVLine = (line: string): string[] => {
  const result = []
  let current = ''
  let inQuotes = false
  for (let i = 0; i < line.length; i++) {
    const char = line[i]
    if (char === '"') {
      inQuotes = !inQuotes
    } else if (char === ',' && !inQuotes) {
      result.push(current)
      current = ''
    } else {
      current += char
    }
  }
  //Remover last carriage return, tab, space or newline
  current = current.replace(/[\r\t\n\s]+$/, '')
  result.push(current)
  return result
}
