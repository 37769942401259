<template>
  <div
    v-if="rfp && hotel && account"
    class="bg-gray-800 w-full pb-4 flex flex-col gap-4 stage-height print:text-black"
  >
    <FileUpload />
  </div>
</template>

<script>
import { firestore } from "@/utils/firebase";
import { doc, getDoc } from "firebase/firestore";
import FileUpload from "@/components/rfp/authorization/FileUpload.vue";

export default {
  components: {
    FileUpload,
  },
  data() {
    return {
      rfp: null,
      hotel: null,
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  async mounted() {
    this.getData();
    document.title = `Launchpad - Crewfare - RFP - Contract`;
  },
  methods: {
    async getData() {
      const rfpRef = doc(firestore, "rfp_hotel", this.$route.params.rfp);
      this.rfp = await getDoc(rfpRef);

      const hotelRef = doc(firestore, "hotels", this.rfp.data().hotel_id);
      this.hotel = await getDoc(hotelRef);
      this.contractMethod = this.rfp.data().contractMethod || "internal";
    },
  },
};
</script>
