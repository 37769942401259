<template>
  <div
    v-if="dialog"
    class="fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center z-50 bg-black/50"
  >
    <div
      class="bg-gray-900 rounded-lg shadow-md p-4 text-white flex flex-col gap-4 justify-center w-[420px]"
    >
      <div class="flex justify-between">
        <p class="text-xl">This is a restricted content</p>
        <span
          @click="hide_dialog"
          class="cursor-pointer text-red-400 material-symbols-rounded"
        >
          close
        </span>
      </div>
      <p>To update this value you can request the edit</p>
      <div class="flex justify-between">
        <button @click="hide_dialog" class="cancel-button !px-0">
          Dismiss
        </button>
        <router-link
          :to="{ name: 'rfpView', params: { rfp: rfp.id } }"
          class="green-button"
          >Go to the RFP</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["dialog", "rfp"],
  methods: {
    hide_dialog() {
      this.$emit("hide_dialog");
    },
  },
};
</script>
