import { firestore, db } from '@/utils/firebase';
import { addDoc, query, getDocs, collection, where, writeBatch } from 'firebase/firestore';

const addInfoToEmailCollection = async emailInfo => {
  console.log('Email Info', emailInfo);
  return addDoc(collection(firestore, 'emailsStatus'), {
    created_at: new Date(),
    updated_at: new Date(),
    ...emailInfo,
    status: 'processed',
    statusHistory: [],
  });
};

export { addInfoToEmailCollection };
