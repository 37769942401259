<template>
  <div
    v-if="dialog"
    class="fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center z-50 bg-black/50"
  >
    <div
      class="bg-gray-900 rounded-lg shadow-md p-4 text-white flex flex-col gap-4 justify-center w-[420px]"
    >
      <p class="text-xl">
        Hello,
        <span class="text-yellow-200 print:text-yellow-900">
          {{ hotel_name }}
        </span>
        .
      </p>
      <p>
        It is our pleasure to present you with our Crewfare Contract. Please
        review and fill in all sections that are highlighted yellow.
      </p>
      <p>
        - You can request the edits of this contract after filling all the
        fields and comment on what should be updated
      </p>
      <p>
        - Message us directly at the
        <span class="text-yellow-200 print:text-yellow-900">Messages</span>
        at the sidebar
      </p>
      <p>
        - Reply to the email in which you were notified of the agreement to
        reach our team directly.
      </p>
      <div class="flex justify-between">
        <button @click="hide_dialog" class="green-button">Continue</button>
        <button @click="hide_dialog" class="green-button">Continue</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["dialog"],
  methods: {
    hide_dialog() {
      this.$emit("hide_dialog");
    },
  },
};
</script>
