<template>
  <div>
    <div class="fixed top-0 right-0 left-0 bottom-0" @click="closeDialog"></div>
    <div class="overlay">
      <div
        class="bg-gray-900 shadow-md rounded-lg flex min-w-[598px] flex-col items-center relative text-gray-200"
      >
        <div class="px-4 pt-4 flex justify-between items-center gap-4 w-full">
          <p class="text-2xl">Proposal Chart</p>
          <span
            @click="closeDialog"
            class="cursor-pointer top-2 right-2 text-red-200 hover:text-red-400 material-symbols-rounded"
          >
            close
          </span>
        </div>

        <div class="flex justify-between px-4 pt-4 pb-2 border-b w-full">
          <div class="flex py-2 items-center gap-4 text-left">
            <span @click="toggleAll" class="flex w-[18px]">
              <span
                v-if="selectedAll"
                class="text-gray-800 bg-crewfareGreen rounded w-[18px] h-[18px] flex items-center justify-center"
              >
                <img src="/icons/small-check.svg" />
              </span>
              <span v-else class="border rounded w-[18px] h-[18px]"></span>
            </span>
            Name
          </div>
          <div class="flex items-center gap-4 text-white">
            <span>{{ selectedCount }} selected</span>
            <input
              type="search"
              v-model="search"
              class="bg-gray-700 rounded px-4"
              placeholder="Search..."
            />
          </div>
        </div>

        <div class="flex flex-col gap-2 w-full">
          <div class="gap-4 divide-y text-white w-full">
            <div class="divide-y max-h-[260px] overflow-auto">
              <div
                v-for="proposal in rfp_hotels_filtered"
                :key="proposal.id"
                class="py-2 text-white cursor-pointer"
                @click="proposal.isSelected = !proposal.isSelected"
              >
                <div class="flex py-2 items-center px-4 gap-4">
                  <span class="w-[18px] flex">
                    <span
                      v-if="proposal.isSelected"
                      class="text-gray-800 bg-crewfareGreen rounded w-full h-[18px] flex items-center justify-center"
                    >
                      <img src="/icons/small-check.svg" />
                    </span>
                    <span v-else class="border rounded w-full h-[18px]"></span>
                  </span>
                  {{ proposal.hotel_name }}
                  <span v-html="statusPill(proposal.status)"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-end p-4">
          <button class="green-button" @click="generateChart">
            Generate chart
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RFPStatusPill } from "@/utils/rfp.jsx";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "@/utils/firebase";

import moment from "moment";
export default {
  props: ["rfp_hotels", "rfp"],
  data() {
    return {
      search: "",
      rfp_hotels_filtered: [],
    };
  },
  computed: {
    selectedAll() {
      return (
        this.rfp_hotels.length ===
        this.rfp_hotels_filtered.filter((hotel) => hotel.isSelected).length
      );
    },
    selectedCount() {
      return this.rfp_hotels_filtered.filter((hotel) => hotel.isSelected)
        .length;
    },
  },
  watch: {
    search() {
      if (this.search.length === 0) {
        this.rfp_hotels_filtered = this.rfp_hotels.sort((a, b) => {
          if (a.hotel_name < b.hotel_name) {
            return -1;
          }
          if (a.hotel_name > b.hotel_name) {
            return 1;
          }
          return 0;
        });
      } else {
        this.rfp_hotels_filtered = this.rfp_hotels
          .filter(
            (proposal) =>
            proposal
                .hotel_name.toLowerCase()
                .indexOf(this.search.toLowerCase()) >= 0
          )
          .sort((a, b) => {
            if (a.hotel_name < b.hotel_name) {
              return -1;
            }
            if (a.hotel_name > b.hotel_name) {
              return 1;
            }
            return 0;
          });
      }
    },
  },
  mounted() {
    this.rfp_hotels_filtered = this.rfp_hotels.sort((a, b) => {
      if (a.hotel_name < b.hotel_name) {
        return -1;
      }
      if (a.hotel_name > b.hotel_name) {
        return 1;
      }
      return 0;
    });
  },
  methods: {
    statusPill(status) {
      return RFPStatusPill(status);
    },
    toggleAll() {
      if (this.selectedAll) {
        this.rfp_hotels_filtered.map((hotel) => (hotel.isSelected = false));
      } else {
        this.rfp_hotels_filtered.map((hotel) => (hotel.isSelected = true));
      }
    },
    generateChart() {
      const selected = this.rfp_hotels.filter((proposal) => proposal.isSelected);
      let csvFile =
        "Hotel Name,Hotel Address,Distance,Rate King,Rate Double Queen,Notes\n";
      selected.forEach(async (proposal) => {
        const hotelRef = doc(firestore, "hotels", proposal.hotel_id);
        const hotel_data = await getDoc(hotelRef);
        let value = {};
        proposal.roomList?.forEach((days) => {
          days.rooms.forEach((item) => {
            if (item.value && item.value > 0 && item.value < value) {
              value[item.type] = item.value;
            }
          });
        });
        let line = `${hotel_data.data().name},"${
          hotel_data.data().hotel_address
        }",${proposal.distance.toFixed(2)}mi,${value["King"] || "-"},${
          value["Double Queen"] || "-"
        },Breakfast: ${proposal.breakfast} WiFI: ${
          proposal.wifi
        } Parking: ${proposal.parking}\n`;
        csvFile += line;
      });

      var blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
      const date = moment().format("YYYY-MM-DD");
      const filename = `proposal-chart-${this.rfp
        .name.replace(/[ ]/, "-")
        .toLowerCase()}-${date}.csv`;
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, filename);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", filename);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    closeDialog() {
      this.$emit("hideGroupDialog");
    },
  },
};
</script>
