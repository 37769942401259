<template>
  <div>
    <DeclineReason
      v-if="rfp.status.toLowerCase().indexOf('declined') === 0"
      :reasonDecline="rfp.reasonDecline"
    />
    <p>Venue: {{ rfp.venue }}</p>
    <p>{{ formatedDates }}</p>
  </div>
</template>

<script>
import moment from "moment";
import { DeclineReason } from "@/components/rfp";
import { getMomentFromFirebaseDate } from "@/utils/dateUtils";
export default {
  props: ["rfp"],
  components: {
    DeclineReason,
  },
  computed: {
    formatedDates() {
      const startDate = this.rfp.startDate;
      const endDate = this.rfp.endDate;
      return `From ${getMomentFromFirebaseDate(startDate).format(
        "ddd MMM. DD, YYYY"
      )} to ${getMomentFromFirebaseDate(endDate).format("ddd MMM. DD, YYYY")}`;
    },
  },
};
</script>
