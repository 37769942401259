<template>
  <div class="relative">
    <dropdown-menu @opened="toggleReadNotifications">
      <template #trigger>
        <span
          class="relative cursor-pointer rounded-full w-[34px] h-[34px] bg-[#6707FD] text-white flex justify-center items-center"
        >
          {{ initials }}
          <span
            v-if="notificationsUnreadCount > 0"
            class="bg-[#EE1C9A] rounded-full text-white absolute -top-2 leading-[20px] -right-2 h-[20px] w-[20px] text-[11px] flex items-center justify-center"
          >
            {{ notificationsUnreadCount > 9 ? "9+" : notificationsUnreadCount }}
          </span>
        </span>
      </template>
      <template #body>
        <div
          class="bg-[#23262F] absolute top-[40px] z-[100] right-0 p-4 rounded-lg w-[400px] -ml-[360px] text-white z-30 flex flex-col"
        >
          <div class="flex py-2 gap-2 items-center">
            <font-awesome-icon :icon="['fas', 'bell']" />
            Notifications
          </div>
          <div class="flex flex-col gap-2 max-h-[400px] overflow-auto">
            <ListItem
              v-for="notification in notifications"
              :key="notification.id"
              :notification="notification"
              class="hover:bg-gray-200"
            />
          </div>
          <router-link
            v-if="notificationsCount > 9"
            :to="{ name: 'notificationsList' }"
            class="text-center hover:text-sky-600 py-2 w-full"
          >
            View all
          </router-link>
        </div>
      </template>
    </dropdown-menu>
  </div>
</template>

<script>
import { firestore } from "@/utils/firebase";
import {
  onSnapshot,
  orderBy,
  limit,
  getCountFromServer,
  query,
  doc,
  setDoc,
  getDocs,
  collection,
  where,
} from "firebase/firestore";
import ListItem from "@/components/notifications/ListItem.vue";
import { FwbDropdown, FwbListGroup, FwbListGroupItem } from "flowbite-vue";
import { markNotificationsAsRead } from "@/utils/notifications";
import {getHotelsIdsByGroup} from "@/utils/hotel";

export default {
  components: {
    FwbDropdown,
    FwbListGroup,
    FwbListGroupItem,
    ListItem,
  },
  data() {
    return {
      showList: false,
      notifications: [],
      notificationsCount: 0,
      notificationsUnreadCount: 0,
    };
  },
  computed: {
    initials() {
      if (!this.account.name) return "";
      const nameSplit = this.account.name.split(" ");
      const firstLetter = nameSplit[0];
      let lastLetter = "";
      if (nameSplit.length > 1) {
        const lastName = nameSplit.reverse()[0];
        lastLetter = lastName[0] || "";
      }
      return `${firstLetter[0]}${lastLetter}`;
    },
    account() {
      return this.$store.state.account;
    },
  },
  methods: {
    async toggleReadNotifications() {
      await markNotificationsAsRead();
      this.getData();
    },
    async getData() {
      this.loading = true;
      switch (this.account.type.toLowerCase()) {
        case "internal":
        case "admin":
          await this.getUserNotifications();
          break;
        case "group manager":
          await this.getGroupNotifications();
          break;
        case "nso":
          await this.getNSONotifications();
          break;
      }
      if (this.account.hotelIds && this.account.hotelIds.length > 0) {
        this.getHotelNotifications();
      }
      this.loading = false;
    },
    getHotelNotifications() {
      onSnapshot(
        query(
          collection(firestore, "notifications"),
          orderBy("created_at", "desc"),
          where("hotel_id", "in", this.account.hotelIds),
          limit(4)
        ),
        async (doc) => {
          this.notifications = doc.docs;

          let qNotifications = query(
            collection(firestore, "notifications"),
            where("hotel_id", "in", this.account.hotelIds)
          );
          const notifications = await getCountFromServer(qNotifications);
          this.notificationsCount = notifications.data().count;
          let qMessagesUnread = query(
            collection(firestore, "notifications"),
            where("hotel_id", "in", this.account.hotelIds),
            where("unread", "==", true)
          );
          const notificationsUnread = await getCountFromServer(qMessagesUnread);
          this.notificationsUnreadCount = notificationsUnread.data().count;
        }
      );
    },
    getUserNotifications() {
      onSnapshot(
        query(
          collection(firestore, "notifications"),
          orderBy("created_at", "desc"),
          where("account_id", "==", this.account.id),
          limit(4)
        ),
        async (doc) => {
          this.notifications = doc.docs;

          let qNotifications = query(
            collection(firestore, "notifications"),
            where("account_id", "==", this.account.id)
          );
          const notifications = await getCountFromServer(qNotifications);
          this.notificationsCount = notifications.data().count;
          let qMessagesUnread = query(
            collection(firestore, "notifications"),
            where("account_id", "==", this.account.id),
            where("unread", "==", true)
          );
          const notificationsUnread = await getCountFromServer(qMessagesUnread);
          this.notificationsUnreadCount = notificationsUnread.data().count;
        }
      );
    },
    async getGroupNotifications() {
      const hotel_ids = await getHotelsIdsByGroup();

      onSnapshot(
        query(
          collection(firestore, "notifications"),
          orderBy("created_at", "desc"),
          where("hotel_id", "in", hotel_ids.splice(0, 30)),
          limit(4)
        ),
        async (doc) => {
          this.notifications = doc.docs;

          let qNotifications = query(
            collection(firestore, "notifications"),
            where("hotel_id", "in", hotel_ids.splice(0, 30))
          );
          const notifications = await getCountFromServer(qNotifications);
          this.notificationsCount = notifications.data().count;
          let qMessagesUnread = query(
            collection(firestore, "notifications"),
            where("hotel_id", "in", hotel_ids.splice(0, 30)),
            where("unread", "==", true)
          );
          const notificationsUnread = await getCountFromServer(qMessagesUnread);
          this.notificationsUnreadCount = notificationsUnread.data().count;
        }
      );
    },
    async getNSONotifications() {
      let qChain = query(
        collection(firestore, "chains"),
        where("manager_ids", "array-contains-any", [this.account.id])
      );

      const chains = await getDocs(qChain);
      this.chains = chains.docs.map((chain) => chain.data().name);
      if(this.chains.length === 0) return;
      let qHotels = query(
        collection(firestore, "hotels"),
        where("chain", "in", this.chains)
      );
      const hotels = await getDocs(qHotels);
      const hotel_ids = hotels.docs.map((hotel) => hotel.id);
      const hotels_filtered = hotel_ids.splice(0, 30);
      if(!hotels_filtered.length) return;
      onSnapshot(
        query(
          collection(firestore, "notifications"),
          orderBy("created_at", "desc"),
          where("hotel_id", "in", hotels_filtered),
          limit(4)
        ),
        async (doc) => {
          this.notifications = doc.docs;

          let qNotifications = query(
            collection(firestore, "notifications"),
            where("hotel_id", "in", hotels_filtered)
          );
          const notifications = await getCountFromServer(qNotifications);
          this.notificationsCount = notifications.data().count;
          let qMessagesUnread = query(
            collection(firestore, "notifications"),
            where("hotel_id", "in", hotels_filtered),
            where("unread", "==", true)
          );
          const notificationsUnread = await getCountFromServer(qMessagesUnread);
          this.notificationsUnreadCount = notificationsUnread.data().count;
        }
      );
    },
  },
  async mounted() {
    this.getData();
  },
};
</script>
