<template>
  <div
    class="fixed bottom-0 right-0 z-[150] left-0 top-0 bg-gray-300 bg-opacity-40 flex items-center justify-center"
  >
    <div
      class="bg-gray-900 rounded flex flex-col gap-4 items-center w-full max-w-[900px] p-4 relative text-white"
    >
      <div class="flex w-full flex-col gap-4">
        <div class="flex w-full justify-between">
          <p class="text-xl">History rate</p>
          <span
            @click="close"
            class="cursor-pointer text-red-400 material-symbols-rounded"
          >
            close
          </span>
        </div>
        <div class="overflow-y-auto max-h-[500px]">
          <table
            class="w-full px-4 justify-between py-2 border border-gray-700 border-x-0 border-t-0 text-white"
          >
            <thead>
              <tr class="border-b text-left">
                <th class="py-2 px-4">Date</th>
                <th class="py-2">Room List</th>
                <th class="py-2 px-4">Sent By</th>
              </tr>
            </thead>
            <tbody class="divide-y px-4 divide-gray-700">
              <tr v-for="(rate, index) in history" :key="index">
                <td class="px-4">{{ rate.date }}</td>
                <td class="divide-y divide-gray-700 py-2 text-sm">
                  <div class="grid grid-cols-3 py-1">
                    <span>Date</span>
                    <span class="px-4">Room types</span>
                    <span></span>
                  </div>
                  <div
                    v-for="(item, indexRoomList) in rate.roomList"
                    :key="indexRoomList"
                    class="grid grid-cols-3 py-1"
                  >
                    {{ item.date }}
                    <span
                      v-for="(roomListItem, indexRoom) in item.rooms"
                      :key="indexRoom"
                      class="flex gap-2 px-4"
                    >
                      {{ roomListItem.amount }} {{ roomListItem.type }} - ${{
                        roomListItem.value || 0
                      }}
                    </span>
                  </div>
                </td>
                <td class="px-4 text-center">
                  <p class="text-xl">
                    {{ getOwner(rate) }}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="flex flex-col gap-2">
          <div class="flex justify-end items-center">
            <button class="text-red-400 hover:text-red-600" @click="close">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["roomListHistoric"],
  data() {
    return {
      history: [],
    };
  },
  mounted() {
    const history = this.roomListHistoric;
    this.history = history.filter((item) => item.account).reverse();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getOwner(rate){
      const currentOwner = ["internal", "admin"].includes(
        rate.account.type.toLowerCase()
      )
        ? "Crewfare"
        : "Hotel";
      console.log(currentOwner);
      return currentOwner;
    }
  },
};
</script>
