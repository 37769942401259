<template>
  <div class="bg-gray-900 w-full p-4 flex flex-col gap-4 stage-height">
    <h1 class="text-xl text-white gap-4 flex items-center">
      <button
        @click="goBack"
        class="material-symbols-rounded hover:text-crewfareGreen"
      >
        chevron_left
      </button>
      {{ hotel.data().name }} - {{ title }}
    </h1>
    <form @submit.prevent="save" class="flex flex-col gap-4 text-white w-full">
      <div class="flex flex-col gap-1">
        <label class="text-white font-bold">Name</label>
        <input
          type="text"
          v-model="name"
          class="w-full bg-gray-700 rounded px-4"
        />
      </div>
      <div class="flex flex-col gap-1">
        <label class="text-white font-bold">Role</label>
        <input
          type="text"
          v-model="role"
          class="w-full bg-gray-700 rounded px-4"
        />
      </div>
      <div class="flex flex-col gap-1">
        <label class="text-white font-bold">Email</label>
        <input
          type="email"
          v-model="email"
          @blur="changeToLowerCase"
          @input="changeToLowerCase"
          class="w-full bg-gray-700 rounded px-4"
          :readonly="contact?.id"
          :disabled="contact?.id"
        />
        <p v-if="isExistingEmail" class="text-red-700">The email already added as a contact</p>
        <p v-if="contact?.id" class="text-red-700">Email isn't editable</p>
      </div>
      <div class="flex flex-col gap-1">
        <label class="text-white font-bold">Phone</label>
        <input
          type="text"
          v-model="phone"
          class="w-full bg-gray-700 rounded px-4"
        />
      </div>
      <div class="flex gap-4 mt-4 justify-between">
        <span @click="goBack" class="cancel-button"> Cancel </span>
        <button :disabled="!valid || loading" class="green-button">
          <span
            v-if="loading"
            class="material-symbols-rounded animate-spin flex items-center"
          >
            autorenew
          </span>
          <span v-else> Save </span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { firestore } from "@/utils/firebase";
import { sendNotificationAdmin } from "@/utils/notifications.jsx";
import {
  addDoc,
  setDoc,
  getDocs,
  doc,
  getDoc,
  query,
  collection,
  where,
} from "firebase/firestore";
import { updateHotelContacts } from "@/utils/hotel.jsx";
import { isValidEmail } from "@crewfare/utils";

export default {
  data() {
    return {
      title: this.contact ? "Edit Contact" : "Add Contact",
      name: "",
      role: "",
      email: "",
      phone: "",
      loading: false,
      contacts: []
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
    contact() {
      return this.$store.state.contact;
    },
    hotel() {
      return this.$store.state.hotel;
    },
    hotelContacts() {
      return this.contacts.filter(_=>_.id !== this.contact.id).map(_=>_.data().email.toLowerCase())
    },
    isExistingEmail(){
      return this.hotelContacts.includes(this.email)
    },
    valid() {
      return isValidEmail(this.email) && !this.isExistingEmail
    },
  },
  async mounted() {
    this.loading = true;
    await this.getContacts()
    if (this.contact !== "new") {
      this.name = this.contact.data().name;
      this.email = this.contact.data().email;
      this.phone = this.contact.data().phone;
    }
    this.loading = false;
  },
  methods: {
    async getContacts(){
      if (!this.hotel) return [];
      const qContacts = query(
        collection(firestore, "contacts"),
        where("hotel_id", "==", this.hotel.id)
      );
      const contacts = await getDocs(qContacts);
      this.contacts = contacts.docs;
    },
    changeToLowerCase() {
      this.email = (this.email||"").toLowerCase().trim();
    },
    goBack() {
      this.$store.commit("setContact", null);
    },
    async save() {
      this.email = this.email.toLowerCase();
      if (!this.valid) return;
      this.loading = true;
      if (this.contact.id) {
        await setDoc(doc(firestore, "contacts", this.contact.id), {
          ...this.contact.data(),
          name: this.name,
          email: this.email,
          phone: this.phone,
          updated_at: new Date(),
        });
        const account_id = this.contact.data().account_id;
        const accountRef = doc(firestore, "accounts", account_id);
        const account = await getDoc(accountRef);
        await setDoc(doc(firestore, "accounts", account_id), {
          ...account.data(),
          name: this.name,
          updated_at: new Date(),
        });
        sendNotificationAdmin({
          type: "status_change",
          content: `Contact ${this.name} has been updated by ${
            this.account.name
          }`,
        });
      } else {
        const qAccounts = query(
          collection(firestore, "accounts"),
          where("email", "==", this.email)
        );
        const accounts = await getDocs(qAccounts);
        let account;
        if (accounts.docs.length === 0) {
          account = await addDoc(collection(firestore, "accounts"), {
            name: this.name,
            email: this.email.toLowerCase(),
            permissions: "",
            owner: "",
            type: "Contact",
            created_at: new Date(),
          });
        } else {
          account = accounts.docs[0];
        }
        await addDoc(collection(firestore, "contacts"), {
          name: this.name,
          email: this.email,
          phone: this.phone,
          role: this.role,
          hotel_id: this.hotel.id,
          account_id: account.id,
          created_at: new Date(),
          type: "Contact",
        });
        sendNotificationAdmin({
          type: "status_change",
          content: `A new contact ${this.name} has been added to ${
            this.hotelName
          } by ${this.account.name}`,
        });
      }
      await updateHotelContacts(this.hotel.id);
      this.$store.commit("setContact", null);
      this.loading = false;
    },
  },
};
</script>
