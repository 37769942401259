<template>
  <div class="flex items-center gap-4 text-white text-xs">
    <button
      @click="toggleFavorite"
      class="px-4 py-1 border rounded whitespace-nowrap"
      :class="isFavorite ? 'bg-crewfareGreen text-gray-900' : 'text-white'"
    >
      Is partner
    </button>
    <select class="select" v-model="chain" @change="updateFilter">
      <option value="All">All chains</option>
      <option v-for="chain in chainList" :key="chain">
        {{ chain }}
      </option>
    </select>
    <select class="select" v-model="city" @change="updateFilter">
      <option value="All">All cities</option>
      <option v-for="city in cityList" :key="city">{{ city }}</option>
    </select>
    <select class="select" v-model="county" @change="updateFilter">
      <option value="All">All counties</option>
      <option v-for="county in countyList" :key="county">
        {{ county }}
      </option>
    </select>
    <input
      type="search"
      v-model="search"
      class="bg-gray-700 rounded px-4"
      placeholder="Search..."
      @keyup="updateFilter"
    />
  </div>
</template>

<script>
import { uniqueSortedList } from "@/utils/formatter.jsx";
export default {
  props: ["filteredHotels"],
  data() {
    return {
      cityList: [],
      stateList: [],
      countyList: [],
      chainList: [],
      search: "",
      city: "All",
      state: "All",
      county: "All",
      chain: "All",
      isFavorite: false,
    };
  },
  watch: {
    filteredHotels() {
      this.setFilters();
    },
  },
  mounted() {
    this.setFilters();
  },
  methods: {
    toggleFavorite() {
      this.isFavorite = !this.isFavorite;
      this.updateFilter();
    },
    updateFilter() {
      this.$emit("filter", {
        search: this.search,
        isFavorite: this.isFavorite,
        city: this.city,
        chain: this.chain,
        county: this.county,
        state: this.state,
      });
    },
    setFilters() {
      const chains = [];
      const cities = [];
      const states = [];
      const counties = [];
      this.filteredHotels.forEach((hotel) => {
        if (hotel.data().chain) chains.push(hotel.data().chain.trim());
        if (hotel.data().city) cities.push(hotel.data().city.trim());
        if (hotel.data().state) states.push(hotel.data().state.trim());
        if (hotel.data().county) counties.push(hotel.data().county.trim());
      });
      this.chainList = uniqueSortedList(chains);
      this.cityList = uniqueSortedList(cities);
      this.stateList = uniqueSortedList(states);
      this.countyList = uniqueSortedList(counties);
    },
  },
};
</script>
