<template>
  <tr @click="hotel.selected = !hotel.selected" class="cursor-pointer">
    <td class="px-4 py-2">
      <div class="flex items-center gap-4">
        <button
          class="material-symbols-rounded text-[16px]"
          :class="hotel.selected ? 'text-crewfareGreen' : 'text-white'"
        >
          {{ hotel.selected ? "check_box" : "check_box_outline_blank" }}
        </button>
        <p class="font-bold">{{ hotel.name }}</p>
      </div>
    </td>
    <td class="px-4 py-2">
      <div class="flex flex-col justify-start gap-2">
        <div v-if="rooms" class="w-full flex gap-4 justify-between">
          <span class="text-sm text-[#777E90] font-bold">Rooms</span>
          <span class="text-crewfareGreen">
            {{ rooms }}
          </span>
        </div>
        <div v-if="chain" class="w-full flex gap-4 justify-between">
          <span class="text-sm text-[#777E90] font-bold">Chain</span>
          <span class="text-crewfareGreen">
            {{ chain }}
          </span>
        </div>
      </div>
    </td>
    <td class="px-4 py-2">
      <div class="flex items-center">
        <div class="flex gap-2 w-full justify-between">
          <div>
            <div v-if="contactsCount > 0">
              <p class="text-sm">
                {{ hotel.contacts[0].name }}<br />
                <span class="text-xs truncate block"
                  >&lt;{{
                    hotel.contacts[0].email.toLowerCase().trim()
                  }}&gt;</span
                >
              </p>
            </div>
            <div v-else>
              <p class="text-sm">No contacts</p>
            </div>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import { firestore } from "@/utils/firebase";
import { setDoc, deleteDoc, doc } from "firebase/firestore";
import { sendHotelMessage } from "@/utils/messages.jsx";
import StatusHistoryDialog from "@/components/rfps/hotels/StatusHistoryDialog.vue";
import { FwbDropdown, FwbListGroup, FwbListGroupItem } from "flowbite-vue";

export default {
  props: ["hotel"],
  components: {
    StatusHistoryDialog,
    FwbDropdown,
    FwbListGroup,
    FwbListGroupItem,
  },
  data() {
    return {
      showStatusHistoryDialog: false,
      selected: false,
      contacts: null,
      loading: false,
      rooms: null,
      chain: null,
      isPartner: false,
      addr: null,
      loadingMessage: false,
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
    contactsCount() {
      if (!this.hotel.contacts) return 0;
      return this.hotel.contacts.length;
    },
  },
  mounted() {
    this.isPartner = this.hotel.isPartner || false;
    this.rooms = this.hotel.rooms || false;
    this.chain = this.hotel.chain || false;
  },
  methods: {
    async togglePartner() {
      this.isPartner = !this.isPartner;
      await setDoc(doc(firestore, "hotels", this.hotel.id), {
        ...this.hotel,
        isPartner: this.isPartner,
      });
    },
    async sendMessage() {
      this.loadingMessage = true;
      await sendHotelMessage(this.hotel, this.account);
      this.loadingMessage = false;
    },
    async removeHotel() {
      if (!confirm("Delete this hotel?")) {
        return;
      }
      deleteDoc(doc(firestore, "hotels", this.hotel.id));
      this.$emit("getData");
    },
  },
};
</script>
