<template>
  <tr>
    <td class="text-sm px-4 text-ellipsis overflow-hidden">
      {{ chain.name || "No chain" }}
    </td>
    <td class="text-sm px-4 text-ellipsis overflow-hidden">
      {{ chain.countHotels }}
    </td>
    <td class="text-sm px-4 text-ellipsis overflow-hidden">
      <span v-if="loading" class="material-symbols-rounded animate-spin text-[28px]">
        autorenew
      </span>
      <span v-else>
        {{ managers || 0 }}
      </span>
    </td>
    <td class="px-4 py-2">
      <div class="flex gap-4 justify-end">
        <button
          v-tippy="'Edit chain'"
          @click="editChain"
          class="text-crewfareGreen text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1 border-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900"
        >
          edit
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import router from "@/router";
import { getChain, createChain } from "@/utils/chain.jsx";

export default {
  props: ["chain"],
  name: "ChainItem",
  data() {
    return {
      loading: false,
      chain_id: null,
      managers: null,
    };
  },
  async mounted() {
    if (!this.chain.name) return;
    this.loading = true
    const chains = await getChain(this.chain.name);
    if (!chains.empty) {
      this.chain_id = chains.docs[0].id;
      this.managers = chains.docs.reduce(
        (acc, chain) => acc + (chain.data().manager_ids?.length || 0),
        0
      );
    }
    this.loading = false;
  },
  methods: {
    async editChain() {
      if (this.chain_id) {
        router.push({
          name: "hotelsChainEdit",
          params: { chain_id: this.chain_id },
        });
      } else {
        const chain = await createChain(this.chain.name);
        router.push({
          name: "hotelsChainEdit",
          params: { chain_id: chain.id },
        });
      }
    },
  },
};
</script>
