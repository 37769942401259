import axios from 'axios';
import { AnyObject } from 'chart.js/dist/types/basic';
import store from '@/store';

export async function getToken() {
  const user = store.state.user;
  if (!user) {
    return '';
  }
  return user.getIdToken();
}
export const generateQueryParams = (options: AnyObject) => {
  let queryParams = '';
  Object.keys(options).forEach(key => {
    if (queryParams === '') {
      queryParams += `${key}=${encodeURIComponent(options[key])}`;
    } else {
      queryParams += `&${key}=${encodeURIComponent(options[key])}`;
    }
    return;
  });
  return queryParams;
};

export class BaseApi {
  apiPath?: string;
  apiUrl?: string;
  basePath?: string;

  constructor(options: { name: string }) {
    this.basePath = (import.meta as any).env.VITE_NEST_API_URL;
    this.apiPath = options.name;
    this.apiUrl = `${this.basePath}/${this.apiPath}`;
  }
  async axios(axiosOptions: AnyObject) {
    try {
      const response = await axios(axiosOptions);
      return {
        error: false,
        data: response.data,
      };
    } catch (e: any) {
      const responseData = e.response?.data;
      if (responseData?.statusCode == 300) {
        alert(responseData.message);
      }
      console.error('Error in axios', e);
      return {
        error: true,
        message: e.message,
        data: null,
        ...responseData,
      };
    }
  }
  // eslint-disable-next-line
  async get(id: string): Promise<any> {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/${id}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  // eslint-disable-next-line
  list = async (options: any = null, signal: any): Promise<any> => {
    const token = await getToken();
    const url = options ? `${this.apiUrl}?${generateQueryParams(options)}` : `${this.apiUrl}`;
    return this.axios({
      url,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal,
    });
  };

  // eslint-disable-next-line
  remove = async (id: string): Promise<any> => {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/${id}`,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
  // eslint-disable-next-line
  update = async (id: string, data: AnyObject): Promise<any> => {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'PUT',
      data,
    });
  };
  // eslint-disable-next-line
  create = async (data: AnyObject): Promise<any> => {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}`,
      method: 'POST',
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
}
