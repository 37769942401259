<template>
  <div>
    <div class="fixed top-0 right-0 left-0 bottom-0" @click="closeDialog"></div>
    <div class="overlay">
      <div
        class="bg-gray-900 shadow-md rounded-lg flex flex-col gap-4 items-center p-4 relative text-gray-200"
      >
        <span
          @click="closeDialog"
          class="cursor-pointer top-2 right-2 absolute text-red-200 hover:text-red-400 material-symbols-rounded"
        >
          close
        </span>
        <p class="text-2xl">Assign RFP to a group</p>

        <div class="flex flex-col gap-2 rounded pb-4 relative">
          <div
            class="flex p-4 items-center bg-gray-800 justify-between sticky top-0"
          >
            <div class="flex gap-2 items-center text-crewfareGreen">
              <strong class="rounded-full bg-black px-3 py-1">{{
                hotelsFound
              }}</strong>
              Groups
            </div>
            <div class="flex items-center gap-4 text-white">
              <input
                type="search"
                v-model="search"
                class="bg-gray-700 rounded px-4"
                placeholder="Search..."
              />
            </div>
          </div>
          <div>
            <div>
              <table
                class="w-full px-4 justify-between py-2 border border-gray-700 border-x-0 border-t-0 text-white"
              >
                <tr class="border-b px-4 border-gray-700">
                  <th class="text-white px-4 text-left py-2">Name</th>
                  <th class="text-white px-4 text-left">Hotels</th>
                  <th class="text-white px-4 text-left">Manager</th>
                  <th class="flex gap-4 px-4 justify-end"></th>
                </tr>
                <tbody class="divide-y px-4 divide-gray-700">
                  <DialogListItem
                    v-for="group in sliceHotels"
                    :key="group.id"
                    :group="group"
                    @assignRFP="assignRFP"
                  />
                </tbody>
              </table>
            </div>
            <Pagination
              :actual="actual"
              :max="16"
              :total="filteredHotels.length"
              @togglePage="togglePage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { firestore } from "@/utils/firebase";
import {
  addDoc,
  collection,
  doc,
  orderBy,
  getDocs,
  where,
  setDoc,
  query,
} from "firebase/firestore";
import DialogListItem from "./DialogListItem.vue";
import Pagination from "@/components/default/Pagination.vue";

export default {
  components: {
    DialogListItem,
    Pagination,
  },
  props: ["rfp"],
  computed: {
    account() {
      return this.$store.state.account;
    },
    sliceHotels() {
      const sliceStart = this.actual * 15;
      return this.filteredHotels.slice(sliceStart, sliceStart + 15);
    },
    hotelsFound() {
      return this.filteredHotels.length;
    },
  },
  data() {
    return {
      search: "",
      actual: 0,
      loading: false,
      loadingMore: false,
      hotels: [],
      toggleDialog: false,
      filteredHotels: [],
    };
  },
  watch: {
    search() {
      if (this.search.length > 3) {
        this.filteredHotels = this.hotels.filter(
          (hotel) => hotel.data().name.indexOf(this.search) >= 0
        );
      } else {
        this.filteredHotels = this.hotels;
      }
      this.actual = 0;
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    closeDialog() {
      this.$emit("hideGroupDialog");
    },
    async assignRFP(group) {
      if (!confirm("Assign selected group to this RFP?")) return;
      this.loading = true;
      const selectedHotels = group.data().hotels;
      const selected = [];
      selectedHotels.forEach((hotel) => selected.push(hotel.id));
      const qContacts = query(
        collection(firestore, "contacts"),
        where("hotel_id", "in", selected)
      );
      const contacts = await getDocs(qContacts);
      const hotelsContacts = contacts.docs.map((contact) => contact.id);
      const hotels = [...this.rfp.hotels, ...selected];
      const shared_with = [...this.rfp.shared_with, ...hotelsContacts];
      await setDoc(doc(firestore, "rfps", this.rfp.id), {
        ...this.rfp,
        hotels,
        shared_with,
        updated_at: new Date(),
      });
      for (const hotel of selectedHotels) {
        const qHasRFPHotel = query(
          collection(firestore, "rfp_hotel"),
          where("hotel_id", "==", hotel.id),
          where("rfp", "==", this.rfp.id)
        );
        const RFPHotel = await getDocs(qHasRFPHotel);
        if (RFPHotel.empty) {
          const status_history = [
            {
              created_at: new Date(),
              content: "RFP created",
              status: "New",
              account: this.account,
            },
          ];
          await addDoc(collection(firestore, "rfp_hotel"), {
            ...this.rfp,
            hotel_id: hotel.id,
            hotel_name: hotel.name,
            value: hotel.value || "",
            rfp: this.rfp.id,
            status_history,
            created_at: new Date(),
            status: "New",
          });
        }
      }

      this.$store.commit("setToast", {
        content: `<div class='flex flex-col gap-2'>
            <p>${selected.length} added to ${this.rfp.name}</p>
            <p>You can now send this RFP!</p>
          </div>`,
      });
      this.toggleDialog = false;
      this.loading = false;
      this.$emit("getData");
    },
    togglePage(page) {
      this.actual = page;
    },
    async getData() {
      this.loading = true;
      const qHotels = query(
        collection(firestore, "hotel_groups"),
        orderBy("name")
      );
      const hotels = await getDocs(qHotels);
      const hotelsDocs = hotels.docs;
      this.hotels = this.filteredHotels = hotelsDocs;
      document.title = `Launchpad - Crewfare - Hotels - Groups`;
      this.loading = false;
    },
  },
};
</script>
