<template>
  <div
    v-if="rfp"
    class="bg-gray-900 overflow-hidden w-full py-4 flex justify-center gap-4 stage-height items-center text-white relative"
  >
    <div class="z-10 relative">
      <img src="/logo.svg" class="h-[50px] mb-12 mx-auto" />
      <div class="text-center text-xl">You have marked the CC Authorization done!</div>
      <ConffetiAnimation />
      <div class="text-center relative z-30">
        You can download the authorization
        <a :href="rfp.data().authorization_url" target="_blank" class="underline">clicking here</a>.
      </div>
    </div>
  </div>
</template>

<script>
import { firestore } from '@/utils/firebase.tsx';
import { doc, getDoc } from 'firebase/firestore';
import ConffetiAnimation from '@/components/default/ConffetiAnimation.vue';

export default {
  components: {
    ConffetiAnimation,
  },
  data() {
    return {
      rfp: null,
    };
  },
  async mounted() {
    const rfpRef = doc(firestore, 'rfp_hotel', this.$route.params.rfp);
    this.rfp = await getDoc(rfpRef);
  },
};
</script>
