import Form from './Form.vue';
import List from './List.vue';
import Dashboard from '@/layouts/Dashboard.vue';

export const users = [
  {
    path: '/users',
    name: 'users',
    component: Dashboard,
    meta: {
      view: 'Users',
    },
    children: [
      {
        path: '',
        name: 'usersList',
        component: List,
      },
      {
        path: 'new',
        name: 'usersAdd',
        component: Form,
      },
      {
        path: ':id/edit',
        name: 'usersEdit',
        component: Form,
      },
    ],
  },
];
