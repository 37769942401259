<template>
  <div v-if="account" class="flex">
    <div
      class="min-h-[100vh] sticky top-0 left-0 z-[90]"
      :class="!menuExpanded && 'w-[82px]'"
    >
      <Menu />
    </div>
    <main class="bg-[#23262f] w-full flex flex-col">
      <Header />
      <RouterView />
    </main>
  </div>
</template>

<script>
import Menu from "@/components/default/Menu.vue";
import Header from "@/components/default/Header.vue";
import { RouterView } from "vue-router";

export default {
  components: {
    Menu,
    Header,
    RouterView,
  },
  computed: {
    menuExpanded() {
      return this.$store.state.menuStatus;
    },
    account() {
      return this.$store.state.account;
    },
  },
};
</script>
