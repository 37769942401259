<template>
  <div ref="contractContentHolder" class="text-white">
    <h2 class="text-xl text-center mb-4">CREWFARE HOTEL AGREEMENT</h2>
    <div style="margin-top: 12px">
      This Master Hotel Agreement (this "Agreement") is made and entered into as
      of
      <span class="!text-yellow-200 print:text-yellow-900">{{
        effectiveDateFormated
      }}</span>
      (the "Effective Date") by and between Crewfare LLC ("Group") and
      <span class="!text-yellow-200 print:text-yellow-900">{{
        hotel.data().name
      }}</span>
      (the "Hotel"). Group and Hotel are sometimes referred to herein
      individually as a "Party" and collectively as the "Parties".
    </div>
    <div style="margin-top: 12px" class="text-xl font-bold text-center py-4">
      TENTATIVE NAME OF EVENT: {{ rfp.data().name }} (the "Event").
    </div>
    <table
      style="margin-top: 12px; width: 100%"
      cellpadding="0"
      cellspacing="0"
    >
      <tr>
        <td valign="top" width="50%">
          <h3 class="text-xl">GROUP:</h3>
          <div style="margin-top: 12px">Company: Crewfare LLC</div>
          <div style="margin-top: 12px">Name: Jason van Esso</div>
          <div style="margin-top: 12px">Title: Partner</div>
          <div style="margin-top: 12px">
            Address: 2678 Edgewater Court, Weston FL 33332
          </div>
          <div style="margin-top: 12px">Telephone: 954-591-9005</div>
          <div style="margin-top: 12px">Email: jason@crewfare.com</div>
        </td>
        <td valign="top" width="50%">
          <h3 class="text-xl">HOTEL:</h3>
          <div style="margin-top: 12px">
            Hotel Name: {{ hotel.data().name }}
          </div>
          <div style="margin-top: 12px" class="flex gap-2 items-center">
            Name:
            <span class="!text-yellow-200 print:text-yellow-900">{{
              rfp.data().hotel_person_name
            }}</span>
          </div>
          <div style="margin-top: 12px" class="flex gap-2 items-center">
            Title:

            <span class="!text-yellow-200 print:text-yellow-900">{{
              rfp.data().hotel_title
            }}</span>
          </div>
          <div style="margin-top: 12px" class="flex gap-2 items-center">
            Address:
            <span class="!text-yellow-200 print:text-yellow-900">{{
              rfp.data().hotel_address
            }}</span>
          </div>
          <div style="margin-top: 12px" class="flex gap-2 items-center">
            Telephone:
            <span class="!text-yellow-200 print:text-yellow-900">{{
              rfp.data().hotel_telephone
            }}</span>
          </div>
          <div style="margin-top: 12px" class="flex gap-2 items-center">
            Email:
            <span class="!text-yellow-200 print:text-yellow-900">{{
              rfp.data().hotel_email
            }}</span>
          </div>
        </td>
      </tr>
    </table>
    <h3 style="margin-top: 12px" class="text-xl">1. TERMS.</h3>
    <div style="margin-top: 12px">
      <span class="underline">A. Reservation of Rooms.</span> The Hotel will
      reserve, exclusively for the Event's team, staff, fans, or other guests
      (collectively the "Guests" or the "Group" or the "Patrons"), sleeping
      rooms (the "Rooms") on the event dates below (the "Reservation Period")
      subject to the terms of this Agreement.
    </div>
    <div style="margin-top: 12px">
      <span class="underline">B. Courtesy Room Block.</span> Room rates have
      been established for Group's room block ("Room Block"). The Hotel is
      obligated to hold the Room Block available to the Group up until the
      Cut-Off Date. There is no commitment by Group to pick up these rooms.
    </div>
    <div style="margin-top: 12px">
      <span class="underline">C. Room Assignments.</span>
      No later than
      <span class="!text-yellow-200 print:text-yellow-900">{{
        cutoffDays
      }}</span>
      days prior to the Event Date (the "Cut-Off Date")
      <span class="!text-yellow-200 print:text-yellow-900">{{
        cutoffDateFormated
      }}</span
      >, the Group will notify the Hotel of such assignments by providing a
      Rooming List to the Hotel with the assigned names to the Hotel. It is
      expressly agreed and understood that the Group makes no representations
      that the number of Rooms reserved under this Agreement will be assigned.
      Only assigned Rooms will be paid for. After the Cut-off Date, Rooms
      reserved under this Agreement, but not assigned to specific Patrons, may
      be released, at no cost to the Group, for sale to the general public.
      After the Cut-off Date, Hotel will accept additional reservations from the
      Group, subject to availability, at Group Rates
    </div>
    <div style="margin-top: 12px">
      <span class="underline">D. Room Inventory (the "Inventory").</span>&nbsp;
      <span class="!text-yellow-200 print:text-yellow-900">{{
        startDateFormated
      }}</span>
      through
      <span class="!text-yellow-200 print:text-yellow-900">{{
        endDateFormated
      }}</span>
      shall be the "Peak Nights". The number of rooms below shall be allocated
      and committed to Group as Rooms for the Guests.
    </div>
    <table
      style="
        margin-top: 12px;
        width: 100%;
        border-color: #000;
        border-collapse: collapse;
      "
      border="1"
      class="w-full"
    >
      <tr>
        <td class="px-4 py-1 border border-black text-center">Date</td>
        <td
          v-for="(type, index) in types"
          :key="index"
          class="px-4 py-1 border border-black text-center"
        >
          {{ type }}
        </td>
        <td class="px-4 py-1 border border-black text-center">Total</td>
      </tr>
      <tr v-for="(days, index) in rfp.data().roomList" :key="index">
        <td class="px-4 py-1 border border-black text-center">
          {{ days.date }}
        </td>
        <td
          v-for="(type, index) in types"
          :key="index"
          class="px-4 py-1 border border-black text-center"
        >
          {{ days.rooms.find((room) => room.type === type).amount || 0 }}
        </td>
        <td class="px-4 py-1 border border-black text-center">
          {{ getTotalByDay(days.rooms) }}
        </td>
      </tr>
      <tr>
        <td class="px-4 py-1 border border-black text-center"></td>
        <td
          v-for="(type, index) in types"
          :key="index"
          class="px-4 py-1 border border-black text-center"
        >
          {{ getTotalByType(type) }}
        </td>
        <td class="px-4 py-1 border border-black text-center">
          {{ getTotal() }}
        </td>
      </tr>
    </table>
    <div style="margin-top: 12px">
      <span class="underline">E. Room Rates. (the "Room Rates").</span>
    </div>
    <table
      style="
        margin-top: 12px;
        width: 100%;
        border-color: #000;
        border-collapse: collapse;
      "
      border="1"
      class="w-full"
    >
      <tr>
        <td class="px-4 py-1 border border-black text-center">Date</td>
        <td
          v-for="(type, index) in types"
          :key="index"
          class="px-4 py-1 border border-black text-center"
        >
          {{ type }}
        </td>
      </tr>
      <tr v-for="(days, index) in rfp.data().roomList" :key="index">
        <td class="px-4 py-1 border border-black text-center">
          {{ days.date }}
        </td>
        <td
          v-for="(type, index) in types"
          :key="index"
          class="px-4 py-1 border border-black text-center"
        >
          {{
            parseFloat(days.rooms.find((room) => room.type === type).value) > 0
              ? `${
                  days.rooms.find((room) => room.type === type).currency ||
                  "$ USD"
                } ${days.rooms.find((room) => room.type === type).value}`
              : "-"
          }}
        </td>
      </tr>
    </table>
    <div style="margin-top: 12px" v-if="rfp.data().groupDateHonored === 'No'">
      The group rate is
      <span v-if="rfp.data().groupDateHonoredPre">
        <span class="!text-yellow-200 print:text-yellow-900"
          >$ {{ rfp.data().groupDateHonoredPreRate }}</span
        >
        pre
      </span>
      <span
        v-if="rfp.data().groupDateHonoredPre && rfp.data().groupDateHonoredPost"
        >&nbsp;and&nbsp;</span
      >
      <span v-if="rfp.data().groupDateHonoredPost">
        <span class="!text-yellow-200 print:text-yellow-900"
          >$ {{ rfp.data().groupDateHonoredPostRate }}</span
        >
        post
      </span>
      on "Shoulder Nights" known as +/-
      <span v-if="rfp.data().groupDateHonoredPre">
        <span class="!text-yellow-200 print:text-yellow-900">{{
          rfp.data().groupDateHonoredPreDays
        }}</span>
        pre
      </span>
      <span
        v-if="rfp.data().groupDateHonoredPre && rfp.data().groupDateHonoredPost"
        >&nbsp;and&nbsp;</span
      >
      <span v-if="rfp.data().groupDateHonoredPost">
        <span class="!text-yellow-200 print:text-yellow-900">{{
          rfp.data().groupDateHonoredPostDays
        }}</span>
        post
      </span>
      the "Peak Nights".
    </div>
    <div style="margin-top: 12px" v-if="rfp.data().groupDateHonored === 'Yes'">
      The group rate is on "Shoulder Nights" known as +/-
      <span v-if="rfp.data().groupDateHonoredPre">
        <span class="!text-yellow-200 print:text-yellow-900">{{
          rfp.data().groupDateHonoredPreDays
        }}</span>
        pre
      </span>
      <span
        v-if="rfp.data().groupDateHonoredPre && rfp.data().groupDateHonoredPost"
        >&nbsp;and&nbsp;</span
      >
      <span v-if="rfp.data().groupDateHonoredPost">
        <span class="!text-yellow-200 print:text-yellow-900">{{
          rfp.data().groupDateHonoredPostDays
        }}</span>
        post
      </span>
      the "Peak Nights".
    </div>
    <div style="margin-top: 12px">
      <span class="underline">F.Taxes & Fees.</span> Rates above are quoted
      exclusive of applicable state and local taxes, fees and other charges
      known as the "Ancillary Costs". All Ancillary Costs must be stated in the
      below chart in order for the Hotel to be compensated for those fees
    </div>
    <table
      style="
        width: 100%;
        border-color: #000;
        border-collapse: collapse;
        margin-top: 12px;
      "
      border="1"
      class="w-full"
    >
      <tr>
        <th class="px-4 py-1 border border-black text-center">Charge</th>
        <th class="px-4 py-1 border border-black text-center">
          Per Room Night
        </th>
      </tr>
      <tr>
        <td class="px-4 py-1 border border-black">Taxes</td>
        <td class="px-4 py-1 border border-black text-center">
          <span>
            <span class="!text-yellow-200 print:text-yellow-900">
              {{
                parseFloat(rfp.data().hotel_taxes) > 0
                  ? `${rfp.data().hotel_taxes}
            %`
                  : "-"
              }}
            </span>
          </span>
        </td>
      </tr>
      <tr>
        <td class="px-4 py-1 border border-black">Resort Fee</td>
        <td class="px-4 py-1 border border-black text-center">
          <span class="!text-yellow-200 print:text-yellow-900">
            {{
              rfp.data().resortFee === "Resort Fee"
                ? rfp.data().resortFeeAmount
                : rfp.data().resortFee
            }}
          </span>
        </td>
      </tr>
      <tr>
        <td class="px-4 py-1 border border-black">Other Fees</td>
        <td class="px-4 py-1 border border-black text-center">
          <span class="!text-yellow-200 print:text-yellow-900">
            {{
              !rfp.data().otherFees
                ? "No"
                : rfp.data().otherFees === "Yes"
                ? rfp.data().otherFeesAmount
                : rfp.data().otherFees
            }}
          </span>
        </td>
      </tr>
    </table>
    <div style="margin-top: 12px">
      <span class="underline">G. Pricing Confidentiality.</span> The prices
      contained in this Agreement are considered confidential and Hotel will not
      disclose such information to any third parties or Patrons without Group's
      prior written consent.
    </div>
    <div style="margin-top: 12px">
      <span class="underline">H. Rate Integrity.</span> If, from the date of
      contracting through the Cut-Off Date, it is determined that a qualifying
      lower single/double rate for the same room type, dates, bed type, number
      of guests, same or better restrictions and policies is offered to other
      guests over the Group's scheduled dates, Hotel agrees to use commercially
      reasonable efforts to promptly remove the lower published rate from all
      distribution channels. This commitment only applies to direct published
      rates made available to the general public.
    </div>
    <div style="margin-top: 12px">
      <span class="underline">I. Commissions and Rebates.</span> Rooms occupied
      and actualized by Guests shall include a
      <span class="!text-yellow-200 print:text-yellow-900">{{
        rfp.data().commission
      }}</span>
      commission and
      <span class="!text-yellow-200 print:text-yellow-900">{{
        rfp.data().rebate
      }}</span>
      rebate, payable per actualized room night booked in the Room Block and all
      room nights found outside of the Room Block, regardless of actual guest
      room rate. For cancelled reservations or No-Shows where the reservation
      was guaranteed with a deposit or major credit card, commission/rebates are
      due on any charges that Hotel is able to collect from the Patrons causing
      the cancellation or No-Show, as applicable. Any commission or rebate must
      be detailed in a final folio to Group, sent no later than seven (7) days
      after the Event. Commissions and rebates are payable to Crewfare LLC (IATA
      #:
      <span class="!text-yellow-200 print:text-yellow-900">{{
        rfp.data().iata
      }}</span
      >) no later than thirty (30) days from invoice date.
    </div>
    <div style="margin-top: 12px">
      <span class="underline">J. Event Planner Program.</span> Jason van Esso
      (collectively, the "Event Planner") is eligible to earn an event planner
      bonus for qualifying events. Event Planner is eligible to earn
      <span class="!text-yellow-200 print:text-yellow-900">{{
        rfp.data().rewards
      }}</span>
      Rewards points. The Event Planner's member number is
      <span class="!text-yellow-200 print:text-yellow-900">
        {{ rfp.data().crewfare_members }} </span
      >.
    </div>
    <div style="margin-top: 12px">
      <span class="underline">K. Complimentary Rooms.</span> For its use during
      the dates of the Event, and ancillary planning dates, Group will be
      entitled to
      <span class="!text-yellow-200 print:text-yellow-900">{{
        rfp.data().compRooms
      }}</span>
      complimentary room night for every room nights occupied, on a cumulative
      basis. Hotel agrees to credit to Group's Master Account the actual value
      of the earned complimentary rooms.
    </div>
    <div style="margin-top: 12px">
      <span class="underline">L. Exclusive Right to Use Rooms.</span> Hotel
      acknowledges and agrees that Group shall have the exclusive right to sell,
      rent, or otherwise assign the Rooms. It is expressly agreed that where the
      Group resells Rooms, the rates that Group charges may vary from the Room
      Rates listed in this Agreement. Hotel agrees (i) to keep the rate charged
      to the Group for any given Room confidential between the Parties, and (ii)
      not to disclose the rate charged to the Group to any Patrons, whether
      directly or indirectly, under any circumstances. Notwithstanding anything
      to the contrary in this Agreement, substitution of any reservations (i.e.,
      a change to the name/details included on the Rooming List) by the Group
      shall be allowed at any time up to and through the time of the Event.
    </div>
    <h3 class="text-xl">2. HOTEL TAXES AND OTHER FEES.</h3>
    <div style="margin-top: 12px">
      Hotel sleeping room rates are subject to applicable state and local taxes
      in effect at the time of check-in. During the term of this Agreement,
      Hotel shall not impose any other mandatory charge on guests or master
      accounts associated with the Group or the Event. Hotel will notify Group
      of the tax requirements and any changes in the tax requirements.
    </div>
    <div style="margin-top: 12px">
      <span class="underline">A. Master Account & Payment Arrangements.</span>
      Master account credit card will the expenses for the room and applicable
      tax for the room only. All Guests must provide credit card at check-in for
      their own incidental charges, resort fees and other charges. Group shall
      not be responsible for any incidental charges for any Hotel guests or any
      other charges without written consent from Group
    </div>
    <div style="margin-top: 12px">
      <span class="underline">B. Deposit Schedule.</span> Group and Hotel agree
      to the following deposit schedule. Hotel agrees to obtain approval from
      Crewfare's accounting team,
      <a href="mailto:accounting@crewfare.com" class="underline">
        accounting@crewfare.com</a
      >, (Tony Tohme) before making any such charges to the credit card on file
      at any time
    </div>
    <table
      style="
        margin-top: 12px;
        width: 100%;
        border-color: #000;
        border-collapse: collapse;
      "
      border="1"
      class="w-full"
    >
      <tr>
        <th></th>
        <th class="border border-black px-4 py-2">Due Date</th>
        <th class="border border-black px-4 py-2">
          Amount (Not Including Service Charge or Tax)
        </th>
      </tr>
      <tr>
        <th class="border border-black px-4 py-2">Upon Signing</th>
        <td class="border border-black px-4 py-2">Upon signing of Agreement</td>
        <td class="border border-black px-4 py-2">
          Credit Card Guarantee Only ($0.00 deposit)
        </td>
      </tr>
      <tr>
        <th class="border border-black px-4 py-2">Second Deposit</th>
        <td class="border border-black px-4 py-2">At Cut-Off Date:</td>
        <td class="border border-black px-4 py-2">
          25% of Anticipated Master Account Balance (Based On Group Pick Up)
        </td>
      </tr>
      <tr>
        <th class="border border-black px-4 py-2">Final Deposit</th>
        <td class="border border-black px-4 py-2">3 Days Ahead of Arrival:</td>
        <td class="border border-black px-4 py-2">
          100% Of Anticipated Master Account Balance.
        </td>
      </tr>
    </table>
    <div style="margin-top: 12px">
      <span class="underline">C. Early Departure.</span> Neither Group nor the
      Guests will be charged for early departure fees.
    </div>
    <div style="margin-top: 12px">
      <span class="underline">D. No-Shows.</span> Hotel agrees that it will not
      release a No-Show accommodation without prior approval from Crewfare. A
      No-Show room night billed to the master account is considered an
      actualized room night and will count towards Group's final pick-up.
    </div>
    <div style="margin-top: 12px">
      <span class="underline"
        >E. Construction, Renovation, Hotel Closings.</span
      >
      Hotel agrees to immediately notify the Group of any plans for renovation
      or construction. Under such circumstances, the Group shall have the right
      to reduce or terminate their planned use of the Hotel upon written notice
      to Hotel and if so terminated, the Group shall be indemnified by the Hotel
      for any costs and expenses incurred in hosting the Patrons at the Hotel.
      Since the property is undergoing renovations and is expected to have them
      completed in May, we ask that the Hotel contacts us in advance to notify
      us if they cannot accommodate the block after the signing of this
      agreement.
    </div>
    <div style="margin-top: 12px">
      <span class="underline">F. Americans with Disabilities Act.</span> As
      among the parties, Hotel shall be responsible for compliance with the
      Americans with Disabilities Act ("ADA") including, but not limited to,
      compliance with respect to: (i) the Hotel's policies practices, procedures
      and eligibility criteria; (ii) the provision of auxiliary aids and
      services in the Hotel; (iii) architectural, communications and
      transportation barriers in the Hotel; and (iv) the provision of wheelchair
      seating spaces in assembly areas
    </div>
    <div style="margin-top: 12px">
      <span class="underline">G. Insurance.</span> Hotel will maintain full
      insurance coverage throughout the term of this Agreement, in such amounts,
      coverages, types and insurers as are maintained by other hotels of similar
      quality in the State of Florida, against all third party liability,
      including insurance in respect of liability to guests and others using the
      hotel facilities and liability to the employees and agents of the Hotel,
      and will supply certificates of insurance to the Group upon request.
    </div>
    <div style="margin-top: 12px">
      <span class="underline">H. Change in Hotel Management or Ownership.</span>
      Hotel shall notify Group of any change in Hotel management or ownership.
      Group shall have the right to terminate this Agreement without liability
      if, after the time of contracting but before the Event, the Hotel changes
      the hotel brand, such that the level of service provided by the new brand
      is lower than Hotel's original brand at the time of contracting. Any
      change in the point of contact must also be communicated to the Group.
      Conversely, new management or ownership agrees to honor Group under terms
      set forth in this agreement
    </div>
    <div style="margin-top: 12px">
      <span class="underline">I. Concessions</span>
    </div>
    <ol class="upgrade-list">
      <li>
        Suite Upgrades:
        <span class="!text-yellow-200 print:text-yellow-900">{{
          rfp.data().suiteUpgrades
        }}</span>
      </li>
      <li>
        Wi-Fi:
        <span class="!text-yellow-200 print:text-yellow-900">{{
          rfp.data().wifi
        }}</span>
      </li>
      <li>
        Parking:
        <span class="!text-yellow-200 print:text-yellow-900"
          >{{ rfp.data().parking }}
          {{
            rfp.data().parking === "Not included" ? rfp.data().parkingOther : ""
          }}</span
        >
      </li>
      <li>
        Breakfast:
        <span class="!text-yellow-200 print:text-yellow-900"
          >{{ rfp.data().breakfast }}
          {{
            rfp.data().breakfast === "Not included"
              ? rfp.data().breakfastOther
              : ""
          }}</span
        >
      </li>
      <li>
        Additional terms:
        <span class="!text-yellow-200 print:text-yellow-900">{{
          !rfp.data().terms ? "No" : rfp.data().terms
        }}</span>
      </li>
    </ol>
    <div style="margin-top: 12px">
      <span class="font-bold">3. COMPLIANCE WITH LAW.</span> Each of the Parties
      undertakes to comply with all applicable laws, rules regulations and
      ordinances, whether applicable nationally, at federal, state or local
      level or by a competent foreign lawmaker. For the avoidance of doubt, this
      includes applicable laws, rules, regulations and ordinances which relate
      to the processing of personal data/personally identifiable information.
    </div>
    <div style="margin-top: 12px">
      <span class="font-bold">4. ACCEPTANCE.</span> Once executed, this
      Agreement shall be effective as of the date first above mentioned (the
      "Effective Date")
    </div>
    <div style="margin-top: 12px">
      <span class="font-bold">5. INTELLECTUAL PROPERTY.</span> Hotel shall not
      use any Intellectual Property Rights of Group, Event, or any of their
      respective affiliates. No such rights are granted pursuant to this
      Agreement. For these purposes, "Intellectual Property Rights" means all
      patents, trademarks, and copyright of Group or Event
    </div>
    <div style="margin-top: 12px">
      <span class="font-bold">6. INDEMNIFICATION.</span> Hotel agrees to
      indemnify, defend and hold harmless Group, Group's owners, managers,
      partners, subsidiaries, affiliates, officers, directors, employees and
      agents (collectively, the "Group Indemnified Parties"), from and against
      any and all Claims (as such term is defined above) arising out of or
      relating to the Event that is the subject of this Agreement but only to
      the extent any such Claim(s) to the extent any such Claim(s) arise out of
      the negligence, gross negligence or intentional misconduct of Hotel's
      employees, agents, or contractors.
    </div>
    <h3 class="text-xl">7. MISCELLANEOUS.</h3>
    <div style="margin-top: 12px">
      <span class="font-bold">A.</span> Severability Hotel agrees that it will
      not release a No-Show accommodation without prior approval from Crewfare.
      A No-Show room night billed to the master account is considered an
      actualized room night and will count towards Group's final pick-up.
    </div>
    <div style="margin-top: 12px">
      <span class="font-bold">B.</span> Entire Agreement This Agreement,
      including any attachments, constitutes the entire agreement of the parties
      and its provisions supersede any and all prior and contemporaneous
      agreements or understandings relating to the same subject matter
    </div>
    <div style="margin-top: 12px">
      <span class="font-bold">C.</span> Governing Law. This Agreement shall be
      governed and construed in accordance with the laws of the State of
      Florida, USA, without regard to its choice-of-law principles. Any claim
      arising under this Agreement shall be prosecuted exclusively in a federal
      or state court of competent jurisdiction located within Miami-Dade
      County,Florida, USA and the parties consent to the jurisdiction of such
      court and to the service of process by mail. Notwithstanding anything to
      the contrary contained herein, each Party hereby waives, to the maximum
      extent not prohibited by law, any right it may have to claim or recover in
      any legal action or proceeding arising under this Agreement any special,
      exemplary, punitive or consequential damages.
    </div>
    <div style="margin-top: 12px">
      <span class="font-bold">D.</span> Successors and Assigns. This Agreement
      may not be transferred or assigned by any party without the prior written
      consent of the other parties, which may not be unreasonably withheld or
      delayed, provided that F1R may assign this Agreement to its subsidiary
      undertakings, its holding company or holding companies and other
      subsidiary undertakings of each of its holding companies from time to
      time, each, an "Affiliate", and "Affiliates" shall mean all or more than
      one such Affiliate. Any attempted assignment or transfer in violation of
      this Section shall be void and a material breach of this Agreement. This
      Agreement will be binding upon any authorized successors or assigns. Prior
      to any transfer or assignment of this Agreement, the party seeking to
      assign or transfer this Agreement shall obtain a written acknowledgement
      of the binding nature of this Agreement from the successor or assignee
    </div>
    <div style="margin-top: 12px">
      <span class="font-bold">E.</span> Authority Hotel hereby represents and
      warrants to Crewfare that: (i) Hotel has the authority to enter into this
      Agreement; (ii) its execution and performance of this Agreement does not
      and will not conflict with or violate any law, regulation or agreement by
      which it is bound; and (iii) Hotel has obtained all consents, approvals
      and/or authorizations necessary to make this Agreement binding upon the
      Hotel, the owner of the Hotel, Hotel's management and all other persons or
      entities which ay have any interest in the Hotel, its management and/or
      premises and upon any and all successors purchaser or transferees which
      may obtain any interest in Hotel, its management and/or premises during
      the term of this Agreement. At Crewfare's request, Hotel shall use its
      best efforts to obtain a written non-interference agreement from any
      person or entity which may have any ownership, mortgage or deb-related
      interest in Hotel. This non-interference agreement shall provide that any
      successor-in-title by foreclosure or to other conveyance will be bound by
      this Agreement.
    </div>
    <div style="margin-top: 12px">
      <span class="font-bold">F.</span> Notices. All notices, demands, requests
      or other communications relating to this agreement shall be in writing and
      shall be mailed, first class, or transmitted by hand delivery or email,
      addressed to the contacts as included in the beginning of the Agreement.
    </div>
    <div style="margin-top: 12px">
      <span class="font-bold">G.</span> Amendments. This Agreement may not be
      modified, amended, terminated or otherwise changed, except by a written
      instrument executed by each of the Parties.
    </div>
    <div style="margin-top: 12px">
      <span class="font-bold">H.</span> Interpretation. No provisions of this
      Agreement shall be construed against or interpreted to the disadvantage of
      any party to this Agreement by any court or other governmental or judicial
      authority by reason of such party having or being deemed to have drafted
      or dictated such provision.
    </div>
    <div style="margin-top: 12px">
      <span class="font-bold">I.</span> Independent Contractors. The status of
      the Parties under this Agreement shall be that of independent contractors.
      No Party shall be authorized to waive any right, or assume or create any
      contract or obligation of any kind in the name of, or on behalf of, the
      others or to make any statement that it has the authority to do so.
      Nothing in this Agreement shall be construed as establishing a
      partnership, joint venture, agency, employment or other similar
      relationship among the parties hereto
    </div>
    <div style="margin-top: 12px">
      <span class="font-bold">J.</span> Waiver. The failure of any party to
      enforce any condition or part of this Agreement at any time shall not be
      construed as a waiver of that condition or part, nor shall it forfeit any
      rights to future enforcement thereof.
    </div>
    <div style="margin-top: 12px">
      <span class="font-bold">K.</span> Counterparts. This Agreement may be
      executed and delivered in counterparts, each of which shall be deemed an
      original and all of which, taken together, shall constitute one
      instrument. Copies of executed counterparts of this Agreement transmitted
      by email shall be considered original executed counterparts provided that
      receipt of such email is confirmed.
    </div>
    <div style="margin-top: 12px">
      <span class="font-bold">L.</span> Costs Each Party shall bear its own
      costs in connection with the preparation, negotiation and execution of
      this Agreement
    </div>
    <div style="margin-top: 12px">
      <span class="font-bold">M.</span> Force Majeure. Neither party shall be
      liable for any failure or delay in performance of its obligations under
      this Agreement arising out of or caused, directly or indirectly, by
      circumstances beyond its reasonable control, including, without
      limitation, cancellation of the Event for whatever reason; government
      regulations prohibiting travel to the city in which the Hotel is located
      over the Event dates and/or the issuance of a "Level 3 Travel Warning" by
      the Centers for Disease Control that specifically advises travelers to
      avoid all non-essential travel to the city in which the Hotel or the Event
      is located and which travel advisory is in effect over the Event dates;
      acts of God; earthquakes; fires; floods; wars; civil or military
      disturbances; acts of terrorism; sabotage; strikes; epidemics; riots;
      power failures; computer failure and any such circumstances beyond its
      reasonable control as may cause interruption, loss or malfunction of
      utility, transportation, computer (hardware or software) or telephone
      communication service; accidents; labor disputes; acts of civil or
      military authority; governmental actions; or inability to obtain labor,
      material, equipment or transportation. The affected party may terminate
      this Agreement without liability upon providing written notice to the
      other party within ten (10) days of the occurrence. If the Event is
      properly cancelled this shall be considered a valid impossibility force
      majeure occurrence, then upon written request by Group, Hotel agrees to
      refund to Group all prepaid deposits or advance payments paid to Hotel
      without deduction, less any expenses incurred by Hotel in preparation for
      the Event.
    </div>
    <div style="margin-top: 12px">
      IN WITNESS WHEREOF, the Parties have executed this Agreement as of the
      Effective Date.
    </div>
  </div>
</template>

<script>
import { firestore } from "@/utils/firebase";
import { setStatusHistory } from "@/utils/rfp.jsx";
import { doc, setDoc } from "firebase/firestore";
import moment from "moment";

export default {
  props: ["rfp", "hotel", "signed"],
  computed: {
    cutoffDays() {
      const date = moment(this.rfp.data().startDate.toDate());
      const cutoff = moment(this.rfp.data().cutoffDate.toDate());
      const diff = date.diff(cutoff);
      const duration = moment.duration(diff);
      return Math.floor(duration.asDays());
    },
    types() {
      const types = [];
      this.rfp.data().roomList.forEach((day) => {
        day.rooms.forEach((room) => {
          if (!types.includes(room.type)) {
            types.push(room.type);
          }
        });
      });
      return types;
    },
    rooms() {
      const roomsList = [];
      this.rfp.data().roomList.forEach((day) => {
        roomsList.push({
          day: day.date,
          ...day.rooms,
        });
      });
      return roomsList;
    },
    endDateFormated() {
      if (!this.rfp.data().endDate) return "";
      const date = this.rfp.data().endDate;
      return moment(date.toDate()).format("MM-DD-YYYY");
    },
    startDateFormated() {
      if (!this.rfp.data().startDate) return "";
      const date = this.rfp.data().startDate;
      return moment(date.toDate()).format("MM-DD-YYYY");
    },
    effectiveDateFormated() {
      if (!this.rfp.data().effective_date) return "";
      const date = this.rfp.data().effective_date;
      return moment(date.toDate()).format("MM-DD-YYYY");
    },
    cutoffDateFormated() {
      if (!this.rfp.data().cutoffDate) return "";
      const date = this.rfp.data().cutoffDate;
      return moment(date.toDate()).format("MM-DD-YYYY");
    },
    account() {
      return this.$store.state.account;
    },
  },
  data() {
    return {
      hotel_person_name: "",
      hotel_address: "",
      hotel_email: "",
      hotel_telephone: "",
      hotel_title: "",
      hotel_date: "",
      crewfare_date: "",
      crewfare_name: "",
      crewfare_title: "",
      crewfare_members: "",
      hotel_taxes: "",
      hotel_other_fees: "",
    };
  },
  methods: {
    validateDate(date) {
      const regex =
        /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/;
      if (!regex.test(date)) {
        date = "";
      }
    },
    getTotal() {
      const roomList = this.rfp.data().roomList;
      const total = roomList.map((day) =>
        day.rooms
          .map((room) => parseFloat(room.amount || 0))
          .reduce((accumulator, value) => accumulator + value, 0)
      );
      return total.reduce((accumulator, value) => accumulator + value, 0);
    },
    getTotalByType(type) {
      const roomList = this.rfp.data().roomList;
      const total = roomList.map((day) =>
        day.rooms
          .filter((room) => room.type === type)
          .map((room) => parseFloat(room.amount || 0))
      );
      return total.reduce(
        (accumulator, value) => accumulator + parseFloat(value[0]),
        0
      );
    },
    getTotalByDay(rooms) {
      const amount = rooms.map((room) => parseFloat(room.amount || 0));
      return amount.reduce((accumulator, value) => accumulator + value, 0);
    },
  },
};
</script>
