<template>
  <div class="flex flex-col gap-1 w-full">
    <div
      class="text-sm font-bold flex justify-between items-center mb-2"
      :class="has_changes && 'text-yellow-500'"
    >
      <div class="flex gap-2 items-center">
        <span
          class="material-symbols-rounded text-[16px]"
          :class="checked ? 'text-crewfareGreen' : 'text-white'"
        >
          {{ checked ? "check_box" : "check_box_outline_blank" }}
        </span>
        Attrition:
      </div>
      <div class="flex gap-4 print:hidden">
        <ButtonNotesDialog
          v-if="!isNSO"
          type="Comment on concession"
          :subtitle="rfp.name"
          title="Comments at Attrition"
          :id="`${rfp.id}-attrition`"
          :rfp="rfp.id"
          :hotel_id="rfp.hotel_id"
          field="Attrition"
          text="Comment"
        />
        <span
          v-if="is_locked"
          class="text-[18px] flex items-center px-4 print:px-0 material-symbols-rounded text-crewfarePurple py-1"
        >
          lock
        </span>
      </div>
    </div>
    <select
      :disabled="is_blocked || is_locked"
      class="w-full h-[40px] print:appearance-none px-4 print:px-0 rounded"
      :class="
        has_changes
          ? 'bg-yellow-500 text-gray-900'
          : 'bg-gray-700 text-white print:text-black'
      "
      @change="save"
      v-model="value"
    >
      <option>Select</option>
      <option>Courtesy Block</option>
      <option>70% Attrition</option>
      <option>80% Attrition</option>
      <option>90% Attrition</option>
      <option>100% Attrition</option>
      <option>Other</option>
    </select>
    <small v-if="has_changes && !is_blocked" class="text-yellow-500">
      {{ changes_by }}
    </small>
  </div>
</template>

<script>
import { noteAdded, noteDone } from "@/utils/messages.jsx";
import {
  saveField,
  checkIsLocked,
  checkHasChanges,
  getChangesBy,
} from "@/utils/rfp.jsx";
import ButtonNotesDialog from "@/components/default/ButtonNotesDialog.vue";

export default {
  props: ["rfp", "account", "is_blocked"],
  components: {
    ButtonNotesDialog,
  },
  data() {
    return {
      value: "",
      is_locked: false,
      changes_by: null,
      has_changes: null,
      field: "Attrition",
    };
  },
  computed: {
    checked() {
      return this.value === this.rfp.attrition;
    },
    rfpSession() {
      return this.$store.state.rfpSession;
    },
    isNSO() {
      return this.account.type.toLowerCase() === "nso";
    },
  },
  watch: {
    rfpSession() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.value = this.rfpSession.field.attrition || this.rfp.attrition;
      if (this.account) {
        const account_type = this.account.type.toLowerCase();
        this.has_changes = checkHasChanges(this.rfp, this.field, account_type);
        this.is_locked = checkIsLocked(this.rfp, "attrition");
        if (this.has_changes) {
          this.changes_by = getChangesBy(this.has_changes, this.rfp);
        }
      }
    },
    async note_done() {
      noteDone(this.field, this.rfp);
      this.$emit("refreshData");
    },
    async note_added() {
      noteAdded(this.field, this.rfp);
      this.$emit("refreshData");
    },
    async save() {
      if (this.is_blocked) return;
      await saveField(
        this.rfp,
        {
          label: this.field,
          before: this.rfp.attrition,
          after: this.value || "",
        },
        {
          attrition: this.value || "",
        },
        "attrition"
      );
      this.$emit("refreshData");
    },
  },
};
</script>
