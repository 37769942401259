<template>
  <div>
    <div class="divide-y divide-gray-700">
      <Hotel
        v-for="item in hotelSlice"
        :key="item.id"
        :hotel="item"
        :selectedList="selected"
        :selectedSet="selectedSet"
        @updateSelected="updateSelected"
      />
    </div>
    <Pagination
      :actual="actual"
      :max="hotelAmount"
      :total="hotelCount"
      @togglePage="togglePage"
    />
  </div>
</template>

<script>
import { Hotel } from "./hotel";
import Pagination from "@/components/default/Pagination.vue";

export default {
  props: ["sliceList", "selected", "list", "actual", "selectedNames"],
  components: {
    Hotel,
    Pagination,
  },
  computed: {
    selectedSet(){
      const idSet = new Set(this.selected?.map((selected)=>selected.id )||[])
      return idSet
    },
    hotelSlice() {
      const sliceStart = this.actual * this.hotelAmount;
      return this.sliceList.slice(sliceStart, sliceStart + this.hotelAmount);
    },
    hotelCount() {
      return this.sliceList.length;
    },
  },
  data() {
    return {
      hotelAmount: 15,
    };
  },
  methods: {
    togglePage(page) {
      this.$emit("togglePage", page);
    },
    updateSelected(data) {
      this.$emit("updateSelected", data);
    },
  },
};
</script>
