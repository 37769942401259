import List from './List.vue';
import Dashboard from '@/layouts/Dashboard.vue';
import Chat from './Chat.vue';
import Files from './Files.vue';

export const messages = [
  {
    path: '/messages',
    name: 'messages',
    meta: {
      view: 'Messages',
    },
    component: Dashboard,
    children: [
      {
        path: '',
        name: 'messagesList',
        component: List,
        children: [
          {
            path: ':id',
            name: 'messagesChat',
            component: Chat,
          },
        ],
      },
      {
        path: ':id/files',
        name: 'messagesFiles',
        component: Files,
      },
    ],
  },
];
