<template>
  <div v-if="account" class="relative bg-[#343842] py-4 px-4 z-[60]">
    <div class="flex justify-end gap-4">
      <Notifications />
      <Account />
    </div>
  </div>
</template>

<script>
import Notifications from "@/components/default/header/Notifications.vue";
import Account from "@/components/default/header/Account.vue";

export default {
  components: {
    Notifications,
    Account,
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
};
</script>
