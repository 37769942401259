<template>
  <div
    class="border-y flex text-white text-left flex-col divide-y overflow-auto flex-1"
  >
    <button
      @click="crewfareMessage"
      class="hover:bg-gray-600 w-full py-3 text-left px-4"
    >
      Talk to Crewfare
    </button>
    <button
      class="hover:bg-gray-600 w-full py-3 px-4 text-left"
      v-if="chain"
      @click="chainMessage"
    >
      Talk to {{ chain.data().name }} hotels
    </button>
    <div>
      <input
        type="search"
        class="w-full bg-gray-700 px-4"
        v-model="search"
        @input="doSearch"
        placeholder="Search Hotels"
      />
    </div>
    <button
      class="hover:bg-gray-600 w-full py-3 text-left px-4"
      v-for="(hotel, index) in hotelsFiltered"
      :key="index"
      @click="hotelMessage(hotel)"
    >
      {{ hotel.data().name }}
    </button>
  </div>
</template>

<script>
import { getDocs, query, collection, where } from "firebase/firestore";
import { firestore } from "@/utils/firebase";
import {
  sendMessageCrewfare,
  sendMessageChain,
  sendMessageNSO,
} from "@/utils/messages.jsx";

export default {
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  data() {
    return {
      hotels: [],
      search: "",
      hotelsFiltered: [],
      chain: null,
    };
  },
  methods: {
    doSearch() {
      if (this.search.length > 0) {
        this.hotelsFiltered = [...this.hotelsFiltered].filter(
          (hotel) =>
            hotel
              .data()
              .name.toLowerCase()
              .indexOf(this.search.toLowerCase()) >= 0
        );
      } else {
        this.hotelsFiltered = this.hotels;
      }
    },
    chainMessage() {
      sendMessageChain(this.account, this.chain.data().name);
      this.$emit("toggleMessage");
    },
    crewfareMessage() {
      sendMessageCrewfare(this.account);
      this.$emit("toggleMessage");
    },
    hotelMessage(hotel) {
      sendMessageNSO(this.account, this.chain.data().name, hotel);
      this.$emit("toggleMessage");
    },
  },
  async mounted() {
    let qChain = query(
      collection(firestore, "chains"),
      where("manager_ids", "array-contains-any", [this.account.id])
    );

    const chains = await getDocs(qChain);
    this.chain = chains.docs[0];
    let qHotels = query(
      collection(firestore, "hotels"),
      where("chain", "==", this.chain.data().name)
    );
    const hotels = await getDocs(qHotels);
    this.hotels = hotels.docs;
    this.hotelsFiltered = this.hotels;
  },
};
</script>
