<template>
  <div
    v-if="closeDialog"
    class="fixed bottom-0 right-0 z-[200] rounded-lg left-0 top-0 bg-gray-300 bg-opacity-40 flex items-center justify-center"
  >
    <div
      class="p-8 bg-gray-900 rounded flex flex-col gap-4 items-center w-full max-w-[900px] relative text-white"
    >
      <div class="flex w-full flex-col gap-4 relative">
        <span
          class="absolute top-0 right-4 material-symbols-rounded text-crewfareGreen"
          :class="isPartner ? 'material-symbols-filled' : ''"
        >
          star
        </span>
        <p class="text-2xl">
          {{ hotel.data().name }}
        </p>
        <p v-if="hotel.data().chain" class="text-base">
          Chain: {{ hotel.data().chain }}
        </p>
        <p v-if="hotel.data().rooms" class="text-base">
          Rooms: {{ hotel.data().rooms }}
        </p>
        <p v-if="addr" class="text-base border-t border-white pt-4">
          Address:<br />{{ addr }}
        </p>
        <div
          v-if="hotel.data().manager && hotel.data().manager.length > 0"
          class="flex flex-col gap-2 border-t border-white pt-4"
        >
          <strong>Manager</strong>
          <p v-for="(manager, index) in hotel.data().manager" :key="index">
            {{ manager.name }} &lt;{{ manager.email }}&gt;
          </p>
        </div>
        <div
          v-if="contacts.length > 0"
          class="flex flex-col gap-2 border-t border-white pt-4"
        >
          <strong>Contacts</strong>
          <p v-for="(contact, index) in contacts" :key="index">
            {{ contact.data().name }} - {{ contact.data().role }} &lt;{{
              contact.data().email
            }}&gt;
          </p>
        </div>
      </div>
      <div class="flex justify-end w-full">
        <button class="cancel-button" @click="closeDialog">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import { firestore } from "@/utils/firebase";
import { getDocs, query, collection, where } from "firebase/firestore";

export default {
  props: ["hotel"],
  data() {
    return {
      contacts: [],
    };
  },
  computed: {
    isPartner() {
      return this.hotel.data().isPartner;
    },
    addr() {
      if (this.hotel.data().addr) {
        return `${this.hotel.data().addr}, ${this.hotel.data().county}, ${
          this.hotel.data().city
        }, ${this.hotel.data().state} ${this.hotel.data().zipCode}`;
      }
    },
  },
  async mounted() {
    if (this.hotel) {
      const qContacts = query(
        collection(firestore, "contacts"),
        where("hotel_id", "==", this.hotel.id)
      );
      const contacts = await getDocs(qContacts);
      this.contacts = contacts.docs;
    }
  },
  methods: {
    closeDialog() {
      this.$emit("closeView");
    },
  },
};
</script>
