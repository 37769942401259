<template>
  <div>
    <GMapMap
      :center="center"
      :zoom="10"
      map-type-id="terrain"
      style="width: 100%; height: 600px"
    >
      <GMapCircle :radius="5000" :center="center" :options="circleOptions" />
      <GMapMarker
        :clickable="true"
        :position="center"
        :icon="{
          url: '/push_pin_FILL0_wght400_GRAD0_opsz24.png',
          scaledSize: { width: 30, height: 30 },
        }"
      />
      <GMapCluster :imagePath="imagePath" :zoomOnClick="true">
        <GMapMarker
          v-for="(marker, index) in markers"
          :key="marker.name"
          :clickable="true"
          :icon="marker.icon"
          :position="{
            lat: marker.mapMarker?.latLong.latitude || 0,
            lng: marker.mapMarker?.latLong.longitude || 0,
          }"
          @click="openedMarker = marker.name"
        >
          <GMapInfoWindow
            @closeclick="openedMarker = null"
            :opened="openedMarker === marker.name"
          >
            <MapMarker
              :hotel="marker"
              :selected="selected"
              @updateSelected="updateSelected"
            />
          </GMapInfoWindow>
        </GMapMarker>
      </GMapCluster>
    </GMapMap>
  </div>
</template>

<script>
import MapMarker from "@/components/search/hotel/MapMarker.vue";

export default {
  props: ["sliceList", "selected", "list", "actual"],
  components: {
    MapMarker,
  },
  data() {
    return {
      openedMarker: null,
      circleOptions: {
        strokeColor: "#FF0000",
        strokeOpacity: 0,
        strokeWeight: 1,
        fillColor: "#000",
        fillOpacity: 0.08,
      },

      imagePath:
        "https://raw.githubusercontent.com/googlemaps/v3-utility-library/37c2a570c318122df57b83140f5f54665b9359e5/packages/markerclustererplus/images/m",
    };
  },
  computed: {
    markers() {
      return this.list.map((item) => {
        let color = "red";
        const isSelected =
          this.selected?.findIndex(
            (selected) => selected.data().name === item.name
          ) >= 0;
        if (item.hasContact) color = "green";
        if (isSelected) color = "blue";
        const icon = {
          path: "M-1.547 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
          fillColor: color,
          fillOpacity: 1,
          strokeWeight: 0,
          rotation: 0,
          scale: 2,
          anchor: new google.maps.Point(0, 20),
        };
        return {
          icon,
          ...item,
        };
      });
    },
    search() {
      return this.$store.state.search;
    },
    center() {
      return { lat: this.search.lat, lng: this.search.lng };
    },
  },
  mounted() {},
  methods: {
    updateSelected(data) {
      this.$emit("updateSelected", data);
    },
    togglePage() {
      this.$emit("togglePage");
    },
  },
};
</script>
