<template>
  <div
    class="bg-gray-900 overflow-hidden w-full py-4 flex justify-center gap-4 stage-height items-center text-white relative"
  >
    <div class="z-10 relative">
      <img src="/logo.svg" class="h-[50px] mb-12 mx-auto" />
      <div class="text-center text-xl">You have signed the contract!</div>
      <div class="text-center">
        We will get in touch as soon as we sign the contract!
      </div>
    </div>
    <ConffetiAnimation />
  </div>
</template>

<script>
import ConffetiAnimation from "@/components/default/ConffetiAnimation.vue";

export default {
  components: {
    ConffetiAnimation,
  },
  data() {
    return {
      apiUrl: import.meta.env.VITE_API_URL,
      rfp: null,
    };
  },
  computed: {
    isCompleted() {
      return this.rfp.data().status.toLowerCase() === "signed by both sides";
    },
  },
};
</script>
