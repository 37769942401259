<template>
  <tr v-if="rfp" class="px-4 items-center text-white">
    <td class="py-2 text-sm flex flex-col gap-2 items-start">
      {{ rfp.name || "New RFP" }}
      <span
        class="px-2 leading-6 rounded-full text-xs uppercase"
        :class="
          rfp.status === 'Sent'
            ? 'bg-crewfareGreen text-gray-900'
            : 'bg-gray-700'
        "
      >
        {{ rfp.status }}
      </span>
    </td>
    <td class="text-sm capitalize">{{ formatedDates }}</td>
    <td class="text-sm">{{ rfp.hotels?.length || 0 }}</td>
    <td class="text-sm capitalize">{{ formatedCreatedAt }}</td>
    <td class="text-sm capitalize">{{ formatedUpdatedAt }}</td>
    <td>
      <div class="flex gap-4 justify-end items-center">
        <button
          @click="assignToRFP"
          class="text-crewfareGreen text-[18px] flex items-center px-4 border rounded py-1 border-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900"
        >
          <span v-if="loading" class="material-symbols-rounded animate-spin">
            autorenew
          </span>
          <span v-else> Assign To RFP </span>
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import router from "@/router";
import moment from "moment";
import { addToRfp } from "@/utils/rfp.jsx";
import { getMomentFromFirebaseDate } from "@/utils/dateUtils";

export default {
  props: ["rfp"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    formatedDates() {
      const startDate = this.rfp.startDate;
      const endDate = this.rfp.endDate;
      return `${getMomentFromFirebaseDate(startDate).format(
        "ddd MMM. DD, YYYY"
      )} to ${getMomentFromFirebaseDate(endDate).format("ddd MMM. DD, YYYY")}`;
    },
    formatedCreatedAt() {
      if (!this.rfp.created_at) return "";
      const created_at = getMomentFromFirebaseDate(this.rfp.created_at);
      return created_at.fromNow();
    },
    formatedUpdatedAt() {
      if (!this.rfp.updated_at) return "";
      const updated_at = getMomentFromFirebaseDate(this.rfp.updated_at);
      return updated_at.fromNow();
    },
    account() {
      return this.$store.state.account;
    },
  },
  methods: {
    async assignToRFP() {
      this.loading = true;
      await addToRfp({
        rfp: this.rfp,
        hotels: this.$store.state.selectedHotels,
      });
      this.loading = false;
      this.$store.commit("setAssignRFP", false);
      router.push({ name: "rfpsHotels", params: { id: this.rfp.id } });
    },
  },
};
</script>
