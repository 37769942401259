<template>
  <div
    class="flex flex-col text-white gap-4 items-center justify-center min-h-[100vh] w-full"
  >
    <p class="text-3xl">Welcome to Crewfare Launchpad!</p>
    <p>Please wait, we have something great for you!</p>
    <div class="flex">
      <span
        class="material-symbols-rounded animate-spin text-crewfareGreen flex items-center"
      >
        autorenew
      </span>
    </div>
  </div>
</template>
