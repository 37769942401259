<template>
  <tr class="animate-pulse px-4 py-2 text-white">
    <td class="p-4 text-sm min-w-[600px]">
      <span class="flex text-sm w-[150px] h-[18px] bg-gray-500 rounded"></span>
    </td>
    <td class="p-4 text-sm">
      <span class="flex text-sm w-[160px] h-[18px] bg-gray-500 rounded"></span>
    </td>
    <td class="p-4 text-sm">
      <span class="flex text-sm w-[70px] h-[18px] bg-gray-500 rounded"></span>
    </td>
    <td class="p-4 flex gap-4 justify-end items-center">
      <span class="w-[52px] h-[18px] bg-gray-500 rounded rounded"> </span>
      <span class="w-[52px] h-[18px] bg-gray-500 rounded rounded"> </span>
      <span class="w-[52px] h-[18px] bg-gray-500 rounded rounded"> </span>
    </td>
  </tr>
</template>
