<template>
  <div class="text-white flex gap-2 items-center">
    <span class="text-crewfareGreen">
      <font-awesome-icon :icon="icon" />
    </span>
    <span v-for="item in items" :key="item" class="flex items-center">
      {{ item }}
      <span v-if="item !== items[items.length - 1]" class="text-[10px] ml-2">
        <font-awesome-icon icon="chevron-right" />
      </span>
    </span>
  </div>
</template>

<script>
export default {
  props: ["icon", "items"],
};
</script>
