<template>
  <div
    class="grid grid-cols-5 px-4 flex justify-between py-2 items-center text-white"
  >
    <p class="text-sm">
      {{ contact.data().name }}
    </p>
    <p class="text-sm">{{ contact.data().role }}</p>
    <p class="text-sm text-ellipsis overflow-hidden">
      {{ contact.data().email }}
    </p>
    <p class="text-sm">{{ contact.data().phone }}</p>
    <div>
      <div class="flex gap-4 justify-end items-center">
        <router-link
          v-tippy="'Edit contact'"
          :to="{
            name: 'rfpsHotelContactEdit',
            params: {
              contact_id: contact.id,
              hotel_id: $route.params.hotel_id,
              id: $route.params.id,
            },
          }"
          class="text-crewfareGreen text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1 border-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900"
        >
          edit
        </router-link>
        <button
          v-tippy="'Remove contact'"
          @click="removeContact"
          class="text-red-400 text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1 border-red-400 hover:bg-red-400 hover:text-white"
        >
          delete
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { deleteContact } from "@/utils/hotel.jsx";

export default {
  props: ["contact", "hotel_id"],
  data() {
    return {
      selected: false,
      contacts: [],
      loading: false,
    };
  },
  computed: {
    contactsCount() {
      return this.contacts.length;
    },
  },
  methods: {
    async removeContact() {
      if (!confirm("Delete this contact?")) {
        return;
      }
      await deleteContact(this.hotel_id, this.contact);
      this.$emit("getData");
    },
  },
};
</script>
