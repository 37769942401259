const contractExecutedInternalEmailContent = `<tr>
  <td style="width: 100%;border-radius: 8px;background: rgba(34, 197, 94, 0.04);border: 1px dashed rgba(34, 197, 94, 1);padding: 48px 24px;text-align:center; ">
    <table style="margin: 0 auto 40px;">
      <tr>
        <td valign="top">
          <table style="margin: 0 auto;">
            <tr>
              <td valign="center" align="center" style="width: 60px;height:60px;background: rgba(34, 197, 94, 0.08);border-radius:40px;">
                <img src="https://dev-api-launchpad.crewfare.com/email/email-check.png" />
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
    <div style="margin-top:24px; font: bold 24px Poppins, Helvetica, Arial;color: #292929;">Contract Executed!</div>
    <div style="margin-top: 24px;font: bold 14px Poppins, Helvetica, Arial;color: #292929;">
    The contract for ##name## just got signed by both sides.</div>
  </td>
</tr>`;

const contractExecutedInternalSubject =
  'Crewfare’s Launchpad - Executed Attendee Agreement - ##event_name## - ##hotel_name##';

export { contractExecutedInternalEmailContent, contractExecutedInternalSubject };
