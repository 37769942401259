import { proposalsApi } from './apis/proposalsApi';
import { rfpsApi } from './apis/rfpsApi';

export const getProposal = async (proposalId: string) => {
  return proposalsApi.get(proposalId).then(_ => _.data);
};

export const listProposal = async (options: any = { per_page: 1000, page: 0 }, signal: any) => {
  return proposalsApi.list(options, signal).then(_ => _.data);
};

export const listProposalByRfpId = async (rfpId: string) => {
  return rfpsApi.listProposals(rfpId).then(_ => _.data);
};
