<template>
  <FwbTimelineItem>
    <FwbTimelinePoint> </FwbTimelinePoint>
    <FwbTimelineContent>
      <FwbTimelineTime> {{ formatedCreatedAt }} </FwbTimelineTime>
      <FwbTimelineTitle>
        <div class="flex gap-4 items-center">
          New contract added by {{ type }}
        </div>
      </FwbTimelineTitle>
      <FwbTimelineBody>
        <div class="flex flex-col gap-2">
          <p>
            <a :href="item.url" download target="_blank">Download contract</a>
          </p>
          <small v-if="item.account" class="text-sm">
            by {{ item.account.name }}
          </small>
        </div>
      </FwbTimelineBody>
    </FwbTimelineContent>
  </FwbTimelineItem>
</template>

<script>
import {
  FwbTimelineItem,
  FwbTimelinePoint,
  FwbTimelineTime,
  FwbTimelineContent,
  FwbTimelineTitle,
  FwbTimelineBody,
} from "flowbite-vue";
import moment from "moment";

export default {
  props: ["item"],
  components: {
    FwbTimelineItem,
    FwbTimelinePoint,
    FwbTimelineTime,
    FwbTimelineContent,
    FwbTimelineTitle,
    FwbTimelineBody,
  },
  computed: {
    formatedCreatedAt() {
      if (!this.item.created_at) return "";
      const created_at = moment(this.item.created_at.toDate());
      return created_at.format("ddd MMM. DD, YYYY - hh:mm A");
    },
    type() {
      if (!this.item.account) return;
      if (["admin", "internal"].includes(this.item.account.type.toLowerCase()))
        return "Crewfare";
      return "Hotel";
    },
  },
};
</script>
