<template>
  <div v-if="rfp" class="overlay">
    <div
      class="bg-white rounded flex flex-col gap-4 items-center w-full max-w-[450px] relative text-gray-900"
    >
      <div class="flex w-full flex-col">
        <div
          class="flex w-full justify-between border-b rounded-t-lg px-4 py-2 bg-gray-50"
        >
          <p class="text-xl">Forward this RFP</p>
          <span
            @click="close"
            class="cursor-pointer text-gray-400 hover:text-gray-900 material-symbols-rounded"
          >
            close
          </span>
        </div>
        <div class="flex flex-col gap-2 px-4 py-2">
          <p>
            Please add the name and email of the person is responsable for the
            RFP at your property, we will send an email to them.
          </p>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-gray-500">Name</label>
            <input
              type="text"
              v-model="name"
              class="w-full bg-gray-50 border rounded px-4"
            />
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-gray-500">Email</label>
            <input
              type="text"
              v-model="email"
              class="w-full bg-gray-50 border rounded px-4"
            />
          </div>
          <div class="flex justify-between items-center">
            <button class="text-gray-600" @click="close">Cancel</button>
            <button :disabled="!valid || loading" class="green-" @click="save">
              <span
                v-if="loading"
                class="material-symbols-rounded animate-spin text-[12px]"
              >
                autorenew
              </span>
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { forwardRFP } from "@/utils/rfp.jsx";

export default {
  props: ["rfp"],
  data() {
    return {
      message: false,
      name: "",
      email: "",
      loading: false,
    };
  },
  computed: {
    valid() {
      return this.email.length > 0 && this.name.length > 0;
    },
  },
  methods: {
    async save() {
      if (this.loading) return;
      this.loading = true;
      await forwardRFP(this.rfp, this.email, this.name);
      this.email = "";
      this.name = "";
      this.$store.commit("setToast", {
        content: `<p>You just sent 1 RFP</p>`,
      });
      this.$emit("closeDialog");
      this.loading = false;
    },
    close() {
      this.$emit("closeDialog");
    },
  },
};
</script>
