<template>
  <div class="flex flex-col gap-2">
    <div>
      <input
        type="search"
        class="w-full bg-gray-700 px-4 py-2 rounded-full"
        v-model="search"
        @input="doSearch"
        placeholder="Search"
      />
    </div>
    <button
      class="hover:bg-gray-800 bg-gray-900 rounded-lg w-full py-3 text-left px-4 flex flex-col"
      v-for="(account, index) in accountsFiltered"
      :key="index"
      @click="accountMessage(account)"
    >
      <span>{{ account.name }} - {{ account.type }}</span>
      <span class="text-xs">&lt;{{ account.email }}&gt;</span>
    </button>
    <button
      class="hover:bg-gray-800 bg-gray-900 rounded-lg w-full py-3 text-left px-4"
      v-for="(hotel, index) in hotelsFiltered"
      :key="index"
      @click="hotelMessage(hotel)"
    >
      {{ hotel.data().name }}
    </button>
  </div>
</template>

<script>
import { getDocs, query, collection, where } from "firebase/firestore";
import { firestore } from "@/utils/firebase";
import { sendHotelMessage, sendDirectMessage } from "@/utils/messages.jsx";

export default {
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  data() {
    return {
      search: "",
      hotels: [],
      accounts: [],
      accountsFiltered: [],
      hotelsFiltered: [],
    };
  },
  methods: {
    accountMessage(account) {
      sendDirectMessage(
        [account.id, this.account.id],
        [account, this.account]
      );
      this.$emit("toggleMessage");
    },
    hotelMessage(hotel) {
      sendHotelMessage(hotel, this.account);
      this.$emit("toggleMessage");
    },
    doSearch() {
      if (this.search.length > 0) {
        this.accountsFiltered = [...this.accounts]
          .filter(
            (account) =>
              account
                .data()
                .name.toLowerCase()
                .indexOf(this.search.toLowerCase()) >= 0
          )
          .slice(0, 5);
        this.hotelsFiltered = [...this.hotels]
          .filter(
            (hotel) =>
              hotel
                .data()
                .name.toLowerCase()
                .indexOf(this.search.toLowerCase()) >= 0
          )
          .slice(0, 5);
      } else {
        this.accountsFiltered = [];
        this.hotelsFiltered = [];
      }
    },
  },
  async mounted() {
    let qAccounts = query(
      collection(firestore, "accounts"),
      where("type", "in", ["Admin", "Internal", "NSO"])
    );
    const accounts = await getDocs(qAccounts);
    this.accounts = accounts.docs.map((doc) => { return {...doc.data(), id: doc.id}});
    let qHotels = query(collection(firestore, "hotels"));
    const hotels = await getDocs(qHotels);
    this.hotels = hotels.docs;
  },
};
</script>
