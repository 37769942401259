<template>
  <div
    id="bg-login"
    class="bg-black min-h-[100vh] bg-center bg-no-repeat bg-cover flex items-center"
  >
    <div class="max-w-[548px] w-full mx-auto px-6 pt-16 pb-6">
      <div class="flex flex-col gap-12">
        <img src="/logo.svg" class="h-[60px]" />
        <div class="rounded-3xl bg-[#23262F]">
          <div class="max-w-[480px] mx-auto p-6 md:p-11 flex flex-col gap-4">
            <p class="text-white font-semibold text-center text-2xl">
              Forgot Password
            </p>
            <p class="text-neutral-400 font-normal text-center text-sm">No worries, we’ll send you reset instructions</p>
            <p v-if="done" class="text-[#22C55E] text-center">
              {{ message }}
            </p>
            <form v-else @submit.prevent="doForgot" class="flex flex-col gap-4">
              <div class="flex flex-col gap-3">
                <label for="emailInput" class="text-white text-base">
                  Email
                </label>
                <input
                  id="emailInput"
                    class="px-3 w-full rounded-lg leading-12 bg-[#2F2F31] border-1 border-[#2F2F31] text-white"
                    :class="error && 'border-[#FF6A55]'"
                  type="text"
                  required
                  v-model="email"
                  placeholder="Enter email"
                />
              </div>
              <p
                v-if="error"
                v-html="message"
                class="text-[#FF6A55] text-left text-sm"
              ></p>
              <button
                :disabled="!valid"
                class="bg-[#4323FF] rounded-lg text-center text-white leading-10"
              >
                {{ loading ? "Sending..." : "Send" }}
              </button>
            </form>
            <div class="flex justify-center">
              <a href="/" class="text-neutral-400 text-sm">
                <font-awesome-icon
                  class="mr-2"
                  :icon="['fas', 'arrow-left']" />
                Back to log in
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sendPasswordResetEmail, onAuthStateChanged } from "firebase/auth";
import { auth } from "@/utils/firebase";

export default {
  name: "Login",
  data: () => {
    return {
      email: "",
      done: false,
      error: false,
      message: "",
      search: "",
      loading: false,
    };
  },
  computed: {
    valid() {
      return this.email.length > 0;
    },
  },
  created() {
    document.title = `Crewfare Launchpad`;
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.$store.commit("setUser", user);
      }
    });
  },
  methods: {
    doForgot() {
      this.loading = true;
      this.error = false;
      this.message = "";
      sendPasswordResetEmail(auth, this.email)
        .then(() => {
          this.done = true;
          this.loading = false;
          this.message =
            "You should have received an email to set a new password, please check your spam.";
        })
        .catch(() => {
          this.loading = false;
          this.message =
            "We couldn’t find an account matching the email you entered. Please try again.";
          this.error = true;
        });
    },
  },
};
</script>
