<template>
  <div>
    <div class="fixed top-0 right-0 left-0 bottom-0" @click="closeDialog"></div>
    <div class="overlay">
      <div class="px-4 max-w-[960px] w-full">
        <div
          class="bg-gray-900 shadow-md rounded-lg flex flex-col gap-4 items-center p-4 relative text-gray-200 modal-print"
        >
          <div
            class="print:hidden flex justify-between items-center gap-4 w-full"
          >
            <p class="text-2xl">Status History</p>
            <div class="flex gap-4 items-center">
              <input
                type="search"
                v-model="search"
                class="bg-gray-700 rounded px-4"
                placeholder="Search..."
                @keyup="toggleSearch"
              />
              <button
                @click="download"
                class="green-button-small flex gap-2 items-center"
              >
                <span
                  v-if="loading"
                  class="material-symbols-rounded animate-spin text-[12px]"
                >
                  autorenew
                </span>
                Download
              </button>
              <span
                @click="closeDialog"
                class="cursor-pointer top-2 right-2 text-red-200 hover:text-red-400 material-symbols-rounded"
              >
                close
              </span>
            </div>
          </div>
          <div
            class="print:text-black w-full max-h-[70vh] overflow-auto pl-4"
            ref="printTimeLine"
          >
            <FwbTimeline>
              <StatusHistoryItem
                v-for="(item, index) in statusHistorySorted"
                :key="index"
                :item="item"
              />
            </FwbTimeline>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FwbTimeline } from "flowbite-vue";
import StatusHistoryItem from "./StatusHistoryItem.vue";

export default {
  props: ["status_history", "rfp"],
  components: {
    FwbTimeline,
    StatusHistoryItem,
  },
  data() {
    return {
      search: "",
      loading: false,
    };
  },
  computed: {
    statusHistory() {
      return this.status_history.reverse();
    },
    statusHistorySorted() {
      let status_history = this.statusHistory;
      if (this.search.length > 0)
        status_history = status_history.filter(
          (item) =>
            item.content.toLowerCase().indexOf(this.search.toLowerCase()) >= 0
        );
      return status_history;
    },
  },
  methods: {
    async download() {
      this.loading = true;
      const dataContract = {
        name: `${this.rfp}${Math.floor(Math.random() * 100000)}`,
        content: this.$refs.printTimeLine.innerHTML,
      };
      const resp = await fetch(`${import.meta.env.VITE_API_URL}/pdf`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContract),
      }).then((response) => response.json());
      const link = document.createElement("a");
      link.setAttribute("href", `${import.meta.env.VITE_API_URL}${resp.url}`);
      link.setAttribute("target", "_blank");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.loading = false;
    },
    closeDialog() {
      this.$emit("hideStatusHistoryDialog");
    },
  },
};
</script>
