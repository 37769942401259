<template>
  <div v-if="account" class="bg-gray-800 w-full flex flex-col stage-height">
    <div class="p-4 flex justify-between bg-gray-900 items-center">
      <div class="flex gap-4 items-center">
        <router-link
          :to="{ name: 'hotelsChainList' }"
          class="material-symbols-rounded text-white hover:text-crewfareGreen"
        >
          chevron_left
        </router-link>
        <h1 class="text-xl text-white">Hotels - Chain - Edit</h1>
      </div>
    </div>
    <div class="flex flex-col gap-2 rounded pb-4 relative">
      <div class="flex flex-col gap-1 w-full p-4">
        <label class="text-white font-bold">Name</label>
        <input
          type="text"
          v-model="name"
          disabled
          class="w-full bg-gray-700 rounded px-4 text-white"
        />
      </div>
      <div>
        <div
          class="flex p-4 items-center bg-gray-800 justify-between sticky top-0"
        >
          <div class="flex gap-2 items-center text-crewfareGreen">
            <strong class="rounded-full bg-black px-3 py-1">{{
              hotelsFound
            }}</strong>
            Hotels
          </div>
          <div class="flex items-center gap-4 text-white">
            <input
              type="search"
              v-model="search"
              class="bg-gray-700 rounded px-4"
              placeholder="Search..."
            />
          </div>
        </div>
        <div v-if="!loading">
          <table class="gap-4 mt-4 px-4 divide-y text-white w-full">
            <thead>
              <tr>
                <th class="flex py-2 items-center px-4 gap-4 text-left">
                  Name
                </th>
                <th class="px-4 py-1 text-left">County</th>
                <th class="px-4 py-1 text-left">City</th>
                <th class="px-4 py-1 text-left">State</th>
                <th></th>
              </tr>
            </thead>
            <tbody class="divide-y">
              <tr
                v-for="hotel in sliceHotels"
                :key="hotel.id"
                class="py-2 text-white"
              >
                <td class="flex py-2 items-center px-4 gap-4">
                  {{ hotel.data().name }}
                </td>
                <td class="px-4">{{ hotel.data().county }}</td>
                <td class="px-4">{{ hotel.data().city }}</td>
                <td class="px-4">{{ hotel.data().state }}</td>
                <td>
                  <button @click="viewHotel(hotel)" class="green-button-small">
                    View
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <Pagination
            :max="16"
            :actual="actual"
            :total="filteredHotels.length"
            @togglePage="togglePage"
          />
        </div>
      </div>
      <div class="flex flex-col gap-1 w-full px-4 text-white">
        <label class="text-white font-bold">Manager</label>
        <input
          type="text"
          placeholder="Search for NSO user accounts"
          v-model="searchAccount"
          class="w-full bg-gray-700 rounded px-4"
        />
        <div
          v-if="accountsFiltered.length > 0"
          class="flex flex-col gap-2 mt-2 pb-4 mb-4 border-b"
        >
          <span
            >{{ accountsFiltered.length }} Account{{
              accountsFiltered.length > 1 ? "s" : ""
            }}
            found:
          </span>
          <div
            v-for="account in accountsFiltered"
            :key="account.id"
            @click="addManager(account)"
            class="px-4 py-2 flex border border-gray-50 gap-4 items-center rounded hover:bg-gray-600 cursor-pointer"
          >
            <span
              class="rounded-full w-[34px] h-[34px] bg-crewfareGreen text-gray-900 flex justify-center items-center"
            >
              {{ getInitials(account.data().name) }}
            </span>
            <div class="flex flex-col gap-1">
              <p>{{ account.data().name }}</p>
              <p>{{ account.data().email }}</p>
            </div>
          </div>
        </div>
        <div
          v-if="searchAccount.length > 1 && accountsFiltered.length < 1"
          class="flex flex-col gap-2 mt-2 pb-4 mb-4 border-b"
        >
          <span>
            No accounts found, create a new on
            <router-link :to="{ name: 'usersAdd' }" class="underline"
              >clicking here</router-link
            >.
          </span>
        </div>

        <div
          v-if="manager && manager.length > 0"
          class="flex flex-col gap-2 mt-2"
        >
          <div
            v-for="(account, index) in manager"
            :key="account.id"
            class="px-4 py-2 flex border border-gray-50 gap-4 items-center rounded hover:bg-gray-600 cursor-pointer"
          >
            <span
              class="rounded-full w-[34px] h-[34px] bg-crewfareGreen text-gray-900 flex justify-center items-center"
            >
              {{ getInitials(account.name) }}
            </span>
            <div class="flex flex-col gap-1 flex-grow">
              <p>{{ account.name }}</p>
              <p>{{ account.email }}</p>
            </div>
            <div>
              <span
                @click="removeManager(index)"
                class="cursor-pointer text-red-400 material-symbols-rounded"
              >
                close
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex gap-4 p-4 justify-between">
      <router-link :to="{ name: 'hotelsChainList' }" class="cancel-button">
        Cancel
      </router-link>
      <button @click="save" :disabled="!valid || loading" class="green-button">
        <span
          v-if="loading"
          class="material-symbols-rounded animate-spin flex items-center"
        >
          autorenew
        </span>
        <span v-else> Save </span>
      </button>
    </div>
    <ViewDialog
      v-if="toggleViewHotel"
      :hotel="selectedHotel"
      @closeView="toggleViewHotel = false"
    />
  </div>
</template>

<script>
import router from "@/router";
import { firestore } from "@/utils/firebase";
import {
  getDocs,
  getDoc,
  query,
  collection,
  doc,
  where,
  orderBy,
  addDoc,
  or,
  setDoc,
  deleteDoc
} from "firebase/firestore";
import { ListItem, ListItemSkeleton } from "@/components/hotels";
import Pagination from "@/components/default/Pagination.vue";
import ViewDialog from "@/components/hotels/ViewDialog.vue";

export default {
  components: {
    ListItem,
    Pagination,
    ListItemSkeleton,
    ViewDialog,
  },
  computed: {
    selectedAll() {
      return this.filteredHotels.length === this.selectedHotels.length;
    },
    valid() {
      return true;
    },
    account() {
      return this.$store.state.account;
    },
    availableAccounts() {
      const emails = this.manager.map((manager) => manager.email);
      return this.accounts.filter(
        (manager) =>
          !emails.includes(manager.data().email) &&
          !this.taken_managers_filtered.includes(manager.id)
      );
    },
    sliceHotels() {
      const sliceStart = this.actual * 15;
      return this.filteredHotels.slice(sliceStart, sliceStart + 15);
    },
    hotelsFound() {
      return this.filteredHotels.length;
    },
  },
  data() {
    return {
      toggleViewHotel: false,
      selectedHotel: null,
      chain: {},
      searchAccount: "",
      manager: [],
      search: "",
      actual: 0,
      range: [...Array(8).keys()],
      loading: false,
      loadingMore: false,
      hotels: [],
      city: "",
      state: "",
      country: "",
      name: "",
      filteredHotels: [],
      accountsFiltered: [],
      selectedHotels: [],
      linesToImport: null,
      linesProccessed: 0,
      report: {},
      groupHotel: {},
      accounts: [],
      chains: [],
      taken_managers_filtered: null,
    };
  },
  watch: {
    searchAccount() {
      if (this.searchAccount.length === 0) {
        this.accountsFiltered = [];
      } else {
        this.accountsFiltered = this.availableAccounts
          .filter(
            (account) =>
              account
                .data()
                .name.toLowerCase()
                .indexOf(this.searchAccount.toLowerCase()) >= 0 ||
              account
                .data()
                .email.toLowerCase()
                .indexOf(this.searchAccount.toLowerCase()) >= 0
          )
          .slice(0, 5);
      }
    },
    search() {
      if (this.search.length) {
        this.filteredHotels = this.hotels.filter(
          (hotel) =>
            hotel
              .data()
              .name.toLowerCase()
              .indexOf(this.search.toLowerCase()) >= 0
        );
      } else {
        this.filteredHotels = this.hotels;
      }
      this.actual = 0;
    },
  },
  async mounted() {
    const qChains = query(collection(firestore, "chains"));
    const chainDocs = await getDocs(qChains);
    this.chains = chainDocs.docs;
    const taken_managers_all = [];
    this.chains
      .filter((chain) => chain.data().manager_ids)
      .map((chain) => taken_managers_all.push(...chain.data().manager_ids));
    this.taken_managers_filtered = [...new Set(taken_managers_all)];
    const chainRef = doc(firestore, "chains", this.$route.params.chain_id);
    this.chain = await getDoc(chainRef);
    this.name = this.chain.data().name;
    this.manager = this.chain.data().manager || [];
    const duplicateChains = this.chains.filter(
      (chain) => chain.data().name === this.chain.data().name
    );
    const managersFromAllDuplicateChains = [];
    const managersFromAllDuplicateChainsIds = []
    duplicateChains.forEach((chain) =>
      {
        chain.data().manager?.forEach((manager) =>
        managersFromAllDuplicateChains.push(manager)
      )
    }
    );
    duplicateChains.forEach((chain) =>
      {
        chain.data().manager_ids?.forEach((manager) =>
        managersFromAllDuplicateChainsIds.push(manager)
      )
    }
    );
    //If duplicate entry is found delete the duplicate entries
    if(duplicateChains.length>1){
      duplicateChains.map((chain) => {
        if (chain.id !== this.chain.id) {
          console.log("Deleting duplicate chain", chain.id, "current id", this.chain.id)
          deleteDoc(doc(firestore, "chains", chain.id));
        }
      });
      this.manager = managersFromAllDuplicateChains;
      await this.save()
    }


    this.getData();
  },
  methods: {
    viewHotel(hotel) {
      this.selectedHotel = hotel;
      this.toggleViewHotel = true;
    },
    addManager(account) {
      const id = this.manager.findIndex((manager) => manager.id === account.id);
      if (id > 0) {
        return;
      } else {
        this.manager.push({ id: account.id, ...account.data() });
        this.searchAccount = "";
      }
    },
    removeManager(index) {
      this.manager.splice(index, 1);
    },
    getInitials(name) {
      const nameSplit = name.split(" ");
      const firstLetter = nameSplit[0];
      let lastLetter = "";
      if (nameSplit.length > 1) {
        const lastName = nameSplit.reverse()[0];
        lastLetter = lastName[0] || "";
      }
      return `${firstLetter[0]}${lastLetter}`;
    },
    togglePage(page) {
      this.actual = page;
    },
    async getData() {
      this.loading = true;
      const qHotels = query(
        collection(firestore, "hotels"),
        where("chain", "==", this.chain.data().name),
        orderBy("name")
      );
      const hotels = await getDocs(qHotels);
      const hotelsDocs = hotels.docs;
      this.hotels = this.filteredHotels = hotelsDocs;
      let qUsers = query(
        collection(firestore, "accounts"),
        or(where("type", "==", "nso"), where("type", "==", "lead manager"))
      );
      const accounts = await getDocs(qUsers);
      document.title = `Launchpad - Crewfare - Hotel - Chain - ${
        this.chain.data().name
      }`;
      this.accounts = accounts.docs;
      this.loading = false;
    },
    async save() {
      if (!this.valid) return;
      this.loading = true;
      await setDoc(doc(firestore, "chains", this.$route.params.chain_id), {
        ...this.chain.data(),
        manager: this.manager,
        manager_ids: this.manager.map((manager) => manager.id),
        updated_at: new Date(),
      });
      this.$store.commit("setToast", {
        content: `<p>Hotel Chain Updated</p>`,
      });
      router.push({ name: "hotelsChainList" });
      this.loading = false;
    },
  },
};
</script>
