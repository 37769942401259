<template>
  <div class="bg-gray-800">
    <form @submit.prevent="setSearch" class="flex gap-4 p-4 text-white pb-7">
      <!-- <div class="flex flex-col gap-1 w-full max-w-[140px]">
          <label class="text-sm font-bold">Search Provider </label>
          <select
            class="w-full bg-gray-700 h-[42px] appearance-none rounded"
            v-model="searchProvider"
            @change="changeProvider"
          >
            <option selected value="hotel.com">Hotel.com</option>
            <option value="amadeus">Amadeus</option>
          </select>
        </div> -->
      <div class="flex flex-col gap-1 flex-grow relative">
        <label class="text-sm font-bold">Event Address</label>

        <GMapAutocomplete
          ref="gmapAutocomplete"
          placeholder="Search Location"
          class="w-full bg-gray-700 rounded px-4 py-2 border border-gray-500"
          :class="loading || loadingPlace ? 'opacity-60' : ''"
          v-model="search"
          @place_changed="setPlace"
        >
        </GMapAutocomplete>
        <small
          v-if="searchError"
          class="absolute -bottom-5 whitespace-nowrap text-xs text-red-200"
        >
          We have not found hotels at this address, please try the neighborhood
          or the city.
        </small>
      </div>
      <div
        v-if="searchProvider !== 'hotel.com' || regionId > 0"
        class="flex w-full gap-4 max-w-[779px]"
      >
        <div class="w-full max-w-[250px] flex flex-col gap-1">
          <label class="text-sm font-bold">Event Dates:</label>
          <VueDatePicker
            :disabled="loading || loadingPlace"
            :format="format"
            :range="true"
            :enable-time-picker="false"
            :min-date="new Date()"
            v-model="dates"
            ref="interval"
            input-class-name="disabled:opacity-60 w-full bg-gray-700 h-[42px] appearance-none rounded text-white border border-gray-500"
          >
            <template #action-preview="{ value }">
              {{ "" }}
            </template>
            <template #action-buttons>
              <span
                class="cursor-pointer rounded border border-crewfareGreen text-crewfareGreen hover:bg-crewfareGreen hover:text-white px-4 py-2"
                @click="selectInterval"
              >
                Select
              </span>
            </template>
          </VueDatePicker>
        </div>
        <div class="flex flex-col gap-1 w-full max-w-[140px]">
          <label class="text-sm font-bold">Rating:</label>
          <select
            :disabled="loading || loadingPlace"
            class="disabled:opacity-60 w-full bg-gray-700 h-[42px] appearance-none rounded"
            v-model="rating"
          >
            <option selected value="1,2,3,4,5">Any rating</option>
            <option value="1">1 Star</option>
            <option value="2">2 Stars</option>
            <option value="3">3 Stars</option>
            <option value="4">4 Stars</option>
            <option value="5">5 Stars</option>
          </select>
        </div>
        <div class="w-full max-w-[220px] flex">
          <div class="flex flex-col gap-1 w-full max-w-[100px]">
            <label class="text-sm font-bold">Min Price:</label>
            <div
              class="flex gap-2 bg-gray-700 items-center justify-center rounded h-[42px] border border-gray-500"
            >
              <span class="flex px-2 items-center justify-center">$</span>
              <input
                :disabled="loading || loadingPlace"
                type="text"
                class="disabled:opacity-60 px-2 bg-transparent w-full text-right h-[42px] border-0"
                v-model="minPrice"
                @input="validateMinPrice"
                placeholder="Enter amount"
              />
            </div>
          </div>
          <div class="px-3 flex items-end py-2">-</div>
          <div class="flex flex-col gap-1 w-full max-w-[100px]">
            <label class="text-sm font-bold">Max Price:</label>
            <div
              class="flex gap-2 bg-gray-700 items-center justify-center rounded h-[42px] border border-gray-500"
            >
              <span class="flex px-2 items-center justify-center">$</span>
              <input
                :disabled="loading || loadingPlace"
                type="text"
                class="disabled:opacity-60 px-2 bg-transparent w-full text-right h-[42px] border-0"
                v-model="maxPrice"
                @input="validateMaxPrice"
                placeholder="Enter amount"
              />
            </div>
          </div>
        </div>
        <div class="w-full flex max-w-[120px] flex-col gap-1 relative">
          <label class="text-sm font-bold">Miles Radius:</label>
          <input
            :disabled="loading || loadingPlace"
            type="text"
            :max="maxRadius"
            class="disabled:opacity-60 w-full bg-gray-700 rounded px-4 h-[42px]"
            v-model="radius"
          />
          <small class="absolute -bottom-5 text-xs text-gray-500"
            >Up to {{ maxRadius }} miles</small
          >
        </div>
      </div>
      <div class="flex items-end">
        <button
          :disabled="!valid || loading || loadingPlace"
          class="disabled:border-gray-800 h-[42px] disabled:bg-gray-800 disabled:text-gray-400 text-crewfareGreen flex items-center px-4 border rounded border-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900"
        >
          <span
            class="material-symbols-rounded"
            :class="(loading || loadingPlace) && 'animate-spin'"
          >
            {{ loading || loadingPlace ? "autorenew" : "search" }}
          </span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["loading"],
  data() {
    const date = new Date();
    let startDate = new Date(date.setDate(date.getDate() + 35));
    let endDate = new Date(date.setDate(date.getDate() + 4));
    if (this.$route.query.startDate) {
      startDate = new Date(moment(this.$route.query.startDate, "MM-DD-YYYY"));
      endDate = new Date(moment(this.$route.query.endDate, "MM-DD-YYYY"));
    }
    return {
      search: "",
      rating: "1,2,3,4,5",
      dates: [startDate, endDate],
      regionId: "",
      minPrice: 0,
      maxPrice: 1000,
      radius: 10,
      reference: "",
      lat: "",
      lng: "",
      addr: [],
      searchError: false,
      loadingPlace: false,
      searchProvider: "",
      itemsSearch: [],
    };
  },
  computed: {
    valid() {
      return (
        (this.searchProvider !== "hotel.com" || this.regionId > 0) &&
        this.minPrice !== "" &&
        this.maxPrice > 0 &&
        this.radius > 0 &&
        this.radius <= this.maxRadius &&
        this.dates &&
        this.dates.length === 2
      );
    },
    maxRadius() {
      return this.searchProvider == "hotel.com" ? 49 : 99;
    },
  },
  async mounted() {
    if (this.$store.state.search) {
      const search = this.$store.state.search;
      this.dates = search.dates;
      this.regionId = search.regionId;
      this.minPrice = search.minPrice;
      this.maxPrice = search.maxPrice;
      this.radius = search.radius;
      this.search = search.search;
      this.rating = search.rating;
    }

    if (this.$route.query.search) {
      const search = this.$route.query.search
      const query = this.$route.query;
      this.loadingPlace = true;
      this.search = query.search;
      this.itemsSearch = this.search.split(", ")
      this.$refs.gmapAutocomplete.$refs.input.value = this.search;
      if(!(query.lat && query.lng)){
        await this.$gmapApiPromiseLazy();
        const geocoder = new google.maps.Geocoder();
        const place = await geocoder.geocode({address: search})
        console.log("Place", place)
        this.setPlace(place.results[0])
        return;
      }
      else{
        this.lat = parseFloat(query.lat||"0");
        this.lng = parseFloat(query.lng||"0");
      }
      
      
      await this.searchRegion(); 
      console.log((this.searchProvider !== "hotel.com" || this.regionId > 0),
        this.minPrice !== "",
        this.maxPrice > 0,
        this.radius > 0,
        this.radius <= this.maxRadius,
        this.dates,
        this.dates.length === 2)
      this.setSearch(); 
      this.loadingPlace = false;
    }
  },
  watch: {
    maxPrice() {
      if (parseFloat(this.maxPrice) < 0) {
        this.maxPrice = "";
      }
    },
    minPrice() {
      if (parseFloat(this.minPrice) < 0) {
        this.minPrice = "";
      }
    },
    radius() {
      if (
        parseFloat(this.radius) < 0 ||
        parseFloat(this.radius) > this.maxRadius
      ) {
        this.radius = "";
      }
    },
  },
  methods: {
    async changeProvider(e) {
      await this.searchRegion();
    },
    async searchRegion() {
      this.searchError = false;
      const itemsSearch = this.itemsSearch;
      if (this.searchProvider !== "hotel.com" || itemsSearch.length <= 0) {
        return;
      }
      const query = itemsSearch.join(
          ", "
        ).slice(0,50);
      const result = await fetch(
        `https://hotels-com-provider.p.rapidapi.com/v2/regions?locale=en_US&query=${query}&domain=US`,
        {
          method: "GET",
          headers: {
            "X-RapidAPI-Host": "hotels-com-provider.p.rapidapi.com",
            "X-RapidAPI-Key":
              "572ef5b2admshbb85ad0c7ce456cp185401jsn722424625e07",
          },
        }
      );
      this.regionId = false;
      const searchData = await result.json();
      if (searchData && searchData.data) {
        for(const data of searchData.data) {
          if (
            data.type === "CITY" ||
            data.type === "NEIGHBORHOOD" ||
            data.type === "POI" ||
            !this.regionId 
          ) {
            this.regionId = data.gaiaId;
          }
        }
        if (!this.regionId) {
          this.itemsSearch.shift();
          if (this.itemsSearch.length > 0) {
            await this.searchRegion();
          } else {
            this.searchError = true;
          }
        }
      }
    },

    async setPlace(place) {
      this.searchError = false;
      this.search = place.address_components
        .map((item) => item.short_name)
        .join(", ");
      this.loadingPlace = true;
      this.addr = place.address_components;
      this.lat = place.geometry.location.lat();
      this.lng = place.geometry.location.lng();
      const itemsSearch = [place.name];
      place.address_components.forEach((items) => {
        if (items.types.includes("locality")) {
          itemsSearch.push(items.short_name);
        }
      });
      this.itemsSearch = itemsSearch;
      if (this.searchProvider === "hotel.com") {
        await this.searchRegion();
      }
      this.setSearch(); 
      this.loadingPlace = false;
    },
    selectInterval() {
      this.$refs.interval.selectDate();
    },
    format(date) {
      return `${moment(date[0]).format("MM-DD-YYYY")} to ${moment(
        date[1]
      ).format("MM-DD-YYYY")}`;
    },
    async setSearch() {
      if (!this.valid) {
        console.log("This is not valid")
        return;
      };
      const search = this.$store.state.search;
      this.$store.commit("setSearch", {
        dates: this.dates,
        regionId: this.regionId,
        minPrice: this.minPrice,
        maxPrice: this.maxPrice,
        radius: this.radius,
        search: this.search,
        rating: this.rating,
        lat: this.lat,
        lng: this.lng,
        sorting: search?.sorting || "DISTANCE",
        page_number: 1,
        addr: this.addr,
        searchProvider: this.searchProvider,
      });
      this.$emit("doSearch");
    },
  },
};
</script>
