<template>
  <main>
    <Header />
    <div class="flex gap-4">
      <Menu />
      <div class="bg-gray-900 w-full p-4 flex flex-col gap-4 stage-height">
        <DefaultData v-if="account" :account="account" />
        <UpdatePassword v-if="account.type === 'regular'" :account="account" />
        <Notifications v-if="account" />
      </div>
    </div>
  </main>
</template>

<script>
import { RouterView } from "vue-router";
import Header from "@/components/default/Header.vue";
import Menu from "@/components/default/Menu.vue";
import {
  DefaultData,
  UpdatePassword,
  Notifications,
} from "@/components/myAccount";

export default {
  components: {
    Header,
    Menu,
    RouterView,
    UpdatePassword,
    DefaultData,
    Notifications,
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
};
</script>
