<template>
  <tr>
    <td class="text-sm px-4 text-ellipsis overflow-hidden">
      {{ reminder.data().isInternal && "Internal" }}
      {{ reminder.data().isHotel && "Hotel" }}
    </td>
    <td class="text-sm px-4 text-ellipsis overflow-hidden">
      {{ reminder.data().status }}
    </td>
    <td
      class="text-sm px-4 text-ellipsis overflow-hidden flex flex-col divide-y"
    >
      {{ reminder.data().days }}
    </td>
    <td
      class="text-sm px-4 text-ellipsis overflow-hidden flex flex-col divide-y"
    >
      {{ formatedUpdatedAt }}
    </td>
    <td class="px-4 py-2">
      <div class="flex gap-4 justify-end">
        <router-link
          v-tippy="'Edit reminder'"
          :to="{
            name: 'rfpsRemindersEdit',
            params: { reminderId: reminder.id, id: reminder.data().rfp_id },
          }"
          class="text-crewfareGreen text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1 border-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900"
        >
          edit
        </router-link>
        <button
          v-tippy="'Remove reminder'"
          @click="removeReminder"
          class="text-red-400 text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1 border-red-400 hover:bg-red-400 hover:text-white"
        >
          delete
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import { firestore } from "@/utils/firebase";
import { deleteDoc, doc } from "firebase/firestore";
import moment from "moment";

export default {
  props: ["reminder"],
  name: "User",
  computed: {
    formatedUpdatedAt() {
      if (!this.reminder.data().updated_at) return "";
      const created_at = moment(this.reminder.data().updated_at.toDate());
      return created_at.fromNow();
    },
  },
  methods: {
    async removeReminder() {
      if (!confirm("Delete this reminder?")) {
        return;
      }
      deleteDoc(doc(firestore, "rfp_reminders", this.reminder.id));
      this.$store.commit("setToast", {
        content: `<p>Reminder Removed</p>`,
      });
      this.$emit("getData");
    },
  },
};
</script>
