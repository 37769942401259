<template>
  <div>
    <button
      @click="showDialog = true"
      class="border cursor-pointer text-crewfareGreen rounded border-crewfareGreen px-2 py-1 hover:bg-crewfareGreen hover:text-black text-xs disabled:bg-gray-200 disabled:border-gray-200 disabled:text-black"
    >
      Request changes
    </button>
    <div
      v-if="showDialog"
      class="fixed bottom-0 right-0 z-[200] rounded-lg left-0 top-0 bg-gray-300 bg-opacity-40 flex items-center justify-center"
    >
      <div
        class="fixed top-0 right-0 left-0 bottom-0"
        @click="showDialog = false"
      ></div>
      <div class="overlay">
        <div
          class="bg-gray-900 shadow-md max-w-[470px] rounded-lg flex min-w-[598px] flex-col gap-4 items-center p-4 relative text-gray-200"
        >
          <div class="flex justify-between items-center gap-4 w-full">
            <p class="text-2xl">Request changes</p>
            <span
              @click="showDialog = false"
              class="cursor-pointer top-2 right-2 text-red-200 hover:text-red-400 material-symbols-rounded"
            >
              close
            </span>
          </div>
          <div class="w-full">
            <p class="mb-4">
              Can you share what are the changes needed for this contract?
            </p>
            <div class="mb-4">
              <div
                ref="reasonInput"
                @keydown="reasonHasChanges = true"
                contenteditable="true"
                class="w-full bg-gray-700 rounded px-4 py-2"
              ></div>
            </div>
            <div class="flex justify-between items-center">
              <button @click="showDialog = false" class="cancel-button">
                cancel
              </button>
              <span
                @click="sendRequest"
                class="green-button"
                :class="
                  !reasonHasChanges
                    ? 'bg-gray-700 border-gray-700 text-gray-900'
                    : 'cursor-pointer hover:bg-crewfareGreen hover:text-gray-900 border-crewfareGreen'
                "
              >
                <span
                  v-if="loading"
                  class="material-symbols-rounded animate-spin"
                >
                  autorenew
                </span>
                <span v-else> Send request </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { requestContractEdit } from "@/utils/rfp.jsx";

export default {
  props: ["rfp"],
  data() {
    return {
      reasonHasChanges: false,
      loading: false,
      showDialog: false,
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  methods: {
    async sendRequest() {
      if (!this.reasonHasChanges) return;
      this.loading = true;
      const reason = this.$refs.reasonInput.innerHTML;
      await requestContractEdit(this.rfp, this.account, reason);
      this.$refs.reasonInput.innerHTML = "";
      this.showDialog = false;
      this.loading = false;
    },
  },
};
</script>
