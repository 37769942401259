<template>
  <div>
    <span v-if="loading" class="material-symbols-rounded animate-spin">
      autorenew
    </span>
    <select
      v-else
      class="bg-gray-800 text-white rounded text-sm"
      v-model="status"
      @change="toggleStatus"
    >
      <option value="">Select new status</option>
      <option v-for="status in statusList" :key="status">
        {{ status }}
      </option>
    </select>
  </div>
</template>

<script>
import { statusList, setStatusHistory } from "@/utils/rfp.jsx";
import { firestore } from "@/utils/firebase";
import { getDoc, doc, setDoc } from "firebase/firestore";

export default {
  name: "DefineStatus",
  props: ["rfp"],
  data() {
    return {
      statusList,
      status: "",
      loading: false,
    };
  },
  methods: {
    async toggleStatus() {
      if (!confirm("Are you sure?")) return;
      this.loading = true;
      const rfpRef = doc(firestore, "rfp_hotel", this.rfp.id);
      const rfpData = await getDoc(rfpRef);
      const data = await setStatusHistory({
        data: rfpData.data(),
        content: "Status fix",
        status: this.status,
      });
      await setDoc(doc(firestore, "rfp_hotel", this.rfp.id), {
        ...data,
        status: this.status,
        updated_at: new Date(),
      });
      this.$emit("getData");
    },
  },
};
</script>
