<template>
  <tr v-if="rfp && rfp.signedHotelsList > 0" class="text-white relative">
    <td class="text-sm p-4 flex items-center">
      {{ rfp.event_name || "-" }}
    </td>
    <td class="text-sm p-4">
      {{ rfp.name }}
    </td>
    <td class="text-sm p-4">
      {{ rfp.signedHotelsList }}
    </td>
    <td class="text-sm p-4">
      {{ rfp.roomCount }}
    </td>
    <td
      class="text-sm px-4 flex gap-4 items-center py-2 capitalize justify-between min-w-[350px]"
    >
      <div class="text-sm flex justify-center gap-2 capitalize flex-col">
        <div class="whitespace-nowrap">
          <span class="text-gray-200 mr-2">From</span>
          <span class="font-medium">{{ formatedStartDate }}</span>
        </div>
        <div class="whitespace-nowrap">
          <span class="text-gray-200 mr-2">To</span>
          <span class="font-medium">{{ formatedEndDate }}</span>
        </div>
      </div>
      <router-link :to="{ name: 'filesFile', params: { id: rfp.id } }">
        <button class="green-button">View Files</button>
      </router-link>
    </td>
  </tr>
</template>

<script>
import { getMomentFromFirebaseDate } from "@/utils/dateUtils";
import moment from "moment";

export default {
  props: ["rfp"],
  computed: {
    cutoffDateFormatted() {
      if (!this.rfp.cutoffDate) return "";
      return getMomentFromFirebaseDate(this.rfp.cutoffDate).format("D-M-Y");
    },
    formatedStartDate() {
      if (!this.rfp.startDate) return "";
      const startDate = this.rfp.startDate;
      return getMomentFromFirebaseDate(startDate).format("ddd MMM. DD, YYYY");
    },
    formatedEndDate() {
      if (!this.rfp.endDate) return "";
      const endDate = this.rfp.endDate;
      return getMomentFromFirebaseDate(endDate).format("ddd MMM. DD, YYYY");
    },
  },
};
</script>
