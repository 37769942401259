<template>
  <div class="my-6 text-center text-red-200">
    <div class="text-xl">RFP Declined.</div>
    <div
      v-if="reasonDecline"
      class="text-red my-6"
      v-html="reasonDecline"
    ></div>
  </div>
</template>

<script>
export default {
  props: ["reasonDecline"],
};
</script>
