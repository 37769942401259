<template>
  <div v-if="hotel" class="absolute left-0 right-0 top-0 bottom-0 z-10">
    <Form v-if="contact" />
    <List v-else @update="getData" />
  </div>
</template>

<script>
import List from "./contacts/List.vue";
import Form from "./contacts/Form.vue";

export default {
  components: {
    List,
    Form,
  },
  computed: {
    hotel() {
      return this.$store.state.hotel;
    },
    contact() {
      return this.$store.state.contact;
    },
  },
  methods:{
    getData() {
      this.$emit("update");
    },
  }
};
</script>
