<template>
  <div v-if="rfp" class="overlay">
    <div
      class="bg-gray-900 rounded flex flex-col gap-4 items-center w-full max-w-[450px] p-4 relative text-white"
    >
      <div class="flex w-full flex-col gap-4">
        <div class="flex w-full justify-between">
          <p class="text-xl">Calculate Delta</p>
          <span
            @click="close"
            class="cursor-pointer text-red-400 material-symbols-rounded"
          >
            close
          </span>
        </div>
        <div class="flex flex-col gap-2">
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">Online Rate</label>
            <input
              type="text"
              v-model="rate"
              class="w-full bg-gray-700 rounded px-4"
            />
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">Proposal</label>
            <input
              type="text"
              v-model="proposal"
              class="w-full bg-gray-700 rounded px-4"
            />
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">Delta</label>
            <div class="text-center text-xl" v-html="delta"></div>
          </div>
          <div class="flex justify-between items-center">
            <button class="text-gray-200" @click="close">Cancel</button>
            <button class="green-button" @click="save">Save Rate</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["rfp"],
  data() {
    return {
      message: false,
      rate: null,
      proposal: null,
      delta: null,
    };
  },
  mounted() {
    if (!this.rfp) return;
    this.rate = this.rfp.value ? this.rfp.value.toFixed(2) : 0;
    let value = 5000000;
    this.rfp.roomList?.forEach((days) => {
      days.rooms.forEach((item) => {
        if (item.value && item.value > 0 && item.value < value) {
          value = item.value;
        }
      });
    });
    if (value === 5000000) value = null;
    this.proposal = value;
  },
  watch: {
    rate() {
      this.getDelta();
    },
    proposal() {
      this.getDelta();
    },
  },
  methods: {
    save() {
      this.$emit("saveRate", this.rate);
    },
    close() {
      this.$emit("close");
    },
    getDelta() {
      const rate = parseFloat(this.rate);
      if (rate === 0 || !this.proposal) {
        this.delta = `<span>-</span>`;
        return;
      }
      const proposal = this.proposal;
      const delta = Math.floor(((rate - proposal) / rate) * 100);
      if (isNaN(delta)) return "-";
      let color = "";
      if (delta < 10) {
        color = "color: rgb(248 113 113);";
      } else if (delta < 20) {
        color = "color: rgb(250 204 21);";
      } else {
        color = "color: rgb(74 222 128);";
      }
      this.delta = `<span style='${color}'>${delta}%</span>`;
    },
  },
};
</script>
