<template>
  <div class="flex flex-col gap-1 w-full">
    <div
      class="text-sm font-bold flex justify-between items-center mb-2"
      :class="has_changes && 'text-yellow-500'"
    >
      <div class="flex gap-2 items-center">
        <span
          class="material-symbols-rounded text-[16px]"
          :class="checked ? 'text-crewfareGreen' : 'text-white'"
        >
          {{ checked ? "check_box" : "check_box_outline_blank" }}
        </span>
        Wi-Fi:
      </div>

      <div class="flex gap-4 print:hidden">
        <ButtonNotesDialog
          v-if="!isNSO"
          type="Comment on concession"
          :subtitle="rfp.name"
          title="Comments at Wifi"
          :hotel_id="rfp.hotel_id"
          :id="`${rfp.id}-wifi`"
          :rfp="rfp.id"
          field="Wifi"
          text="Comment"
        />
        <span
          v-if="is_locked"
          class="text-[18px] flex items-center px-4 print:px-0 material-symbols-rounded text-crewfarePurple py-1"
        >
          lock
        </span>
      </div>
    </div>
    <select
      :disabled="is_locked || is_blocked"
      id="wifi"
      class="w-full px-4 print:px-0 print:appearance-none bg-gray-700 h-[40px] rounded"
      v-model="wifi"
      @change="save"
      :class="
        has_changes
          ? 'bg-yellow-500 text-gray-900'
          : 'bg-gray-700 text-white print:text-black'
      "
    >
      <option value="">Select</option>
      <option>Included</option>
      <option>Not included</option>
    </select>
    <div v-if="wifi === 'Not included'" class="flex flex-col gap-1 mt-4">
      <div class="text-sm font-bold" :class="has_changes && 'text-yellow-500'">
        Price:
      </div>
      <input
        :disabled="is_blocked || is_locked"
        @blur="save_wifi_price"
        type="text"
        v-model="other"
        class="w-full bg-gray-700 rounded px-4 print:px-0"
        :class="
          has_changes
            ? 'bg-yellow-500 text-gray-900'
            : 'bg-gray-700 text-white print:text-black'
        "
      />
      <small v-if="has_changes" class="text-yellow-500">{{ changesBy }}</small>
    </div>
    <small v-if="has_changes" class="text-yellow-500">{{ changes_by }}</small>
  </div>
</template>

<script>
import { noteAdded, noteDone } from "@/utils/messages.jsx";
import {
  saveField,
  checkIsLocked,
  checkHasChanges,
  getChangesBy,
} from "@/utils/rfp.jsx";
import ButtonNotesDialog from "@/components/default/ButtonNotesDialog.vue";

export default {
  props: ["rfp", "account", "is_blocked"],
  components: {
    ButtonNotesDialog,
  },
  data() {
    return {
      wifi: "",
      other: "",
      is_locked: false,
      changes_by: null,
      has_changes: null,
    };
  },
  computed: {
    checked() {
      return this.wifi === this.rfp.wifi;
    },
    rfpSession() {
      return this.$store.state.rfpSession;
    },
    isNSO() {
      return this.account.type.toLowerCase() === "nso";
    },
  },
  watch: {
    rfpSession() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.wifi = this.rfp.wifi;
      this.other = this.rfp.wifi_price;
      if (this.account) {
        const account_type = this.account.type.toLowerCase();
        this.has_changes =
          checkHasChanges(this.rfp, "Wifi", account_type) ||
          checkHasChanges(this.rfp, "Wifi Price", account_type);
        this.is_locked = checkIsLocked(this.rfp, "wifi");
        if (this.has_changes) {
          this.changes_by = getChangesBy(this.has_changes, this.rfp);
        }
      }
    },
    async note_done() {
      noteDone("Wifi", this.rfp);
      this.$emit("refreshData");
    },
    async note_added() {
      noteAdded("Wifi", this.rfp);
      this.$emit("refreshData");
    },
    async save() {
      if (this.is_blocked) return;
      await saveField(
        this.rfp,
        {
          label: "Wifi",
          before: this.rfp.wifi,
          after: this.wifi || "",
        },
        {
          wifi: this.wifi || "",
        },
        "wifi"
      );
      this.$emit("refreshData");
    },
    async save_price() {
      if (this.is_blocked) return;
      await saveField(
        this.rfp,
        {
          label: "Wifi Price",
          before: this.rfp.wifi_price,
          after: this.other || "",
        },
        {
          wifi_price: this.other || "",
        },
        "wifi_price"
      );
      this.$emit("refreshData");
    },
  },
};
</script>
