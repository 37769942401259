import store from '@/store';
import { firestore } from '@/utils/firebase';
import { getDocs, getDoc, addDoc, doc, query, collection, where, setDoc, orderBy } from 'firebase/firestore';
import { sendNotificationHotels, sendNotificationAdmin } from './notifications.jsx';
import { sendEmail, processVariables } from './emails.jsx';
import { setStatusHistory } from './rfp.jsx';
import { messageNewEmailContent, messageNewSubject } from '@/emails/message-new';
import { messageNewFileEmailContent, messageNewFileSubject } from '@/emails/message-new-file';

const saveMessageNotification = async (message, chatText) => {
  const messageRef = doc(firestore, 'messages', message);
  const messageData = await getDoc(messageRef);
  const data = {
    text: chatText,
    created_name: store.state.account.name,
    account_id: store.state.account.id,
    hotel_id: messageData.data().hotel_id || '',
    message_id: messageData.id,
    unread: true,
    accounts: messageData.data().accounts || '',
    created_at: new Date(),
  };
  await addDoc(collection(firestore, 'message_notification'), data);
  await setDoc(doc(firestore, 'messages', messageData.id), {
    ...messageData.data(),
    new_message: true,
    new_message_by_id: store.state.account.id,
    new_message_by_name: store.state.account.name,
    new_message_content: chatText,
    new_message_date: new Date(),
    last_interaction: new Date(),
  });
};

const getConversations = () => {
  switch (store.state.account.type) {
    case 'Admin':
      return getDataAdmin();
    case 'Internal':
      return getDataInternal();
    default:
      return getDataUser();
  }
};

const getDataInternal = async () => {
  let qMessages = query(
    collection(firestore, 'messages'),
    where('accounts', 'in', [store.state.account.id]),
    where('direct', '==', true),
    orderBy('last_interaction', 'desc'),
  );
  const messages = await getDocs(qMessages);
  return messages.docs;
};

const getDataUser = async () => {
  let qMessages = query(
    collection(firestore, 'messages'),
    where('direct', '==', true),
    where('hotel_id', 'in', store.state.account.hotelIds),
    orderBy('last_interaction', 'desc'),
  );
  const messages = await getDocs(qMessages);
  return messages.docs;
};

const getDataAdmin = async () => {
  let qMessages = query(
    collection(firestore, 'messages'),
    where('direct', '==', true),
    orderBy('last_interaction', 'desc'),
  );
  const messages = await getDocs(qMessages);
  return messages.docs;
};

const addFile = async (account, message, url, name) => {
  await addDoc(collection(firestore, 'files'), {
    url,
    name,
    created_name: account.name,
    created_id: account.id,
    message_id: message.id,
    created_at: new Date(),
  });
  await setDoc(doc(firestore, 'messages', message.id), {
    ...message.data(),
    done: false,
    last_interaction: new Date(),
  });
  if (account.type.toLowerCase() === 'contact') {
    sendNotificationAdmin({
      type: 'message',
      content: `${account.name} just a new file`,
      to: { name: 'messagesFiles', params: { id: message.id } },
    });
    message.data().accounts.forEach(async account => {
      const accountRef = doc(firestore, 'accounts', account);
      const accountData = await getDoc(accountRef);
      sendEmail({
        template: 'emails.new-default',
        to: accountData.data().email,
        subject: messageNewFileSubject,
        content: processVariables(messageNewFileEmailContent, {
          name: account.name,
          link: `${import.meta.env.VITE_URL}/messages/${message.id}/chat`,
        }),
      });
    });
  } else {
    if (message.data().internal_rfp || message.data().internal_hotel) return;
    sendNotificationHotels({
      type: 'message',
      ids: [message.data().hotel_id],
      content: `${account.name} just a new file`,
      to: { name: 'messagesFiles', params: { id: message.id } },
    });
    const qContacts = query(collection(firestore, 'contacts'), where('hotel_id', '==', message.data().hotel_id));
    const contacts = await getDocs(qContacts);
    const contactList = contacts.docs;
    contactList.forEach(contact => {
      sendEmail({
        template: 'emails.new-default',
        to: contact.data().email,
        subject: messageNewFileSubject,
        content: processVariables(messageNewFileEmailContent, {
          name: account.name,
          link: `${import.meta.env.VITE_URL}/messages/${message.id}/chat`,
        }),
      });
    });
  }
};

const addChat = async (account, message, content) => {
  await addDoc(collection(firestore, 'chat'), {
    text: content,
    created_name: account.name,
    created_id: account.id,
    message_id: message.id,
    created_at: new Date(),
  });
  saveMessageNotification(message.id, content);
  await setDoc(doc(firestore, 'messages', message.id), {
    ...message.data(),
    direct: true,
    done: false,
    new_message: true,
    new_message_by_id: store.state.account.id,
    new_message_by_name: store.state.account.name,
    new_message_content: content,
    new_message_date: new Date(),
    last_interaction: new Date(),
  });
  if (account.type.toLowerCase() === 'contact') {
    sendNotificationAdmin({
      type: 'message',
      content: `${account.name} just sent a new message`,
      to: { name: 'messagesChat', params: { id: message.id } },
    });
    if (!message.data().accounts) return;
    message.data().accounts.forEach(accountEmail => {
      sendEmail({
        template: 'emails.new-default',
        to: accountEmail,
        subject: messageNewSubject,
        content: processVariables(messageNewEmailContent, {
          name: account.name,
          content: content,
          link: `${import.meta.env.VITE_URL}/messages/${message.id}/chat`,
        }),
      });
    });
  } else {
    if (message.data().internal_rfp || message.data().internal_hotel || !message.data().hotel_id) return;
    const qContacts = query(collection(firestore, 'contacts'), where('hotel_id', '==', message.data().hotel_id));
    const contacts = await getDocs(qContacts);
    const contactList = contacts.docs;
    contactList.forEach(contact => {
      sendEmail({
        template: 'emails.new-default',
        to: contact.data().email,
        subject: messageNewSubject,
        content: processVariables(messageNewEmailContent, {
          name: account.name,
          content: content,
          link: `${import.meta.env.VITE_URL}/messages/${message.id}/chat`,
        }),
      });
    });
    sendNotificationHotels({
      type: 'message',
      ids: [message.data().hotel_id],
      content: `${account.name} just sent a new message`,
      to: { name: 'messagesChat', params: { id: message.id } },
    });
  }
};

const sendMessageChain = async (account, chain) => {
  let qMessages = query(collection(firestore, 'messages'), where('chain_all', '==', chain));
  const messages = await getDocs(qMessages);
  if (messages.empty) {
    const message = await addDoc(collection(firestore, 'messages'), {
      chain_all: chain,
      type: 'message',
      title: `${chain} Internal Message`,
      created_at: new Date(),
      last_interaction: new Date(),
      accounts: [account.email],
    });
    const messageRef = doc(firestore, 'messages', message.id);
    const messageData = await getDoc(messageRef);
    store.commit('setMessageOpened', messageData);
  } else {
    const message = messages.docs[0];
    store.commit('setMessageOpened', message);
  }
};

const sendMessageNSO = async (account, chain, hotel) => {
  let qMessages = query(
    collection(firestore, 'messages'),
    where('chain_single', '==', chain),
    where('hotel_id', '==', hotel.id),
  );
  const messages = await getDocs(qMessages);
  if (messages.empty) {
    const message = await addDoc(collection(firestore, 'messages'), {
      chain_single: chain,
      hotel_id: hotel.id,
      type: 'message',
      title: `NSO ${chain} - ${hotel.data().name}`,
      created_at: new Date(),
      last_interaction: new Date(),
      accounts: [account.email],
    });
    const messageRef = doc(firestore, 'messages', message.id);
    const messageData = await getDoc(messageRef);
    store.commit('setMessageOpened', messageData);
  } else {
    const message = messages.docs[0];
    store.commit('setMessageOpened', message);
  }
};

const sendMessageCrewfare = async account => {
  let qMessages = query(
    collection(firestore, 'messages'),
    where('account_id', '==', account.id),
    where('internal', '==', true),
  );
  const messages = await getDocs(qMessages);
  if (messages.empty) {
    const message = await addDoc(collection(firestore, 'messages'), {
      account_id: account.id,
      type: 'message',
      title: `Talk to crewfare - ${account.name}`,
      created_at: new Date(),
      last_interaction: new Date(),
      internal: true,
      accounts: [account.email],
    });
    const messageRef = doc(firestore, 'messages', message.id);
    const messageData = await getDoc(messageRef);
    store.commit('setMessageOpened', messageData);
  } else {
    const message = messages.docs[0];
    store.commit('setMessageOpened', message);
  }
};

const sendHotelMessage = async (hotel, account, data = {}) => {
  let qMessages = query(
    collection(firestore, 'messages'),
    where('hotel_id', '==', hotel.id),
    where('internal', '==', true),
  );
  const messages = await getDocs(qMessages);
  if (messages.empty) {
    const message = await addDoc(collection(firestore, 'messages'), {
      hotel_id: hotel.id,
      account_id: account.id,
      type: 'message',
      internal: true,
      hotel_name: hotel.data().name,
      title: hotel.data().name,
      created_at: new Date(),
      last_interaction: new Date(),
      accounts: [account.email],
      ...data,
    });
    const messageRef = doc(firestore, 'messages', message.id);
    const messageData = await getDoc(messageRef);
    store.commit('setMessageOpened', messageData);
  } else {
    const message = messages.docs[0];
    store.commit('setMessageOpened', message);
  }
};

const sendDirectMessage = async (ids, accounts) => {
  let qMessages = query(collection(firestore, 'messages'), where('accounts', '==', ids));
  const messages = await getDocs(qMessages);
  if (messages.empty) {
    const message = await addDoc(collection(firestore, 'messages'), {
      type: 'message',
      title: accounts[0].name,
      title_alt: accounts[1].name,
      created_at: new Date(),
      last_interaction: new Date(),
      accounts: ids,
    });
    const messageRef = doc(firestore, 'messages', message.id);
    const messageData = await getDoc(messageRef);
    store.commit('setMessageOpened', messageData);
  } else {
    const message = messages.docs[0];
    store.commit('setMessageOpened', message);
  }
};

const sendRFPMessage = async (rfp, account) => {
  let qMessages = query(
    collection(firestore, 'messages'),
    where('hotel_id', '==', rfp.hotel_id),
    where('internal', '==', true),
    where('rfp_id', '==', rfp.id),
  );
  const messages = await getDocs(qMessages);
  if (messages.empty) {
    const message = await addDoc(collection(firestore, 'messages'), {
      rfp_id: rfp.id,
      hotel_id: rfp.hotel_id,
      type: 'message',
      title: `${rfp.name} - ${rfp.hotel_name}`,
      created_at: new Date(),
      last_interaction: new Date(),
      accounts: [account.email],
    });
    const messageRef = doc(firestore, 'messages', message.id);
    const messageData = await getDoc(messageRef);
    store.commit('setMessageOpened', messageData);
  } else {
    const message = messages.docs[0];
    store.commit('setMessageOpened', message);
  }
};

const noteAdded = async (field, rfp) => {
  const note = rfp.data().note;
  note.push({ field });
  let data = rfp.data();
  data = await setStatusHistory({
    data,
    content: `Note added at ${field}`,
    status: data.status,
  });
  await setDoc(doc(firestore, 'rfp_hotel', rfp.id), {
    ...data,
    note,
    updated_at: new Date(),
  });
};

const noteDone = async (field, rfp) => {
  const note = rfp.data().note;
  note.splice(
    note.findIndex(note => note.field === field),
    1,
  );
  let data = rfp.data();
  data = await setStatusHistory({
    data,
    content: `Note mark as done at ${field}`,
    status: rfp.data().status,
  });
  await setDoc(doc(firestore, 'rfp_hotel', rfp.id), {
    ...data,
    note,
    updated_at: new Date(),
  });
};

export {
  getConversations,
  saveMessageNotification,
  addChat,
  addFile,
  sendMessageCrewfare,
  sendMessageChain,
  sendRFPMessage,
  sendMessageNSO,
  sendDirectMessage,
  noteAdded,
  noteDone,
  sendHotelMessage,
};
