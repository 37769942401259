<template>
  <div
    class="border rounded border-gray-600 hover:border-crewfareGreen px-4 text-white mt-[45px] flex flex-col"
  >
    <div v-if="hotel.image" class="flex justify-center -mt-[45px]">
      <div class="h-[90px] w-[90px] rounded-full overflow-hidden bg-gray-600">
        <img
          :src="hotel.image"
          class="h-[90px] !max-w-none duration-500 rounded-full"
        />
      </div>
    </div>
    <div v-else class="flex justify-center -mt-[45px]">
      <div
        class="h-[90px] w-[90px] flex items-center justify-center rounded-full overflow-hidden bg-gray-600"
      >
        <span class="material-symbols-rounded">hotel</span>
      </div>
    </div>
    <div class="flex flex-col flex-grow gap-4 my-4">
      <div class="text-center">
        <p class="font-bold">{{ hotel.data().name }}</p>
      </div>
      <div v-if="addr" class="text-center">
        <p class="text-sm">{{ addr }}</p>
      </div>
      <div v-if="contactsCount > 0" class="text-center flex flex-col gap-1">
        <p class="text-xs text-gray-200">Main Contact</p>
        <p class="text-sm">
          {{ hotel.data().contacts[0].name }}<br />
          <span class="text-xs truncate block"
            >&lt;{{
              hotel.data().contacts[0].email.toLowerCase().trim()
            }}&gt;</span
          >
        </p>
      </div>
    </div>

    <div class="flex gap-4 justify-between mb-4">
      <router-link
        v-tippy="'View Hotel Contacts'"
        :to="{ name: 'groupHotelsContactsList', params: { id: hotel.id } }"
        class="rounded flex gap-2 items-center justify-center bg-gray-700 hover:bg-gray-600 w-full px-4 text-crewfareGreen"
      >
        {{ contactsCount }}
        <span class="material-symbols-rounded text-[18px]">group</span>
      </router-link>
      <router-link
        v-tippy="'Manage hotel'"
        :to="{ name: 'groupHotelEdit', params: { id: hotel.id } }"
        class="text-crewfareGreen text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-2 px-4 border-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900"
      >
        settings
      </router-link>
      <button
        v-tippy="'Delete Hotel'"
        @click="removeHotel"
        class="text-red-400 text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-2 px-4 border-red-400 hover:bg-red-400 hover:text-white"
      >
        delete
      </button>
    </div>
  </div>
</template>

<script>
import { firestore } from "@/utils/firebase";
import { deleteDoc, doc } from "firebase/firestore";

export default {
  props: ["hotel"],
  data() {
    return {
      selected: false,
      contacts: null,
      loading: false,
      addr: null,
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
    contactsCount() {
      if (!this.hotel.data().contacts) return 0;
      return this.hotel.data().contacts.length;
    },
  },
  methods: {
    async removeHotel() {
      if (!confirm("Delete this hotel?")) {
        return;
      }
      deleteDoc(doc(firestore, "hotels", this.hotel.id));
      this.$emit("getData");
    },
  },
};
</script>
