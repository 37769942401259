import { collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore';
import { firestore } from './firebase';
import { processVariables, sendEmail } from './emails';

const sendNsoCommunication = async ({ chainName, subject, content, variables }) => {
  const accounts = await getNsoByChain(chainName);
  if (!accounts) return;
  for (const account of accounts) {
    const email = account.email.trim();
    const link = `${import.meta.env.VITE_URL}/rfp/${variables.id}/contract`;
    sendEmail({
      banner: variables.banner ?? '',
      template: 'emails.new-default',
      to: email,
      replyTo: variables.replyTo ?? '',
      subject: processVariables(subject, variables),
      content: processVariables(content, { ...variables, link }),
    });
  }
};

const getNsoByChain = async (chainName: string) => {
  const qChains = query(collection(firestore, 'chains'), where('name', '==', chainName));
  const chains = await getDocs(qChains);
  const accounts = [];
  console.log(chains.docs);
  for (const chain of chains.docs) {
    if (!chain.data().manager_ids) return [];
    for (const account_id of chain.data().manager_ids) {
      console.log(account_id);
      const accountRef = doc(firestore, 'accounts', account_id);
      const accountData = await getDoc(accountRef);
      if (accountData.exists()) {
        accounts.push({ id: accountData.id, ...accountData.data() });
      }
    }
  }
  console.log(accounts, 'accounts');
  return accounts;
};

export { sendNsoCommunication, getNsoByChain };
