<template>
  <div class="flex flex-col gap-1 w-full">
    <div
      class="text-sm font-bold flex justify-between items-center mb-2"
      :class="has_changes && 'text-yellow-500'"
    >
      <div class="flex gap-2 items-center">
        <span
          class="material-symbols-rounded text-[16px]"
          :class="checked ? 'text-crewfareGreen' : 'text-white'"
        >
          {{ checked ? "check_box" : "check_box_outline_blank" }}
        </span>
        Commission(%):
      </div>
      <div class="flex gap-4 print:hidden">
        <ButtonNotesDialog
          v-if="!isNSO"
          type="Comment on concession"
          :subtitle="rfp.name"
          title="Comments at Commission"
          :rfp="rfp.id"
          field="Commission"
          :id="`${rfp.id}-comission`"
          :hotel_id="rfp.hotel_id"
          text="Comment"
        />
        <span
          v-if="is_locked"
          class="text-[18px] flex items-center px-4 print:px-0 material-symbols-rounded text-crewfarePurple py-1"
        >
          lock
        </span>
      </div>
    </div>
    <select
      :disabled="is_locked || is_blocked"
      class="w-full bg-gray-700 print:appearance-none h-[40px] px-4 print:px-0 rounded"
      @change="save_commission"
      v-model="commission"
      :class="
        has_changes
          ? 'bg-yellow-500 text-gray-900'
          : 'bg-gray-700 text-white print:text-black'
      "
    >
      <option>Select</option>
      <option>10%</option>
      <option>7%</option>
      <option>Other</option>
      <option>Non Commissionable</option>
    </select>
    <div v-if="commission === 'Other'" class="flex flex-col gap-1 mt-4">
      <div class="text-sm font-bold" :class="has_changes && 'text-yellow-500'">
        Other %:
      </div>
      <input
        :disabled="is_blocked || is_locked"
        type="number"
        v-model="other_amount"
        @blur="save_other_amount"
        class="w-full bg-gray-700 rounded px-4 print:px-0"
        @keypress="isNumber($event)"
        :class="
          has_changes
            ? 'bg-yellow-500 text-gray-900'
            : 'bg-gray-700 text-white print:text-black'
        "
      />
    </div>
    <div
      v-if="commission === 'Non Commissionable'"
      class="flex flex-col gap-1 mt-4"
    >
      <div class="text-sm font-bold" :class="has_changes && 'text-yellow-500'">
        Commission Amount:
      </div>
      <input
        :disabled="is_blocked || is_locked"
        type="text"
        v-model="amount"
        @blur="save_amount"
        class="w-full bg-gray-700 rounded px-4 print:px-0"
        :class="
          has_changes
            ? 'bg-yellow-500 text-gray-900'
            : 'bg-gray-700 text-white print:text-black'
        "
      />
      <small v-if="has_changes" class="text-yellow-500">{{ changes_by }}</small>
    </div>
  </div>
</template>

<script>
import { noteAdded, noteDone } from "@/utils/messages.jsx";
import {
  saveField,
  checkIsLocked,
  checkHasChanges,
  getChangesBy,
} from "@/utils/rfp.jsx";
import ButtonNotesDialog from "@/components/default/ButtonNotesDialog.vue";

export default {
  props: ["rfp", "account", "is_blocked"],
  components: {
    ButtonNotesDialog,
  },
  data() {
    return {
      commission: "",
      other_amount: "",
      amount: "",
      is_locked: false,
      changes_by: null,
      has_changes: null,
    };
  },
  computed: {
    checked() {
      return this.commission === this.rfp.commission;
    },
    rfpSession() {
      return this.$store.state.rfpSession;
    },
    isNSO() {
      return this.account.type.toLowerCase() === "nso";
    },
  },
  watch: {
    rfpSession() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.commission = this.rfp.commission;
      this.amount = this.rfp.commissionAmount;
      this.other_amount = this.rfp.commissionOtherAmount;
      if (this.account) {
        const account_type = this.account.type.toLowerCase();
        this.has_changes =
          checkHasChanges(this.rfp, "Commission", account_type) ||
          checkHasChanges(this.rfp, "Commission Other Amount", account_type) ||
          checkHasChanges(this.rfp, "Commission Amount", account_type);
        this.is_locked = checkIsLocked(this.rfp, "Commission");
        if (this.has_changes) {
          this.changes_by = getChangesBy(this.has_changes, this.rfp);
        }
      }
    },
    async note_done() {
      noteDone("Commission", this.rfp);
      this.$emit("refreshData");
    },
    async note_added() {
      noteAdded("Commission", this.rfp);
      this.$emit("refreshData");
    },
    async save_commission() {
      if (this.is_blocked) return;
      await saveField(
        this.rfp,
        {
          label: "Commission",
          before: this.rfp.commission,
          after: this.commission,
        },
        {
          commission: this.commission,
        },
        "commission"
      );
      this.$emit("refreshData");
    },
    async save_other_amount() {
      if (this.is_blocked) return;
      await saveField(
        this.rfp,
        {
          label: "Commission Other Amount",
          before: this.rfp.commissionOtherAmount,
          after: this.other_amount || "",
        },
        {
          commissionOtherAmount: this.other_amount || "",
        },
        "commissionOtherAmount"
      );
      this.$emit("refreshData");
    },
    async save_commission_amount() {
      if (this.is_blocked) return;
      await saveField(
        this.rfp,
        {
          label: "Commission Amount",
          before: this.rfp.commissionAmount,
          after: this.amount || "",
        },
        {
          commissionAmount: this.amount || "",
        },
        "commissionAmount"
      );
      this.$emit("refreshData");
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>
