<template>
  <FwbTimelineItem>
    <FwbTimelinePoint> </FwbTimelinePoint>
    <FwbTimelineContent>
      <FwbTimelineTime>
        {{ formatedCreatedAt }}
      </FwbTimelineTime>
      <FwbTimelineTitle>
        <span v-if="item.is_added" class="text-crewfareGreen"> Addition </span>
        <span v-else class="text-red-200"> Removal </span>
      </FwbTimelineTitle>
      <FwbTimelineBody>
        <div class="flex flex-col gap-2">
          <p>Changes:</p>
          <p v-if="item.change" v-html="changeSubStritem"></p>
          <small class="text-sm">by {{ item.account.name }} - {{ type }}</small>
          <div>
            <button class="green-button-small" @click="toggleContent">
              Recover this content
            </button>
          </div>
        </div>
      </FwbTimelineBody>
    </FwbTimelineContent>
  </FwbTimelineItem>
</template>

<script>
import {
  FwbTimelineItem,
  FwbTimelinePoint,
  FwbTimelineTime,
  FwbTimelineContent,
  FwbTimelineTitle,
  FwbTimelineBody,
} from "flowbite-vue";
import moment from "moment";
import { RFPStatusPill, setStatusHistory } from "@/utils/rfp.jsx";

export default {
  props: ["item"],
  components: {
    FwbTimelineItem,
    FwbTimelinePoint,
    FwbTimelineTime,
    FwbTimelineContent,
    FwbTimelineTitle,
    FwbTimelineBody,
  },
  data() {
    return {
      termsParse: {
        terms: "Terms",
        attrition: "Attrition",
        commission: "Commission(%)",
        iata: "IATA #",
        rebate: "Rebate",
        groupDateHonored: "Group Rate Honored",
        compRooms: "Comp Rooms",
        staffRooms: "Staff Rooms",
        suiteUpgrades: "Suite Upgrades",
        staffDiscount: "Staff Discount",
        rewardPoints: "Reward Points",
        resortFee: "Resort Fee",
        reservationSubmission: "Reservation Submission",
        billing: "Billing",
        incidentals: "Incidentals",
        incidentalsOther: "Incidentals Other",
        billingsOther: "Billings Other",
        resortFeeAmount: "Resort Fee Amount",
        staffDiscountAmount: "Staff Discount Amount",
        rebateType: "RebateType",
        groupRateTypePre: "Group Rate Type Pre",
        groupRateTypePost: "Group Rate Type Post",
        commissionAmount: "Commission Amount",
        roomList: "Room List",
        wifi: "Wi-fi",
        breakfast: "Breakfast",
        breakfastOther: "Breakfast Other",
        parking: "Parking",
        parkingOther: "Parking Other",
        groupDateHonoredPostDays: "Group Date Honored Post Days",
        groupDateHonoredPreDays: "Group Date Honored Pre Days",
        otherFees: "Other Fees",
        otherFeesAmount: "Other Fess Amount",
      },
    };
  },
  computed: {
    changeSubStritem() {
      const count = this.item.change.length;
      if (count > 250) {
        return `${this.item.change.substr(0, 250)}...`;
      } else {
        return this.item.change;
      }
    },
    changesParsed() {
      const changes = this.item.change.filter((change) => !!change);
      return changes.map((change) => this.termsParse[change]).join(", ");
    },
    statusPill() {
      return RFPStatusPill(this.item.status);
    },
    formatedCreatedAt() {
      if (!this.item.created_at) return "";
      const created_at = moment(this.item.created_at.toDate());
      return created_at.format("ddd MMM. DD, YYYY - hh:mm A");
    },
    type() {
      if (!this.item.account) return;
      if (["admin", "internal"].includes(this.item.account.type.toLowerCase()))
        return "Crewfare";
      return "Hotel";
    },
  },
  methods: {
    async toggleContent() {
      const rfpRef = doc(firestore, "rfp_hotel", this.$route.params.rfp);
      const rfp = await getDoc(rfpRef);
      let data = {
        ...rfp.data(),
        contract_content: this.item.content,
      };
      if (!data["contract_history"]) data["contract_history"] = [];
      data["contract_history"].push({
        created_at: new Date(),
        content: this.item.content,
        recovered: true,
        account: this.account,
      });
      data = await setStatusHistory({
        data,
        content: "Contract recovered",
        status: data.status,
      });
      await setDoc(doc(firestore, "rfp_hotel", rfp.id), data);
      this.$emit("contractRecovered");
    },
  },
};
</script>
