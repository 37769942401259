<template>
  <div class="flex min-h-full w-full items-center justify-center bg-black">
    <img src="/loading.gif" class="max-w-[500px]" />
  </div>
</template>

<script>
export default {
  props: ["size"],
};
</script>
