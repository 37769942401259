<template>
  <div
    class="px-4 flex justify-between py-2"
    :class="selected && 'bg-gray-600'"
  >
    <label
      @click="updateSelected"
      class="flex gap-4 group text-white items-center"
      :class="
        contactsCount === 0
          ? 'opacity-60'
          : 'hover:text-gray-400 cursor-pointer'
      "
    >
      <div v-if="contactsCount > 0" class="flex items-center">
        <span
          v-if="selected"
          class="text-gray-800 bg-crewfareGreen rounded w-[18px] h-[18px] flex items-center justify-center"
        >
          <img src="/icons/small-check.svg" />
        </span>
        <span v-else class="border rounded w-[18px] h-[18px]"></span>
      </div>
      <div v-else class="flex items-center">
        <span class="opacity-50 border rounded w-[18px] h-[18px]"></span>
      </div>
      <div
        class="max-h-[90px] max-w-[90px] min-h-[90px] min-w-[90px] bg-gray-200 overflow-hidden relative rounded flex justify-center"
      >
        <img
          v-if="hotel.propertyImage"
          :src="hotel.propertyImage.image.url"
          class="h-[90px] !max-w-none duration-500 rounded"
          :class="contactsCount > 0 && 'group-hover:scale-125'"
        />
      </div>
      <div class="py-2 pr-2 w-full">
        <div class="flex flex-col gap-1">
          <p class="text-xl font-bold text-ellipsis overflow-hidden">
            {{ hotelName }}
          </p>
          <div
            v-if="hotel.distance > 0"
            class="flex items-center gap-2 text-sm"
          >
            <img src="/icons/pin.svg" />
            {{ hotel.distance?.toFixed(2) || 0 }} mi
          </div>
          <div v-if="hotel.star" class="flex items-center gap-2 text-sm">
            <span class="flex items-center gap-1">
              <img
                :src="`/icons/star${hotel.star >= 1 ? '-filled' : ''}.svg`"
              />
              <img
                :src="`/icons/star${hotel.star >= 2 ? '-filled' : ''}.svg`"
              />
              <img
                :src="`/icons/star${hotel.star >= 3 ? '-filled' : ''}.svg`"
              />
              <img
                :src="`/icons/star${hotel.star >= 4 ? '-filled' : ''}.svg`"
              />
              <img
                :src="`/icons/star${hotel.star >= 5 ? '-filled' : ''}.svg`"
              />
            </span>
            {{ hotel.star }} rating
          </div>
        </div>
      </div>
    </label>
    <div class="flex gap-4 flex-grow justify-end">
      <div
        v-if="hotel.price"
        class="rounded text-crewfareGreen flex min-w-[200px] max-w-[200px] w-full items-center justify-center text-xl bg-gray-700 gap-1"
        :class="contactsCount === 0 ? 'opacity-60' : selected && 'bg-gray-600'"
      >
        <strong>{{ hotel.price.lead.formatted }}</strong>
        per night
      </div>
      <div
        v-else
        class="rounded text-crewfareGreen flex min-w-[200px] max-w-[200px] w-full items-center justify-center text-xl bg-gray-700 gap-1 text-sm"
      >
        Internal source
      </div>
      <button
        @click="setSelectedHotel"
        class="rounded text-white flex items-center justify-center min-w-[170px] bg-gray-700 hover:bg-gray-600 w-full max-w-[170px]"
        :class="selected && 'bg-gray-600'"
      >
        <span v-if="loading" class="material-symbols-rounded animate-spin">
          autorenew
        </span>
        <span v-else-if="contactsCount === 0" class="text-crewfareGreen"
          >Add Contact</span
        >
        <span v-else-if="contactsCount === 1" class="flex flex-col gap-2">
          <p v-if="contacts" class="text-sm">
            {{ contacts[0].name }}<br />
            <span class="max-w-[120px] block text-ellipsis overflow-hidden"
              >&lt;{{ contacts[0].email.toLowerCase().trim() }}&gt;</span
            >
          </p>
          <p class="text-sm">Add More</p>
        </span>
        <span v-else class="flex flex-col gap-2">
          <p v-if="contacts" class="text-sm">
            {{ contacts[0].name }}<br />
            <span class="max-w-[120px] block text-ellipsis overflow-hidden"
              >&lt;{{ contacts[0].email.toLowerCase().trim() }}&gt;</span
            >+{{ contactsCount - 1 }} {{  contactsCount - 1 > 1 ? 'contacts' : 'contact' }}
          </p>
          <div class="flex flex-col gap-1">
            <p class="text-sm">Add More</p>
          </div>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { firestore } from "@/utils/firebase";
import {
  addDoc,
  doc,
  getDocs,
  getDoc,
  query,
  collection,
  where,
} from "firebase/firestore";

export default {
  props: ["hotel", "selectedList", "selectedSet"],
  data() {
    return {
      loading: false,
      exists: false,
      hotelData: {},
    };
  },
  computed: {
    selected() {
      return this.selectedSet.has(this.hotel.id)
    },
    hotelName() {
      if (this.hotel.name) return this.hotel.name;
      return this.hotel.data().name;
    },
    selectedHotels() {
      return this.$store.state.selectedHotels;
    },
    updateHotels() {
      return this.$store.state.updateHotels;
    },
    contactsCount() {
      if (this.hotel.hasContact) {
        return this.hotel.data().contacts.length || 0;
      }
      if (this.hotel.crewfare) {
        return this.hotel.crewfare.data().contacts?.length || 0;
      }
      return 0;
    },
    contacts() {
      if (this.hotel.hasContact) return this.hotel.data().contacts;
      if (this.hotel.crewfare) return this.hotel.crewfare.data().contacts;
      return [];
    },
  },
  methods: {
    updateSelected() {
      if (this.contactsCount > 0) {
        console.log("HOtel", this.hotel);
        const distance =
          this.hotel.destinationInfo?.distanceFromDestination.value ||
          this.hotel.distance;
        const value = this.hotel.price?.lead.amount || 0;
        const hotelAttributes = this.hotel.data ? this.hotel.data() : {}
        const hotelData = {
          name: this.hotelName,
          id: this.hotel?.crewfare?.id || this.hotel.id,
          hotel_id: this.hotel.id,
          lat: this.hotel.mapMarker?.latLong.latitude || this.hotel.latitude || (this.hotel.data ? this.hotel.data().lat : ''),
          lng:
            this.hotel.mapMarker?.latLong.longitude || this.hotel.longitude ||
            (this.hotel.data ? this.hotel.data().lng : ''),
          image: this.hotel.propertyImage?.image.url || hotelAttributes.image||"",
          addrFull: hotelAttributes?.addrFull || hotelAttributes?.addr || "",
          distance,
          value,
          created_at: new Date(),
        };
        this.$emit("updateSelected", hotelData);
      }
    },
    async setSelectedHotel() {
      this.loading = true;
      const distance =
        this.hotel.destinationInfo?.distanceFromDestination.value ||
        this.hotel.distance;
      const value = this.hotel.price?.lead.amount || 0;
      const hotelData = {
        name: this.hotelName,
        id: this.hotel.id,
        hotel_id: this.hotel.id,
        lat:
          this.hotel.latitude ||
          this.hotel.mapMarker?.latLong.latitude ||
          this.hotel.data().lat,
        lng:
          this.hotel.longitude ||
          this.hotel.mapMarker?.latLong.longitude ||
          this.hotel.data().longitude,
        image: this.hotel.propertyImage?.image.url,
        distance,
        value,
      };
      if (this.hotel.propertyImage && !this.hotel.crewfare) {
        const hotelDataTmp = {
          ...hotelData,
          created_at: new Date(),
        };
        const hotelDataAdd = await addDoc(
          collection(firestore, "hotels"),
          hotelDataTmp
        );
        hotelData.id = hotelDataAdd.id;
      }
      if (this.hotel.crewfare) {
        hotelData.id = this.hotel.crewfare.id;
      }
      this.$emit("updateSelected", hotelData);

      if (1 || this.contactsCount === 0) {
        const hotelRef = doc(firestore, "hotels", hotelData.id);
        const hotel = await getDoc(hotelRef);
        this.$store.commit("setSelectedHotel", hotel);
        this.$store.commit("setContact", "new");
      }
      this.loading = false;
    },
  },
};
</script>
