import store from '@/store';
import { accountsApi } from './apis/accountsApi';

const updateAccount = async values => {
  const accountId = store.state.account.id;
  const updated = await accountsApi.update(accountId, values);
  if (updated.error) {
    alert('Error updating account');
    return;
  }
  const updatedAccountData = updated.data;
  store.commit('setAccount', updatedAccountData);
};

export { updateAccount };
