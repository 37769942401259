<template>
  <div class="bg-gray-800 w-full flex flex-col stage-height text-white">
    <div v-if="!rfp.data().location.lat" class="text-center py-8">
      This event doesn't have a location defined
    </div>
    <div v-else>
      <div class="p-4 flex gap-4 text-sm">
        <label class="flex gap-2 items-center" for="filter_new">
          <input type="checkbox" v-model="filter" value="New" id="filter_new" />
          <span>New</span>
        </label>
        <label class="flex gap-2 items-center" for="filter_sent">
          <input
            type="checkbox"
            v-model="filter"
            value="Sent"
            id="filter_sent"
          />
          <span>Sent</span>
        </label>
        <label class="flex gap-2 items-center" for="filter_negotiation">
          <input
            type="checkbox"
            v-model="filter"
            value="Negotiation"
            id="filter_negotiation"
          />
          <span>Negotiation</span>
        </label>
        <label class="flex gap-2 items-center" for="filter_accepted">
          <input
            type="checkbox"
            v-model="filter"
            value="Accepted"
            id="filter_accepted"
          />
          <span>Accepted</span>
        </label>
        <label class="flex gap-2 items-center" for="filter_signed">
          <input
            type="checkbox"
            v-model="filter"
            value="Signed"
            id="filter_signed"
          />
          <span>Signed</span>
        </label>
        <label class="flex gap-2 items-center" for="filter_declined">
          <input
            type="checkbox"
            v-model="filter"
            value="Declined"
            id="filter_declined"
          />
          <span>Declined</span>
        </label>
      </div>
      <GMapMap
        v-if="center"
        :center="center"
        :zoom="10"
        map-type-id="terrain"
        style="width: 100%; height: 600px"
      >
        <GMapMarker
          :clickable="true"
          :position="center"
          @click="openedMarker = 1"
        >
          <GMapInfoWindow
            @closeclick="openedMarker = null"
            :opened="openedMarker === 1"
          >
            <div class="text-black px-4 py-2">
              <p class="text-xl">{{ rfp.data().venue }}</p>
            </div>
          </GMapInfoWindow>
        </GMapMarker>
        <GMapCluster :imagePath="imagePath" :zoomOnClick="true">
          <GMapMarker
            v-for="(marker, index) in markers"
            :key="marker.hotel_name"
            :clickable="true"
            :icon="marker.icon"
            :position="{ lat: marker.lat, lng: marker.lng }"
            @click="openedMarker = marker.hotel_name"
          >
            <GMapInfoWindow
              @closeclick="openedMarker = null"
              :opened="openedMarker === marker.hotel_name"
            >
              <InfoWindow :marker="marker" />
            </GMapInfoWindow>
          </GMapMarker>
        </GMapCluster>
      </GMapMap>
      <div class="flex justify-center gap-2 my-4 text-xs">
        <div class="flex gap-2 items-center">
          <img src="/icons/pin-map.svg" class="h-[16px] w-[16px]" />
          <span>Event</span>
        </div>
        <div class="flex gap-2 items-center">
          <span class="h-[16px] w-[16px] bg-white"></span>
          <span>New</span>
        </div>
        <div class="flex gap-2 items-center">
          <span class="h-[16px] w-[16px] bg-black"></span>
          <span>Sent</span>
        </div>
        <div class="flex gap-2 items-center">
          <span class="h-[16px] w-[16px] bg-crewfareBlue"></span>
          <span>Negotiation</span>
        </div>
        <div class="flex gap-2 items-center">
          <span class="h-[16px] w-[16px] bg-sky-400"></span>
          <span>Accepted</span>
        </div>
        <div class="flex gap-2 items-center">
          <span class="h-[16px] w-[16px] bg-crewfareGreen"></span>
          <span>Signed</span>
        </div>
        <div class="flex gap-2 items-center">
          <span class="h-[16px] w-[16px] bg-red-600"></span>
          <span>Declined</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { InfoWindow } from "@/components/rfps/hotels";
import { firestore } from "@/utils/firebase";
import { getDoc, doc } from "firebase/firestore";

export default {
  props: ["rfp_hotels", "rfp", "rfp_hotels_filtered"],
  components: { InfoWindow },
  data() {
    return {
      openedMarker: null,
      showStatusHistoryDialog: false,
      center: null,
      markers: [],
      imagePath:
        "https://raw.githubusercontent.com/googlemaps/v3-utility-library/37c2a570c318122df57b83140f5f54665b9359e5/packages/markerclustererplus/images/m",
      filter: ["New", "Sent", "Negotiation", "Accepted", "Signed", "Declined"],
    };
  },
  watch: {
    filter() {
      this.getData();
    },
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    pill(status) {
      return RFPStatusPill(status);
    },
    async getData() {
      this.center = { ...this.rfp.data().location };
      document.title = `Launchpad - Crewfare - RFP - ${this.rfp.data().name}`;
      this.markers = [];
      this.rfp_hotels.forEach(async (rfp_hotel) => {
        let status = "";
        let color = "";
        switch (rfp_hotel.status.toLowerCase()) {
          case "new":
            status = "New";
            color = "white";
            break;
          case "sent":
          case "bid opened":
          case "progress saved":
            status = "Sent";
            color = "black";
            break;
          case "bid submited":
          case "crewfare counter":
          case "hotel countered":
            status = "Negotiation";
            color = "rgb(67 35 255)";
            break;
          case "signed by both sides":
          case "signed by crewfare":
          case "signed by the hotel":
            status = "Signed";
            color = "rgb(103 7 253)";
            break;
          case "accepted by hotel":
          case "accepted by crewfare":
            status = "Accepted";
            color = "rgb(56 189 248)";
            break;
          case "declined by hotel":
          case "declined by crewfare":
            status = "Declined";
            color = "rgb(224 36 36)";
            break;
        }
        if (!this.filter.includes(status)) return;
        const hotelRef = doc(firestore, "hotels", rfp_hotel.hotel_id);
        const hotel = await getDoc(hotelRef);

        const svgMarker = {
          path: "M-5,0a5,5 0 1,0 10,0a5,5 0 1,0 -10,0",
          fillColor: color,
          fillOpacity: 1,
          strokeWeight: 2,
          strokeColor: "rgb(255, 255, 255)",
          rotation: 0,
          scale: 2,
          anchor: new google.maps.Point(0, 20),
        };
        this.markers.push({
          ...hotel.data(),
          ...rfp_hotel,
          icon: svgMarker,
        });
      });
    },
  },
};
</script>
