<template>
  <div 
    class="animate-pulse gap-4 grid grid-cols-7 px-4 flex justify-between py-2 items-center text-white"
  >
    <div class="text-sm">
      <span class="flex text-sm w-[80px] h-[18px] bg-gray-500 rounded"></span>
    </div>
    <div class="text-sm">
      <span class="flex  text-sm w-[130px] h-[18px] bg-gray-500 rounded"></span>
    </div>
    <div class="text-sm">
      <span class="flex  text-sm w-[80px] h-[18px] bg-gray-500 rounded"></span>
    </div>
    <div class="text-sm">
      <span class="flex  text-sm w-[80px] h-[18px] bg-gray-500 rounded"></span>
    </div>
    <div class="text-sm">
      <span class="flex  text-sm w-[130px] h-[18px] bg-gray-500 rounded"></span>
    </div>
    <div class="text-sm">
      <span class="flex  text-sm w-[130px] h-[18px] bg-gray-500 rounded"></span>
    </div>
    <div class="flex gap-4 justify-end items-center">
      <span class="w-[52px] h-[28px] bg-gray-500 rounded">
      </span>
    </div>
  </div>
</template>
