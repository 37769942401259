import View from '@/components/default/View.vue';
import Form from './Form.vue';
import List from './List.vue';
import Preview from './Preview.vue';
import ListByType from './ListByType.vue';
import HotelsContactsList from './hotels/contacts/List.vue';
import HotelsContactsForm from './hotels/contacts/Form.vue';
import Reminders from './reminders/View.vue';
import RemindersList from './reminders/List.vue';
import RemindersForm from './reminders/Form.vue';
import ListByTypeHotels from './hotels/List.vue';
import ListByTypeMap from './hotels/Map.vue';
import Dashboard from '@/layouts/Dashboard.vue';

export const rfps = [
  {
    path: '/rfps',
    name: 'rfps',
    component: Dashboard,
    meta: {
      view: 'RFPs',
    },
    children: [
      {
        path: '',
        name: 'rfpsList',
        component: List,
      },
      {
        path: ':id',
        children: [
          {
            path: 'edit',
            name: 'rfpsForm',
            component: Form,
          },
          {
            path: 'preview',
            name: 'rfpsPreview',
            component: Preview,
          },
          {
            path: 'hotels/:hotel_id',
            children: [
              {
                path: 'contacts',
                name: 'rfpsHotelContact',
                component: HotelsContactsList,
              },
              {
                path: 'contacts/add',
                name: 'rfpsHotelContactAdd',
                component: HotelsContactsForm,
              },
              {
                path: 'contacts/:contact_id/edit',
                name: 'rfpsHotelContactEdit',
                component: HotelsContactsForm,
              },
            ],
          },
          {
            path: 'hotels',
            component: ListByType,
            children: [
              {
                path: '',
                name: 'rfpsHotels',
                component: ListByTypeHotels,
              },
              {
                path: 'map',
                name: 'rfpsMap',
                component: ListByTypeMap,
              },
            ],
          },
          {
            path: 'reminders',
            name: 'rfpsReminders',
            component: Reminders,
            children: [
              {
                path: '',
                name: 'rfpsRemindersList',
                component: RemindersList,
              },
              {
                path: 'add',
                name: 'rfpsRemindersAdd',
                component: RemindersForm,
              },
              {
                path: ':reminderId/edit',
                name: 'rfpsRemindersEdit',
                component: RemindersForm,
              },
            ],
          },
        ],
      },
    ],
  },
];
