<template>
  <div
    class="fixed bottom-0 right-0 z-[150] left-0 top-0 bg-gray-300 bg-opacity-40 flex items-center justify-center"
  >
    <div
      class="bg-gray-900 rounded min-w-[600px] flex flex-col gap-4 items-center p-4 relative text-white"
    >
      <div class="flex w-full flex-col gap-4">
        <div class="flex w-full justify-between gap-4">
          <p class="text-xl">Adding hotel to RFP</p>
          <span
            @click="closeDialog"
            class="cursor-pointer text-red-400 material-symbols-rounded"
          >
            close
          </span>
        </div>
        <div class="overflow-y-auto max-h-[500px] flex flex-col">
          <p v-if="loading" class="my-4 text-white">Fetching RFPs...</p>
          <RfpDialogItem
            v-for="(rfp, index) of allRfps"
            :key="index"
            :rfp="rfp"
          />
        </div>
        <div class="flex flex-col gap-2">
          <div class="flex justify-end items-center">
            <button
              class="text-red-400 hover:text-red-600"
              @click="closeDialog"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { firestore } from "@/utils/firebase";
import {
  getDocs,
  query,
  collection,
  orderBy,
  where,
  limit,
} from "firebase/firestore";
import RfpDialogItem from "./RfpDialogItem.vue";

export default {
  components: { RfpDialogItem },
  props: ["hotel"],
  data() {
    return {
      allRfps: [],
      loading: false,
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  mounted() {
    this.getAllRfps();
  },
  methods: {
    closeDialog() {
      this.createDialogToggle = false
      this.$emit("close");
    },
    async getAllRfps() {
      this.loading = true;
      let qRfps = query(
        collection(firestore, "rfps"),
        where("status", "==", "Sent"),
        orderBy("updated_at", "desc")
      );
      const rfps = await getDocs(qRfps);
      const rfpList = rfps.docs;
      const parsedRfps = this.parseData(rfpList);
      this.allRfps = parsedRfps;
      this.loading = false;
    },
    parseData(list) {
      const rfpsList = [];
      list.forEach((rfp) => {
        rfpsList.push({
          id: rfp.id,
          name: rfp.data().name,
          event_name: rfp.data().event_name,
          venue: rfp.data().venue,
          hotel_goal: rfp.data().hotel_goal,
          room_goal: rfp.data().room_goal,
          created_by: rfp.data().created_by,
          status: rfp.data().status,
          startDate: rfp.data().startDate,
          endDate: rfp.data().endDate,
          created_at: rfp.data().created_at,
          updated_at: rfp.data().updated_at,
        });
      });
      return rfpsList;
    },
  },
};
</script>
