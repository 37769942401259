<template>
  <div
    v-if="rfp"
    class="bg-gray-800 w-full flex flex-col stage-height text-white"
  >
    <div class="p-4 flex justify-between bg-gray-900 items-center">
      <div class="flex gap-4">
        <router-link
          :to="{ name: 'rfpsHotels' }"
          class="material-symbols-rounded hover:text-crewfareGreen"
        >
          chevron_left
        </router-link>
        <div class="flex flex-col gap-2">
          <p class="text-xl">{{ rfp.data().name || "New RFP" }}</p>
        </div>
      </div>
      <div class="flex items-center gap-4">
        <router-link
          :to="{ name: 'rfpsRemindersAdd', params: { id: rfp.id } }"
          class="green-button"
          >Create Reminder</router-link
        >
      </div>
    </div>
    <div>
      <table
        v-if="reminders.length > 0"
        class="w-full mt-4 text-white px-4 justify-between py-2 border border-gray-700 border-x-0 border-t-0"
      >
        <tr class="border-b border-gray-700">
          <th class="text-left px-4 py-4 flex gap-2 items-start"></th>
          <th class="text-left px-4">Status</th>
          <th class="text-left px-4">Days</th>
          <th class="text-left px-4">Last Update At</th>
          <th class="justify-end px-4"></th>
        </tr>
        <tbody class="divide-y divide-gray-700">
          <ListItem
            v-for="reminder in reminders"
            :reminder="reminder"
            :key="reminder.id"
            @getData="getData"
          />
        </tbody>
      </table>
      <div class="mt-6 text-center">
        There are no reminders setted on this RFP
      </div>
    </div>
  </div>
</template>

<script>
import { firestore } from "@/utils/firebase";
import {
  where,
  getDocs,
  getDoc,
  doc,
  query,
  collection,
} from "firebase/firestore";
import { ListItem } from "@/components/rfps/reminders";

export default {
  components: {
    ListItem,
  },
  data() {
    return {
      rfp: null,
      reminders: [],
    };
  },
  computed: {},
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const rfpRef = doc(firestore, "rfps", this.$route.params.id);
      this.rfp = await getDoc(rfpRef);
      const qRFPReminder = query(
        collection(firestore, "rfp_reminders"),
        where("rfp", "==", this.$route.params.id)
      );
      const RFPReminder = await getDocs(qRFPReminder);
      this.reminders = RFPReminder.docs;

      document.title = `Launchpad - Crewfare - RFP - ${
        this.rfp.data().name
      } - Reminder`;
    },
  },
};
</script>
