<template>
  <main>
    <Header />
    <div class="flex gap-4">
      <Menu />
      <RouterView />
    </div>
  </main>
</template>

<script>
import { RouterView } from "vue-router";
import Header from "@/components/default/Header.vue";
import Menu from "@/components/default/Menu.vue";

export default {
  components: {
    Header,
    Menu,
    RouterView,
  },
};
</script>
