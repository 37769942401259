<template>
  <div
    class="print:hidden px-4 pb-4 text-white border-b border-b-white text-sm"
  >
    <p>Hints:</p>
    <p>
      - All agreed upon terms have been input into the agreement below. 
    </p>
    <p>
      - You can adjust the agreement by leaving redlines, making comments, or renegotiating terms.
    </p>
    <p>
      - If the agreement is good to go, you can e-sign below or download and sign then return the agreement to your contact.
    </p>
    <p v-if="!isNSO">
      - For any questions click <ButtonNotesDialog
            type="Questions on contract"
            :subtitle="proposal.name"
            title="Questions on Contract"
            :id="`${proposal.id}-contract`"
            :rfp="proposal.id"
            field="Contract"
            :hotel_id="proposal.hotel_id"
            text="Here"
            class="text-yellow-200 print:text-yellow-900"
            :no_default_classes="true"
          />
      
    </p>
  </div>
</template>
<script>
import ButtonNotesDialog from "@/components/default/ButtonNotesDialog.vue";
export default {
  name: 'ContractHints',
  props: ['proposal'],
  components: {
    ButtonNotesDialog,
  },
  computed: {
    isNSO(){
      return this.account.type.toLowerCase() === "nso";
    },
    rfpLink(){
      const link = `/messages/${this.proposal.hotelId}`;
      return link;
    },
    account() {
      return this.$store.state.account;
    },
  },
  data(){
    return {}
  }
}
</script>

