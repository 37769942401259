<template>
  <div>
    <div class="my-2">
      <p class="text-2xl">Update my password</p>
    </div>
    <div class="gap-4 flex flex-col">
      <div class="flex flex-col gap-2">
        <label>Old password</label>
        <input class="w-full bg-gray-700 rounded px-4" type="text" />
      </div>
      <div class="flex flex-col gap-2">
        <label>New password</label>
        <input class="w-full bg-gray-700 rounded px-4" type="text" />
      </div>
      <div class="flex flex-col gap-2">
        <label>Confirm new password</label>
        <input class="w-full bg-gray-700 rounded px-4" type="text" />
      </div>
      <div>
        <button class="green-button">Save</button>
      </div>
    </div>
  </div>
</template>
