<template>
  <div class="border-y flex text-white text-left flex-col divide-y">
    <div>
      <input
        type="search"
        class="w-full bg-gray-700 px-4"
        v-model="search"
        @input="doSearch"
        placeholder="Search"
      />
    </div>
    <button
      class="hover:bg-gray-600 w-full py-3 px-4 text-left"
      v-for="(hotel, index) in hotelsWithChain"
      :key="index"
      @click="chainMessage(hotel)"
    >
      Talk to the NSO of {{ hotel.data().chain }}
    </button>
    <button
      class="hover:bg-gray-600 w-full py-3 text-left px-4"
      v-for="(rfp, index) in rfps"
      :key="index"
      @click="rfpMessage(rfp)"
    >
      Talk about {{ rfp.name }}
    </button>
    <div v-if="loading">
      <p class="py-2 text-center">Fetching accounts...</p>
    </div>
    <div v-else>
      <button
        class="hover:bg-gray-600 w-full py-3 text-left px-4 flex flex-col"
        v-for="(account, index) in accountsFiltered"
        :key="index"
        @click="accountMessage(account)"
      >
        <span>{{ account.name }} - {{ account.type }}</span>
        <span class="text-xs">&lt;{{ account.email }}&gt;</span>
      </button>
    </div>
  </div>
</template>

<script>
import {
  getDocs,
  getDoc,
  doc,
  query,
  collection,
  where,
  orderBy,
} from "firebase/firestore";
import { firestore } from "@/utils/firebase";
import {
  sendMessageNSO,
  sendRFPMessage,
  sendDirectMessage,
} from "@/utils/messages.jsx";

export default {
  computed: {
    account() {
      return this.$store.state.account;
    },
    doSearch() {
      if (this.search.length > 0) {
        this.accountsFiltered = [...this.accountsFiltered].filter(
          (account) =>
            account
              .data()
              .name.toLowerCase()
              .indexOf(this.search.toLowerCase()) >= 0
        );
      } else {
        this.accountsFiltered = this.accounts;
      }
    },
  },
  data() {
    return {
      loading: false,
      search: "",
      rfps: [],
      hotels: [],
      accounts: [],
      accountsFiltered: [],
      chain: null,
    };
  },
  methods: {
    accountMessage(account) {
      sendDirectMessage(
        [account.id, this.account.id],
        [account, this.account]
      );
      this.$emit("toggleMessage");
    },
    chainMessage(hotel) {
      sendMessageNSO(this.account, hotel.data().chain, hotel);
      this.$emit("toggleMessage");
    },
    crewfareMessage() {
      sendMessageCrewfare(this.account);
      this.$emit("toggleMessage");
    },
    rfpMessage(rfp) {
      sendRFPMessage(rfp, this.account);
      this.$emit("toggleMessage");
    },
    hotelsWithChain() {
      return this.hotels.filter((hotel) => hotel.data().chain);
    },
  },
  async mounted() {
    this.loading = true;
    const statusList = [
      "sent",
      "bid viewed",
      "signed by the hotel",
      "signed by crewfare",
      "counter sent",
      "bid opened",
      "hotel countered",
      "progress saved",
      "crewfare counter",
      "accepted by hotel",
      "accepted by crewfare",
      "declined by hotel",
      "declined by crewfare",
      "bid submited",
      "signed by both sides",
      "update bid terms",
      "accepted contract updates",
    ];

    let qRfps = query(
      collection(firestore, "rfp_hotel"),
      where("hotel_id", "in", this.account.hotelIds),
      orderBy("updated_at", "desc")
    );
    const rfps = await getDocs(qRfps);
    rfps.docs
      .filter((rfp) => statusList.includes(rfp.data().status.toLowerCase()))
      .forEach((rfp) => {
        this.rfps.push({
          id: rfp.id,
          name: rfp.data().name,
          hotel_id: rfp.data().hotel_id,
          hotel_name: rfp.data().hotel_name,
        });
      });

    for (const hotelId of this.account.hotelIds) {
      const hotelRef = doc(firestore, "hotels", hotelId);
      const hotel = await getDoc(hotelRef);
      this.hotels.push(hotel);
    }

    let qAccounts = query(
      collection(firestore, "accounts"),
      where("type", "in", ["Admin", "Internal"])
    );
    const accounts = await getDocs(qAccounts);
    this.accounts = accounts.docs.map((doc) => {
      return { ...doc.data(), id: doc.id };
    });
    this.accountsFiltered = this.accounts;
    this.loading = false;
  },
};
</script>
