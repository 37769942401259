<template>
  <div class="flex flex-col gap-1 w-full">
    <div
      class="text-sm font-bold flex justify-between items-center mb-2"
      :class="has_changes && 'text-yellow-500'"
    >
      <div class="flex gap-2 items-center">
        <span
          class="material-symbols-rounded text-[16px]"
          :class="checked ? 'text-crewfareGreen' : 'text-white'"
        >
          {{ checked ? "check_box" : "check_box_outline_blank" }}
        </span>
        Rebate:
      </div>

      <div class="flex gap-4 print:hidden">
        <ButtonNotesDialog
          v-if="!isNSO"
          type="Comment on concession"
          :subtitle="rfp.name"
          title="Comments at Rebate"
          :id="`${rfp.id}-rebate`"
          :hotel_id="rfp.hotel_id"
          :rfp="rfp.id"
          field="Rebate"
          text="Comment"
        />
        <span
          v-if="is_locked"
          class="text-[18px] flex items-center px-4 print:px-0 material-symbols-rounded text-crewfarePurple py-1"
        >
          lock
        </span>
      </div>
    </div>
    <select
      :disabled="is_locked || is_blocked"
      class="w-full bg-gray-700 print:appearance-none h-[40px] px-4 print:px-0 rounded"
      v-model="rebate"
      @change="save_rebate"
      :class="
        has_changes
          ? 'bg-yellow-500 text-gray-900'
          : 'bg-gray-700 text-white print:text-black'
      "
    >
      <option>Select</option>
      <option>$10</option>
      <option>$5</option>
      <option>$4</option>
      <option>$3</option>
      <option>$2</option>
      <option>Other</option>
      <option>No Rebate</option>
    </select>
    <div class="flex gap-4">
      <div
        for="rebatePerNight"
        class="flex gap-1 w-full items-center"
        :class="has_changes ? 'text-yellow-500' : 'text-white print:text-black'"
      >
        <input
          :disabled="is_blocked || is_locked"
          @change="save_type"
          id="rebatePerNight"
          type="radio"
          value="Per Night"
          v-model="type"
        />
        <span>Per Night</span>
      </div>
      <div
        for="rebatePerRoom"
        class="flex gap-1 w-full items-center"
        :class="has_changes ? 'text-yellow-500' : 'text-white print:text-black'"
      >
        <input
          :disabled="is_blocked || is_locked"
          @change="save_type"
          id="rebatePerRoom"
          type="radio"
          value="Per Room"
          v-model="type"
        />
        <span>Per Room</span>
      </div>
    </div>
    <small v-if="has_changes" class="text-yellow-500">{{ changes_by }}</small>
    <div v-if="rebate === 'Other'" class="flex flex-col gap-1 mt-4">
      <div class="text-sm font-bold" :class="has_changes && 'text-yellow-500'">
        Other Amount:
      </div>
      <input
        :disabled="is_blocked || is_locked"
        type="number"
        v-model="rebate_amount"
        @blur="save_rebate_amount"
        class="w-full bg-gray-700 rounded px-4 print:px-0"
        @keypress="isNumber($event)"
        :class="
          has_changes
            ? 'bg-yellow-500 text-gray-900'
            : 'bg-gray-700 text-white print:text-black'
        "
      />
    </div>
  </div>
</template>

<script>
import { noteAdded, noteDone } from "@/utils/messages.jsx";
import {
  saveField,
  checkIsLocked,
  checkHasChanges,
  getChangesBy,
} from "@/utils/rfp.jsx";
import ButtonNotesDialog from "@/components/default/ButtonNotesDialog.vue";

export default {
  props: ["rfp", "account", "is_blocked"],
  components: {
    ButtonNotesDialog,
  },
  data() {
    return {
      rebate: "",
      rebate_amount: "",
      type: "",
      is_locked: false,
      changes_by: null,
      has_changes: null,
    };
  },
  computed: {
    checked() {
      return this.rebate === this.rfp.rebate;
    },
    rfpSession() {
      return this.$store.state.rfpSession;
    },
    isNSO() {
      return this.account.type.toLowerCase() === "nso";
    },
  },
  watch: {
    rfpSession() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.rebate = this.rfp.rebate;
      this.rebate_amount = this.rfp.rebateAmount;
      this.type = this.rfp.rebateType;
      if (this.account) {
        const account_type = this.account.type.toLowerCase();
        this.has_changes =
          checkHasChanges(this.rfp, "Rebate", account_type) ||
          checkHasChanges(this.rfp, "Rebate Type", account_type);
        this.is_locked = checkIsLocked(this.rfp, "rabete");
        if (this.has_changes) {
          this.changes_by = getChangesBy(this.has_changes, this.rfp);
        }
      }
    },
    async note_done() {
      noteDone("Rebate", this.rfp);
      this.$emit("refreshData");
    },
    async note_added() {
      noteAdded("Rebate", this.rfp);
      this.$emit("refreshData");
    },
    async save_rebate() {
      if (this.is_blocked) return;
      await saveField(
        this.rfp,
        {
          label: "Rebate",
          before: this.rfp.rebate,
          after: this.rebate || "",
        },
        {
          rebate: this.rebate || "",
        },
        "rebate"
      );
      this.$emit("refreshData");
    },
    async save_rebate_amount() {
      if (this.is_blocked) return;
      await saveField(
        this.rfp,
        {
          label: "Rebate Amount",
          before: this.rfp.rebateAmount,
          after: this.rebate_amount || "",
        },
        {
          rebateAmount: this.rebate_amount || "",
        },
        "rebateAmount"
      );
      this.$emit("refreshData");
    },
    async save_type() {
      if (this.is_blocked) return;
      await saveField(
        this.rfp,
        {
          label: "Rebate Type",
          before: this.rfp.rebateType,
          after: this.type || "",
        },
        {
          rebateType: this.type || "",
        },
        "rebateType"
      );
      this.$emit("refreshData");
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>
