<template>
  <div class="px-4 flex flex-col gap-2 justify-between py-2">
    <label
      @click="updateSelected"
      class="flex gap-4 group items-center"
      :class="
        contactsCount === 0
          ? 'opacity-80'
          : 'hover:text-gray-400 cursor-pointer'
      "
    >
      <div v-if="contactsCount > 0" class="flex items-center">
        <span
          v-if="selected"
          class="text-gray-800 bg-crewfareGreen rounded w-[18px] h-[18px] flex items-center justify-center"
        >
          <img src="/icons/small-check.svg" />
        </span>
        <span
          v-else
          class="border border-black rounded w-[18px] h-[18px]"
        ></span>
      </div>
      <div v-else class="flex items-center">
        <span class="opacity-50 border rounded w-[18px] h-[18px]"></span>
      </div>
      <div
        class="max-h-[90px] max-w-[90px] min-h-[90px] min-w-[90px] bg-gray-200 overflow-hidden relative rounded flex justify-center"
      >
        <img
          v-if="hotel.propertyImage"
          :src="hotel.propertyImage.image.url"
          class="h-[90px] !max-w-none duration-500 rounded"
          :class="contactsCount > 0 && 'group-hover:scale-125'"
        />
      </div>
      <div class="py-2 pr-2 w-full">
        <div class="flex flex-col gap-1">
          <p class="text-xl font-bold text-ellipsis overflow-hidden">
            {{ hotelName }}
          </p>
          <div
            v-if="hotel.distance > 0"
            class="flex items-center gap-2 text-sm"
          >
            <img src="/icons/pin.svg" />
            {{ hotel.distance?.toFixed(2) || 0 }} mi
          </div>
          <div v-if="hotel.star" class="flex items-center gap-2 text-sm">
            <span class="flex items-center gap-1">
              <img
                :src="`/icons/star${hotel.star >= 1 ? '-filled' : ''}.svg`"
              />
              <img
                :src="`/icons/star${hotel.star >= 2 ? '-filled' : ''}.svg`"
              />
              <img
                :src="`/icons/star${hotel.star >= 3 ? '-filled' : ''}.svg`"
              />
              <img
                :src="`/icons/star${hotel.star >= 4 ? '-filled' : ''}.svg`"
              />
              <img
                :src="`/icons/star${hotel.star >= 5 ? '-filled' : ''}.svg`"
              />
            </span>
            {{ hotel.star }} rating
          </div>
        </div>
      </div>
    </label>
    <div class="flex gap-4">
      <div
        v-if="hotel.price"
        class="rounded text-crewfareGreen flex w-full items-center justify-center text-xl bg-gray-700 gap-1 py-2 px-4"
        :class="contactsCount === 0 ? 'opacity-60' : selected && 'bg-gray-600'"
      >
        <strong>{{ hotel.price.lead.formatted }}</strong>
        per night
      </div>
      <div
        v-else
        class="rounded text-crewfareGreen flex w-full items-center justify-center text-xl bg-gray-700 gap-1 text-sm"
      >
        Internal source
      </div>
      <button
        @click="setSelectedHotel"
        class="rounded text-white flex items-center justify-center bg-gray-700 hover:bg-gray-600 w-full py-2 px-4"
        :class="selected && 'bg-gray-600'"
      >
        <span v-if="loading" class="material-symbols-rounded animate-spin">
          autorenew
        </span>
        <span v-else-if="contactsCount === 0" class="text-crewfareGreen"
          >Add Contact</span
        >
        <span v-else-if="contactsCount === 1" class="flex flex-col gap-2">
          <p class="text-sm whitespace-nowrap flex items-center gap-2">
            {{ contacts[0].name }}
            <span
              class="max-w-[120px] inline-block text-ellipsis overflow-hidden"
            >
              &lt;{{ contacts[0].email.toLowerCase().trim() }}&gt;
            </span>
          </p>
          <p class="text-sm">Add More</p>
        </span>
        <span v-else class="flex flex-col gap-2">
          <p class="text-sm whitespace-nowrap flex items-center gap-2">
            {{ contacts[0].name }}
            <span
              class="max-w-[120px] inline-block text-ellipsis overflow-hidden"
            >
              &lt;{{ contacts[0].email.toLowerCase().trim() }}&gt;
            </span>
          </p>
          <div class="flex flex-col gap-1">
            <p class="text-sm">+{{ contactsCount - 1 }} contacts</p>
          </div>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { firestore } from "@/utils/firebase";
import {
  addDoc,
  doc,
  getDocs,
  getDoc,
  query,
  collection,
  where,
} from "firebase/firestore";

export default {
  props: ["hotel", "selected"],
  data() {
    return {
      contacts: [],
      loading: false,
      exists: false,
      hotelData: {},
    };
  },
  computed: {
    hotelName() {
      if (!this.hotel) return;
      if (this.hotel.name) return this.hotel.name;
      return "";
    },
    selectedHotels() {
      return this.$store.state.selectedHotels;
    },
    updateHotels() {
      return this.$store.state.updateHotels;
    },
    contactsCount() {
      return this.contacts?.length;
    },
  },
  watch: {
    updateHotels() {
      this.getData();
    },
  },
  async mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const qHotel = query(
        collection(firestore, "hotels"),
        where("name", "==", this.hotelName)
      );
      const hotels = await getDocs(qHotel);
      if (hotels.docs.length > 0) {
        this.hotelData = hotels.docs[0];
        this.exists = true;
        this.hotel.hasContact = this.hotelData.data().contacts?.length > 0;
        this.contacts = this.hotelData.data().contacts || [];
      }
    },
    updateSelected() {
      if (this.contactsCount > 0) {
        const distance =
          this.hotel.destinationInfo?.distanceFromDestination.value ||
          this.hotel.distance;
        const value = this.hotel.price?.lead.amount || 0;
        this.hotelData.value = value;
        this.hotelData.distance = distance;
        this.$emit("updateSelected", this.hotelData);
      }
    },
    async setSelectedHotel() {
      this.loading = true;
      if (!this.exists) {
        const hotelData = {
          name: this.hotelName,
          hotel_id: this.hotel.id,
          lat: this.hotel.mapMarker.latLong.latitude,
          lng: this.hotel.mapMarker.latLong.longitude,
          image: this.hotel.propertyImage.image.url,
          created_at: new Date(),
        };
        const contact = await addDoc(
          collection(firestore, "hotels"),
          hotelData
        );
        const hotelRef = doc(firestore, "hotels", contact.id);
        this.hotelData = await getDoc(hotelRef);
      }
      this.$store.commit("setSelectedHotel", this.hotelData);
      if (this.contactsCount === 0) {
        this.$store.commit("setContact", "new");
      }
      this.loading = false;
    },
  },
};
</script>
