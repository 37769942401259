<template>
  <div class="bg-gray-800 w-full flex flex-col stage-height text-white">
    <div class="p-4 flex justify-between bg-gray-900 items-center">
      <h1 class="text-xl text-white">Files</h1>
    </div>
    <div class="flex flex-col rounded pb-4 relative">
      <div class="flex p-4 items-center justify-between sticky top-0">
        <div class="text-crewfareGreen">
          <strong class="rounded-full bg-black px-3 py-1">{{
            rfps.length
          }}</strong>
          RFPs
        </div>
      </div>
      <div v-if="rfps.length > 0">
        <table
          class="w-full px-4 justify-between py-2 border border-gray-700 border-x-0 border-t-0"
        >
          <tr class="border-b border-gray-700">
            <th class="text-white text-left p-4">Event Dates</th>
            <th class="text-white text-left px-4">Cut Off Date</th>
            <th class="text-white text-left px-4">RFP Name</th>
            <th class="text-white text-left px-4">Contract</th>
            <th class="text-white text-left px-4">Travel Coordinator</th>
          </tr>
          <tbody class="divide-y divide-gray-700">
            <ContactListItem
              v-for="(rfp, index) in rfps"
              :key="index"
              :rfp="rfp"
              @getData="getData"
            />
          </tbody>
        </table>
      </div>
      <div v-else>
        <p class="text-white my-4 text-center">
          There are no RFPs at the moment.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getDocs,
  query,
  or,
  collection,
  where,
  orderBy,
} from "firebase/firestore";
import { firestore } from "@/utils/firebase";
import ContactListItem from "@/components/files/ContactListItem.vue";

export default {
  components: {
    ContactListItem,
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  data() {
    return {
      loading: false,
      messages: [],
      rfps: [],
      group: null,
      chain: null,
    };
  },
  watch: {
    async account() {
      if (this.account.hotelIds && this.account.hotelIds.length > 0) {
        await this.getData();
      }
    },
    async $route() {
      await this.getData();
    },
  },
  async mounted() {
    this.getData();
    document.title = `Launchpad - Crewfare - RFPs`;
  },
  methods: {
    async getData() {
      this.loading = true;
      if (this.account.hotelIds && this.account.hotelIds.length > 0) {
        this.getDataUser();
      }
      this.loading = false;
    },
    async getDataUser() {
      let qRfps = query(
        collection(firestore, "rfp_hotel"),
        where("status", "==", "Signed by both sides"),
        where("hotel_id", "in", this.account.hotelIds),
        orderBy("updated_at", "desc")
      );
      const rfps = await getDocs(qRfps);
      const rfpsListOrdered = rfps.docs.sort(
        (a, b) =>
          Number(a.data().startDate.toDate()) <
          Number(b.data().startDate.toDate())
      );
      this.rfps = rfpsListOrdered;
    },
  },
};
</script>
