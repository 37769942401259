<template>
  <div class="flex flex-col gap-1">
    <div
      class="text-sm font-bold flex justify-between items-center mb-2"
      :class="has_changes && 'text-yellow-500'"
    >
      Additional Terms (Optional)
      <div class="flex gap-4 print:hidden">
        <ButtonNotesDialog
          v-if="!isNSO"
          type="Comment on concession"
          :subtitle="rfp.name"
          title="Comments at Additional Terms"
          :hotel_id="rfp.hotel_id"
          :id="`${rfp.id}-additional-terms`"
          :rfp="rfp.id"
          field="Additional Terms"
          text="Comment"
        />
      </div>
    </div>
    <div
      ref="termsInput"
      @keydown="has_changes = true"
      @blur="save"
      :contenteditable="!is_blocked"
      class="w-full bg-gray-700 min-h-[42px] rounded px-4 print:px-0 py-2"
      :class="
        has_changes
          ? 'bg-yellow-500 text-gray-900'
          : 'bg-gray-700 text-white print:text-black'
      "
    ></div>
    <small v-if="has_changes" class="text-yellow-500">{{ changes_by }}</small>
  </div>
</template>

<script>
import { noteAdded, noteDone } from "@/utils/messages.jsx";
import { saveField, checkHasChanges, getChangesBy } from "@/utils/rfp.jsx";
import ButtonNotesDialog from "@/components/default/ButtonNotesDialog.vue";

export default {
  props: ["rfp", "account", "is_blocked"],
  components: {
    ButtonNotesDialog,
  },
  data() {
    return {
      value: "",
      changes_by: null,
      has_changes: null,
    };
  },
  computed: {
    rfpSession() {
      return this.$store.state.rfpSession;
    },
    isNSO() {
      return this.account.type.toLowerCase() === "nso";
    },
  },
  watch: {
    rfpSession() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.value = this.rfp.terms;
      this.$refs.termsInput.innerHTML = this.value || "";
      if (this.account) {
        const account_type = this.account.type.toLowerCase();
        this.has_changes = checkHasChanges(
          this.rfp,
          "Additional Terms",
          account_type
        );
        if (this.has_changes) {
          this.changes_by = getChangesBy(this.has_changes, this.rfp);
        }
      }
    },
    async note_done() {
      noteDone("Additional Terms", this.rfp);
      this.$emit("refreshData");
    },
    async note_added() {
      noteAdded("Additional Terms", this.rfp);
      this.$emit("refreshData");
    },
    async save() {
      if (this.is_blocked) return;
      const value = this.$refs.termsInput.innerHTML;
      saveField(
        this.rfp,
        {
          label: "Additional Terms",
          before: this.rfp.terms,
          after: value || "",
        },
        {
          terms: value || "",
        },
        "terms"
      );
      this.$emit("refreshData");
    },
  },
};
</script>
