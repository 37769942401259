<template>
  <div class="fixed bottom-4 right-4 z-[100]">
    <div
      v-if="messagesStatus"
      class="fixed bg-gray-900 right-0 top-0 flex bottom-0 w-[500px] border-l border-l-white flex-col"
    >
      <div class="flex flex-col z-50 min-h-full">
        <div class="text-xl text-white p-4 leading-10 flex justify-between">
          <span>Messages</span>
          <span class="flex gap-2 items-center">
            <button
              v-if="!newMessage"
              @click="newMessage = true"
              class="text-green-200 green-button-small"
            >
              <span class="material-symbols-rounded text-[16px]">add</span>
              New Message
            </button>
            <button
              v-else
              @click="newMessage = false"
              class="text-green-200 red-button-small"
            >
              <span class="material-symbols-rounded text-[16px]">close</span>
              Back
            </button>
            <button
              @click="showMessages"
              class="material-symbols-rounded text-red-200 hover:text-red-400"
            >
              close
            </button>
          </span>
        </div>
        <div v-if="newMessage" class="flex flex-col flex-1 overflow-auto">
          <AdminInternal
            v-if="
              ['admin', 'internal'].includes(account.type.toLowerCase())
            "
            @toggleMessage="newMessage = false"
          />
          <Hotel
            v-if="['contact'].includes(account.type.toLowerCase())"
            @toggleMessage="newMessage = false"
          />
          <NSO
            v-if="
              ['nso', 'lead manager'].includes(
                account.type.toLowerCase()
              )
            "
            @toggleMessage="newMessage = false"
          />
        </div>
        <div v-else class="flex flex-col flex-1">
          <div class="flex-grow overflow-y-auto">
            <div
              v-if="messages.length < 1"
              class="text-white flex min-h-full justify-center items-center text-center"
            >
              There no messages at the moment.
            </div>
            <ul v-else class="divide-y">
              <MessagesListItem
                v-for="message in messages"
                :key="message.id"
                :message="message"
                @showMessages="showMessages"
              />
            </ul>
          </div>
          <router-link
            :to="{ name: 'messagesList' }"
            class="text-center py-4 block text-crewfareGreen hover:bg-gray-800"
          >
            View all
          </router-link>
        </div>
      </div>
    </div>
    <FloatingMessages />
  </div>
</template>

<script>
import { firestore } from "@/utils/firebase";
import {
  onSnapshot,
  orderBy,
  query,
  getDocs,
  getDoc,
  or,
  doc,
  collection,
  where,
  setDoc,
} from "firebase/firestore";
import MessagesListItem from "./MessagesListItem.vue";
import FloatingMessages from "./FloatingMessages.vue";
import { AdminInternal, Hotel, NSO } from "@/components/messages/options/";
import { getHotelsIdsByGroup } from "@/utils/hotel.jsx";

export default {
  components: {
    MessagesListItem,
    FloatingMessages,
    AdminInternal,
    Hotel,
    NSO,
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
    newMessagesCount() {
      return this.messages.filter(
        (message) =>
          message.data().new_message &&
          message.data().new_message_by_id !== this.account.id
      ).length;
    },
  },
  data() {
    return {
      newMessage: false,
      messagesStatus: false,
      toggleList: false,
      messages: [],
    };
  },
  methods: {
    showMessages() {
      this.messagesStatus = !this.messagesStatus;
      if (!this.messagesStatus) this.newMessage = false;
      this.updateUnread();
    },
    async updateUnread() {
      const queryData = [];
      if (this.account.hotelIds) {
        queryData.push(where("hotel_id", "in", this.account.hotelIds));
      } else {
        queryData.push(where("account_id", "==", this.account.id));
      }
      const qUnreadMessage = query(
        collection(firestore, "message_notification"),
        where("unread", "==", true),
        ...queryData
      );
      const unreadMessages = await getDocs(qUnreadMessage);
      unreadMessages.docs.forEach((message_notification) => {
        setDoc(
          doc(firestore, "message_notification", message_notification.id),
          {
            ...message_notification.data(),
            unread: false,
          }
        );
      });
    },
    async getData() {
      this.loading = true;
      switch (this.account.type.toLowerCase()) {
        case "internal":
        case "admin":
          await this.getUserMessages();
          break;
        case "group manager":
          await this.getGroupMessages();
          break;
        case "nso":
          await this.getNSOMessages();
          break;
      }
      if (this.account.hotelIds && this.account.hotelIds.length > 0) {
        this.getHotelMessages();
      }
      this.loading = false;
    },
    async getHotelMessages() {
      const chains = [];
      for (const hotel of this.account.hotelIds) {
        const hotelRef = doc(firestore, "hotels", hotel);
        const hotelData = await getDoc(hotelRef);
        if (hotelData.exists() && hotelData.data().chain) {
          chains.push(hotelData.data().chain);
        }
      }
      let chainClause = [];
      if (chains.length > 0)
        chainClause.push(where("chain_all", "array-contains-any", chains));
      onSnapshot(
        query(
          collection(firestore, "messages"),
          or(
            where("accounts", "array-contains-any", [this.account.id]),
            where("hotel_id", "array-contains-any", this.account.hotelIds),
            ...chainClause
          ),
          orderBy("new_message_date", "desc")
        ),
        async (doc) => {
          const messages = doc.docs;
          this.messages = messages;
        }
      );
    },
    getUserMessages() {
      onSnapshot(
        query(
          collection(firestore, "messages"),
          or(
            where("accounts", "array-contains-any", [
              this.account.email,
            ]),
            where("internal", "==", true)
          ),
          orderBy("new_message_date", "desc")
        ),
        async (doc) => {
          const messages = doc.docs;
          this.messages = messages;
        }
      );
    },
    async getGroupMessages() {
      const hotel_ids = await getHotelsIdsByGroup();
      const hotels_filtered = hotel_ids.splice(0, 30);

      onSnapshot(
        query(
          collection(firestore, "messages"),
          where("hotel_id", "in", hotels_filtered),
          orderBy("new_message_date", "desc")
        ),
        async (doc) => {
          const messages = doc.docs;
          this.messages = messages;
        }
      );
    },
    async getNSOMessages() {
      let qChain = query(
        collection(firestore, "chains"),
        where("manager_ids", "array-contains-any", [this.account.id])
      );

      const chains = await getDocs(qChain);
      this.chains = chains.docs.map((chain) => chain.data().name);
      if (this.chains.length === 0) return;
      onSnapshot(
        query(
          collection(firestore, "messages"),
          or(
            where("chain_single", "in", this.chains),
            where("chain_all", "in", this.chains),
            where("account_id", "array-contains-any", [this.account.id])
          ),
          orderBy("new_message_date", "desc")
        ),
        async (doc) => {
          const messages = doc.docs;
          this.messages = messages;
        }
      );
    },
  },
  async mounted() {
    this.getData();
  },
};
</script>
