import { renderCenterIcon } from './renderCentercon';

export const rfpInformaticIcons = (attendees: number, nights: number, venue: string, roomsPerNight: number) => {
  let isAllAvailable = attendees && nights && venue && roomsPerNight;
  const iconsTexts = [
    attendees
      ? {
          iconUrl: 'https://dev-api-launchpad.crewfare.com/email/group.png',
          text: '##estimated_attendees## Estimated<br>attendees',
          padding: 2,
        }
      : null,
    nights
      ? {
          iconUrl: 'https://dev-api-launchpad.crewfare.com/email/hotel.png',
          text: '##nights## <br> Nights',
          padding: 5,
        }
      : null,
    venue
      ? { iconUrl: 'https://dev-api-launchpad.crewfare.com/email/location.png', text: '##venue##', padding: 5 }
      : null,
    roomsPerNight
      ? {
          iconUrl: 'https://dev-api-launchpad.crewfare.com/email/bed.png',
          text: '##rooms## Rooms <br> per night',
          padding: 5,
        }
      : null,
  ].filter(_ => !!_);
  return `
        <table class="isDesktop" style="margin: 0 auto;">
            <tr>
            ${iconsTexts
              .map(({ iconUrl, text, padding }) => {
                return renderCenterIcon(iconUrl, text, padding);
              })
              .join('')}
            </tr>
        </table>
        ${`<table class="isMobile" style="margin: 0 auto;">
        <tr>
            ${iconsTexts
              .slice(0, 2)
              .map(({ iconUrl, text, padding }) => {
                return renderCenterIcon(iconUrl, text, padding, 300);
              })
              .join('')}
        <tr style="height:20px;"></tr>
        <tr>
            ${iconsTexts
              .slice(2, 4)
              .map(({ iconUrl, text, padding }) => {
                return renderCenterIcon(iconUrl, text, padding, 300);
              })
              .join('')}
        </tr>
        </table>`}
    `;
};
