import SearchView from './View.vue';
import Dashboard from '@/layouts/Dashboard.vue';

export const search = [
  {
    path: '/search',
    name: 'search',
    component: Dashboard,
    children: [
      {
        path: '',
        name: 'searchView',
        component: SearchView,
      },
    ],
  },
];
