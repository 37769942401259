<template>
  <div
    v-if="hotel"
    class="bg-gray-900 w-full p-4 flex flex-col gap-4 stage-height"
  >
    <h1 class="text-xl text-white gap-4 flex items-center">
      <button
        @click="close"
        class="material-symbols-rounded hover:text-crewfareGreen"
      >
        close
      </button>
      {{ hotel.data().name }} - Contacts
    </h1>
    <div class="flex flex-col gap-2 bg-gray-800 rounded pb-4 relative">
      <div
        class="flex px-4 items-center justify-between sticky top-0 py-2 bg-gray-800"
      >
        <div class="flex gap-2 items-center">
          <div class="text-crewfareGreen">
            <strong class="rounded-full bg-black px-3 py-1">{{
              contactsFound
            }}</strong>
            Contacts
          </div>
        </div>
        <div class="flex items-center gap-4">
          <button @click="setContact" class="green-button">
            <span class="material-symbols-rounded">add</span>
            Contact
          </button>
        </div>
      </div>
      <div class="">
        <div
          class="grid grid-cols-5 px-4 flex justify-between py-2 border border-gray-700 border-x-0 border-t-0"
        >
          <div class="flex gap-4 group text-white">Name</div>
          <div class="flex gap-4 group text-white">Role</div>
          <div class="flex gap-4 group text-white">Email</div>
          <div class="flex gap-4 group text-white">Phone</div>
          <div class="flex gap-4 justify-end"></div>
        </div>
        <div class="divide-y divide-gray-700">
          <ListItem
            v-for="(contact, index) in contacts"
            :hotel_id="hotel.id"
            :key="index"
            :contact="contact"
            @getData="getData"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { firestore } from "@/utils/firebase";
import { getDocs, query, collection, where } from "firebase/firestore";
import ListItem from "./ListItem.vue";

export default {
  components: {
    ListItem,
  },
  data() {
    return {
      hotelName: "",
      contacts: [],
    };
  },
  computed: {
    hotel() {
      return this.$store.state.hotel;
    },
    contactsFound() {
      return this.contacts.length || 0;
    },
  },
  async mounted() {
    this.getData();
  },
  methods: {
    close() {
      this.$emit("update");
      this.$store.commit("setSelectedHotel", null);
      this.$store.commit("setUpdateHotels", Math.random() * 100);
    },
    setContact() {
      this.$store.commit("setContact", "new");
    },
    async getData() {
      if (!this.hotel) return;
      const qContacts = query(
        collection(firestore, "contacts"),
        where("hotel_id", "==", this.hotel.id)
      );
      const contacts = await getDocs(qContacts);
      this.contacts = contacts.docs;
    },
  },
};
</script>
