<template>
  <div v-if="assignRFP" class="absolute left-0 right-0 top-0 bottom-0 z-10">
    <div class="bg-gray-900 w-full p-4 flex flex-col gap-4 stage-height">
      <div class="text-2xl text-white flex items-center gap-4">
        <div class="flex justify-between w-full">
          <div class="flex gap-4 items-center">
            <button @click="close" class="material-symbols-rounded hover:text-crewfareGreen">close</button>
            <span class="flex items-center gap-2">
              RFPS
              <span class="text-sm">
                ({{ countHotels === 1 ? '1 hotel' : `${countHotels} hotels` }}
                selected)
              </span>
            </span>
          </div>
          <div>
            <input
              type="search"
              v-model="search"
              class="bg-gray-700 rounded px-4"
              placeholder="Search..."
              @keyup="doSearch"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-2 bg-gray-800 rounded pb-4 relative">
        <div class="flex px-4 items-center justify-between sticky top-0 py-2 bg-gray-800">
          <div class="flex gap-2 items-center">
            <div class="text-crewfareGreen">
              <strong class="rounded-full bg-black px-3 py-1">{{ rfpsFound }}</strong>
              rfps
            </div>
          </div>
        </div>
        <div v-if="loading" class="my-6 flex items-center justify-center">
          <span class="material-symbols-rounded animate-spin text-[32px]">autorenew</span>
        </div>
        <div v-else-if="rfps.length > 0" class="px-4">
          <table class="w-full px-4 py-2 border border-gray-700 border-x-0 border-t-0">
            <tr class="border-b border-gray-700">
              <th class="text-left text-white py-4">Name</th>
              <th class="text-left text-white">Event Dates</th>
              <th class="text-left text-white">Hotels</th>
              <th class="text-left text-white">Created At</th>
              <th class="text-left text-white">Last Update At</th>
              <th class=""></th>
            </tr>
            <tbody class="divide-y divide-gray-700">
              <ListItem v-for="(rfp, index) in rfps" :key="index" :rfp="rfp" @getData="getData" />
            </tbody>
          </table>
          <Pagination :actual="actual" :max="per_page" :total="total" @togglePage="togglePage" />
        </div>
        <p v-else class="text-center my-6 text-white">
          There are no RFPs that this selection of hotels can be assigned to, select a different group or create a new
          RFP
          <span @click="createRFP" class="underline hover:text-sky-600">clicking here</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { firestore } from "@/utils/firebase";
import { getDocs, query, collection, orderBy } from "firebase/firestore";
import ListItem from "./rfps/ListItem.vue";
import { createSimpleRFP } from "@/utils/rfp.jsx";
import Pagination from "@/components/default/Pagination.vue";
import { rfpsApi } from "@/utils/apis/rfpsApi";
import { getMomentFromFirebaseDate } from "@/utils/dateUtils";
import { RFPStatus } from "@crewfare/commons/src/model";

export default {
  components: {
    ListItem,
    Pagination,
  },
  data() {
    return {
      loading: false,
      actual: 0,
      rfps: [],
      search: '',
      per_page: 10,
      searchCount: 0,
      total: 0
    };
  },
  computed: {
    countHotels() {
      return this.$store.state.selectedHotels?.length || 0;
    },
    assignRFP() {
      return this.$store.state.assignRFP;
    },
    account() {
      return this.$store.state.account;
    },
    rfpsFound() {
      return this.rfps.length || 0;
    },
  },
  watch: {
    assignRFP() {
      this.rfps = [];
      if (this.assignRFP) {
        this.getData();
      }
    },
  },
  methods: {
    doSearch() {
      this.getData()
        
    },
    togglePage(page) {
      this.actual = page;
      this.getData();
    },
    close() {
      this.$store.commit('setAssignRFP', false);
    },
    async createRFP() {
      await createSimpleRFP(this.account);
    },
    async listRFPs(searchCount){
      const selectedHotelIds = [];
      this.$store.state.selectedHotels?.forEach(hotel => selectedHotelIds.push(hotel.id));
      let searchOptions = {
        search: this.search,
        onlyAssignables: JSON.stringify(selectedHotelIds),
        page: this.actual,
        per_page: this.per_page,
      }
      let rfps = await rfpsApi.list(searchOptions).then(res => res.data);  
      if(this.searchCount !== searchCount){
        console.log("Stale results, skipping")
        return;
      }
      this.rfps = rfps?.rfps || []; 
      this.loading = false;
      this.total = rfps?.total || 0;
    },
    async getData() {
      this.rfps = []
      this.loading = true;
      this.searchCount++;
      const currentSearchCount = this.searchCount
      this.listRFPs(currentSearchCount);
       
    },
  },
};
</script>
