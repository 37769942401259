<template>
  <div class="text-black flex flex-col gap-4 p-4">
    <div class="flex gap-4 items-center relative">
      <p class="text-xl bold">{{ marker.hotel_name }}</p>
      <dropdown-menu>
        <template #trigger>
          <button
            class="inline-flex relative items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            type="button"
          >
            <span v-if="loading" class="material-symbols-rounded animate-spin">
              autorenew
            </span>
            <span v-else class="material-symbols-rounded">more_vert</span>
            <span
              v-if="hasInternalMessage"
              class="bg-red-400 rounded-full text-white px-1 absolute top-0 right-0 h-[12px] w-[12px] flex items-center justify-center"
            ></span>
          </button>
        </template>
        <template #body>
          <FwbListGroup class="absolute top-[40px] right-0 z-30 w-[240px] dark:bg-gray-700 bg-gray-700">
            <FwbListGroupItem
              v-if="
                ['Declined by hotel', 'Declined by crewfare'].includes(
                  marker.status
                )
              "
              class="!px-0 !py-0"
            >
              <button
                @click="reopenRFP"
                class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
              >
                Reopen RFP
              </button>
            </FwbListGroupItem>
            <FwbListGroupItem
              v-if="marker.status !== 'New'"
              class="!px-0 !py-0"
            >
              <router-link
                :to="{
                  name: 'rfpView',
                  params: { rfp: marker.id },
                }"
                class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
              >
                View RFP
              </router-link>
            </FwbListGroupItem>
            <FwbListGroupItem class="!px-0 !py-0">
              <router-link
                :to="{
                  name: 'rfpContract',
                  params: { rfp: marker.id },
                }"
                class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
              >
                Contract
              </router-link>
            </FwbListGroupItem>
            <FwbListGroupItem class="!px-0 !py-0">
              <button
                @click="reminderRFP"
                class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
              >
                Reminder
              </button>
            </FwbListGroupItem>
            <FwbListGroupItem class="!px-0 !py-0">
              <button
                @click="resendRFP"
                class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
              >
                Send
              </button>
            </FwbListGroupItem>
            <FwbListGroupItem v-if="marker.message_id" class="!px-0 !py-0">
              <button
                @click="sendMessage"
                class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
              >
                Messages
              </button>
            </FwbListGroupItem>
            <FwbListGroupItem class="!px-0 !py-0">
              <ButtonNotesDialog
                type="Notes on RFP"
                :subtitle="marker.name"
                :title="`Notes at ${marker.name} for ${marker.hotel_name}`"
                :id="`${marker.id}-note-${marker.hotel_id}`"
                text="Notes"
              />
            </FwbListGroupItem>
            <FwbListGroupItem class="!px-0 !py-0">
              <button
                @click="toggleStatusHistoryDialog"
                class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
              >
                Status History
              </button>
            </FwbListGroupItem>
            <FwbListGroupItem
              v-if="marker.roomListHistoric.length > 1"
              class="!px-0 !py-0"
            >
              <button
                @click="toggleHistoryDialog"
                class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
              >
                History rates
              </button>
            </FwbListGroupItem>
            <FwbListGroupItem class="!px-0 !py-0">
              <button
                @click="removeHotel"
                class="text-red-400 hover:text-red-100 flex items-center gap-2 px-4 py-2 w-full"
              >
                Remove hotel
              </button>
            </FwbListGroupItem>
          </FwbListGroup>
        </template>
      </dropdown-menu>
    </div>
    <div class="flex gap-4 items-center">
      <span v-html="statusPill"></span>
      <span class="flex gap-2 items-center">
        <img src="/icons/pin.svg" />
        {{ marker.distance ? `${marker.distance.toFixed(2)} mi` : "-" }}
      </span>
      <span> {{ formatedUpdatedAt }}</span>
    </div>
    <StatusHistoryDialog
      v-if="showStatusHistoryDialog"
      :rfp="marker.id"
      @hideStatusHistoryDialog="showStatusHistoryDialog = false"
      :status_history="marker.status_history"
    />
  </div>
</template>

<script>
import { sendHotelMessage } from "@/utils/messages.jsx";
import { firestore } from "@/utils/firebase";
import {
  where,
  getDocs,
  getDoc,
  addDoc,
  doc,
  query,
  deleteDoc,
  collection,
  setDoc,
} from "firebase/firestore";
import moment from "moment";
import ListItemHotelSkeleton from "./ListItemHotelSkeleton.vue";
import RateDialog from "./RateDialog.vue";
import { RFPStatusPill, getRFP, reopenRFP } from "@/utils/rfp.jsx";
import { FwbDropdown, FwbListGroup, FwbListGroupItem } from "flowbite-vue";
import ButtonNotesDialog from "@/components/default/ButtonNotesDialog.vue";
import HistoryRateDialog from "@/components/default/HistoryRateDialog.vue";
import StatusHistoryDialog from "./StatusHistoryDialog.vue";

export default {
  props: ["marker"],
  components: {
    HistoryRateDialog,
    StatusHistoryDialog,
    RateDialog,
    ListItemHotelSkeleton,
    FwbDropdown,
    FwbListGroup,
    FwbListGroupItem,
    ButtonNotesDialog,
  },
  data() {
    return {
      loading: false,
      hotel: null,
      showMenu: false,
      contacts: null,
      proposal: "-",
      hasMessage: false,
      delta: "-",
      isPartner: false,
      showRateDialog: false,
      showNoteDialog: false,
      showHistoryDialog: false,
      hasInternalMessage: false,
      showStatusHistoryDialog: false,
      internalMessage: null,
    };
  },
  computed: {
    cutoffDateFormated() {
      if (!this.marker.cutoffDate) return "";
      const date = this.marker.cutoffDate;
      return moment(date.toDate()).format("MM-DD-YYYY");
    },
    showCutoffDate() {
      return [
        "accepted by hotel",
        "accepted by crewfare",
        "signed by the hotel",
        "signed by both sides",
        "accepted by crewfare",
      ].includes(this.marker.status.toLowerCase());
    },
    showContractLink() {
      return [
        "accepted by hotel",
        "accepted by crewfare",
        "signed by the hotel",
        "accepted by crewfare",
      ].includes(this.marker.status.toLowerCase());
    },
    showLink() {
      return ![
        "new",
        "accepted by hotel",
        "accepted by crewfare",
        "signed by the hotel",
        "accepted by crewfare",
      ].includes(this.marker.status.toLowerCase());
    },
    distance() {
      if (this.marker.distance) return `${this.marker.distance.toFixed(2)} mi`;
      return "-";
    },
    contractAvailable() {
      return this.rfp.data().accepted?.length == 2;
    },
    statusPill() {
      return RFPStatusPill(this.marker.status);
    },
    account() {
      return this.$store.state.account;
    },
    rate() {
      const rate = parseInt(this.marker.value || 0);
      if (rate) return `$ ${rate.toFixed(2)}`;
      return "-";
    },
    formatedUpdatedAt() {
      if (!this.marker.updated_at) return "";
      const updated_at = moment(this.marker.updated_at.toDate());
      return updated_at.fromNow();
    },
  },
  async mounted() {
    const hotelRef = doc(firestore, "hotels", this.marker.hotel_id);
    this.hotel = await getDoc(hotelRef);
    this.isPartner = this.hotel.data().isPartner || false;

    const qContacts = query(
      collection(firestore, "contacts"),
      where("hotel_id", "==", this.marker.hotel_id)
    );
    const contacts = await getDocs(qContacts);
    this.contacts = contacts.docs;

    const qMessages = query(
      collection(firestore, "messages"),
      where("hotel_id", "==", this.marker.hotel_id)
    );
    const message = await getDocs(qMessages);
    if (message.docs.reverse()[0])
      this.hasMessage = message.docs.reverse()[0].data().new_message;

    let value = 500000;
    this.marker.roomList?.forEach((days) => {
      days.rooms.forEach((item) => {
        const itemValue = parseFloat(item.value);
        if (itemValue && itemValue !== 0 && value > itemValue) {
          value = itemValue;
        }
      });
    });
    if (value !== 500000) {
      this.proposal = `$ ${value.toFixed(2)}`;
    }
    if (this.proposal === "-") return;
    const rate = this.marker.value;
    if (isNaN(parseFloat(rate))) return;
    const delta = Math.floor(((rate - value) / rate) * 100);
    let color = "";
    if (delta < 10) {
      color = "color: rgb(248 113 113);";
    } else if (delta < 20) {
      color = "color: rgb(250 204 21);";
    } else {
      color = "color: rgb(74 222 128);";
    }
    this.delta = `<span style='${color}'>${delta}%</span>`;
  },
  methods: {
    async reopenRFP() {
      this.loading = true;
      reopenRFP(this.rfp, this.account);
      this.$emit("getData");
      this.loading = false;
    },
    copyContractLink(account_id) {
      navigator.clipboard.writeText(
        `${import.meta.env.VITE_URL}/rfp/${
          this.marker.id
        }/contract?accountId=${account_id}`
      );
      this.showMenu = false;
      this.$store.commit("setToast", {
        content: `<p>Link copied!</p>`,
      });
    },
    copyRFPLink(account_id) {
      navigator.clipboard.writeText(
        `${import.meta.env.VITE_URL}/rfp/${
          this.marker.id
        }/view?accountId=${account_id}`
      );
      this.showMenu = false;
      this.$store.commit("setToast", {
        content: `<p>Link copied!</p>`,
      });
    },
    toggleRateDialog() {
      this.showRateDialog = !this.showRateDialog;
    },
    toggleStatusHistoryDialog() {
      this.showStatusHistoryDialog = !this.showStatusHistoryDialog;
    },
    toggleHistoryDialog() {
      this.showHistoryDialog = !this.showHistoryDialog;
    },
    toggleSelected() {
      this.$emit("toggleSelected", this.rfp);
    },
    resendRFP() {
      this.loading = true;
      this.$emit("resendRFP", [this.rfp]);
      this.loading = false;
    },
    reminderRFP() {
      this.showMenu = false;
      this.$emit("reminderRFP", {
        rfp: [this.rfp],
        status: this.marker.status.toLowerCase(),
      });
    },
    async sendMessage() {
      sendHotelMessage(this.hotel, this.account);
    },
    async removeHotel() {
      if (!confirm("Remove hotel from RFP?")) return;
      this.showMenu = false;
      const hotel_id = this.marker.hotel_id;
      const rfpData = await getRFP(this.marker.rfp);
      const hotels = rfpData.data().hotels || [];
      this.showMenu = false;
      if (hotels.length > 0) hotels.splice(hotels.indexOf(hotel_id), 1);
      await setDoc(doc(firestore, "rfps", this.marker.rfp), {
        ...rfpData.data(),
        hotels,
        updated_at: new Date(),
      });
      this.$store.commit("setToast", {
        content: `<p>Hotel removed from RFP</p>`,
      });
      deleteDoc(doc(firestore, "rfp_hotel", this.marker.id));
      this.$emit("getData");
    },
  },
};
</script>
