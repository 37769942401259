import { AnyObject } from '../model';

export function calculateTotals(totals: AnyObject[]) {
  const sumObj = {};
  for (let i = 0; i < totals.length; i++) {
    const obj = totals[i];
    for (const prop in obj) {
      if (typeof obj[prop] === 'number') {
        sumObj[prop] = (sumObj[prop] || 0) + obj[prop];
      }
    }
  }
  return sumObj as any;
}

export const convertArrayToIdMappedObject = (arr: { id: string; [key: string]: any }[]) => {
  const obj = {};
  arr.forEach(item => {
    obj[item.id] = item;
  });
  return obj;
};

export const convertArrayToKeyMappedObjectArrays = (arr: AnyObject[], key) => {
  const obj = {};
  arr.forEach(item => {
    if (obj[item[key]]) {
      obj[item[key]].push(item);
    } else {
      obj[item[key]] = [item];
    }
  });

  return obj;
};

export const convertArrayToKeyMappedObjectSum = (arr: AnyObject[], keyToMap, keyToSum) => {
  const obj = {};
  arr.forEach(item => {
    if (obj[item[keyToMap]]) {
      obj[item[keyToMap]] += item[keyToSum];
    } else {
      obj[item[keyToMap]] = item[keyToSum];
    }
  });
  return obj;
};

export const uniqueByName = (data, key) =>
  data.filter((item, index, self) => index === self.findIndex(t => t[key] === item[key]));
