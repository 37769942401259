<template>
  <div
    class="fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center z-[100] bg-black/50"
  >
    <div
      class="bg-gray-900 rounded-lg shadow-md py-4 text-white flex flex-col gap-4 justify-center min-w-[900px]"
    >
      <div class="px-4 pt-4 flex justify-between items-center gap-4 w-full">
        <p class="text-xl px-4">Contract</p>
        <span
          v-if="editHotelFields"
          @click="closeDialog"
          class="cursor-pointer top-2 right-2 text-red-200 hover:text-red-400 material-symbols-rounded"
        >
          close
        </span>
      </div>
      <p class="text-sm px-4" v-if="isHotel">
        Please fill out the information below based on the property in which you
        are contracting our group with.
      </p>
      <p v-else class="text-sm px-4">
        Before reviewing or submitting a contract please provide us with the
        following information:
      </p>
      <div v-if="isHotel" class="flex gap-4 px-4 overflow-auto max-h-[70vh]">
        <div class="flex flex-col gap-4 w-full">
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">{{editHotelFields ? "Hotel Person Name": "Your Name"}}</label>
            <input
              type="text"
              v-model="hotel_person_name"
              class="w-full bg-gray-700 rounded px-4"
            />
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">Title</label>
            <input
              type="text"
              v-model="hotel_title"
              class="w-full bg-gray-700 rounded px-4"
            />
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">Address</label>
            <input
              type="text"
              v-model="hotel_address"
              class="w-full bg-gray-700 rounded px-4"
            />
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">Telephone</label>
            <input
              type="text"
              v-model="hotel_telephone"
              class="w-full bg-gray-700 rounded px-4"
            />
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">Email</label>
            <input
              type="text"
              v-model="hotel_email"
              class="w-full bg-gray-700 rounded px-4"
            />
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">Check-In Age:</label>
            <div class="flex flex-row gap-2">
              <input
                type="text"
                v-model="hotel_check_in_age"
                class="w-full bg-gray-700 rounded px-4"
              />
            </div>
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">Check-In Time:</label>
            <div class="flex flex-row gap-2">
              <input
                type="text"
                v-model="hotel_check_in_time"
                class="w-full bg-gray-700 rounded px-4"
              />
            </div>
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">Check-Out Time:</label>
            <div class="flex flex-row gap-2">
              <input
                type="text"
                v-model="hotel_check_out_time"
                class="w-full bg-gray-700 rounded px-4"
              />
            </div>
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">Wifi:</label>
            <div class="flex flex-row gap-2">
              <input
                type="text"
                v-model="wifi"
                class="w-full bg-gray-700 rounded px-4"
              />
            </div>
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">Parking:</label>
            <div class="flex flex-row gap-2">
              <input
                type="text"
                v-model="parking"
                class="w-full bg-gray-700 rounded px-4"
              />
            </div>
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">Breakfast:</label>
            <div class="flex flex-row gap-2">
              <input
                type="text"
                v-model="breakfast"
                class="w-full bg-gray-700 rounded px-4"
              />
            </div>
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">Other Fees:</label>
            <div class="flex flex-row gap-2">
              <input
                type="text"
                v-model="other_fees"
                class="w-full bg-gray-700 rounded px-4"
              />
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-4 w-full">
          <p class="text-xl -mb-3">Taxes:</p>
          <p class="text-white text-base -mb-3">
            If something does not apply please input the number zero. Please
            indicate $ or %.
          </p>
          <div class="flex flex-col mt-[2px] gap-1 w-full">
            <label class="text-white font-bold">Local Occupancy Tax:</label>
            <div class="flex flex-row gap-2">
              <input
                type="text"
                v-model="hotel_local_occupancy_tax"
                class="w-full bg-gray-700 rounded px-4"
              />
            </div>
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">State Occupancy Tax:</label>
            <div class="flex flex-row gap-2">
              <input
                type="text"
                v-model="hotel_state_occupancy_tax"
                class="w-full bg-gray-700 rounded px-4"
              />
            </div>
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">City Tax:</label>
            <div class="flex flex-row gap-2">
              <input
                type="text"
                v-model="hotel_city_tax"
                class="w-full bg-gray-700 rounded px-4"
              />
            </div>
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold"
              >Convention & Tourism Tax:</label
            >
            <div class="flex flex-row gap-2">
              <input
                type="text"
                v-model="hotel_convention_tax"
                class="w-full bg-gray-700 rounded px-4"
              />
            </div>
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">VAT Tax:</label>
            <div class="flex flex-row gap-2">
              <input
                type="text"
                v-model="hotel_vat_tax"
                class="w-full bg-gray-700 rounded px-4"
              />
            </div>
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">SAT Tax:</label>
            <div class="flex flex-row gap-2">
              <input
                type="text"
                v-model="hotel_sat_tax"
                class="w-full bg-gray-700 rounded px-4"
              />
            </div>
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold"
              >Municipal Accommodation Tax:</label
            >
            <div class="flex flex-row gap-2">
              <input
                type="text"
                v-model="hotel_municipal_accomodation_tax"
                class="w-full bg-gray-700 rounded px-4"
              />
            </div>
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">HST Tax:</label>
            <div class="flex flex-row gap-2">
              <input
                type="text"
                v-model="hotel_hst_tax"
                class="w-full bg-gray-700 rounded px-4"
              />
            </div>
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">Sustaintability Fee:</label>
            <div class="flex flex-row gap-2">
              <input
                type="text"
                v-model="hotel_sustaintability_fee"
                class="w-full bg-gray-700 rounded px-4"
              />
            </div>
          </div>
          <div class="flex flex-col gap-1 w-full">
            <label class="text-white font-bold">Other Taxes:</label>
            <div class="flex flex-row gap-2">
              <input
                type="text"
                v-model="hotel_taxes"
                class="w-full bg-gray-700 rounded px-4"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isHotel" class="flex px-4 gap-4 flex-col">
        <div class="flex flex-col gap-1 w-full">
          <label class="text-white font-bold">
            Event Planner's member number
          </label>
          <input
            type="text"
            v-model="crewfare_members"
            class="w-full bg-gray-700 rounded px-4"
          />
        </div>
      </div>
      <div class="flex justify-end px-4">
        <button @click="saveFields" class="green-button gap-2">
          <span v-if="savingFields" class="flex items-center">
            <span class="material-symbols-rounded animate-spin">
              autorenew
            </span>
          </span>
          Save
        </button>
      </div>
    </div>
    <div
      v-if="error"
      class="z-40 fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center"
    >
      <div
        class="fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center z-[100] bg-black/50"
      ></div>
      <div
        class="bg-gray-900 z-[101] rounded-lg shadow-md py-4 text-white flex flex-col gap-4 justify-center p-4 items-center"
      >
        <p class="text-sm text-center px-4">Please fill out all the fields</p>
        <div class="flex px-4">
          <button @click="error = false" class="cancel-button">Dismiss</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { firestore } from "@/utils/firebase";
import { doc, setDoc } from "firebase/firestore";
import { setStatusHistory } from "@/utils/rfp.jsx";

export default {
  name: "ContractFields",
  props: ["rfp", "isHotel", "account", "editHotelFields", "closeDialog"],
  data() {
    return {
      error: false,
      savingFields: false,
      error: false,
      hotel_signature: this.rfp?.data().hotel_signature || "",
      hotel_name: this.rfp?.data().hotel_name || "",
      hotel_person_name: this.rfp?.data().hotel_person_name || "",
      hotel_address: this.rfp?.data().hotel_address || "",
      hotel_email: this.rfp?.data().hotel_email || "",
      hotel_telephone: this.rfp?.data().hotel_telephone || "",
      hotel_title: this.rfp?.data().hotel_title || "",
      hotel_date: this.rfp?.data().hotel_date || "",
      crewfare_date: this.rfp?.data().crewfare_date || "",
      crewfare_name: this.rfp?.data().crewfare_name || "",
      crewfare_title: this.rfp?.data().crewfare_title || "",
      crewfare_members: this.rfp?.data().crewfare_members || "",
      hotel_taxes: this.rfp?.data().hotel_taxes || "",
      hotel_other_fees: this.rfp?.data().hotel_other_fees || "",
      hotel_check_in_age: this.rfp?.data().hotel_check_in_age || "",
      hotel_check_in_time: this.rfp?.data().hotel_check_in_time || "",
      hotel_check_out_time: this.rfp?.data().hotel_check_out_time || "",
      hotel_local_occupancy_tax: this.rfp?.data().hotel_local_occupancy_tax || "",
      hotel_state_occupancy_tax: this.rfp?.data().hotel_state_occupancy_tax || "",
      hotel_city_tax: this.rfp?.data().hotel_city_tax || "",
      hotel_convention_tax: this.rfp?.data().hotel_convention_tax || "",
      hotel_vat_tax: this.rfp?.data().hotel_vat_tax || "",
      hotel_sat_tax: this.rfp?.data().hotel_sat_tax || "",
      hotel_municipal_accomodation_tax: this.rfp?.data().hotel_municipal_accomodation_tax || "",
      hotel_hst_tax: this.rfp?.data().hotel_hst_tax || "",
      hotel_sustaintability_fee: this.rfp?.data().hotel_sustaintability_fee || "",
      wifi: this.rfp?.data().wifi || "",
      parking: this.rfp?.data().parking || "",
      breakfast: this.rfp?.data().breakfast || "",
      other_fees: this.rfp?.data().other_fees || "",
    };
  },
  computed: {
    isValidEmpty() {
      if (this.isHotel) {
        return (
          this.hotel_person_name.length < 1 ||
          this.hotel_address.length < 1 ||
          this.hotel_email.length < 1 ||
          this.hotel_title.length < 1 ||
          this.hotel_taxes.length < 1 ||
          this.hotel_check_in_age.length < 1 ||
          this.hotel_check_in_time.length < 1 ||
          this.hotel_check_out_time.length < 1 ||
          this.hotel_local_occupancy_tax.length < 1 ||
          this.hotel_state_occupancy_tax.length < 1 ||
          this.hotel_city_tax.length < 1 ||
          this.hotel_convention_tax.length < 1 ||
          this.hotel_vat_tax.length < 1 ||
          this.hotel_sat_tax.length < 1 ||
          this.hotel_municipal_accomodation_tax.length < 1 ||
          this.hotel_hst_tax.length < 1 ||
          this.hotel_sustaintability_fee.length < 1 ||
          this.wifi.length < 1 ||
          this.parking.length < 1 ||
          this.breakfast.length < 1 ||
          this.other_fees.length < 1
        );
      }
      return this.crewfare_members.length < 1;
    },
  },
  watch: {
    hotel_taxes() {
      this.hotel_taxes = this.validate(this.hotel_taxes);
    },
    hotel_check_in_age() {
      this.hotel_check_in_age = this.validate(this.hotel_check_in_age);
    },
    hotel_local_occupancy_tax() {
      this.hotel_local_occupancy_tax = this.validate(
        this.hotel_local_occupancy_tax
      );
    },
    hotel_check_in_time() {
      this.hotel_check_in_time = this.validate(this.hotel_check_in_time);
    },
    hotel_check_out_time() {
      this.hotel_check_out_time = this.validate(this.hotel_check_out_time);
    },
    hotel_check_out_time() {
      this.hotel_check_out_time = this.validate(this.hotel_check_out_time);
    },
    hotel_check_out_time() {
      this.hotel_check_out_time = this.validate(this.hotel_check_out_time);
    },
    hotel_state_occupancy_tax() {
      this.hotel_state_occupancy_tax = this.validate(
        this.hotel_state_occupancy_tax
      );
    },
    hotel_city_tax() {
      this.hotel_city_tax = this.validate(this.hotel_city_tax);
    },
    hotel_convention_tax() {
      this.hotel_convention_tax = this.validate(this.hotel_convention_tax);
    },
    hotel_vat_tax() {
      this.hotel_vat_tax = this.validate(this.hotel_vat_tax);
    },
    hotel_sat_tax() {
      this.hotel_sat_tax = this.validate(this.hotel_sat_tax);
    },
    hotel_municipal_accomodation_tax() {
      this.hotel_municipal_accomodation_tax = this.validate(
        this.hotel_municipal_accomodation_tax
      );
    },
    hotel_municipal_accomodation_tax() {
      this.hotel_municipal_accomodation_tax = this.validate(
        this.hotel_municipal_accomodation_tax
      );
    },
    hotel_hst_tax() {
      this.hotel_hst_tax = this.validate(this.hotel_hst_tax);
    },
    hotel_sustaintability_fee() {
      this.hotel_sustaintability_fee = this.validate(
        this.hotel_sustaintability_fee
      );
    },
  },
  methods: {
    validate(value) {
      return value.replace(/[^\d$% .,:]/g, "");
    },
    async saveFields() {
      if (this.isValidEmpty) {
        this.error = true;
        return;
      }
      this.savingFields = true;
      let data = {};
      if (this.isHotel) {
        data = {
          ...this.rfp.data(),
          hotel_person_name: this.hotel_person_name,
          hotel_address: this.hotel_address,
          hotel_email: this.hotel_email,
          hotel_telephone: this.hotel_telephone,
          hotel_title: this.hotel_title,
          hotel_taxes: this.hotel_taxes,
          hotel_check_in_age: this.hotel_check_in_age,
          hotel_check_in_time: this.hotel_check_in_time,
          hotel_check_out_time: this.hotel_check_out_time,
          hotel_local_occupancy_tax: this.hotel_local_occupancy_tax,
          hotel_state_occupancy_tax: this.hotel_state_occupancy_tax,
          hotel_city_tax: this.hotel_city_tax,
          hotel_convention_tax: this.hotel_convention_tax,
          hotel_vat_tax: this.hotel_vat_tax,
          hotel_sat_tax: this.hotel_sat_tax,
          hotel_municipal_accomodation_tax:
            this.hotel_municipal_accomodation_tax,
          hotel_hst_tax: this.hotel_hst_tax,
          hotel_sustaintability_fee: this.hotel_sustaintability_fee,
          wifi: this.wifi,
          parking: this.parking,
          breakfast: this.breakfast,
          other_fees: this.other_fees,
          updated_at: new Date(),
        };
      } else {
        data = {
          ...this.rfp.data(),
          crewfare_members: this.crewfare_members,
          updated_at: new Date(),
        };
      }
      data = await setStatusHistory({
        data,
        content: "Fields updated",
        status: data.status,
      });
      await this.saveUpdateContract({ data });
      this.$emit("get_data");
      this.closeDialog();
    },
    async saveUpdateContract({ data }) {
      const rfpData = {
        ...this.rfp.data(),
        ...data,
      };
      const rfpDataStatusHistory = await setStatusHistory({
        data: rfpData,
        content: "Contract Field Updated",
        status: rfpData.status,
      });
      await setDoc(
        doc(firestore, "rfp_hotel", this.rfp.id),
        rfpDataStatusHistory
      );
    },
  },
};
</script>
