<template>
  <div
    v-if="rfp"
    class="bg-gray-800 w-full flex flex-col stage-height"
    :class="!account && 'max-w-[1080px] w-full mx-auto'"
  >
    <div
      class="bg-gray-900 p-4 text-xl text-white gap-4 flex items-center justify-between sticky top-0"
    >
      <div class="flex gap-4 h-[42px] items-center">
        <router-link
          :to="{ name: 'rfpsList' }"
          class="material-symbols-rounded hover:text-crewfareGreen"
        >
          chevron_left
        </router-link>
        <div>RFP - {{ name }} - Preview</div>
      </div>
    </div>
    <img :src="banner" />
    <div class="flex flex-col p-4 gap-4 text-white w-full">
      <p class="text-2xl my-2 mb-4">{{ name }}</p>
      <p>From {{ startDateFormated }} to {{ endDateFormated }}</p>
      <hr class="my-4" />
      <div>
        <p class="text-2xl">Rooms</p>
        <div class="flex flex-col divide-y">
          <div
            v-for="(roomItem, index) in roomList"
            :key="index"
            class="flex gap-4 py-4"
          >
            <div
              class="flex w-[220px] items-center flex-col justify-center gap-4"
            >
              <span>&nbsp;</span>
              <span class="w-full text-right">{{ roomItem.date }}</span>
              <span class="w-full text-right text-crewfareGreen"
                >Nightly Rate</span
              >
            </div>
            <div class="flex gap-4">
              <div
                v-for="(room, roomIndex) in roomItem.rooms"
                :key="roomIndex"
                class="flex flex-col gap-4"
              >
                <input
                  type="text"
                  :disabled="true"
                  class="max-w-[120px] bg-gray-700 rounded px-2"
                  v-model="room.type"
                />
                <input
                  type="text"
                  :disabled="true"
                  class="max-w-[120px] bg-gray-700 rounded px-2"
                  v-model="room.amount"
                  @keypress="isNumber($event)"
                />
                <div
                  class="max-w-[120px] flex gap-2 bg-gray-700 items-center justify-center rounded"
                >
                  <span class="flex px-2 items-center justify-center">$</span>
                  <input
                    type="text"
                    :disabled="true"
                    class="px-4 pl-0 bg-transparent w-full text-right"
                    v-model="room.value"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-4" />
      <p class="text-2xl">Preferences</p>
      <div class="grid grid-cols-3 gap-4 w-full">
        <div class="flex flex-col gap-1 w-full">
          <div
            class="text-sm font-bold flex h-[44px] justify-between items-center mb-2"
          >
            Attrition:
            <div class="flex gap-4">
              <button
                @click="() => setReply('Attrition')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded text-crewfareGreen border rounded border-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900 py-1"
              >
                comment
              </button>
              <span
                v-if="lockedFields.includes('attrition')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded text-crewfarePurple py-1"
              >
                lock
              </span>
            </div>
          </div>
          <select
            :disabled="true"
            class="w-full bg-gray-700 h-[40px] px-4 rounded"
            v-model="attrition"
          >
            <option>Select</option>
            <option>Courtesy Block</option>
            <option>70% Attrition</option>
            <option>80% Attrition</option>
            <option>90% Attrition</option>
            <option>100% Attrition</option>
            <option>Other</option>
          </select>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <div class="text-sm font-bold flex justify-between items-center mb-2">
            <div class="flex flex-col gap-2">
              Cut-Off Date:
              <span class="text-xs" v-html="cutoffDateDays"></span>
            </div>
            <div class="flex gap-4 items-center">
              <button
                @click="() => setReply('Cutoff date')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded text-crewfareGreen border rounded border-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900 py-1"
              >
                comment
              </button>
              <span
                v-if="lockedFields.includes('cutoffDate')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded text-crewfarePurple py-1"
              >
                lock
              </span>
            </div>
          </div>
          <VueDatePicker
            :disabled="true"
            :format="format"
            :enable-time-picker="false"
            :min-date="new Date()"
            :max-date="startDate"
            v-model="cutoffDate"
            auto-apply
            input-class-name="w-full bg-gray-700 h-[40px] appearance-none rounded border-gray-700  text-white"
          >
            <template #action-preview="{ value }">
              {{ null }}
            </template>
          </VueDatePicker>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <div
            class="text-sm font-bold flex h-[44px] justify-between items-center mb-2"
          >
            Commission(%):
            <div class="flex gap-4">
              <button
                @click="() => setReply('Commission(%)')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded text-crewfareGreen border rounded border-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900 py-1"
              >
                comment
              </button>
              <span
                v-if="lockedFields.includes('commission')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded text-crewfarePurple py-1"
              >
                lock
              </span>
            </div>
          </div>
          <select
            :disabled="true"
            class="w-full bg-gray-700 h-[40px] px-4 rounded"
            v-model="commission"
          >
            <option>Select</option>
            <option>10%</option>
            <option>7%</option>
            <option>Non Commissionable</option>
          </select>
          <div
            v-if="commission === 'Non Commissionable'"
            class="flex flex-col gap-1 mt-4"
          >
            <div class="text-sm font-bold">Commission Amount:</div>
            <input
              :disabled="true"
              type="text"
              v-model="commissionAmount"
              class="w-full bg-gray-700 rounded px-4"
            />
          </div>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <div class="text-sm font-bold flex justify-between items-center mb-2">
            IATA #:
            <div class="flex gap-4">
              <button
                @click="() => setReply('IATA #')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded text-crewfareGreen border rounded border-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900 py-1"
              >
                comment
              </button>
              <span
                v-if="lockedFields.includes('iata')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded text-crewfarePurple py-1"
              >
                lock
              </span>
            </div>
          </div>
          <input
            :disabled="true"
            type="text"
            v-model="iata"
            class="w-full bg-gray-700 rounded px-4"
          />
        </div>
        <div class="flex flex-col gap-1 w-full">
          <div class="text-sm font-bold flex justify-between items-center mb-2">
            Rebate:
            <div class="flex gap-4">
              <button
                @click="() => setReply('Rebate')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded text-crewfareGreen border rounded border-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900 py-1"
              >
                comment
              </button>
              <span
                v-if="lockedFields.includes('rebate')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded text-crewfarePurple py-1"
              >
                lock
              </span>
            </div>
          </div>
          <select
            :disabled="true"
            class="w-full bg-gray-700 h-[40px] px-4 rounded"
            v-model="rebate"
          >
            <option>Select</option>
            <option>$10</option>
            <option>$5</option>
            <option>$3</option>
            <option>$2</option>
            <option>No Rebate</option>
          </select>
          <div class="flex gap-4">
            <div
              for="rebatePerNight"
              :disabled="true"
              class="flex gap-1 w-full items-center"
            >
              <input
                id="rebatePerNight"
                type="radio"
                value="Per Night"
                v-model="rebateType"
              />
              <span>Per Night</span>
            </div>
            <div
              for="rebatePerRoom"
              :disabled="true"
              class="flex gap-1 w-full items-center"
            >
              <input
                id="rebatePerRoom"
                type="radio"
                value="Per Room"
                v-model="rebateType"
              />
              <span>Per Room</span>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <div class="text-sm font-bold flex justify-between items-center mb-2">
            Group Rate Honored:
            <div class="flex gap-4">
              <button
                @click="() => setReply('Group rate honored')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded text-crewfareGreen border rounded border-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900 py-1"
              >
                comment
              </button>
              <span
                v-if="lockedFields.includes('groupDateHonored')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded text-crewfarePurple py-1"
              >
                lock
              </span>
            </div>
          </div>
          <select
            :disabled="true"
            class="w-full bg-gray-700 h-[40px] px-4 rounded"
            v-model="groupDateHonored"
          >
            <option>Select</option>
            <option>Seven (7) Days</option>
            <option>Six (6) Days</option>
            <option>Five (5) Days</option>
            <option>Four (4) Days</option>
            <option>Three (3) Days</option>
            <option>Two (2) Days</option>
            <option>One (1) Day</option>
            <option>No Days</option>
          </select>
          <div class="flex gap-4">
            <div
              for="rebatePreEvent"
              :disabled="true"
              class="flex gap-1 w-full items-center"
            >
              <input
                id="rebatePreEvent"
                type="checkbox"
                value="Pre Event"
                v-model="groupRateTypePre"
              />
              <span>Pre Event</span>
            </div>
            <div
              for="rebatePostEvent"
              :disabled="true"
              class="flex gap-1 w-full items-center"
            >
              <input
                id="rebatePostEvent"
                type="checkbox"
                value="Post Event"
                v-model="groupRateTypePost"
              />
              <span>Post Event</span>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <div class="text-sm font-bold flex justify-between items-center mb-2">
            Comp Rooms:
            <div class="flex gap-4">
              <button
                @click="() => setReply('Comp rooms')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded text-crewfareGreen border rounded border-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900 py-1"
              >
                comment
              </button>
              <span
                v-if="lockedFields.includes('compRooms')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded text-crewfarePurple py-1"
              >
                lock
              </span>
            </div>
          </div>
          <select
            :disabled="true"
            class="w-full bg-gray-700 h-[40px] px-4 rounded"
            v-model="compRooms"
          >
            <option>Select</option>
            <option>1 Per 50</option>
            <option>1 Per 45</option>
            <option>1 Per 40</option>
            <option>1 Per 35</option>
            <option>1 Per 30</option>
            <option>1 Per 25</option>
            <option>1 Per 20</option>
            <option>1 Per 15</option>
            <option>1 Per 10</option>
            <option>No Comp rooms</option>
          </select>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <div class="text-sm font-bold flex justify-between items-center mb-2">
            Suite Upgrades:
            <div class="flex gap-4">
              <button
                @click="() => setReply('Group rate honored')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded text-crewfareGreen border rounded border-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900 py-1"
              >
                comment
              </button>
              <span
                v-if="lockedFields.includes('suiteUpgrades')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded text-crewfarePurple py-1"
              >
                lock
              </span>
            </div>
          </div>
          <select
            :disabled="true"
            class="w-full bg-gray-700 h-[40px] px-4 rounded"
            v-model="suiteUpgrades"
          >
            <option>Select</option>
            <option>Ten (10)</option>
            <option>Nine (9)</option>
            <option>Eight (8)</option>
            <option>Seven (7)</option>
            <option>Six (6)</option>
            <option>Five (5)</option>
            <option>Four (4)</option>
            <option>Three (3)</option>
            <option>Two (2)</option>
            <option>One (1)</option>
            <option>Zero (0)</option>
          </select>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <div class="text-sm font-bold flex justify-between items-center mb-2">
            Reward Points:
            <div class="flex gap-4">
              <button
                @click="() => setReply('Reward Points')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded text-crewfareGreen border rounded border-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900 py-1"
              >
                comment
              </button>
              <span
                v-if="lockedFields.includes('rewardPoints')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded text-crewfarePurple py-1"
              >
                lock
              </span>
            </div>
          </div>
          <select
            :disabled="true"
            class="w-full bg-gray-700 h-[40px] px-4 rounded"
            v-model="rewardPoints"
          >
            <option>Select</option>
            <option>Triple Rewards Points</option>
            <option>Double Rewards Points</option>
            <option>Single Rewards Points</option>
            <option>No Rewards Points</option>
          </select>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <div class="text-sm font-bold flex justify-between items-center mb-2">
            Staff Rooms:
            <div class="flex gap-4">
              <button
                @click="() => setReply('Staff Rooms')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded text-crewfareGreen border rounded border-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900 py-1"
              >
                comment
              </button>
              <span
                v-if="lockedFields.includes('staffRooms')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded text-crewfarePurple py-1"
              >
                lock
              </span>
            </div>
          </div>
          <select
            :disabled="true"
            class="w-full bg-gray-700 h-[40px] px-4 rounded"
            v-model="staffRooms"
          >
            <option>Select</option>
            <option>Ten (10)</option>
            <option>Nine (9)</option>
            <option>Eight (8)</option>
            <option>Seven (7)</option>
            <option>Six (6)</option>
            <option>Five (5)</option>
            <option>Four (4)</option>
            <option>Three (3)</option>
            <option>Two (2)</option>
            <option>One (1)</option>
            <option>Zero (0)</option>
          </select>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <div class="text-sm font-bold flex justify-between items-center mb-2">
            Staff Discount:
            <div class="flex gap-4">
              <button
                @click="() => setReply('Staff Discount')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded text-crewfareGreen border rounded border-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900 py-1"
              >
                comment
              </button>
              <span
                v-if="lockedFields.includes('staffDiscount')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded text-crewfarePurple py-1"
              >
                lock
              </span>
            </div>
          </div>
          <select
            :disabled="true"
            class="w-full bg-gray-700 h-[40px] px-4 rounded"
            v-model="staffDiscount"
          >
            <option>Select</option>
            <option>10%</option>
            <option>15%</option>
            <option>20%</option>
            <option>25%</option>
            <option>30%</option>
            <option>35%</option>
            <option>40%</option>
            <option>45%</option>
            <option>50%</option>
            <option>75%</option>
            <option>Other</option>
          </select>
          <div
            v-if="staffDiscount === 'Other'"
            class="flex flex-col gap-1 mt-4"
          >
            <div class="text-sm font-bold">Staff Discount Amount:</div>
            <input
              :disabled="true"
              type="text"
              v-model="staffDiscountAmount"
              class="w-full bg-gray-700 rounded px-4"
            />
          </div>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <div class="text-sm font-bold flex justify-between items-center mb-2">
            Resort Fee:
            <div class="flex gap-4">
              <button
                @click="() => setReply('Resort fee')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded text-crewfareGreen border rounded border-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900 py-1"
              >
                comment
              </button>
              <span
                v-if="lockedFields.includes('resortFee')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded text-crewfarePurple py-1"
              >
                lock
              </span>
            </div>
          </div>
          <select
            :disabled="true"
            class="w-full bg-gray-700 h-[40px] px-4 rounded"
            v-model="resortFee"
          >
            <option>Select</option>
            <option>No Resort Fee</option>
            <option>Resort Fee</option>
          </select>
          <div
            v-if="resortFee === 'Resort Fee'"
            class="flex flex-col gap-1 mt-4"
          >
            <div class="text-sm font-bold">Resort Fee Amount:</div>
            <input
              :disabled="true"
              type="text"
              v-model="resortFeeAmount"
              class="w-full bg-gray-700 rounded px-4"
            />
          </div>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <div class="text-sm font-bold flex justify-between items-center mb-2">
            Reservation Submission:
            <div class="flex gap-4">
              <button
                @click="() => setReply('Reservation Submission')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded text-crewfareGreen border rounded border-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900 py-1"
              >
                comment
              </button>
              <span
                v-if="lockedFields.includes('reservationSubmission')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded text-crewfarePurple py-1"
              >
                lock
              </span>
            </div>
          </div>
          <select
            :disabled="true"
            class="w-full bg-gray-700 h-[40px] px-4 rounded"
            v-model="reservationSubmission"
          >
            <option>Select</option>
            <option>Rooming List</option>
            <option>Individual Booking</option>
          </select>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <div class="text-sm font-bold flex justify-between items-center mb-2">
            Billing:
            <div class="flex gap-4">
              <button
                @click="() => setReply('Reservation Submission')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded text-crewfareGreen border rounded border-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900 py-1"
              >
                comment
              </button>
              <span
                v-if="lockedFields.includes('billing')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded text-crewfarePurple py-1"
              >
                lock
              </span>
            </div>
          </div>
          <select
            :disabled="true"
            class="w-full bg-gray-700 h-[40px] px-4 rounded"
            v-model="billing"
          >
            <option>Select</option>
            <option>Room + tax to Master Account</option>
            <option>Room + tax to Individuals</option>
            <option>Other</option>
          </select>
          <div v-if="billing === 'Other'" class="flex flex-col gap-1 mt-4">
            <div class="text-sm font-bold">Other:</div>
            <input
              :disabled="true"
              type="text"
              v-model="billingsOther"
              class="w-full bg-gray-700 rounded px-4"
            />
          </div>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <div class="text-sm font-bold flex justify-between items-center mb-2">
            Incidentals:
            <div class="flex gap-4">
              <button
                @click="() => setReply('Incidentals')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded text-crewfareGreen border rounded border-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900 py-1"
              >
                comment
              </button>
              <span
                v-if="lockedFields.includes('incidentals')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded text-crewfarePurple py-1"
              >
                lock
              </span>
            </div>
          </div>
          <select
            id="incidentals"
            class="w-full px-4 bg-gray-700 h-[40px] rounded"
            v-model="incidentals"
          >
            <option selected="" disabled="">Select</option>
            <option>Incidentals to Individuals</option>
            <option>Incidentals to Master Account</option>
            <option>Other</option>
          </select>
          <div v-if="incidentals === 'Other'" class="flex flex-col gap-1 mt-4">
            <div class="text-sm font-bold">Other:</div>
            <input
              type="text"
              v-model="incidentalsOther"
              class="w-full bg-gray-700 rounded px-4"
            />
          </div>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label
            class="text-sm font-bold flex justify-between items-center mb-2"
          >
            Wi-fi *
            <button
              @click="toggleLockedFields('wifi')"
              class="text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1"
              :class="
                lockedFields.includes('wifi')
                  ? 'bg-crewfarePurple text-white border-crewfarePurple'
                  : 'text-crewfareGreen border-crewfareGreen'
              "
            >
              {{ lockedFields.includes("wifi") ? "lock" : "lock_open" }}
            </button>
          </label>
          <select
            id="wifi"
            class="w-full px-4 bg-gray-700 h-[40px] rounded"
            v-model="wifi"
          >
            <option value="">Select</option>
            <option>Included</option>
            <option>Not included</option>
          </select>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label
            class="text-sm font-bold flex justify-between items-center mb-2"
          >
            Parking *
            <button
              @click="toggleLockedFields('parking')"
              class="text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1"
              :class="
                lockedFields.includes('parking')
                  ? 'bg-crewfarePurple text-white border-crewfarePurple'
                  : 'text-crewfareGreen border-crewfareGreen'
              "
            >
              {{ lockedFields.includes("parking") ? "lock" : "lock_open" }}
            </button>
          </label>
          <select
            id="parking"
            class="w-full px-4 bg-gray-700 h-[40px] rounded"
            v-model="parking"
          >
            <option value="">Select</option>
            <option>Included</option>
            <option>Not included</option>
          </select>
          <div
            v-if="parking === 'Not included'"
            class="flex flex-col gap-1 mt-4"
          >
            <label
              class="text-sm font-bold flex justify-between items-center mb-2"
            >
              Price:
              <button
                @click="toggleLockedFields('parkingOther')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1"
                :class="
                  lockedFields.includes('parkingOther')
                    ? 'bg-crewfarePurple text-white border-crewfarePurple'
                    : 'text-crewfareGreen border-crewfareGreen'
                "
              >
                {{
                  lockedFields.includes("parkingOther") ? "lock" : "lock_open"
                }}
              </button>
            </label>
            <input
              type="text"
              v-model="parkingOther"
              class="w-full bg-gray-700 rounded px-4"
            />
          </div>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label
            class="text-sm font-bold flex justify-between items-center mb-2"
          >
            Breakfast *
            <button
              @click="toggleLockedFields('breakfast')"
              class="text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1"
              :class="
                lockedFields.includes('breakfast')
                  ? 'bg-crewfarePurple text-white border-crewfarePurple'
                  : 'text-crewfareGreen border-crewfareGreen'
              "
            >
              {{ lockedFields.includes("breakfast") ? "lock" : "lock_open" }}
            </button>
          </label>
          <select
            id="breakfast"
            class="w-full px-4 bg-gray-700 h-[40px] rounded"
            v-model="breakfast"
          >
            <option value="">Select</option>
            <option>Included</option>
            <option>Not included</option>
          </select>
          <div
            v-if="breakfast === 'Not included'"
            class="flex flex-col gap-1 mt-4"
          >
            <label
              class="text-sm font-bold flex justify-between items-center mb-2"
            >
              Price:
              <button
                @click="toggleLockedFields('breakfastOther')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1"
                :class="
                  lockedFields.includes('breakfastOther')
                    ? 'bg-crewfarePurple text-white border-crewfarePurple'
                    : 'text-crewfareGreen border-crewfareGreen'
                "
              >
                {{
                  lockedFields.includes("breakfastOther") ? "lock" : "lock_open"
                }}
              </button>
            </label>
            <input
              type="text"
              v-model="breakfastOther"
              class="w-full bg-gray-700 rounded px-4"
            />
          </div>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label
            class="text-sm font-bold flex justify-between items-center mb-2"
          >
            Other fees*
            <button
              @click="toggleLockedFields('otherFees')"
              class="text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1"
              :class="
                lockedFields.includes('otherFees')
                  ? 'bg-crewfarePurple text-white border-crewfarePurple'
                  : 'text-crewfareGreen border-crewfareGreen'
              "
            >
              {{ lockedFields.includes("otherFees") ? "lock" : "lock_open" }}
            </button>
          </label>
          <select
            id="otherFees"
            class="w-full px-4 bg-gray-700 h-[40px] rounded"
            v-model="otherFees"
          >
            <option value="">Select</option>
            <option>Yes</option>
            <option>No</option>
          </select>
          <div
            v-if="otherFees === 'Not included'"
            class="flex flex-col gap-1 mt-4"
          >
            <label
              class="text-sm font-bold flex justify-between items-center mb-2"
            >
              Value:
              <button
                @click="toggleLockedFields('otherFeesValue')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1"
                :class="
                  lockedFields.includes('otherFeesValue')
                    ? 'bg-crewfarePurple text-white border-crewfarePurple'
                    : 'text-crewfareGreen border-crewfareGreen'
                "
              >
                {{
                  lockedFields.includes("otherFeesValue") ? "lock" : "lock_open"
                }}
              </button>
            </label>
            <input
              type="text"
              v-model="otherFeesValue"
              class="w-full bg-gray-700 rounded px-4"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-1">
        <div class="text-sm font-bold flex justify-between items-center mb-2">
          Additional Terms (Optional)
          <div class="flex gap-4">
            <button
              @click="() => setReply('Additional Terms')"
              class="text-[18px] flex items-center px-4 material-symbols-rounded text-crewfareGreen border rounded border-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900 py-1"
            >
              comment
            </button>
          </div>
        </div>
        <div
          ref="termsInput"
          contenteditable="true"
          class="w-full bg-gray-700 rounded px-4 py-2"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { firestore } from "@/utils/firebase";
import { doc, getDoc } from "firebase/firestore";
import moment from "moment";

export default {
  computed: {
    cutoffDateDays() {
      const date = moment(this.startDate);
      const cutoff = moment(this.cutoffDate);
      const diff = date.diff(cutoff);
      const duration = moment.duration(diff);
      return `${Math.floor(duration.asDays())} days from start date`;
    },
    account() {
      return this.$store.state.account;
    },
  },
  data() {
    return {
      autosaving: false,
      loadingDisabled: false,
      message: null,
      loadingAccept: false,
      lockedFields: [],
      nsoUsers: [],
      changesByHotel: {},
      changesByInternal: {},
      nso: null,
      rfp: null,
      banner: null,
      loading: false,
      name: null,
      responseDueDate: null,
      decisionDate: null,
      ccEmails: null,
      attrition: null,
      cutoffDate: null,
      commission: null,
      iata: null,
      rebate: null,
      groupDateHonored: null,
      compRooms: null,
      staffRooms: null,
      suiteUpgrades: null,
      staffDiscount: null,
      rewardPoints: null,
      resortFee: null,
      reservationSubmission: null,
      billing: null,
      incidentals: null,
      incidentalsOther: null,
      billingsOther: null,
      resortFeeAmount: null,
      staffDiscountAmount: null,
      rebateType: null,
      groupRateTypePre: null,
      groupRateTypePost: null,
      commissionAmount: null,
      startDate: null,
      startDateFormated: null,
      endDateFormated: null,
      wifi: null,
      breakfast: null,
      breakfastOther: null,
      parking: null,
      parkingOther: null,
      roomList: [],
      isPreview: false,
      otherFees: "",
      otherFeesAmount: "",
    };
  },
  async mounted() {
    const rfpRef = doc(firestore, "rfps", this.$route.params.id);
    this.rfp = await getDoc(rfpRef);
    this.setData();
    document.title = `Launchpad - Crewfare - RFP - Preview`;
  },
  methods: {
    format(date) {
      return moment(date).format("MM-DD-YYYY");
    },
    setData() {
      this.startDate = this.rfp.data().startDate.toDate();
      this.startDateFormated = moment(this.startDate).format("MM-DD-YYYY");
      this.endDateFormated = moment(this.rfp.data().endDate.toDate()).format(
        "MM-DD-YYYY"
      );
      this.cutoffDate = this.rfp.data().cutoffDate.toDate();
      this.responseDueDate = this.rfp.data().responseDueDate;
      this.decisionDate = this.rfp.data().decisionDate;
      this.roomList = this.rfp.data().roomList;
      this.name = this.rfp.data().name;
      this.ccEmails = this.rfp.data().ccEmails;
      this.attrition = this.rfp.data().attrition;
      this.commission = this.rfp.data().commission;
      this.iata = this.rfp.data().iata;
      this.rebate = this.rfp.data().rebate;
      this.groupDateHonored = this.rfp.data().groupDateHonored;
      this.compRooms = this.rfp.data().compRooms;
      this.staffRooms = this.rfp.data().staffRooms;
      this.suiteUpgrades = this.rfp.data().suiteUpgrades;
      this.staffDiscount = this.rfp.data().staffDiscount;
      this.rewardPoints = this.rfp.data().rewardPoints;
      this.resortFee = this.rfp.data().resortFee;
      this.reservationSubmission = this.rfp.data().reservationSubmission;
      this.billing = this.rfp.data().billing;
      this.incidentals = this.rfp.data().incidentals;
      this.incidentalsOther = this.rfp.data().incidentalsOther;
      this.billingsOther = this.rfp.data().billingsOther;
      this.resortFeeAmount = this.rfp.data().resortFeeAmount;
      this.staffDiscountAmount = this.rfp.data().staffDiscountAmount;
      this.rebateType = this.rfp.data().rebateType;
      this.groupRateTypePre = this.rfp.data().groupRateTypePre;
      this.groupRateTypePost = this.rfp.data().groupRateTypePost;
      this.commissionAmount = this.rfp.data().commissionAmount;
      this.banner = this.rfp.data().banner;
      this.wifi = this.rfp.data().wifi;
      this.breakfast = this.rfp.data().breakfast;
      this.breakfastOther = this.rfp.data().breakfastOther;
      this.parking = this.rfp.data().parking;
      this.parkingOther = this.rfp.data().parkingOther;
      this.nso = this.rfp.data().nso;
      this.lockedFields = this.rfp.data().lockedFields || [];
      this.otherFees = this.rfp.data().otherFees || "";
      this.otherFeesAmount = this.rfp.data().otherFeesAmount || "";
      setTimeout(() => {
        if (this.$refs.termsInput)
          this.$refs.termsInput.innerHTML = this.rfp.data().terms || "";
      }, 1000);
    },
  },
};
</script>
