const bidReopenedHotelEmailContent = `<tr>
  <td style="width: 100%;border-radius: 8px;background: rgba(34, 197, 94, 0.04);border: 1px dashed rgba(34, 197, 94, 1);padding: 48px 24px;text-align:center; ">
    <div style="font: bold 24px Poppins, Helvetica, Arial;color: #292929;">Your bid for ##name## has been reopened</div>
    <div style="margin-top: 24px;font: bold 14px Poppins, Helvetica, Arial;color: #292929;">
    Your offer has been reopened</div>
  </td>
</tr>
<tr>
  <td height="24px"></td>
</tr>
<tr>
  <td style="width: 100%;border-radius: 8px;background: #F5F5F5;padding:24px;text-align: center;">
    <div style="font: 14px Poppins, Helvetica, Arial;color: #292929;">
      We will be in touch very soon!
    </div>
    <a href="##link##"
      style="border-radius: 8px;font: bold 16px Poppins, Helvetica, Arial;display:block;color: #fff;background: #4323FF;text-align: center;width: 180px;margin: 24px auto 0;text-decoration: none;padding: 16px 18px;">View Details</a>
  </td>
</tr>`;

const bidReopenedHotelSubject = 'Crewfare’s Launchpad - Your bid for ##name## has been reopened';

export { bidReopenedHotelEmailContent, bidReopenedHotelSubject };
