<template>
  <div class="bg-gray-900 w-full py-4 flex justify-center gap-4 stage-height items-center text-white relative">
    <div>
      <img src="/logo.svg" class="h-[50px] mb-12 mx-auto" />
      <ConffetiAnimation />
      <div class="text-center relative z-30">
        We are excited to execute our partnership together, please click
        <router-link
          :to="{
            name: 'rfpContract',
            params: { rfp: rfp },
          }"
          class="underline text-crewfareGreen"
        >
          HERE
        </router-link>
        to view the contract!
      </div>
    </div>
  </div>
</template>

<script>
import ConffetiAnimation from '@/components/default/ConffetiAnimation.vue';

export default {
  components: {
    ConffetiAnimation,
  },
  data() {
    return {
      rfp: null,
    };
  },
  mounted() {
    this.rfp = this.$route.params.rfp;
  },
};
</script>
