import { BaseApi, getToken } from './apiFactory';

class HotelsApi extends BaseApi {
  listStates = async (search: string, signal: any) => {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/states?search=${search}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        signal,
      },
    });
  };
}

export const hotelsApi = new HotelsApi({ name: 'hotels' });
