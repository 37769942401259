import Accepted from './Accepted.vue';
import Blocked from './Blocked.vue';
import Contract from './Contract.vue';
import ContractSigned from './ContractSigned.vue';
import CounterBid from './CounterBid.vue';
import Declined from './Declined.vue';
import Form from './Form.vue';
import Admin from './Admin.vue';
import CCAuthorization from './CCAuthorization.vue';
import AuthorizationDone from './AuthorizationDone.vue';
import Bid from './Bid.vue';
import Dashboard from '@/layouts/Dashboard.vue';

export const rfp = [
  {
    path: '/rfp',
    name: 'rfp',
    component: Dashboard,
    children: [
      {
        path: ':rfp/view',
        name: 'rfpView',
        component: Form,
      },
      {
        path: ':rfp/contract',
        name: 'rfpContract',
        component: Contract,
      },
      {
        path: ':rfp/authorization',
        name: 'ccAuthorization',
        component: CCAuthorization,
      },
      {
        path: ':rfp/authorization/done',
        name: 'ccAuthorizationDone',
        component: AuthorizationDone,
      },
      {
        path: ':rfp/contract/signed',
        name: 'rfpContractSigned',
        component: ContractSigned,
      },
      {
        path: ':rfp/accepted',
        name: 'rfpAccepted',
        component: Accepted,
      },
      {
        path: ':rfp/declined',
        name: 'rfpDeclined',
        component: Declined,
      },
      {
        path: ':rfp/counter-bid',
        name: 'rfpCounterBid',
        component: CounterBid,
      },
      {
        path: ':rfp/bid',
        name: 'rfpBid',
        component: Bid,
      },
    ],
  },
];
