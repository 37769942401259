<template>
  <div class="bg-gray-800 w-full flex flex-col stage-height">
    <div class="p-4 flex justify-between bg-gray-900 items-center">
      <h1 class="text-xl text-white">Users</h1>
      <router-link :to="{ name: 'usersAdd' }" class="green-button">
        Add User
      </router-link>
    </div>
    <div class="flex flex-col gap-2 rounded pb-4 relative">
      <div
        class="flex p-4 items-center justify-between bg-gray-800 sticky top-0"
      >
        <div class="flex gap-2 items-center">
          <div class="text-crewfareGreen">
            <strong class="rounded-full bg-black px-3 py-1">{{
              accountsFound
            }}</strong>
            Users
          </div>
        </div>
        <Filter
          :accountsFiltered="accountsFiltered"
          :status="status"
          :types="types"
          @filter="filter"
        />
      </div>
      <div>
        <table
          class="w-full px-4 justify-between py-2 border border-gray-700 border-x-0 border-t-0 text-white"
        >
          <tr class="border-b px-4 border-gray-700">
            <th class="text-white px-4 text-left py-2">Name</th>
            <th class="text-white px-4 text-left">Active At</th>
            <th class="text-white px-4 text-left">Created At</th>
            <th class="flex gap-4 px-4 justify-end"></th>
          </tr>
          <tbody v-if="accounts.length>0" class="divide-y px-4 divide-gray-700">
            <ListItem
              v-for="account in accounts"
              :key="account.id"
              :account="account"
              @getData="reload"
            />
          </tbody>
        </table>
        <div v-if="loading" class="min-h-[100px] flex flex-1 items-center justify-center">
              <span class="material-symbols-rounded animate-spin text-[28px]">
                autorenew
              </span>
        </div>
        <div v-else-if="accounts.length==0" class="min-h-[100px] flex flex-1 items-center justify-center">
          <span class="text-white">No users found</span>
        </div>
        <Pagination
          :max="per_page"
          :actual="actual"
          :total="total"
          @togglePage="togglePage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ListItem, Filter } from "@/components/user";
import Pagination from "@/components/default/Pagination.vue";
import { accountsApi } from "@/utils/apis/accountsApi";
import { debounce } from "@/utils/formatter";
import Loading from "@/components/default/Loading.vue";

export default {
  components: {
    ListItem,
    Filter,
    Pagination,
  },
  data() {
    return {
      types: [
        "admin",
        "group manager",
        "internal",
        "contact",
        "nso",
        "lead manager",
      ],
      status: "active",
      actual: 0,
      loading: false,
      accounts: [],
      accountsFiltered: [],
      search: "",
      per_page: 20,
      total: 0,
      countTracker: {
        controller: new AbortController(),
        count: 0,
      }
    };
  },
  computed: {
    accountsFound() {
      return this.accounts.length;
    },
  },
  async mounted() {
    this.getData();
    document.title = `Launchpad - Crewfare - Users`;
  },
  methods: {
    togglePage(page) {
      this.actual = page;
      this.getData();
    },
    async listAccounts(count, signal){
      this.loading = true;
      const accounts = await accountsApi.list({
        search: this.search,
        status: this.status,
        types: JSON.stringify(this.types),
        page: this.actual,
        per_page: this.per_page,
      }, signal)
      if(count != this.countTracker.count){
        return;
      }
      if(accounts.error){
        alert(accounts.message);
        this.loading = false;
        return;
      }
      const accountsData = accounts.data;
      this.accounts = accountsData.data;
      this.total = accountsData.total;
      this.loading = false;
    },
    getData: debounce(async function(){
      this.countTracker.count++;
      this.countTracker.controller.abort();
      this.countTracker.controller = new AbortController();
      const currentCount = this.countTracker.count;
      const signal = this.countTracker.controller.signal;
      await this.listAccounts(currentCount, signal);
    }, 100),
    reload() {
      this.accounts = [];
      this.getData();
    },
    filter(filters) {
      this.types = filters.type;
      this.search = filters.search;
      this.status = filters.status;
      this.actual = 0;
      this.reload();
    },
  },
};
</script>
