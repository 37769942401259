<template>
  <div class="border my-2 rounded px-4 py-2 flex">
    <div class="flex-grow">
      <div class="flex flex-col gap-2">
        <p class="text-2xl">{{ rfp.name }}</p>
        <p v-if="rfp.event_name">Event: {{ rfp.event_name }}</p>
      </div>
      <div v-if="rfp.venue" class="flex items-center text-sm">
        Venue: {{ rfp.venue }}
      </div>
      <div class="flex items-center text-sm">
        Created by {{ rfp.created_by }}
      </div>
      <div class="flex items-center text-sm">
        From {{ formatedStartDate }} to {{ formatedEndDate }}
      </div>
    </div>
    <div class="flex items-center">
      <button @click="duplicateRfp()" class="green-button gap-2">
        <span v-if="loading" class="material-symbols-rounded animate-spin">
          autorenew
        </span>
        Duplicate
      </button>
    </div>
  </div>
</template>

<script>
import { getMomentFromFirebaseDate } from "@/utils/dateUtils";
import { createSimpleRFP, getRFP } from "@/utils/rfp";

export default {
  props: ["rfp"],
  data() {
    return {
      loading: false,
      account: this.$store.state.account,
    };
  },
  computed: {
    formatedStartDate() {
      if (!this.rfp.startDate) return "";
      const startDate = this.rfp.startDate;
      return getMomentFromFirebaseDate(startDate).format("ddd MMM. DD, YYYY");
    },
    formatedEndDate() {
      if (!this.rfp.endDate) return "";
      const endDate = this.rfp.endDate;
      return getMomentFromFirebaseDate(endDate).format("ddd MMM. DD, YYYY");
    },
  },
  methods: {
    async duplicateRfp() {
      this.loading = true;
      await createSimpleRFP(this.rfp.id)
      this.loading = false;
    },
  },
};
</script>
