<template>
  <div class="divide-x border rounded text-xs">
    <button
      @click="toggleType('list')"
      class="px-4 py-1"
      :class="type === 'list' ? 'bg-crewfareGreen text-gray-900' : 'text-white'"
    >
      List
    </button>
    <button
      @click="toggleType('map')"
      class="px-4 py-1"
      :class="type === 'map' ? 'bg-crewfareGreen text-gray-900' : 'text-white'"
    >
      Map
    </button>
  </div>
</template>

<script>
export default {
  props: ["type"],
  methods: {
    toggleType(type) {
      this.$emit("toggleType", type);
    },
  },
};
</script>
