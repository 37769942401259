<template>
  <aside
    v-if="account"
    class="z-[100] flex w-full min-h-[100vh] transition-all pr-4 py-4 sticky top-0 flex-col gap-2 print:hidden"
    :class="menuExpanded ? 'min-w-[220px] pl-4' : 'pl-4'"
  >
    <div class="relative flex items-center w-full" :class="!menuExpanded && 'justify-center'">
      <router-link to="/rfps" class="text-2xl">
        <img :src="menuExpanded ? '/logo.svg' : '/logo-mini.svg'" class="h-[40px]" />
      </router-link>
      <button
        @click="toggleMenuStatus"
        class="absolute -right-[30px] bg-[#777E90] rounded-full w-[24px] h-[24px] z-[70]"
      >
        <span class="flex justify-center items-center text-[#A4CDE3]">
          <font-awesome-icon :icon="menuExpanded ? 'chevron-left' : 'chevron-right'" />
        </span>
      </button>
    </div>
    <menu v-if="account" class="flex flex-col flex-1 gap-2 mt-8 w-full text-white">
      <li v-for="(item, index) in menuPermissions" :key="index">
        <span v-if="item.children">
          <span
            v-if="menuExpanded"
            @click="item.subActive = !item.subActive"
            :class="item.subActive || (isOnChildren(item) && 'bg-[#2B3236]')"
            class="cursor-pointer flex text-base gap-2 py-2 px-4 items-center rounded border border-transparent flex flex-col w-full"
          >
            <div class="flex justify-between w-full items-center">
              <div class="flex gap-2 items-center w-full">
                <font-awesome-icon :icon="['fas', item.icon]" />
                <span v-if="menuExpanded">{{ item.text }}</span>
              </div>
              <font-awesome-icon :icon="['fas', item.subActive ? 'caret-up' : 'caret-down']" />
            </div>
            <ul v-if="item.subActive || isOnChildren(item)" class="mt-2 w-full">
              <li v-for="(children, indexChildren) in item.children" :key="indexChildren">
                <router-link
                  :to="children.to"
                  class="flex text-white gap-4 py-2 ml-6 items-center"
                  active-class="!text-crewfareGreen"
                >
                  <span v-if="menuExpanded">{{ children.text }}</span>
                </router-link>
              </li>
            </ul>
          </span>
          <span v-if="!menuExpanded" class="relative">
            <dropdown-menu>
              <template #trigger>
                <button
                  class="w-full relative flex justify-center flex-row items-center gap-2 relative"
                  type="button"
                  ref="dropdownTrigger"
                >
                  <font-awesome-icon :icon="['fas', item.icon]" />
                  <font-awesome-icon class="absolute -right-1" :icon="['fas', 'caret-down']" />
                </button>
              </template>
              <template #body>
                <FwbListGroup class="absolute left-2 top-6 z-30 w-[120px] dark:bg-gray-700 bg-gray-700">
                  <FwbListGroupItem
                    v-for="(children, indexChildren) in item.children"
                    :key="indexChildren"
                    class="!px-0 !py-0"
                  >
                    <button
                      @click="() => navigate(children.to)"
                      class="flex gap-4 w-full py-2 px-4 items-center"
                      :class="$route.path === children.to && '!text-crewfareGreen'"
                    >
                      <span>{{ children.text }}</span>
                    </button>
                  </FwbListGroupItem>
                </FwbListGroup>
              </template>
            </dropdown-menu>
          </span>
        </span>
        <router-link
          v-else
          :to="item.to"
          class="flex text-base gap-2 py-2 px-4 items-center rounded border border-transparent"
          active-class="bg-[#2B3236] text-crewfareGreen"
        >
          <font-awesome-icon :icon="['fas', item.icon]" />
          <span v-if="menuExpanded">{{ item.text }}</span>
        </router-link>
      </li>
    </menu>
    <div v-if="menuExpanded" class="flex flex-col gap-4 text-white text-base border-t border-t-[#777E90] py-4">
      <a href="mailto:travel@crewfare.com" class="flex items-center hover:text-sky-600 gap-2">
        <font-awesome-icon icon="question-circle" />
        Help
      </a>
      <a class="text-sm" href="#" onclick="Osano.cm.showDrawer('osano-cm-dom-info-dialog-open')">Cookie Preferences</a>
      <div class="text-[#A4CDE3] text-sm">
        <a href="https://crewfare.com" target="_blank" class="hover:text-sky-600">Crewfare</a>
        &copy;
        {{ year }}
      </div>
    </div>
  </aside>
</template>

<script>
import { FwbDropdown, FwbListGroup, FwbListGroupItem } from 'flowbite-vue';

export default {
  components: { FwbDropdown, FwbListGroup, FwbListGroupItem },
  data() {
    return {
      menu: [
        // {
        //   text: "Dashboard",
        //   icon: "th-large",
        //   to: "/dashboard",
        //   type: [
        //     "admin",
        //     "contact",
        //     "nso",
        //     "lead manager",
        //     "internal",
        //     "group manager",
        //   ],
        //   check: false,
        // },
        {
          text: 'RFPs',
          icon: 'stream',
          to: '/rfps',
          type: ['admin', 'contact', 'nso', 'lead manager', 'internal', 'group manager'],
          check: true,
        },
        {
          text: 'Messages',
          icon: 'message',
          to: '/messages',
          type: ['admin', 'contact', 'nso', 'lead manager', 'internal', 'group manager'],
          check: false,
        },
        {
          text: 'Search',
          icon: 'search',
          to: '/search',
          type: ['admin', 'internal'],
          check: true,
        },
        {
          text: 'My group',
          icon: 'hotel',
          type: 'Group Manager',
          to: '/group',
          type: ['group manager'],
          check: true,
        },
        {
          text: 'Hotels',
          icon: 'hotel',
          type: 'nso',
          to: '/nso',
          type: ['nso', 'lead manager', 'lead manager'],
          check: true,
        },
        {
          text: 'Hotels',
          icon: 'hotel',
          to: '/hotels/list',
          type: ['admin', 'internal'],
          check: true,
          children: [
            {
              text: 'All',
              to: '/hotels/list',
              check: true,
            },
            {
              text: 'Groups',
              to: '/hotels/groups',
              check: true,
            },
            {
              text: 'Chain',
              to: '/hotels/chain',
              check: true,
            },
          ],
        },
        {
          text: 'Files',
          icon: 'folder',
          to: '/files',
          type: ['admin', 'contact', 'nso', 'lead manager', 'internal', 'group manager'],
          check: true,
        },
        {
          text: 'Users',
          icon: 'user',
          to: '/users',
          type: ['admin'],
          check: true,
        },
      ],
    };
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
    account() {
      return this.$store.state.account;
    },
    menuExpanded() {
      return this.$store.state.menuStatus;
    },
    permissions() {
      return this.$store.state.account?.permissions || null;
    },
    menuPermissions() {
      const items = [];
      this.menu.forEach(item => {
        if (item.type.includes(this.account.type.toLowerCase())) {
          items.push(item);
        }
      });
      return items;
    },
  },
  methods: {
    navigate(to) {
      this.$router.push(to);
      this.$refs.dropdownTrigger[0].click();
    },
    toggleMenuStatus() {
      this.$store.commit('setMenuStatus', !this.menuExpanded);
    },
    isOnChildren(item) {
      return item.children.some(child => {
        return this.$route.path === child.to;
      });
    },
  },
};
</script>
