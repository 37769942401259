import { addInfoToEmailCollection } from '@/utils/emailsStatus.jsx';
const sendEmail = async ({
  to,
  replyTo,
  content,
  storeMail,
  subject,
  sub,
  template = 'emails.default',
  title = '',
  fromEmail = 'travel@crewfare.com',
  fromName = 'Crewfare',
  banner = '',
  cc = [],
  attachment,
  versions,
}) => {
  if (banner?.includes('Default_Banner_image.png')) {
    banner = '';
  }
  const randomNumber = Math.random() * 2000;
  await new Promise(r => setTimeout(r, randomNumber));
  if (typeof to === 'string') to = to.toLowerCase().trim();
  fromEmail = fromEmail.toLowerCase().trim();
  const data = {
    content,
    to,
    replyTo,
    subject,
    sub,
    fromEmail,
    fromName,
    title,
    template,
    banner,
    attachment,
    cc,
    versions,
  };
  const emailSent = await fetch(`${import.meta.env.VITE_API_URL}/sendEmail`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then(response => response.json() || [])
    .then(body => {
      let emailInfosToStore = [];
      body.forEach((data, index) => {
        const headers = data.headers || [];
        const xMessageId = headers.find(header => header.search('X-Message-Id') === 0);
        if (xMessageId) {
          let [_, messageId] = xMessageId.split(':');
          messageId = messageId.trim();
          let storeMailData = { toStore: false };
          if (versions && versions[index]?.storeMail) {
            storeMailData.toStore = true;
            storeMailData.info = versions[index].storeMail;
            to = versions[index].to;
          } else if (storeMail) {
            storeMailData.to = true;
            storeMailData.info = storeMail;
          }
          if (storeMailData.toStore) {
            console.log('Store Mail Data', storeMailData);
            const emailInfo = {
              messageId,
              to: to || '',
              from: fromEmail || '',
              subject: subject || '',
              rfp_id: storeMailData.info.rfp_id,
              type: storeMailData.info.type,
            };
            const emailKeys = Object.keys(emailInfo);
            if (
              !(
                emailKeys.includes('messageId') &&
                emailKeys.includes('rfp_id') &&
                emailKeys.includes('type') &&
                emailKeys.includes('to')
              )
            ) {
              console.error('messageId, to, rfp_id and type are required to store the email in the database');
              return false;
            } else {
              emailInfosToStore.push(emailInfo);
            }
          }
        }
      });
      if (emailInfosToStore.length > 0) {
        console.log('Emails to store', emailInfosToStore.length);
        return Promise.all(emailInfosToStore.map(emailInfo => addInfoToEmailCollection(emailInfo)));
      }
      return true;
    })
    .catch(error => {
      alert('Some error was occured in sending email, please check console for more details');
      console.error('Error sending/saving email', error);
      return false;
    });
  return emailSent;
};

const processVariables = (content, variables) => {
  if (!variables) return content;
  let newContent = content;
  Object.keys(variables).forEach(key => {
    const regex = new RegExp(`##${key}##`, 'g');
    newContent = newContent.replace(regex, variables[key]);
  });
  return newContent;
};

export { sendEmail, processVariables };
