<template>
  <div v-if="isMounting" class="flex justify-center min-h-[100vh]">
    <Loading size="text-[30px]"/>
  </div>
  <div
    v-else-if="account"
    id="bg-login"
    class="bg-black min-h-[100vh] bg-center bg-no-repeat bg-cover flex items-center"
  >
    <div class="max-w-[548px] w-full mx-auto px-6 pt-16 pb-6">
      <div class="flex flex-col gap-12">
        <img src="/logo.svg" class="h-[60px]" />
        <div class="rounded-3xl bg-[#23262F]">
          <div class="max-w-[480px] mx-auto p-6 md:p-11 flex flex-col gap-4">
            <p class="text-white text-center text-2xl">
              Password creation
            </p>
            <p class="text-neutral-400 text-center text-sm">Welcome to crewfare, you can create your password now</p>
            <form
              @submit.prevent="doCreateAccount"
              class="flex flex-col gap-4 mt-4"
            >
              <div class="flex flex-col gap-3">
                <label for="emailInput" class="text-white font-normal text-base">
                  Email
                </label>
                <input
                  id="emailInput"
                  class="px-3 leading-12 rounded-lg bg-[#2F2F31] border-0 text-white"
                  type="text"
                  required
                  v-model="email"
                  placeholder="Enter email"
                />
              </div>
              <div class="flex flex-col gap-3">
                <label for="passwordInput" class="text-white font-normal text-base">
                  Password
                </label>
                <div class="relative flex items-center">
                  <input
                    v-if="showPassword"
                    id="passwordInput"
                    class="px-3 w-full rounded-lg leading-12 bg-[#2F2F31] border-0 text-white"
                    type="text"
                    required
                    v-model="password"
                    placeholder="Enter password"
                  />
                  <input
                    v-else
                    id="passwordInput"
                    class="px-3 w-full rounded-lg leading-12 bg-[#2F2F31] border-0 text-white"
                    type="password"
                    required
                    v-model="password"
                    placeholder="Enter password"
                  />
                  <div class="absolute right-3">
                    <label
                      for="toggleShow"
                      class="text-[#A3A3A3]"
                    >
                      <input
                        v-model="showPassword"
                        type="checkbox"
                        id="toggleShow"
                        class="opacity-0 absolute"
                      />
                      <font-awesome-icon
                      class="cursor-pointer"
                        :icon="['fas', showPassword ? 'eye' : 'eye-slash']"
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div class="flex flex-col gap-3">
                <label for="passwordConfirmInput" class="text-white font-normal text-base">
                  Repeat your password
                </label>
                <div class="relative flex items-center">
                  <input
                    v-if="showConfirmPassword"
                    id="passwordConfirmInput"
                    class="px-3 w-full rounded-lg leading-12 bg-[#2F2F31] border-0 text-white"
                    type="text"
                    required
                    v-model="confirmPassword"
                    placeholder="Repeat your password"
                  />
                  <input
                    v-else
                    id="passwordConfirmInput"
                    class="px-3 w-full rounded-lg leading-12 bg-[#2F2F31] border-0 text-white"
                    type="password"
                    required
                    v-model="confirmPassword"
                    placeholder="Repeat your password"
                  />
                  <div class="absolute right-3">
                    <label
                      for="toggleShow"
                      class="text-[#A3A3A3]"
                    >
                      <input
                        v-model="showConfirmPassword"
                        type="checkbox"
                        id="toggleShow"
                        class="opacity-0 absolute"
                      />
                      <font-awesome-icon
                      class="cursor-pointer"
                        :icon="['fas', showConfirmPassword ? 'eye' : 'eye-slash']"
                      />
                    </label>
                  </div>
                </div>
              </div>
              <button
                :disabled="!valid"
                class="bg-[#4323FF] rounded-lg text-center text-white leading-10"
              >
                  {{ loading ? "Loading..." : "Create account" }}
              </button>
            </form>
              <p
                  v-if="error"
                  v-html="message"
                  class="text-red-600 mt-2 px-2 text-center text-sm max-w-[420px]"
              ></p>
              <div class="flex justify-center mt-2">
                <a href="/" class="text-neutral-400 text-sm">
                  <font-awesome-icon
                    class="mr-2"
                    :icon="['fas', 'arrow-left']" />
                  Back to log in
                </a>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/default/Loading.vue";
import router from "@/router";
import { accountsApi } from "@/utils/apis/accountsApi";


export default {
  name: "CreateAccount",
  components: {Loading},
  data: () => {
    return {
      email: "",
      password: "",
      confirmPassword: "",
      account: null,
      error: false,
      showPassword: false,
      showConfirmPassword: false,
      loading: false,
      message: "",
      isMounting: true,
    };
  },
  computed: {
    valid() {
      return this.email.length > 0 && this.password.length >= 6 && this.password === this.confirmPassword;
    },
  },
  mounted() {
    if (this.$route.params.accountId) {
      this.getAccount();
    }
    else{
      this.isMounting = false
    }
  },
  methods: {
    async getAccount() {
      const account = await accountsApi.get(this.$route.params.accountId);
      if (!account.error) {
        this.account = account.data;
        this.email = this.account.email.toLowerCase().trim();
      }
      else{
        router.push({ name: "home" });
      }
     this.isMounting = false;
    },
    async doCreateAccount() {
      this.loading = true;
      this.error = false;
      this.message = "";
      const created = await accountsApi.createPassword({
        email: this.email,
        password: this.password,
        accountId: this.$route.params.accountId,
      })
      console.log("Created",created);
      if (created.error) {
        this.error = true;
        this.message = created.message;
        this.loading = false;
      } else {
        this.error = false;
        this.message = "Account created successfully";
        setTimeout(() => {
          router.push("/?accountId=" + this.$route.params.accountId);
        }, 2000);
      }
    },
  },
};
</script>
