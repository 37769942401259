<template>
  <div
    v-if="hotel"
    class="bg-gray-800 w-full flex flex-col stage-height text-white"
  >
    <div class="p-4 flex justify-between bg-gray-900 items-center">
      <div class="flex gap-4 h-[42px] items-center">
        <router-link
          :to="{ name: 'hotelsGroups' }"
          class="material-symbols-rounded hover:text-crewfareGreen"
        >
          chevron_left
        </router-link>
        <h1 class="text-xl">{{ hotelName }} - Contacts</h1>
      </div>
      <router-link
        :to="{
          name: 'groupHotelContactsAdd',
          params: { id: $route.params.id },
        }"
        class="green-button"
      >
        <span class="material-symbols-rounded">add</span>
        Contact
      </router-link>
    </div>
    <div class="flex flex-col gap-2 bg-gray-800 rounded pb-4 relative">
      <div
        class="flex px-4 items-center justify-between sticky top-0 py-2 bg-gray-800"
      >
        <div class="flex gap-2 items-center">
          <div class="text-crewfareGreen">
            <strong class="rounded-full bg-black px-3 py-1">{{
              contactsFound
            }}</strong>
            Contacts
          </div>
        </div>
      </div>
      <div class="">
        <div
          class="grid grid-cols-5 px-4 flex justify-between py-2 border border-gray-700 border-x-0 border-t-0"
        >
          <div class="flex gap-4 group text-white">Name</div>
          <div class="flex gap-4 group text-white">Role</div>
          <div class="flex gap-4 group text-white">Email</div>
          <div class="flex gap-4 group text-white">Phone</div>
          <div class="flex gap-4 justify-end"></div>
        </div>
        <div v-if="contacts.length > 0" class="divide-y divide-gray-700">
          <ListItem
            v-for="(contact, index) in contacts"
            :hotel_id="hotel.id"
            :key="index"
            :contact="contact"
            @getData="getData"
          />
        </div>
        <div v-else class="divide-y divide-gray-700">
          <ListItemSkeleton />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { firestore } from "@/utils/firebase";
import {
  getDocs,
  query,
  collection,
  where,
  doc,
  getDoc,
} from "firebase/firestore";
import { ListItemSkeleton, ListItem } from "@/components/hotels/contacts";

export default {
  components: {
    ListItem,
    ListItemSkeleton,
  },
  data() {
    return {
      hotelName: "",
      hotel: null,
      contacts: [],
    };
  },
  computed: {
    contactsFound() {
      return this.contacts.length || 0;
    },
  },
  async mounted() {
    const hotelRef = doc(firestore, "hotels", this.$route.params.id);
    this.hotel = await getDoc(hotelRef);
    this.hotelName = this.hotel.data().name;
    document.title = `Launchpad - Crewfare - Contacts - ${this.hotelName}`;
    this.getData();
  },
  methods: {
    async getData() {
      const qContacts = query(
        collection(firestore, "contacts"),
        where("hotel_id", "==", this.$route.params.id)
      );
      const contacts = await getDocs(qContacts);
      this.contacts = contacts.docs;
    },
  },
};
</script>
