import { AnyObject } from 'chart.js/dist/types/basic';
import { BaseApi, getToken } from './apiFactory';

class AccountsApi extends BaseApi {
  async me() {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/me`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  async createPassword(body: AnyObject) {
    return this.axios({
      url: `${this.apiUrl}/password`,
      method: 'POST',
      data: body,
    });
  }
}

export const accountsApi = new AccountsApi({ name: 'account' });
