<template>
  <div>
    <div class="flex justify-between">
      <router-link
        :to="{ name: 'hotelsList' }"
        class="flex px-4 cursor-pointer py-2 justify-between items-center"
      >
        <span class="text-xl text-crewfareGreen flex gap-2 items-center">
          <span class="material-symbols-rounded hover:text-crewfareGreen">
            chevron_left
          </span>
          {{ $route.params.state }}
        </span>
      </router-link>
    </div>
    <div v-if="!loading" class="w-full">
      <table class="w-full py-2">
        <thead class="py-2 text-white border-b border-gray-700">
          <tr>
            <th class="px-4 py-2 font-regular text-left">Hotel Name</th>
            <th class="px-4 font-regular text-left">Chain</th>
            <th class="px-4 font-regular text-left">Rooms</th>
            <th class="px-4 font-regular text-left">Contact</th>
          </tr>
        </thead>
        <tbody class="divide-y divide-dashed divide-gray-700">
          <ListItem
            v-for="hotel in hotels"
            :key="hotel.id"
            :hotel="hotel"
            @getData="getData"
          />
        </tbody>
      </table>
    </div>
    <div v-else class="w-full">
      <div class="w-full min-h-[120px] flex items-center justify-center">
        <span class="material-symbols-rounded animate-spin text-[26px]">
          autorenew
        </span>
      </div>
    </div>
    <Pagination
      :actual="actual"
      :max="15"
      :total="countTotal"
      @togglePage="togglePage"
    />
  </div>
</template>

<script>
import { ListItem, ListItemSkeleton, Filters } from "@/components/hotels";
import Pagination from "@/components/default/Pagination.vue";
import { firestore } from "@/utils/firebase";
import {
  getDocs,
  query,
  where,
  collection,
  orderBy,
  getCountFromServer,
} from "firebase/firestore";
import { hotelsApi } from "@/utils/apis/hotelsApi";
import { debounce } from "@/utils/formatter";

export default {
  props: ["stateList", "filteredHotels", "list", "search"],
  data(){
      return {
      loading: true,
      actual: 0,
      countTotal: 0,
      hotels: [],
      searchCount:0
    }
    },
  components: {
    ListItem,
    ListItemSkeleton,
    Filters,
    Pagination,
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  watch:{
    search:debounce(function(){
      this.actual = 0
      this.getData()
    },1000)
  },
  mounted() {
    this.getData();
  },
  methods: {
    togglePage(page) {
      this.actual = page;
      this.getData()
    },
    parseData(list) {
      const hotelsList = [];
      list.forEach((hotel) => {
        hotelsList.push({
          id: hotel.id,
          name: hotel.data().name,
          contacts: hotel.data().contacts,
          status_history: hotel.data().status_history,
          isPartner: hotel.data().isPartner,
          rooms: hotel.data().rooms,
          room_goal: hotel.data().room_goal,
          chain: hotel.data().chain,
        });
      });
      return hotelsList;
    },
    async getData() {
      this.loading = true;
      this.searchCount++;
      this.searchHotels(this.searchCount)
    },
    async searchHotels(currentCount){
      let hotels = await hotelsApi.list({search: this.search, state: this.$route.params.state, page: this.actual}).then(_=>_.data)
      if(currentCount!==this.searchCount){
        console.log("Data is stale")
        return;
      }
      console.log("HOtels from api search", hotels)
      this.countTotal = hotels.total
      this.hotels = hotels.data
      this.loading = false
    },
  },
};
</script>
