<template>
  <div class="flex justify-between px-4 my-4">
    <div></div>
    <div>
      <input
        type="search"
        class="w-full bg-gray-700 px-4 rounded"
        v-model="search"
        placeholder="Search Hotels"
      />
    </div>
  </div>
  <table
    class="w-full px-4 justify-between py-2 border border-gray-700 border-x-0 border-t-0"
  >
    <tr class="border-b border-gray-700">
      <th class="text-white text-left p-4">RFP Name</th>
      <th class="text-white text-left px-4">Hotel</th>
      <th class="text-white text-left px-4">Venue</th>
      <th class="text-white text-left px-4">Event Dates</th>
      <th class="text-white text-left px-4">Due Date</th>
      <th class="text-white text-left px-4">Last Update At</th>
      <th class="text-white text-left px-4"></th>
    </tr>
    <tbody class="divide-y divide-gray-700">
      <ListContactTableRFP
        v-for="(rfp, index) in sliceRfps"
        :key="index"
        :rfp="rfp"
        @getData="getData"
      />
    </tbody>
  </table>
  <Pagination
    :actual="actual"
    :max="5"
    :total="countTotal"
    @togglePage="togglePage"
  />
</template>

<script>
import { ListContactTableRFP } from "@/components/dashboard";
import Pagination from "@/components/default/Pagination.vue";
export default {
  props: ["rfps"],
  components: { ListContactTableRFP, Pagination },
  data() {
    return {
      actual: 0,
      search: "",
    };
  },
  computed: {
    rfpFiltered() {
      if (this.search.length > 0) {
        return [...this.rfps].filter(
          (rfp) =>
            rfp.data().name.toLowerCase().indexOf(this.search.toLowerCase()) >=
              0 ||
            rfp
              .data()
              .hotel_name.toLowerCase()
              .indexOf(this.search.toLowerCase()) >= 0 ||
            rfp.data().venue.toLowerCase().indexOf(this.search.toLowerCase()) >=
              0
        );
      } else {
        return this.rfps;
      }
    },
    sliceRfps() {
      const sliceStart = this.actual * 5;
      return this.rfpFiltered.slice(sliceStart, sliceStart + 5);
    },
    countTotal() {
      return this.rfpFiltered.length;
    },
  },
  methods: {
    getData() {
      this.$emit("getData");
    },
    togglePage(page) {
      this.actual = page;
    },
    async getRfpPage() {
      this.loading = true;

      this.loading = false;
    },
  },
};
</script>
