import { firestore } from '@/utils/firebase';
import { addDoc, query, getDocs, collection, where } from 'firebase/firestore';

const getChain = async name => {
  const qChains = query(collection(firestore, 'chains'), where('name', '==', name));
  return await getDocs(qChains);
};

const createChain = async name => {
  return await addDoc(collection(firestore, 'chains'), {
    created_at: new Date(),
    updated_at: new Date(),
    name,
  });
};

export { getChain, createChain };
