import List from './List.vue';
import File from './File.vue';
import Dashboard from '@/layouts/Dashboard.vue';

export const files = [
  {
    path: '/files',
    name: 'files',
    meta: {
      view: 'Files',
    },
    component: Dashboard,
    children: [
      {
        path: '',
        name: 'filesList',
        component: List,
      },
      {
        path: ':id',
        name: 'filesFile',
        component: File,
      },
    ],
  },
];
