<template>
  <div class="bg-[#141416]">
    <div v-if="loading" class="flex justify-center min-h-[100vh]">
      <Loading size="text-[30px]" />
    </div>
    <div v-if="!loading">
      <div v-if="isOpenRoute || account">
        <RouterView />
      </div>
      <div v-else>
        <LoginDialog />
      </div>
      <div v-if="account">
        <Messages />
        <NotesDialog />
      </div>
    </div>
    <Toast />
  </div>
</template>

<script>
import { RouterView } from "vue-router";
import Toast from "@/components/default/Toast.vue";
import NotesDialog from "@/components/default/NotesDialog.vue";
import Messages from "@/components/floatInbox/Messages.vue";
import LoginDialog from "@/components/default/LoginDialog.vue";
import Loading from "@/components/default/Loading.vue";
import { auth, firestore } from "@/utils/firebase";
import {
  doc,
  collection,
  setDoc,
  where,
  query,
  getDocs,
} from "firebase/firestore";
import { checkPermission } from "@/utils/permissionVerify.jsx";
import { onAuthStateChanged } from "firebase/auth";
import { accountsApi } from "./utils/apis/accountsApi";

export default {
  computed: {
    isOpenRoute(){
      return ["forgotPassword","invite","resetPassword"].includes(this.$route.name)
    },
    account() {
      return this.$store.state.account;
    },
  },
  components: {
    LoginDialog,
    RouterView,
    NotesDialog,
    Messages,
    Toast,
    Loading,
  },
  data() {
    return {
      loading: true,
      loginRoute: false,
      isRfp: false,
    };
  },
  async mounted() {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log("Got user", user);
        this.$store.commit("setUser", user);
        const me = await accountsApi.me();
        if(me.error){
          this.loading = false;
          return;
        }
        const account = me.data;
        await this.$store.commit("setAccount", account);
        this.loading = false;
      } else {
        this.loading = false;
      }
    });
  },
};
</script>
