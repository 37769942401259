<template>
  <div
    id="bg-login"
    class="bg-black min-h-[100vh] bg-center bg-no-repeat flex items-center"
  >
    <div class="max-w-[548px] w-full mx-auto px-6 pt-16 pb-8">
      <div class="flex flex-col gap-12">
        <img src="/logo.svg" class="h-[60px]" />
        <div class="rounded-3xl bg-[#23262F]">
          <div class="max-w-[400px] mx-auto w-full p-6 py-11 md:px-0 flex flex-col gap-4">
            <div v-if="!isRfp" class="flex flex-col gap-4">
              <p class="text-white text-center text-2xl">Log In</p>
              <p class="text-neutral-400 text-center text-sm">Welcome back! Please enter your details</p>
            </div>
            <div v-if="accountID && isRfp">
              <p class="text-white text-center text-2xl">Welcome to Launchpad!</p>
              <div class="text-center">
                <p class="my-4 text-white font-bold">Please select below:</p>
              </div>
              <div class="flex justify-center rounded-full bg-[#2F2F31] p-1 h-[40px]">
                <button
                  @click="stepType = 1"
                  class="w-full text-center rounded-full"
                  :class="stepType === 1 ? 'bg-[#4323FF] text-white' : 'text-[#A3A3A3]'"
                >
                  I have an account
                </button>
                <button
                  @click="stepType = 2"
                  class="w-full text-center rounded-full"
                  :class="stepType === 2 ? 'bg-[#4323FF] text-white' : 'text-[#A3A3A3]'"
                >
                  New account
                </button>
              </div>
            </div>
            <form v-if="stepType === 1" @submit.prevent="doLogin" class="flex flex-col gap-4">
              <div v-if="isRfp" class="flex flex-col gap-2 justify-start">
                <p class="text-white font-semibold text-2xl">Log In</p>
                <p class="text-neutral-400 text-sm">Welcome back! Please enter your details</p>
              </div>
              <div class="flex flex-col gap-3">
                <label for="emailInput" class="text-white text-base">
                  Email
                </label>
                <input
                  id="emailInput"
                  class="px-3 leading-12 bg-[#2F2F31] border-0 text-white"
                  type="text"
                  required
                  v-model="email"
                  placeholder="Email"
                />
              </div>
              <div class="flex flex-col gap-3">
                <label for="passwordInput" class="text-white text-base">
                  Password
                </label>
                <div class="relative flex items-center">
                  <input
                    v-if="showPassword"
                    id="passwordInput"
                    class="px-3 w-full leading-12 bg-[#2F2F31] border-1 border-[#2F2F31] text-white"
                    :class="error && 'border-[#FF6A55]'"
                    type="text"
                    required
                    v-model="password"
                    placeholder="Password"
                  />
                  <input
                    v-else
                    id="passwordInput"
                    class="px-3 w-full leading-12 bg-[#2F2F31] border-1 border-[#2F2F31] text-white"
                    :class="error && 'border-[#FF6A55]'"
                    type="password"
                    required
                    v-model="password"
                    placeholder="Password"
                  />
                  <div class="absolute right-3">
                    <label
                      for="toggleShow"
                      class="text-[#A3A3A3]"
                    >
                      <input
                        v-model="showPassword"
                        type="checkbox"
                        id="toggleShow"
                        class="opacity-0 absolute"
                      />
                      <font-awesome-icon
                      class="cursor-pointer"
                        :icon="['fas', showPassword ? 'eye' : 'eye-slash']"
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div class="flex justify-end">
                <a href="/forgot-password" class="text-white text-sm">
                  Forgot your password
                </a>
              </div>
              <p
                v-if="error"
                v-html="message"
                class="text-red-600 mt-2 px-2 text-center text-sm max-w-[420px]"
              ></p>
              <button
                :disabled="!valid"
                class="disabled:opacity-60 bg-[#4323FF] rounded-lg text-center text-white leading-10"
              >
                {{ loading ? "Loading..." : "Log in" }}
              </button>
            </form>
            <form v-if="stepType === 2" @submit.prevent="doCreateAccount" class="flex flex-col gap-4">
              <div v-if="isRfp" class="flex flex-col gap-2">
                <p class="text-white font-semibold text-2xl">New account</p>
                <p class="text-neutral-400 text-sm">Welcome! Please enter your details below</p>
              </div>
              <div class="flex flex-col gap-3">
                <label for="emailInput" class="text-white text-base">
                  Email
                </label>
                <input
                  id="emailInput"
                  class="px-3 leading-12 bg-[#2F2F31] border-0 text-white"
                  type="text"
                  required
                  v-model="email"
                  placeholder="Email"
                />
              </div>
              <div class="flex flex-col gap-3">
                <label for="passwordInput" class="text-white text-base">
                  Password
                </label>
                <div class="relative flex items-center">
                  <input
                    v-if="showPasswordCreation"
                    id="passwordInput"
                    class="px-3 w-full leading-12 bg-[#2F2F31] border-1 border-[#2F2F31] text-white"
                    :class="error && 'border-[#FF6A55]'"
                    type="text"
                    required
                    v-model="passwordCreation"
                    placeholder="Password"
                  />
                  <input
                    v-else
                    id="passwordInput"
                    class="px-3 w-full leading-12 bg-[#2F2F31] border-1 border-[#2F2F31] text-white"
                    :class="error && 'border-[#FF6A55]'"
                    type="password"
                    required
                    v-model="passwordCreation"
                    placeholder="Password"
                  />
                  <div class="absolute right-3">
                    <label
                      for="toggleShow"
                      class="text-[#A3A3A3]"
                    >
                      <input
                        v-model="showPasswordCreation"
                        type="checkbox"
                        id="toggleShow"
                        class="opacity-0 absolute"
                      />
                      <font-awesome-icon
                      class="cursor-pointer"
                        :icon="['fas', showPasswordCreation ? 'eye' : 'eye-slash']"
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div class="flex flex-col gap-3">
                <label for="passwordInput" class="text-white text-base">
                  Repeat your password
                </label>
                <div class="relative flex items-center">
                  <input
                    v-if="showConfirmPassword"
                    id="passwordInput"
                    class="px-3 w-full leading-12 bg-[#2F2F31] border-1 border-[#2F2F31] text-white"
                    :class="error && 'border-[#FF6A55]'"
                    type="text"
                    required
                    v-model="confirmPassword"
                    placeholder="Password"
                  />
                  <input
                    v-else
                    id="passwordInput"
                    class="px-3 w-full leading-12 bg-[#2F2F31] border-1 border-[#2F2F31] text-white"
                    :class="error && 'border-[#FF6A55]'"
                    type="password"
                    required
                    v-model="confirmPassword"
                    placeholder="Password"
                  />
                  <div class="absolute right-3">
                    <label
                      for="toggleShow"
                      class="text-[#A3A3A3]"
                    >
                      <input
                        v-model="showConfirmPassword"
                        type="checkbox"
                        id="toggleShow"
                        class="opacity-0 absolute"
                      />
                      <font-awesome-icon
                      class="cursor-pointer"
                        :icon="['fas', showConfirmPassword ? 'eye' : 'eye-slash']"
                      />
                    </label>
                  </div>
                </div>
              </div>
              <p
                v-if="error"
                v-html="message"
                class="text-red-600 mt-2 px-2 text-center text-sm max-w-[420px]"
              ></p>
              <button
                :disabled="!validCreation"
                class="disabled:opacity-60 bg-[#4323FF] rounded-lg text-center text-white leading-10"
              >
                {{ loading ? "Creating account..." : "Create account" }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ForwardDialog from "@/components/rfp/ForwardDialogWhite.vue";
import {
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth, firestore } from "@/utils/firebase";
import {
  doc,
  collection,
  setDoc,
  where,
  or,
  query,
  getDocs,
  getDoc,
} from "firebase/firestore";
import { checkPermission } from "@/utils/permissionVerify";
import { getProposal } from "@/utils/proposals";
import { accountsApi } from "@/utils/apis/accountsApi";

export default {
  components: {
    ForwardDialog,
  },
  data() {
    return {
      emailPwd: "",
      email: "",
      password: "",
      passwordCreation: "",
      confirmPassword: "",
      account: null,
      toggleShareDialog: false,
      error: false,
      showPassword: false,
      showPasswordCreation: false,
      showConfirmPassword: false,
      loading: false,
      stepType: 0,
      dialogStatus: true,
      rfp: null,
      accountID: null,
      isRfp: null,
    };
  },
  computed: {
    valid() {
      return this.email.length > 0 && this.password.length >= 6;
    },
    validCreation() {
      return this.email.length > 0 && this.passwordCreation.length >= 6 && this.passwordCreation === this.confirmPassword;
    },
    validPwd() {
      return this.emailPwd.length > 0;
    },
  },
  async mounted() {
    document.title = `Launchpad - Crewfare`;
    if (this.$route.query.accountId)
      this.accountID = this.$route.query.accountId;
    if (this.$route.params.rfp) {
      this.isRfp = true;
    } else {
      this.isRfp = false;
    }
    if (this.$route.query.accountId) {
      this.getAccount();
    }
    if (!this.accountID) this.stepType = 1;
  },
  methods: {
    closeDialog() {
      this.toggleShareDialog = false;
    },
    doForgot() {
      this.loading = true;
      this.error = false;
      this.message = "";
      sendPasswordResetEmail(auth, this.emailPwd)
        .then(() => {
          this.done = true;
          this.loading = false;
          this.message =
            "You should have received an email to set a new password, please check your spam.";
        })
        .catch(() => {
          this.loading = false;
          this.message =
            "Something went wrong requesting a password remember, try again later";
          this.error = true;
        });
    },
    async getAccount() {
      const account = await accountsApi.get(this.$route.query.accountId)
      if(!account.error) {
        this.account = account.data;
        this.email = this.account.email.toLowerCase().trim();
        if (!this.account.owner) {
          this.stepType = 2;
        } else {
          this.stepType = 1;
        }
      }

    },
    async doCreateAccount() {
      this.loading = true;
      this.error = false;
      this.message = "";
      const created = await accountsApi.createPassword({
        email: this.email.toLowerCase().trim(),
        password: this.passwordCreation,
        accountId: this.accountID,
      })
      console.log("Created", created);
      if(created.error) {
        this.loading = false;
        this.message = created.message;
        this.error = true;
      } else {
        this.password = this.passwordCreation;
        this.doLogin();
      }
    },
    doLogin() {
      this.loading = true;
      this.error = false;
      this.message = "";
      signInWithEmailAndPassword(
        auth,
        this.email.toLowerCase().trim(),
        this.password
      )
        .then(async (result) => {
        })
        .catch((e) => {
          this.loading = false;
          this.message = "Account not found or incorrect password";
          this.error = true;
        });
    },
  },
};
</script>
