import List from './List.vue';
import Dashboard from '@/layouts/Dashboard.vue';

export const notifications = [
  {
    path: '/notifications',
    name: 'notifications',
    component: Dashboard,
    children: [
      {
        path: '',
        name: 'notificationsList',
        component: List,
      },
    ],
  },
];
