<template>
  <div>
    <button
      @click="showDialog = true"
      class="text-xs relative px-4 font-normal print:px-0 text-crewfareGreen border rounded border-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900 py-1"
    >
      View Contract History
    </button>
    <div
      v-if="showDialog"
      class="fixed bottom-0 right-0 z-[200] rounded-lg left-0 top-0 bg-gray-300 bg-opacity-40 flex items-center justify-center"
    >
      <div
        class="fixed top-0 right-0 left-0 bottom-0"
        @click="showDialog = false"
      ></div>
      <div class="overlay">
        <div
          class="bg-gray-900 shadow-md max-w-[470px] rounded-lg flex min-w-[598px] flex-col gap-4 items-center p-4 relative text-gray-200"
        >
          <div class="flex justify-between items-center gap-4 w-full">
            <p class="text-2xl">Status History</p>
            <span
              @click="showDialog = false"
              class="cursor-pointer top-2 right-2 text-red-200 hover:text-red-400 material-symbols-rounded"
            >
              close
            </span>
          </div>
          <div class="w-full max-h-[400px] overflow-auto pl-4">
            <FwbTimeline>
              <ContractInternalHistoryItem
                v-for="(item, index) in contractHistory"
                :key="index"
                :item="item"
                @contractRecovered="contractRecovered"
              />
            </FwbTimeline>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FwbTimeline } from "flowbite-vue";
import ContractInternalHistoryItem from "./ContractInternalHistoryItem.vue";

export default {
  props: ["contract_history"],
  components: {
    FwbTimeline,
    ContractInternalHistoryItem,
  },
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    contractHistory() {
      return this.contract_history.reverse();
    },
  },
  methods: {
    contractRecovered() {
      this.$emit("get_data");
    },
  },
};
</script>
