<template>
  <div>
    <div class="fixed top-0 right-0 left-0 bottom-0" @click="closeDialog"></div>
    <div class="overlay">
      <div
        class="bg-gray-900 shadow-md rounded-lg flex min-w-[598px] flex-col gap-4 items-center p-4 relative text-gray-200"
      >
        <div class="flex justify-between items-center gap-4 w-full">
          <p class="text-2xl">RFP Numbers</p>
          <span
            @click="closeDialog"
            class="cursor-pointer top-2 right-2 text-red-200 hover:text-red-400 material-symbols-rounded"
          >
            close
          </span>
        </div>

        <div class="flex flex-col gap-2 w-full">
          <div class="flex justify-center text-center">
            <div v-if="group_status.new > 0" class="flex flex-col gap-2 px-4">
              <strong class="text-xl">{{ group_status.new }}</strong>
              <div class="text-sm">Newly Added Hotels</div>
            </div>
            <div v-if="group_status.sent > 0" class="flex flex-col gap-2 px-4">
              <strong class="text-xl">{{ group_status.sent }}</strong>
              <div class="text-sm">Sent RFP</div>
            </div>
            <div
              v-if="group_status.negotiation > 0"
              class="flex flex-col gap-2 px-4"
            >
              <strong class="text-xl">{{ group_status.negotiation }}</strong>
              <div class="text-sm">Negotiation</div>
            </div>
            <div
              v-if="group_status.accepted > 0"
              class="flex flex-col gap-2 px-4"
            >
              <strong class="text-xl">{{ group_status.accepted }}</strong>
              <div class="text-sm">Accepted</div>
            </div>
            <div
              v-if="group_status.signed > 0"
              class="flex flex-col gap-2 px-4"
            >
              <strong class="text-xl">{{ group_status.signed }}</strong>
              <div class="text-sm">Signed</div>
            </div>
            <div
              v-if="group_status.declined > 0"
              class="flex flex-col gap-2 px-4"
            >
              <strong class="text-xl">{{ group_status.declined }}</strong>
              <div class="text-sm">Declined</div>
            </div>
          </div>
          <div class="bg-gray-700 h-[6px] flex border border-gray-700">
            <div
              v-if="group_status.new > 0"
              class="bg-white relative flex justify-center group"
              :style="{ width: `${(group_status.new / total) * 100}%` }"
            >
              <div
                class="absolute px-4 py-1 hidden group-hover:block text-xs -top-[18px] rounded-lg bg-white text-gray-900 whitespace-nowrap"
              >
                {{ group_status.new }} Newly Added Hotels
              </div>
            </div>
            <div
              v-if="group_status.sent > 0"
              class="bg-black relative flex justify-center group"
              :style="{ width: `${(group_status.sent / total) * 100}%` }"
            >
              <div
                class="absolute px-4 py-1 text-xs hidden group-hover:block -top-[28px] rounded-lg bg-white text-gray-900 whitespace-nowrap"
              >
                {{ group_status.sent }} Sent RFPs
              </div>
            </div>
            <div
              v-if="group_status.negotiation > 0"
              class="bg-crewfareBlue relative flex justify-center group"
              :style="{ width: `${(group_status.negotiation / total) * 100}%` }"
            >
              <div
                class="absolute px-4 py-1 text-xs hidden group-hover:block -top-[28px] rounded-lg bg-white text-gray-900 whitespace-nowrap"
              >
                {{ group_status.negotiation }} Negotiations
              </div>
            </div>
            <div
              v-if="group_status.accepted > 0"
              class="bg-sky-400 relative flex justify-center group"
              :style="{ width: `${(group_status.accepted / total) * 100}%` }"
            >
              <div
                class="absolute px-4 py-1 text-xs hidden group-hover:block -top-[28px] rounded-lg bg-white text-gray-900 whitespace-nowrap"
              >
                {{ group_status.accepted }} Accepteds
              </div>
            </div>
            <div
              v-if="group_status.signed > 0"
              class="bg-crewfareGreen relative flex justify-center group"
              :style="{ width: `${(group_status.signed / total) * 100}%` }"
            >
              <div
                class="absolute px-4 py-1 text-xs hidden group-hover:block -top-[28px] rounded-lg bg-white text-gray-900 whitespace-nowrap"
              >
                {{ group_status.signed }} Signeds
              </div>
            </div>
            <div
              v-if="group_status.declined > 0"
              class="bg-red-600 relative flex justify-center group"
              :style="{ width: `${(group_status.declined / total) * 100}%` }"
            >
              <div
                class="absolute px-4 py-1 text-xs hidden group-hover:block -top-[28px] rounded-lg bg-white text-gray-900 whitespace-nowrap"
              >
                {{ group_status.declined }} Declineds
              </div>
            </div>
          </div>
          <div v-if="group_status.signed > 0" class="border-t mt-4 pt-4">
            <p class="text-xl mt-4 mb-2">Signed Rooms</p>
            <table
              class="w-full px-4 justify-between py-2 border border-gray-700 border-x-0 border-t-0 text-white"
            >
              <thead>
                <tr class="border-b text-left">
                  <th class="py-2 px-4 text-right">Date</th>
                  <th class="py-2 px-4 text-center">Rooms</th>
                  <th class="py-2 px-4 text-center">Totals</th>
                </tr>
              </thead>
              <tbody class="divide-y px-4 divide-gray-700">
                <tr v-for="(room, date) in rooms" :key="date">
                  <td class="py-2 text-right px-4">{{ date }}</td>
                  <td class="py-2 flex gap-4 px-4">
                    <span v-for="(amount, type) in room" :key="type">
                      <span>{{ type }} - {{ amount }}</span>
                    </span>
                  </td>
                  <td class="text-right font-bold px-4">
                    {{ getTotalPerDate(room) }}
                  </td>
                </tr>
                <tr>
                  <td class="py-2 text-right px-4">Totals</td>
                  <td class="py-2 flex gap-4 px-4">
                    <span v-for="(room, type) in totals" :key="type">
                      <span>{{ type }} - {{ room }}</span>
                    </span>
                  </td>
                  <td class="text-right font-bold px-4">
                    {{ getTotal(totals) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["signedHotelsList", "group_status", "total"],
  data() {
    return {
      rooms: [],
      totals: [],
    };
  },
  mounted() {
    if (this.signedHotelsList[0]) {
      const dates = new Set(
        this.signedHotelsList[0].data().roomList.map((hotel) => hotel.date)
      );
      const roomByDate = {};
      const totals = {};
      this.signedHotelsList.map((signedHotel) => {
        dates.forEach((date) => {
          const roomDate = signedHotel
            .data()
            .roomList.filter((hotel) => hotel.date === date);
          roomDate.map((data) => {
            if (!roomByDate[data.date]) roomByDate[data.date] = {};
            data.rooms.forEach((room) => {
              if (!totals[room.type]) totals[room.type] = 0;
              if (!roomByDate[data.date][room.type])
                roomByDate[data.date][room.type] = 0;
              roomByDate[data.date][room.type] += parseInt(room.amount || 0);
              totals[room.type] += parseInt(room.amount || 0);
            });
          });
        });
      });
      this.rooms = roomByDate;
      this.totals = totals;
    }
  },
  methods: {
    getTotalPerDate(rooms) {
      let total = 0;
      Object.keys(rooms).forEach((room) => (total += rooms[room]));
      return total;
    },
    getTotal(totals) {
      let total = 0;
      Object.keys(totals).forEach((room) => (total += totals[room]));
      return total;
    },
    closeDialog() {
      this.$emit("hideGroupDialog");
    },
  },
};
</script>
