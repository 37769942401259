<template>
  <div
    v-if="rfp && hotel && account"
    class="bg-gray-800 w-full pb-4 flex flex-col gap-4 stage-height print:text-black"
  >
    <InternalContract
      v-if="contractMethod === 'internal'"
      @toggleContractMethod="toggleContractMethod"
    />
    <ExternalContract
      v-if="contractMethod === 'external'"
      @toggleContractMethod="toggleContractMethod"
    />
  </div>
</template>

<script>
import router from "@/router";
import { firestore } from "@/utils/firebase";
import {
  doc,
  getDoc,
  query,
  where,
  collection,
  getDocs,
  addDoc,
  setDoc,
} from "firebase/firestore";
import { InternalContract, ExternalContract } from "@/components/rfp/contract";
import moment from "moment";
import { getHotelsIdByChain } from "@/utils/hotel.jsx";

export default {
  components: {
    InternalContract,
    ExternalContract,
  },
  data() {
    return {
      contractMethod: "internal",
      showMessage: true,
      savingFields: false,
      error: false,
      message: null,
      rfp: null,
      hotel: null,
    };
  },
  computed: {
    isInternal() {
      if (!this.account) return;
      return ["admin", "internal"].includes(
        this.account.type.toLowerCase()
      );
    },
    isNSO() {
      if (!this.account) return;
      return ["nso"].includes(
        this.account.type.toLowerCase()
      );
    },
    hasBeenSigned() {
      if (!this.rfp.data().signed) return false;
      if (this.isHotel) return this.rfp.data().signed.includes("hotel");
      else return this.rfp.data().signed.includes("crewfare");
    },
    cutoffDays() {
      const date = moment(this.rfp.data().startDate.toDate());
      const cutoff = moment(this.rfp.data().cutoffDate.toDate());
      const diff = date.diff(cutoff);
      const duration = moment.duration(diff);
      return Math.floor(duration.asDays());
    },
    isSigned() {
      if (this.isHotel) {
        return this.rfp.data().status.toLowerCase() === "signed by the hotel";
      } else {
        return this.rfp.data().status.toLowerCase() === "signed by crewfare";
      }
    },
    isFullySigned() {
      return this.signed.length === 2;
    },
    isValid() {
      if (this.isHotel) {
        return (
          this.hotel_name.length > 0 &&
          this.hotel_person_name.length > 0 &&
          this.hotel_address.length > 0 &&
          this.hotel_email.length > 0 &&
          this.hotel_telephone.length > 0 &&
          this.hotel_title.length > 0 &&
          this.hotel_date.length > 0 &&
          this.hotel_taxes.length > 0
        );
      } else {
        return (
          this.crewfare_date.length > 0 &&
          this.crewfare_name.length > 0 &&
          this.crewfare_title.length > 0 &&
          this.crewfare_members.length > 0
        );
      }
    },
    isCrewfare() {
      if (!this.account) return;
      return ["internal", "admin"].includes(
        this.account.type.toLowerCase
      );
    },
    isHotel() {
      if (!this.account) return;
      return ["contact"].includes(this.account.type.toLowerCase);
    },
    hasEmptyField() {
      if (this.isHotel) {
        return (
          !this.rfp.data().hotel_person_name ||
          !this.rfp.data().hotel_address ||
          !this.rfp.data().hotel_email ||
          !this.rfp.data().hotel_title ||
          !this.rfp.data().hotel_taxes
        );
      }
      if (this.isCrewfare) {
        return !this.rfp.data().crewfare_members;
      }
      return false;
    },
    isValidEmpty() {
      if (this.isHotel) {
        return (
          this.hotel_person_name.length < 1 ||
          this.hotel_address.length < 1 ||
          this.hotel_email.length < 1 ||
          this.hotel_title.length < 1 ||
          this.hotel_taxes.length < 1
        );
      }
      if (this.isCrewfare) {
        return this.crewfare_members.length < 1;
      }
      return false;
    },
    types() {
      const types = [];
      this.rfp.data().roomList.forEach((day) => {
        day.rooms.forEach((room) => {
          if (!types.includes(room.type)) {
            types.push(room.type);
          }
        });
      });
      return types;
    },
    rooms() {
      const roomsList = [];
      this.rfp.data().roomList.forEach((day) => {
        roomsList.push({
          day: day.date,
          ...day.rooms,
        });
      });
      return roomsList;
    },
    endDateFormated() {
      if (!this.rfp.data().endDate) return "";
      const date = this.rfp.data().endDate;
      return moment(date.toDate()).format("MM-DD-YYYY");
    },
    startDateFormated() {
      if (!this.rfp.data().startDate) return "";
      const date = this.rfp.data().startDate;
      return moment(date.toDate()).format("MM-DD-YYYY");
    },
    effectiveDateFormated() {
      if (!this.rfp.data().effective_date) return "";
      const date = this.rfp.data().effective_date;
      return moment(date.toDate()).format("MM-DD-YYYY");
    },
    account() {
      return this.$store.state.account;
    },
  },
  async mounted() {
    this.getData();
    document.title = `Launchpad - Crewfare - RFP - Contract`;
  },
  methods: {
    async toggleContractMethod() {
      this.contractMethod =
        this.contractMethod === "internal" ? "external" : "internal";
      const data = this.rfp.data();
      data.contractMethod = this.contractMethod;
      await setDoc(doc(firestore, "rfp_hotel", this.rfp.id), data);
    },
    async getData() {
      const rfpRef = doc(firestore, "rfp_hotel", this.$route.params.rfp);
      const rfp = await getDoc(rfpRef);

      if (this.isNSO) {
        const hotel_ids = await getHotelsIdByChain();
        if (!hotel_ids.includes(rfp.data().hotel_id)) {
          this.$router.push("/rfps");
          return;
        }
      }

      if (this.isHotel) {
        let qContacts = query(
          collection(firestore, "contacts"),
          where("hotel_id", "==", rfp.data().hotel_id)
        );
        const contacts = await getDocs(qContacts);
        const contacts_account_ids = contacts.docs.map(
          (contact) => contact.data().account_id
        );
        if (!contacts_account_ids.includes(this.account.id)) {
          this.$router.push("/rfps");
          return;
        }
      }
      this.rfp = rfp;

      const hotelRef = doc(firestore, "hotels", this.rfp.data().hotel_id);
      this.hotel = await getDoc(hotelRef);
      this.contractMethod = this.rfp.data().contractMethod || "internal";
    },
  },
};
</script>
