<template>
  <div
    v-if="message && rfp"
    class="bg-gray-900 w-full p-4 flex flex-col gap-4 stage-height"
  >
    <div class="text-xl text-white gap-4 flex items-center justify-between">
      <div class="flex items-center gap-4">
        <router-link
          :to="{ name: 'messagesChat', params: { id: message.id } }"
          class="material-symbols-rounded hover:text-crewfareGreen"
        >
          chevron_left
        </router-link>
        {{ rfp.data().name }} - Files
      </div>
    </div>
    <div
      v-if="files.length > 0"
      class="flex flex-col gap-2 bg-gray-800 rounded pb-4 relative"
    >
      <div
        class="flex px-4 items-center justify-between sticky top-0 py-2 bg-gray-800"
      >
        <div class="flex gap-2 items-center">
          <div class="text-crewfareGreen">
            <strong class="rounded-full bg-black px-3 py-1">{{
              filesFound
            }}</strong>
            Files
          </div>
        </div>
      </div>
      <div>
        <div
          class="grid grid-cols-4 px-4 flex justify-between py-2 border border-gray-700 border-x-0 border-t-0"
        >
          <div class="flex gap-4 group text-white">Name</div>
          <div class="flex gap-4 group text-white">Sent By</div>
          <div class="flex gap-4 group text-white">Created At</div>
          <div class="flex gap-4 justify-end"></div>
        </div>
        <div class="divide-y divide-gray-700">
          <ListItemFile
            v-for="file in files"
            :key="file.id"
            :file="file"
            @deleteFile="deleteFile"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <p class="text-white text-center my-6">
        There are no files yet, you can go back
        <router-link
          :to="{ name: 'messagesChat', params: { id: message.id } }"
          class="underline"
        >
          clicking here
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
import { firestore } from "@/utils/firebase";
import {
  getDocs,
  query,
  collection,
  doc,
  getDoc,
  where,
} from "firebase/firestore";
import ListItemFile from "@/components/messages/files/ListItemFile.vue";

export default {
  components: {
    ListItemFile,
  },
  data() {
    return {
      files: [],
      message: null,
      rfp: null,
    };
  },
  computed: {
    filesFound() {
      return this.files.length || 0;
    },
  },
  async mounted() {
    const messageRef = doc(firestore, "messages", this.$route.params.id);
    this.message = await getDoc(messageRef);
    const rfpRef = doc(firestore, "rfps", this.message.data().rfp);
    this.rfp = await getDoc(rfpRef);
    this.getData();
  },
  methods: {
    async getData() {
      let qFiles = query(
        collection(firestore, "files"),
        where("message_id", "==", this.$route.params.id)
      );
      const files = await getDocs(qFiles);
      const order = files.docs.sort(
        (a, b) =>
          Number(a.data().created_at.toDate()) <
          Number(b.data().created_at.toDate())
      );
      this.files = order;
    },
  },
};
</script>
