<template>
  <div class="flex flex-col">
    <div class="border-t pt-4">
      <p class="font-bold">Contacts:</p>
      <div class="divide-y">
        <ContactLine
          v-for="(contact, index) in contacts"
          :key="index"
          :hotel_id="hotel?.id"
          :contact="contact"
          @removeContact="removeContact(index)"
        />
      </div>
    </div>
    <div class="flex justify-end border-b pb-4 mb-4">
      <span @click="addContact" class="green-button-small"> + Contact </span>
    </div>
  </div>
</template>

<script>
import ContactLine from "./ContactLine.vue";

export default {
  props: ["contacts", "hotel"],
  components: {
    ContactLine,
  },
  methods: {
    removeContact(index) {
      this.contacts.splice(index, 1);
    },
    addContact() {
      this.contacts.push({});
    },
  },
};
</script>
