<template>
  <div
    class="w-full h-full border border-crewfareBlue rounded-xl border-dashed flex flex-col justify-center items-center gap-2.5 p-7 cursor-pointer relative"
  >
    <div class="font-semibold text-xl">{{ title || 'Drag & Drop Upload File or Click to Browse' }}</div>
    <div class="font-normal text-base">{{ subtitle }}</div>
    <Button :passedFunction="passedFunction" :onDropFunction="onDropFunction">{{ buttonText || 'Select File' }}</Button>
    <slot></slot>
  </div>
</template>

<script>
import Button from '@/components/ui/atoms/Button.vue';

export default {
  name: 'DragAndDrop',
  props: {
    title: String,
    subtitle: String,
    buttonText: String,
    passedFunction: Function,
    onDropFunction: Function,
  },
  components: {
    Button,
  },
};
</script>
