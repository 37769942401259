<template>
  <div
    class="animate-pulse border rounded border-gray-600 px-4 mt-[45px] flex flex-col text-white"
  >
    <div class="flex justify-center -mt-[45px]">
      <div
        class="h-[90px] w-[90px] rounded-full overflow-hidden bg-gray-500"
      ></div>
    </div>
    <div class="flex flex-col flex-grow gap-4 my-4">
      <div class="text-center flex justify-center">
        <p class="w-[100px] h-[10px] bg-gray-500 rounded"></p>
      </div>
      <div class="text-center flex flex-col gap-1 items-center">
        <p class="text-xs">Main Contact</p>
        <p class="w-[150px] h-[16px] rounded bg-gray-500"></p>
        <p class="w-[170px] h-[16px] rounded bg-gray-500"></p>
      </div>
    </div>

    <div class="flex gap-4 justify-between mb-4">
      <span
        class="rounded flex items-center justify-center h-[36px] bg-gray-500 w-full px-4"
      >
      </span>
      <span
        class="h-[36px] w-[52px] bg-gray-500 flex items-center px-4 rounded py-2 px-4"
      >
      </span>
      <span
        class="h-[36px] w-[52px] bg-gray-500 flex items-center px-4 rounded py-2 px-4"
      >
      </span>
    </div>
  </div>
</template>
