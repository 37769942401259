<template>
  <div>
    <button
      @click="createDialogToggle = true"
      class="px-4 border border-crewfareGreen py-2 rounded-full text-crewfareGreen hover:bg-crewfareGreen hover:text-black"
    >
      Create RFP
    </button>
    <div
      v-if="createDialogToggle"
      class="fixed bottom-0 right-0 z-[150] left-0 top-0 bg-gray-300 bg-opacity-40 flex items-center justify-center"
    >
      <div
        class="bg-gray-900 rounded min-w-[600px] flex flex-col gap-4 items-center p-4 relative text-white"
      >
        <div class="flex w-full flex-col gap-4">
          <div class="flex w-full justify-between gap-4">
            <p class="text-xl">Create New RFP</p>
            <span
              @click="createDialogToggle = false"
              class="cursor-pointer text-red-400 material-symbols-rounded"
            >
              close
            </span>
          </div>
          <div class="overflow-y-auto max-h-[500px] flex justify-around">
            <button
              @click="createRFP"
              class="px-4 border border-crewfareGreen py-2 rounded-full text-crewfareGreen hover:bg-crewfareGreen hover:text-black"
            >
            <span
                v-if="loading"
                class="material-symbols-rounded animate-spin text-[12px]"
              >
                autorenew
              </span>
              <span v-else>New RFP</span>
              
            </button>
            <button
              @click="toggleTable = !toggleTable"
              class="px-4 border border-crewfareGreen py-2 rounded-full text-crewfareGreen hover:bg-crewfareGreen hover:text-black"
              :class="toggleTable && 'green-button-active'"
            >
              Copy Existing RFP
            </button>
          </div>
          <div
            v-if="toggleTable"
            class="overflow-y-auto max-h-[500px] flex flex-col"
          >
            <DuplicateRFPList />
          </div>
          <div class="flex flex-col gap-2">
            <div class="flex justify-end items-center">
              <button
                class="text-red-400 hover:text-red-600"
                @click="createDialogToggle = false"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createSimpleRFP } from "@/utils/rfp.jsx";
import CreateRFPDialogListItem from "./CreateRFPDialogListItem.vue";
import DuplicateRFPList from "./DuplicateRFPList.vue";

export default {
  data() {
    return {
      toggleTable: false,
      allRfps: [],
      loading: false,
      createDialogToggle: false,
      page: 0,
      per_page: 20,
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  methods: {
    async createRFP() {
      if(this.loading) return;
      this.loading = true;
      await createSimpleRFP();
      this.loading = false;
    },
  },
  components: { CreateRFPDialogListItem, DuplicateRFPList },
};
</script>
