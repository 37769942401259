<template>
  <div v-if="rfp" class="bg-gray-800 w-full flex flex-col stage-height">
    <div class="p-4 flex justify-between bg-gray-900 items-center">
      <h1 class="text-xl text-white">Files</h1>
    </div>
    <div class="text-xl text-white gap-4 p-2 flex items-center justify-between">
      <span class="flex items-center gap-2">
        <button
          @click="$router.go(-1)"
          class="material-symbols-rounded hover:text-crewfareGreen"
        >
          chevron_left
        </button>

        {{ rfp && rfp.data().name }}
      </span>
    </div>
    <div v-if="rfps.length > 0">
      <table
        class="text-white w-full px-4 justify-between py-2 border border-gray-700 border-x-0 border-t-0"
      >
        <tr class="border-b border-gray-700">
          <th class="text-white text-left p-4">Hotel Name</th>
          <th class="text-white text-left px-4">Cuf Off Date</th>
          <th class="text-white text-left px-4">Contract</th>
          <th class="text-white text-left px-4">Travel Coordinator</th>
        </tr>
        <tbody class="divide-y divide-gray-700">
          <HotelListItem
            v-for="(rfp, index) in rfps"
            :key="index"
            :rfp="rfp"
            @getData="getData"
          />
        </tbody>
      </table>
    </div>
    <div v-else>
      <p class="text-white my-4 text-center">
        There are no RFPs at the moment.
      </p>
    </div>
  </div>
</template>

<script>
import { firestore } from "@/utils/firebase";
import {
  onSnapshot,
  doc,
  getDoc,
  getDocs,
  query,
  collection,
  where,
  orderBy,
} from "firebase/firestore";
import HotelListItem from "@/components/files/HotelListItem.vue";

export default {
  components: {
    HotelListItem,
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  data() {
    return {
      rfp: null,
      rfps: [],
    };
  },
  async mounted() {
    const hotelRef = doc(firestore, "rfps", this.$route.params.id);
    const rfp = await getDoc(hotelRef);
    this.rfp = rfp;
    this.getRfps();
  },
  methods: {
    async getRfps() {
      let qRfps = query(
        collection(firestore, "rfp_hotel"),
        where("rfp", "==", this.$route.params.id),
        where("status", "==", "Signed by both sides"),
        orderBy("updated_at", "desc")
      );
      const rfps = await getDocs(qRfps);
      this.rfps = rfps.docs;
      this.rfps.map(rfp => console.log(rfp.data()))
    },
  },
};
</script>
